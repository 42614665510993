import React, { FC, useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { Loading, MaterialIcon, UserStateComponent } from '../../components';
import { ActionType, store } from '../../services';
import { useFetcher } from '../../utils';

const OAuthLogoutComponent: FC<RouteComponentProps> = ({ location, history }) => {
  const [next, setNext] = useState<string | null>(null);

  const logout = useCallback(
    async (force = false) => {
      const params = new URLSearchParams(location.search);

      if (force) {
        await api.logout();
      } else {
        const redirect_token = params.get('redirect_token');

        if (redirect_token) {
          await api.logout({ redirect_token });
        } else {
          await api.logout({
            access_token: params.get('access_token') ?? 'notprovided',
            client_id: params.get('client_id') ?? 'notprovided',
          });
        }
      }

      const localNext = params.get('next');

      if (localNext) {
        window.location.replace(localNext);
      } else {
        store.dispatch({
          type: ActionType.Logout,
        });
      }
    },
    [location],
  );

  const logoutFetcher = useFetcher(async () => {
    return await logout();
  });

  const params = new URLSearchParams(location.search);

  if (!logoutFetcher.complete && !logoutFetcher.error) {
    return <Loading inline>Logging you out...</Loading>;
  }

  setNext(params.get('next'));

  if (logoutFetcher.complete) {
    return <Loading inline>Redirecting you {next && `to ${next}`}</Loading>;
  }

  return (
    <UserStateComponent>
      <Row className="justify-content-center">
        <Col lg={6} xs={12}>
          <Row className="justify-content-center" id="oauthLogoutError">
            <Col xs={12}>
              <Card className="warning">
                <CardBody className="text-center">
                  <MaterialIcon large name="warning" type="warning" />
                  <CardTitle>Logout Attempt</CardTitle>
                  <CardText>
                    {next ?? 'A website'} is trying to log you out, but we could not verify their
                    OAuth credentials.
                  </CardText>
                  <CardText>
                    If you trust this website, you can click 'Allow Logout'.&nbsp;
                    {next && `You will then be redirected to ${next}. `}
                    Otherwise, click 'Cancel' to return to the homepage.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col className="margin-top-10" lg={6} xs={12}>
              <Button block color="danger" onClick={async () => await logout(true)}>
                Allow Logout
              </Button>
            </Col>
            <Col className="margin-top-10" lg={6} xs={12}>
              <Button
                block
                color="secondary"
                onClick={() => {
                  history.push('/');
                }}
                outline
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </UserStateComponent>
  );
};

// Can this be removed? What is this?
const routedOAuthLogoutComponent = withRouter(OAuthLogoutComponent);
export { routedOAuthLogoutComponent as OAuthLogoutComponent };
