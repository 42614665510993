import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ProductModel } from '../../../../../shared/orders';
import { ActionButtonModal } from '../../../../components';

interface PriceInfoProps {
  readonly product: ProductModel;
  readonly onUpdate: () => void;
}

export const ProductExtraActions: FC<PriceInfoProps> = ({ product, onUpdate }) => {
  return (
    <Card>
      <CardHeader>Actions</CardHeader>
      <CardBody>
        <ActionButtonModal
          actionContent="Clear unpaid orders"
          block
          buttonContent={<>Clear unpaid orders</>}
          color="secondary"
          id="clearUnpaidOrders"
          onComplete={async () => {
            await api.clearProductFromCarts(product.id);
            toast.success('Order items removed');
            onUpdate();
          }}
          title="Clear unpaid orders?"
        >
          Are you you want to remove this product from all carts in which the payment has not yet
          been completed?
        </ActionButtonModal>
      </CardBody>
    </Card>
  );
};
