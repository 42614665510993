import React, { FC, useCallback, useState } from 'react';
import { InputGroup } from 'reactstrap';
import { Option, OptionDataValue } from '../../../shared/options';
import { PhoneInput } from '../PhoneInput';

interface OptionTextProps {
  readonly option: Option;
  readonly disabled?: boolean;
  readonly defaultValue?: OptionDataValue;
  onChange?(id: number, option: OptionDataValue): void;
}

export const OptionPhoneComponent: FC<OptionTextProps> = ({
  disabled,
  option,
  defaultValue,
  onChange,
}) => {
  const [phoneVal, setPhoneVal] = useState<string | null>((defaultValue as string) ?? null);

  const onPhoneChange = useCallback(
    (value: string) => {
      onChange?.(option.id, value);
      setPhoneVal(value);
    },
    [onChange, setPhoneVal],
  );

  return (
    <InputGroup style={{ marginBottom: '2em' }}>
      <PhoneInput disabled={disabled} onChange={onPhoneChange} value={phoneVal} />
    </InputGroup>
  );
};
