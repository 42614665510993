import React, { useEffect, useState } from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { shouldDisableHotkeys } from '../utils';

import './HotkeyButton.scss';

interface HotkeyButtonProps extends ButtonProps {
  readonly actionText: string;
  readonly hotkey: string;
  readonly onClick: () => Promise<void>;
  readonly requiredKeyPressCount?: number;
}

const convertKeyToText = (key: string) => {
  switch (key) {
    case ' ': {
      return 'SPACE BAR';
    }

    default: {
      return key.toUpperCase();
    }
  }
};

// eslint-disable-next-line import/no-unused-modules
export const HotkeyButton: React.FC<HotkeyButtonProps> = ({
  actionText,
  hotkey,
  requiredKeyPressCount = 1,
  onClick,
  children,
  ...buttonProps
}) => {
  const [keyPressCount, setKeyPressCount] = useState(0);
  const remainingKeyPresses = requiredKeyPressCount - keyPressCount;

  useEffect(() => {
    const resetTimer = setTimeout(() => {
      setKeyPressCount(0);
    }, 2000);

    const handleKeyDown = (event: KeyboardEvent) => {
      if (shouldDisableHotkeys()) {
        return;
      }

      if (event.key === hotkey) {
        if (keyPressCount + 1 !== requiredKeyPressCount) {
          setKeyPressCount(keyPressCount + 1);
          return;
        }

        clearTimeout(resetTimer);
        void onClick();
        setKeyPressCount(0);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      clearTimeout(resetTimer);
    };
  }, [keyPressCount, requiredKeyPressCount, onClick, hotkey]);

  return (
    <div className="hotkey-button">
      <Button {...buttonProps} onClick={onClick}>
        {children}
      </Button>
      {!buttonProps.disabled && (
        <div className="hotkey-explanation">
          or press <strong>{convertKeyToText(hotkey)}</strong>{' '}
          {remainingKeyPresses > 1 ? `${remainingKeyPresses} times` : '1 time'} to {actionText}.
        </div>
      )}
    </div>
  );
};
