export const charSets = {
  alphanum: '1234567890abcdefghijklmnopqrstuvwxyz',
  alphanumCaps: '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  code: 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789',
};

/**
 * Generates a cryptographically secure random string
 * of "length" formed from the characters.
 */
export function randomString(length = 32, set: string = charSets.alphanumCaps): string {
  const roof = 0x1_00 - (0x1_00 % set.length);

  const bytes = crypto.getRandomValues(new Uint8Array(length * 1.5));

  let output = '';
  for (let i = 0, l = bytes.length; i < l && output.length < length; i++) {
    if (bytes[i] < roof) {
      output += set[bytes[i] % set.length];
    }
  }

  /* istanbul ignore if */
  if (output.length < length) {
    // This is extremely unlikely to ever be executed
    return output + randomString(length - output.length, set);
  }

  return output;
}
