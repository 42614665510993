import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Badge, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { DealerStatusTypes } from '../../../../shared/dealer';
import * as OrderSummary from '../../../../shared/orders/model';
import { UserRegistration } from '../../../../shared/user/base';
import { MaterialIcon } from '../../../components';
import { DateT } from '../../../components/Date';
import { displayName } from '../../../utils';

export const OrderBadges: FC<{ readonly order: OrderSummary.Order }> = ({ order }) => {
  const badges: JSX.Element[] = [];
  const prefix = `order${order.id}`;

  badges.push(
    order.status === 'paid' ? (
      <Badge color="success" id={`${prefix}Paid`} key="paid">
        Paid in Full
      </Badge>
    ) : (
      <Badge color="danger" id={`${prefix}Unpaid`} key="unpaid">
        Unpaid
      </Badge>
    ),
  );

  return badges.length > 0 ? <>{badges}</> : null;
};

export const RegistrationAttendanceType: FC<{ readonly registration: UserRegistration }> = ({
  registration,
}) => {
  if (registration.paidOrderItem) {
    return (
      <span id="attendanceType">
        {displayName(registration.paidOrderItem)}
        <Link to={`/housekeeping/orders/${registration.paidOrderItem.orderId}`}>
          <span className="inline-icon" id="attendanceTypePaid">
            <MaterialIcon name="done" type="success" />
          </span>
          <UncontrolledPopover placement="bottom" target="attendanceTypePaid" trigger="hover">
            <PopoverBody>
              This user has an active entitlement that grants this attendance type.
            </PopoverBody>
          </UncontrolledPopover>
        </Link>
      </span>
    );
  }

  return (
    <span id="attendanceType">
      {displayName(registration.attendanceType)}
      <span className="inline-icon" id="attendanceTypeMismatch">
        <MaterialIcon name="warning" type="warning" />
      </span>
      <UncontrolledPopover placement="bottom" target="attendanceTypeMismatch" trigger="hover">
        <PopoverBody>
          <p>This user does not have an active order correlated with this registration.</p>
        </PopoverBody>
      </UncontrolledPopover>
    </span>
  );
};

export const DealerApplicationStatus: FC<{
  readonly status: DealerStatusTypes;
  readonly grant: boolean;
}> = ({ status, grant }) => {
  if (status === 'pending') {
    return <>{status}</>;
  }

  return (
    <>
      {status}
      {grant ? (
        <>
          <span className="inline-icon" id="dealerPaid">
            <MaterialIcon name="done" type="success" />
          </span>
          <UncontrolledPopover placement="bottom" target="dealerPaid" trigger="hover">
            <PopoverBody>This dealer has paid for their table.</PopoverBody>
          </UncontrolledPopover>
        </>
      ) : (
        <>
          <span className="inline-icon" id="dealerUnpaid">
            <MaterialIcon name="warning" type="warning" />
          </span>
          <UncontrolledPopover placement="bottom" target="dealerUnpaid" trigger="hover">
            <PopoverBody>
              This dealer has not yet paid for their table and any requested extras.
            </PopoverBody>
          </UncontrolledPopover>
        </>
      )}
    </>
  );
};

interface UserBadgesProps {
  readonly user: {
    id: number;
    permissions?: string[];
    pendingReview?: boolean;
    hasRestrictionNotice: boolean;
    hasBanNotice: boolean;
    isPriorYearAttendee?: boolean | null;
    bornAt: Date | null;
    registration?: Pick<UserRegistration, 'id' | 'paidOrderItem'>;
  };
  readonly showBornAt?: boolean;
}

export const UserBadges: FC<UserBadgesProps> = ({ user, showBornAt }) => {
  const badges: JSX.Element[] = [];

  if (user.permissions) {
    if (user.permissions.includes('system:staff')) {
      badges.push(
        <Badge color="primary" key="staff">
          Staff Member
        </Badge>,
      );
    }

    if (user.permissions.includes('system:staffalumni')) {
      badges.push(
        <Badge color="info" key="staffAlumni">
          Staff Alumni
        </Badge>,
      );
    }
  }

  if (user.hasBanNotice) {
    badges.push(<Badge color="danger">Banned</Badge>);
  }

  if (user.pendingReview) {
    badges.push(
      <>
        <Badge color="warning" id={`pendingReview${user.id}`} key="review">
          Pending Review
        </Badge>
        <UncontrolledPopover placement="bottom" target={`pendingReview${user.id}`} trigger="hover">
          <PopoverBody>
            This user is a possible duplicate account of a banned user. Please review their account.
          </PopoverBody>
        </UncontrolledPopover>
      </>,
    );
  }

  if (user.registration) {
    if (user.registration.paidOrderItem) {
      badges.push(
        <Badge className="registered" color="primary" key="registered">
          Registered, Paid
        </Badge>,
      );
    } else {
      badges.push(
        <Badge className="registered-not-paid" color="warning" key="registeredNotPaid">
          Registered, Not Paid
        </Badge>,
      );
    }
  } else {
    badges.push(
      <Badge className="not-registered" color="secondary" key="notRegistered">
        Not Registered
      </Badge>,
    );
  }

  if (user.isPriorYearAttendee) {
    badges.push(
      <Badge className="prior-year-attendee" color="info" key="priorYearAttendee">
        Returning Attendee
      </Badge>,
    );
  }

  if (user.hasRestrictionNotice) {
    badges.push(
      <Badge className="has-restriction-notice" color="danger" key="hasRestrictionNotice">
        Restriction
      </Badge>,
    );
  }

  if (showBornAt && user.bornAt) {
    badges.push(
      <Badge className="has-born-at" color="secondary" key="hasBornAt" title="Date of Birth">
        DoB: <DateT value={user.bornAt} />
      </Badge>,
    );
  }

  return <span className="badge-list compact">{badges}</span>;
};
