import { JSONForm } from '@conventioncatcorp/common-fe';
import React, { FC } from 'react';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { BaseUser } from '../../../../shared/user/base';
import { ScannedUserInformation } from '../../../../shared/user/cashier';
import { getDate } from '../../../components/Date';
import { useUser } from '../../../utils';

interface ProfileEditModalProps {
  readonly user: BaseUser;
  readonly isMinor?: boolean;
  readonly showAddress?: boolean;
  readonly scannedUserInformation?: ScannedUserInformation;
  complete(): void;
  close(): void;
}

export const ProfileEditModal: FC<ProfileEditModalProps> = ({
  user,
  complete,
  close,
  isMinor,
  showAddress,
  scannedUserInformation,
}) => {
  const localUser = useUser()!;

  return (
    <Modal className="modal-large" id="profileCard" isOpen>
      <JSONForm method="patch" onSuccess={complete} path={`/api/users/${user.id}`}>
        <ModalHeader>Edit User</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label>Preferred Name</Label>
                <Input readOnly value={user.preferredName ?? ''} />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label for="bornAt">Date of Birth</Label>
                <Input
                  defaultValue={user.bornAt ? getDate(new Date(user.bornAt)) : ''}
                  disabled={localUser.id === user.id}
                  invalid={isMinor}
                  name="bornAt"
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  placeholder="YYYY-MM-DD"
                  style={{ minWidth: '150px ' }}
                  type="date"
                />
                {isMinor && (
                  <FormFeedback>Attendee is a MINOR. Check for Parental Consent Form.</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  defaultValue={scannedUserInformation?.firstName ?? user.firstName}
                  name="firstName"
                />
                {scannedUserInformation?.firstName && (
                  <FormText color="muted">(Updated from {user.firstName})</FormText>
                )}
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  defaultValue={scannedUserInformation?.lastName ?? user.lastName}
                  name="lastName"
                />
                {scannedUserInformation?.firstName && (
                  <FormText color="muted">(Updated from {user.lastName})</FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          {showAddress && <UserAddressInformation user={user} />}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" id="saveProfile" type="submit">
            Update User
          </Button>
          <Button color="secondary" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};

const UserAddressInformation: FC<{ readonly user: BaseUser }> = (props) => {
  const { user } = props;

  return (
    <Row>
      <Col lg={6} md={12}>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input defaultValue={user.email} name="email" />
        </FormGroup>
      </Col>
      <Col lg={6} md={12}>
        <FormGroup>
          <Label for="phone">Phone</Label>
          <Input defaultValue={user.phone} name="phone" />
        </FormGroup>
      </Col>
      <Col lg={6} md={12}>
        <FormGroup>
          <Label for="addressLine1">Address Line 1</Label>
          <Input defaultValue={user.address?.line1} name="addressLine1" />
        </FormGroup>
      </Col>
      <Col lg={6} md={12}>
        <FormGroup>
          <Label for="addressLine2">Address Line 2</Label>
          <Input defaultValue={user.address?.line2 ?? ''} name="addressLine2" />
        </FormGroup>
      </Col>
      <Col lg={6} md={12}>
        <FormGroup>
          <Label for="addressCity">City</Label>
          <Input defaultValue={user.address?.city} name="addressCity" />
        </FormGroup>
      </Col>
      <Col lg={6} md={12}>
        <FormGroup>
          <Label for="addressState">State</Label>
          <Input defaultValue={user.address?.state} name="addressState" />
        </FormGroup>
      </Col>
      <Col lg={6} md={12}>
        <FormGroup>
          <Label for="addressZipcode">Zipcode</Label>
          <Input defaultValue={user.address?.zipcode} name="addressZipcode" />
        </FormGroup>
      </Col>
      <Col lg={6} md={12}>
        <FormGroup>
          <Label for="addressCountry">Country</Label>
          <Input defaultValue={user.address?.country} name="addressCountry" />
        </FormGroup>
      </Col>
    </Row>
  );
};
