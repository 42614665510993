import React, { FC } from 'react';
import { Badge } from 'reactstrap';
import { ProductCategoryModel } from '../../../../shared/orders/product';

interface CategoryBadgesProps {
  readonly category: ProductCategoryModel;
}

export const CategoryBadges: FC<CategoryBadgesProps> = ({ category }) => {
  const badges: JSX.Element[] = [];

  if (category.isStore) {
    badges.push(
      <Badge color="success" id="visibleEventStore" pill>
        Visible in Event Store
      </Badge>,
    );
  }

  let categoryType = 'Digital Products';
  if (category.isRegistration) {
    categoryType = 'Registration Products';
  } else if (category.isVendor) {
    categoryType = 'Vendor Products';
  } else if (!category.isDigital) {
    categoryType = 'Physical Products';
  }

  badges.push(
    <Badge color="secondary" pill>
      {categoryType}
    </Badge>,
  );

  return <div className="badge-list compact">{badges}</div>;
};
