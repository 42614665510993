import React, { FC } from 'react';
import { Card, CardBody, Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { Raffle } from '../../../../../shared/orders/raffle';

interface RaffleTextEditProps {
  readonly raffle: Raffle;
}

export const RaffleTextEdit: FC<RaffleTextEditProps> = ({ raffle }) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label for="enteredTitle">Entry confirmation title</Label>
              <Input defaultValue={raffle.enteredTitle} id="enteredTitle" name="enteredTitle" />
              <FormText color="muted">
                This will appear in place of the "Added to Cart" text when the user has entered the
                raffle.
              </FormText>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="enteredSubtext">Entry confirmation body</Label>
              <Input
                defaultValue={raffle.enteredSubtext}
                id="enteredSubtext"
                name="enteredSubtext"
                rows={15}
                type="textarea"
              />
              <FormText color="muted">
                This will appear in place of the "Added to Cart" text when the user has entered the
                silo. <strong>This field supports Markdown syntax.</strong>
              </FormText>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="notEnteredText">Not yet entered message</Label>
              <Input
                defaultValue={raffle.notEnteredText}
                id="notEnteredText"
                name="notEnteredText"
              />
              <FormText color="muted">
                This will be shown to the user when they have created a registration but not yet
                entered the raffle.
              </FormText>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="rescindedText">Entry rescinded message</Label>
              <Input defaultValue={raffle.rescindedText} id="rescindedText" name="rescindedText" />
              <FormText color="muted">
                This will be shown to the user when their entry has been rescinded.
              </FormText>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="winnerText">Entry winner message</Label>
              <Input defaultValue={raffle.winnerText} id="winnerText" name="winnerText" />
              <FormText color="muted">
                This will be shown to the user when their ticket has been drawn as a winner.
              </FormText>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="waitingDrawnText">Entry lost message</Label>
              <Input
                defaultValue={raffle.waitingDrawnText}
                id="waitingDrawnText"
                name="waitingDrawnText"
              />
              <FormText color="muted">
                This will be shown to the user when the raffle has been drawn, but their ticket was
                not a winner.
              </FormText>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="waitingOpenText">Raffle open message</Label>
              <Input
                defaultValue={raffle.waitingOpenText}
                id="waitingOpenText"
                name="waitingOpenText"
              />
              <FormText color="muted">
                This will be shown to the user when the raffle is still accepting entries and has
                not yet been drawn.
              </FormText>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="waitingClosedText">Raffle pending draw message</Label>
              <Input
                defaultValue={raffle.waitingClosedText}
                id="waitingClosedText"
                name="waitingClosedText"
              />
              <FormText color="muted">
                This will be shown to the user when the raffle is no longer open for entry, but has
                not yet been drawn.
              </FormText>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
