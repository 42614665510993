import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, {
  ChangeEventHandler,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import {
  Department,
  VolunteerDepartmentModel,
  VolunteerModel,
  VolunteerOptionInput,
} from '../../../../shared/volunteer';
import {
  LoadingError,
  MaterialIcon,
  MessageCard,
  PageHeader,
  ProductOptionRenderer,
  ProductOptionViewer,
  TermsLoader,
} from '../../../components';
import {
  capitalize,
  Fetcher,
  isAccessError,
  isLogicError,
  isResourceError,
  omit,
  useConfig,
  useConvention,
  useFetcher,
} from '../../../utils';
import { LoadingState } from '../../../utils/LoadingState';
import { captureError, LogicError } from '../../../utils/errorHandling';
import { FelonyDialog } from './felonyDialog';
import { VolunteerDepartments } from './volunteerdepartments';

interface VolunteerFormComponent2Props {
  readonly isAdmin: boolean;
  readonly user: {
    id: number;
    phone?: string;
    email?: string;
  };
}

function getAssignedDepartments(volunteer?: VolunteerModel): VolunteerDepartmentModel[] {
  if (!volunteer) {
    return [];
  }

  return volunteer.departments.filter((t) => t.states.includes('assignment'));
}

export const VolunteerFormComponent: FC<VolunteerFormComponent2Props> = ({ isAdmin, user }) => {
  const history = useHistory();
  const config = useConfig();
  const { enableVolunteers } = useConvention();

  const [contactMethods, setContactMethods] = useState<Record<string, string>>({});
  const [showFelonyDialog, setShowFelonyDialog] = useState(false);
  const [requestFelonyBypass, setRequestFelonyBypass] = useState(false);

  const request = useFetcher(async () => {
    let volunteer: VolunteerModel | undefined;

    try {
      volunteer = await api.getUserVolunteer(user.id);
    } catch (error) {
      if (!isResourceError(error)) {
        throw error;
      }
    }

    const form = await api.getVolunteerForm();

    return {
      departments: form.departments,
      options: form.options,
      volunteer,
      methodList: form.contactMethods,
    };
  });

  useEffect(() => {
    if (request.data?.volunteer) {
      setContactMethods(request.data.volunteer.contactMethods);
    }
  }, [request.data?.volunteer]);

  const onSubmit = useCallback(() => {
    toast.success('Your volunteer application has been saved!');
    request.refresh();
  }, [request.refresh]);

  const deleteApplication = useCallback(() => {
    api
      .deleteVolunteer(request.data!.volunteer!.id)
      .then(() => {
        toast.success('Your volunteer application has been deleted.');
        history.push('/');
      })
      .catch((error: Error) => {
        if (!isLogicError(error)) {
          captureError(error);
          return;
        }

        toast.error(
          `You are currently assigned to a department. Please contact ${config.contact.email.hr}
          if you no longer wish to volunteer.`,
        );
      });
  }, [user.id, request.data]);

  const onSubmitFailure = useCallback((err: Error) => {
    if (isLogicError(err, LogicError.VolunteerFelonyRestrictionBypass)) {
      setShowFelonyDialog(true);
      return true;
    }

    return false;
  }, []);

  const onFelonyDialogClose = useCallback((accepted: boolean) => {
    setShowFelonyDialog(false);
    setRequestFelonyBypass(accepted);

    if (accepted) {
      setTimeout(() => {
        document.getElementById('submitApplication')?.click();
      }, 1000);
    }
  }, []);

  if (!enableVolunteers && !isAdmin) {
    return (
      <MessageCard icon="remove_circle" id="dealerClosed" level="danger">
        Volunteer applications for the convention is currently closed.
      </MessageCard>
    );
  }

  if (request.error) {
    return <VolunteerLoadError error={request.error as Error} />;
  }

  if (request.state === LoadingState.Loading) {
    return <Fetcher result={request} />;
  }

  const { departments, volunteer, options, methodList } = request.data!;
  const hasVolunteerApp = !!volunteer;
  const anythingElse = options.find((t) => t.internalName === 'anythingElse');

  return (
    <>
      {showFelonyDialog && <FelonyDialog onClose={onFelonyDialogClose} />}
      <PageHeader>Volunteer Application</PageHeader>
      <JSONForm<undefined, { bypassFelonyPolicy: boolean; contactMethods: Record<string, string> }>
        id="volunteerForm"
        method="post"
        onFailure={onSubmitFailure}
        onSuccess={onSubmit}
        path={`/api/users/${user.id}/volunteer`}
        preSubmit={({ inputs }) => {
          inputs!.contactMethods = contactMethods;
          inputs!.bypassFelonyPolicy = requestFelonyBypass;
        }}
      >
        <Row className="justify-content-center">
          <VolunteerStatus volunteer={volunteer} />
          <VolunteerTerms
            felonyBypass={requestFelonyBypass}
            hasVolunteerApp={hasVolunteerApp}
            volunteer={volunteer}
          />
          <Col xs={12} />
          <VolunteerContactInfo
            contactMethod={volunteer?.contactMethod ?? undefined}
            contactMethods={contactMethods}
            methodList={methodList}
            setContactMethods={setContactMethods}
            user={user}
          />
          <VolunteerAvailability
            isApproved={getAssignedDepartments(volunteer).length > 0}
            options={options}
            volunteer={volunteer}
          />
          {departments.length > 0 && (
            <DepartmentsAndExperience departments={departments} volunteer={volunteer} />
          )}
          <VolunteerPreviousExperience options={options} volunteer={volunteer} />
          <Col className="margin-bottom-10" lg={8} xs={12}>
            <Card>
              <CardBody>
                <CardTitle>Anything Else</CardTitle>
                <CardText tag="div">
                  {anythingElse && (
                    <ProductOptionViewer
                      defaultValue={volunteer?.options[anythingElse.id]}
                      option={anythingElse}
                    />
                  )}
                  <OtherVolunteerOptions options={options} volunteer={volunteer} />
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col className="margin-top-10" lg={6} xs={12}>
            <Button block color="primary" id="submitApplication" type="submit">
              Submit Application
            </Button>
          </Col>
          <Col xs={12} />
          {volunteer && (
            <Col className="margin-top-10" lg={6} xs={12}>
              <Button
                block
                color="danger"
                id="deleteApplication"
                onClick={deleteApplication}
                type="button"
              >
                Delete Application
              </Button>
            </Col>
          )}
        </Row>
      </JSONForm>
    </>
  );
};

const VolunteerStatus: FC<{ readonly volunteer?: VolunteerModel }> = ({ volunteer }) => {
  const { contact } = useConfig();
  if (!volunteer) {
    return null;
  }

  const assignments = getAssignedDepartments(volunteer);

  if (assignments.length > 0) {
    return (
      <Col lg={8} xs={12}>
        <Alert color="success" id="volunteer-approved">
          <CardTitle>Your application has been approved!</CardTitle>
          You have been approved as a volunteer for this year and are assigned to:
          <ul className="margin-top-5 margin-bottom-5">
            {assignments.map((dept) => (
              <li key={dept.id}>
                <strong>{dept.name}</strong>
              </li>
            ))}
          </ul>
          If you are unhappy with your assignment(s), simply email{' '}
          <strong>{contact.email.hr}</strong> and we'll work something out.
        </Alert>
      </Col>
    );
  }

  return (
    <Col lg={8} xs={12}>
      <Alert color="warning" id="volunteer-pending">
        <CardTitle>Your application is awaiting assignment.</CardTitle>
        Our HR department will review your application and use this to assign you to a department
        before the convention.
      </Alert>
    </Col>
  );
};

interface ContactMethodInput {
  name: string;
  input?: {
    value: string;
    update: ChangeEventHandler<HTMLInputElement>;
  };
  displayText?: string;
}

interface VolunteerContactInfoProps {
  readonly methodList: string[];
  readonly contactMethod?: string;
  readonly contactMethods: Record<string, string>;
  readonly user: { phone?: string; email?: string };
  readonly setContactMethods: Dispatch<SetStateAction<Record<string, string>>>;
}

const VolunteerContactInfo: FC<VolunteerContactInfoProps> = ({
  methodList,
  contactMethod,
  user,
  contactMethods,
  setContactMethods,
}) => {
  const methods: ContactMethodInput[] = [
    { name: 'call', displayText: user.phone },
    { name: 'text', displayText: user.phone },
    { name: 'email', displayText: user.email },
  ];

  const update = useCallback(
    (name: string, value: string) => {
      setContactMethods((old) => {
        if (!value) {
          return omit(old, name);
        }

        return {
          ...old,
          [name]: value || undefined,
        };
      });
    },
    [setContactMethods],
  );

  for (const method of methodList) {
    methods.push({
      input: {
        value: contactMethods[method] ?? '',
        update: (e) => update(method, e.target.value),
      },
      name: method,
    });
  }

  return (
    <Col className="margin-bottom-10" lg={4} xs={12}>
      <Card>
        <CardBody>
          <CardTitle>Contact Information</CardTitle>
          <CardText tag="div">
            <p>
              Please enter all contact methods you are willing to use, and select the circle next to
              your preferred at-con contact method.
            </p>
            <p>
              Before the con, we will mostly contact you through email or phone calls; if they show
              incorrect here, finish this form then use{' '}
              <Badge color="secondary">Edit Personal Details</Badge> to update them.
            </p>
            {methods.map(({ name: nameLower, displayText, input }) => {
              const name = capitalize(nameLower);
              const key = `contactMethod${name}`;
              return (
                <div className="custom-control custom-radio margin-top-10" key={key}>
                  <Input
                    className="custom-control-input"
                    defaultChecked={nameLower === contactMethod}
                    id={key}
                    name="contactMethod"
                    type="radio"
                    value={nameLower}
                  />
                  <Label className="custom-control-label" for={key}>
                    {name}
                    {displayText && <FormText color="muted">{displayText}</FormText>}
                    {input && (
                      <FormText color="muted">
                        <Input id={`${key}Input`} onChange={input.update} value={input.value} />
                      </FormText>
                    )}
                  </Label>
                </div>
              );
            })}
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

interface VolunteerTermsProps {
  readonly volunteer?: VolunteerModel;
  readonly hasVolunteerApp: boolean;
  readonly felonyBypass: boolean;
}

const VolunteerTerms: FC<VolunteerTermsProps> = ({ felonyBypass, hasVolunteerApp, volunteer }) => {
  const { allowVolunteerFelonyRestrictionBypass, enableVolunteerFelonyRestriction } =
    useConvention();

  const isFelonyBypassEnabled = allowVolunteerFelonyRestrictionBypass && felonyBypass;

  return (
    <Col className="margin-bottom-10" lg={8} xs={12}>
      <Card color="info" id="volunteerCodeOfConduct" outline>
        <CardBody>
          <CardTitle>Volunteer Code of Conduct</CardTitle>
          <CardText tag="div">
            <Row>
              <Col className="text-center p-0" lg={2} xs={12}>
                <MaterialIcon large name="info" type="info" />
              </Col>
              <Col className="p-0" lg={10} xs={12}>
                <TermsLoader name="volunteerTerms" />
              </Col>
              <Col xs={12}>
                <hr />
              </Col>
              <Col xs={12}>
                <div className="custom-control custom-checkbox margin-top-10">
                  <Input
                    className="custom-control-input"
                    defaultChecked={hasVolunteerApp}
                    disabled={hasVolunteerApp}
                    id="volunteerPolicy"
                    name="volunteerPolicy"
                    type="checkbox"
                  />
                  <Label className="custom-control-label" for="volunteerPolicy">
                    I agree to the Volunteer Code of Conduct above.
                  </Label>
                </div>
                {enableVolunteerFelonyRestriction && (
                  <>
                    <div className="custom-control custom-checkbox margin-top-10">
                      <Input
                        className="custom-control-input"
                        defaultChecked={volunteer?.hasNoFelonies ?? false}
                        disabled={hasVolunteerApp || isFelonyBypassEnabled}
                        id="noFelonyPolicy"
                        name="noFelonyPolicy"
                        type="checkbox"
                      />
                      <Label className="custom-control-label" for="noFelonyPolicy">
                        I swear, under penalty of perjury, that I have never been convicted of a
                        felony.
                      </Label>
                    </div>
                    <FelonyExceptionStatus volunteer={volunteer} />
                  </>
                )}
              </Col>
            </Row>
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

const FelonyExceptionStatus: FC<{ readonly volunteer?: VolunteerModel }> = ({ volunteer }) => {
  if (!volunteer || volunteer?.hasNoFelonies) {
    return null;
  }

  return (
    <div id="felonyExceptionStatus">
      <small>
        {volunteer?.felonyBypassApproved ? (
          <span className="text-success">Your felony restriction exception has been approved.</span>
        ) : (
          <span className="text-danger">
            Your felony restriction exception is pending approval by HR.
          </span>
        )}
      </small>
    </div>
  );
};

const DepartmentsAndExperience: FC<{
  readonly departments: Department[];
  readonly volunteer?: VolunteerModel;
}> = ({ departments, volunteer }) => {
  return (
    <Col className="margin-bottom-10" lg={8} xs={12}>
      <Card>
        <CardBody>
          <CardTitle>Department and Experience</CardTitle>
          <CardText tag="div">
            <p>
              If you have any prior experience with a department, are interested in specifically
              helping them out, or wish to avoid being assigned there.
            </p>
            <VolunteerDepartments
              departments={departments}
              volunteerDepartments={volunteer?.departments}
            />
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

const VolunteerLoadError: FC<{ readonly error: Error }> = ({ error }) => {
  const config = useConfig();
  if (isAccessError(error)) {
    return (
      <MessageCard icon="block" id="volunteerBanned" level="danger">
        You are currently banned from volunteering for the convention.
        <br />
        Please contact <strong>{config.contact.email.hr}</strong> if you think this is in error.
      </MessageCard>
    );
  }

  if (isLogicError(error, LogicError.ParentCannotVolunteer)) {
    return (
      <MessageCard icon="face" id="parentCanNotVolunteer" level="warning">
        No human being can possibly be capable of volunteering and chaperoning minors at the same
        time.
        <br />
        Thanks for offering, but your kids are more important.
      </MessageCard>
    );
  }

  return <LoadingError errorText="Failed to load" />;
};

const VolunteerPreviousExperience: FC<{
  readonly options: VolunteerOptionInput[];
  readonly volunteer?: VolunteerModel;
}> = ({ options, volunteer }) => {
  const previousConExperience = options.find(
    (t) => t.internalName === 'previousConExperience' && !t.hidden,
  );

  const previousOtherExperience = options.find(
    (t) => t.internalName === 'previousOtherExperience' && !t.hidden,
  );

  const eventsCanNotMiss = options.find((t) => t.internalName === 'eventsCanNotMiss' && !t.hidden);

  if (!previousConExperience && !previousOtherExperience && !eventsCanNotMiss) {
    return null;
  }

  return (
    <Col className="margin-bottom-10" lg={8} xs={12}>
      <Card>
        <CardBody>
          <CardTitle>Previous Experience</CardTitle>
          <CardText tag="div">
            {previousConExperience && (
              <ProductOptionViewer
                defaultValue={volunteer?.options[previousConExperience.id]}
                option={previousConExperience}
              />
            )}
            {previousOtherExperience && (
              <ProductOptionViewer
                defaultValue={volunteer?.options[previousOtherExperience.id]}
                option={previousOtherExperience}
              />
            )}
            {eventsCanNotMiss && (
              <ProductOptionViewer
                defaultValue={volunteer?.options[eventsCanNotMiss.id]}
                option={eventsCanNotMiss}
              />
            )}
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

interface VolunteerAvailabilityProps {
  readonly volunteer?: VolunteerModel;
  readonly isApproved: boolean;
  readonly options: VolunteerOptionInput[];
}

const VolunteerAvailability: FC<VolunteerAvailabilityProps> = ({
  isApproved,
  volunteer,
  options,
}) => {
  const hoursOption = options.find((t) => t.internalName === 'availableHours' && !t.hidden);
  const daysOption = options.find((t) => t.internalName === 'volunteerDays' && !t.hidden);
  const availableBeforeCon = options.find(
    (t) => t.internalName === 'availableBeforeCon' && !t.hidden,
  );

  if (!availableBeforeCon && !hoursOption && !daysOption) {
    return <Col className="margin-bottom-10" lg={4} xs={12} />;
  }

  return (
    <Col className="margin-bottom-10" lg={4} xs={12}>
      <Card>
        <CardBody>
          <CardTitle>Availability</CardTitle>
          <CardText tag="div">
            {hoursOption && (
              <FormGroup>
                <Label for="availableHours">
                  Roughly, how many hours would you want to volunteer in total?
                </Label>
                <ProductOptionRenderer
                  defaultValue={volunteer?.options[hoursOption.id]}
                  disabled={isApproved}
                  option={hoursOption}
                  required={hoursOption.required}
                />
                {isApproved && (
                  <Alert color="warning">
                    If you need to adjust your available hours, please contact HR for assistance.
                  </Alert>
                )}
              </FormGroup>
            )}
            {daysOption && (
              <FormGroup>
                <Label for="availableDays">
                  What convention days will you be available to help?
                </Label>
                <ProductOptionRenderer
                  defaultValue={volunteer?.options[daysOption.id]}
                  option={daysOption}
                  required={false}
                />
              </FormGroup>
            )}
            {availableBeforeCon && (
              <ProductOptionViewer
                defaultValue={volunteer?.options[availableBeforeCon.id]}
                option={availableBeforeCon}
              />
            )}
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

interface OtherVolunteerOptionsProps {
  readonly volunteer?: VolunteerModel;
  readonly options: VolunteerOptionInput[];
}

const OtherVolunteerOptions: FC<OtherVolunteerOptionsProps> = ({ options, volunteer }) => {
  const otherOptions = options.filter((t) => !t.internalName);

  if (otherOptions.length === 0) {
    return null;
  }

  return (
    <>
      {otherOptions.map((option) => {
        return (
          <ProductOptionViewer
            defaultValue={volunteer?.options[option.id]}
            key={option.id}
            option={option}
          />
        );
      })}
    </>
  );
};
