import React from 'react';
import { Fetcher, useFetcher } from '../../../utils';
import { LoadingState } from '../../../utils/LoadingState';

export const KioskSettings: React.FC = () => {
  const kioskList = useFetcher(async () => {
    return await api.getKioskList();
  }, []);

  if (kioskList.state !== LoadingState.Done) {
    return <Fetcher result={kioskList} />;
  }

  return <div>Aaaa</div>;
};
