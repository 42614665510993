import React, { FC } from 'react';
import { PageHeader, UserStateComponent } from '../../../components';
import { SurchargesList } from './surchargesList';

export const SurchargesContainer: FC = () => (
  <UserStateComponent>
    <PageHeader>Surcharges</PageHeader>
    <SurchargesList />
  </UserStateComponent>
);
