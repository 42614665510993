import React, { FC, useState } from 'react';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { ExtendedUser } from '../../../../shared/user/extended';
import { PIIAccessWarning } from '../../../components';

interface EmergencyContactInfo {
  emergencyContactName1?: string;
  emergencyContactName2?: string;
  emergencyContactPhone1?: string;
  emergencyContactPhone2?: string;
}

export const UserEmergencyTab: FC<{ readonly userData?: ExtendedUser }> = ({ userData }) => {
  const [data, setData] = useState<EmergencyContactInfo>();
  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <PIIAccessWarning
      ep={`/api/users/${userData.id}/registration/emergencycontacts`}
      setData={setData}
    >
      <EmergencyContacts data={data} />
    </PIIAccessWarning>
  );
};

const EmergencyContacts: FC<{ readonly data?: EmergencyContactInfo }> = ({ data }) => {
  return (
    <Row>
      <RenderedContactInfo
        contactName={data?.emergencyContactName1}
        id={1}
        phone={data?.emergencyContactPhone1}
      />
      <RenderedContactInfo
        contactName={data?.emergencyContactName2}
        id={2}
        phone={data?.emergencyContactPhone2}
      />
    </Row>
  );
};

const RenderedContactInfo: FC<{
  readonly id: number;
  readonly contactName?: string;
  readonly phone?: string;
}> = ({ id, contactName, phone }) => {
  const hasInfo = contactName && phone;
  return (
    <Col className="margin-bottom-10" lg={6} xs={12}>
      <Card>
        <CardBody>
          <CardTitle>Emergency Contact Info</CardTitle>
          {!hasInfo && (
            <CardText className="text-danger">Contact information was not provided.</CardText>
          )}
          {hasInfo && (
            <>
              <CardText>
                <strong>Name</strong>: <span id={`emergencyContactName${id}`}>{contactName}</span>
              </CardText>
              <CardText>
                <strong>Phone</strong>: <span id={`emergencyContactPhone${id}`}>{phone}</span>
              </CardText>
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
