/* eslint-disable react/require-optimization */
import React, { FC, useMemo } from 'react';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Badge, Col, Nav, Row } from 'reactstrap';
import { ExtendedUser } from '../../../../shared/user/extended';
import { ElementHeader, UserStateComponent } from '../../../components';
import { checkPermission, classNames, Fetcher, useFetcher, useUser } from '../../../utils';
import { LoadingState } from '../../../utils/LoadingState';
import { UserAuditTab } from './auditlogs';
import { UserPermissionsTab } from './permissionstab';
import { RolesTab } from './rolestab';
import { UserEmergencyTab } from './useremergencytab';
import { UserBadges } from './utils';
import { UserVolunteerTab } from './volunteertab';
import { NotesTab, UserOrdersTab, UserProfileTab } from '.';

interface TabDetails {
  name: string;
  link: string;
  getCount?(): number;
  getBody(): JSX.Element;
  enabled?(): boolean;
}

function getDisplayName(userData: ExtendedUser): string {
  const { firstName, lastName, id, preferredName, username } = userData;
  let realName = `${firstName} ${lastName}`;

  if (realName === '') {
    realName = username;
  }

  if (preferredName) {
    return `${preferredName} (${realName}) [ID: ${id}]`;
  }

  return `${realName} [ID: ${id}]`;
}

export const UserProfile: FC = () => {
  const user = useUser()!;
  const match = useRouteMatch<{ id: string }>();
  const userId = Number.parseInt(match.params.id, 10);
  const { state, refresh, data, error } = useFetcher(async () => {
    return await api.getExtendedUser(userId);
  }, [userId]);

  const tabs = useMemo<TabDetails[]>(() => {
    if (!data) {
      return [];
    }

    return [
      {
        getBody: () => <UserProfileTab refresh={refresh} userData={data} />,
        link: '',
        name: 'User',
      },
      {
        getBody: () => <NotesTab userData={data} />,
        getCount: () => data.userNoteCount ?? 0,
        link: '/notes',
        name: 'Notes',
      },
      {
        enabled: () => checkPermission(user, 'order:view'),
        getBody: () => <UserOrdersTab refreshContents={refresh} userId={userId} />,
        getCount: () => data.orderCount ?? 0,
        link: '/orders',
        name: 'Orders',
      },
      {
        enabled: () => !!data.roles && checkPermission(user, 'user:roles:update'),
        getBody: () => <RolesTab refreshContents={refresh} userData={data} />,
        getCount: () => data.roles!.length,
        link: '/roles',
        name: 'Roles',
      },
      {
        enabled: () => checkPermission(user, 'system:permission:read'),
        getBody: () => (
          <UserPermissionsTab
            canEdit={checkPermission(user, 'system:permission:update')}
            userId={userId}
          />
        ),
        link: '/permissions',
        name: 'Permissions',
      },
      {
        enabled: () => checkPermission(user, 'volunteer:read'),
        getBody: () => <UserVolunteerTab userData={data} />,
        link: '/volunteer',
        name: 'Volunteer',
      },
      {
        enabled: () => !!data.registration && checkPermission(user, 'registration:read:pii'),
        getBody: () => <UserEmergencyTab userData={data} />,
        link: '/contacts',
        name: 'Emergency Contact(s)',
      },
      {
        enabled: () => checkPermission(user, 'audit:read'),
        getBody: () => <UserAuditTab userId={data.id} />,
        link: '/auditlogs',
        name: 'Audit',
      },
    ];
  }, [user, data]);

  if (!data || data.id !== userId) {
    return <Fetcher result={{ error, state }} />;
  }

  return (
    <UserStateComponent>
      <ElementHeader icon={{ url: data?.profilePictureUrl }} title={getDisplayName(data)}>
        <UserBadges user={data} />
      </ElementHeader>
      <Row>
        <Col
          className={classNames(
            {
              'profile-loading': state === LoadingState.Loading,
            },
            'margin-bottom-10',
          )}
          xs={12}
        >
          <Nav tabs>
            {tabs
              .filter((tab) => !tab.enabled || tab.enabled())
              .map((tab) => (
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  exact={tab.link === ''}
                  id={`${tab.name}_tab`}
                  key={tab.name}
                  to={`${match.url}${tab.link}`}
                >
                  {tab.name}
                  {tab.getCount && (
                    <>
                      &nbsp;
                      <Badge color={tab.getCount() === 0 ? 'secondary' : 'primary'}>
                        {tab.getCount()}
                      </Badge>
                    </>
                  )}
                </NavLink>
              ))}
          </Nav>
        </Col>
      </Row>
      <UserRoutes tabs={tabs} />
    </UserStateComponent>
  );
};

const UserRoutes: FC<{ readonly tabs: TabDetails[] }> = ({ tabs }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {tabs
        .filter((tab) => !tab.enabled || tab.enabled())
        .map((tab) => (
          <Route component={tab.getBody} exact key={tab.name} path={`${path}${tab.link}`} />
        ))}
    </Switch>
  );
};
