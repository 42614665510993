import React, { FC, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import { ExtendedUser } from '../../../../shared/user/extended';
import { UserNoteCreate, UserNoteSummary } from '../../../../shared/user/notes';
import { AddNoteForm } from '../../../modules/notes/addNoteForm';
import { UserNoteItem } from '../../../modules/notes/noteItem';
import { Fetcher, useFetcher, useForm } from '../../../utils';

interface TabDetailsProps {
  readonly userData: ExtendedUser;
}

export const NotesTab: FC<TabDetailsProps> = ({ userData }) => {
  const fetcher = useFetcher(async () => {
    return await api.getNotes({ userId: userData.id });
  }, [userData.id]);

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return (
    <Row>
      <Col lg={8} xs={12}>
        <Card className="margin-bottom-10" id={`notes${userData.id}`}>
          <CardHeader>Notes</CardHeader>
          <NoteList notes={fetcher.data!} refresh={fetcher.refresh} />
        </Card>
      </Col>
      <Col lg={4} xs={12}>
        <Card id="addNoteForm">
          <CardHeader>Add Note</CardHeader>
          <CardBody>
            <AddNote refresh={fetcher.refresh} userId={userData.id} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

interface NoteListProps {
  readonly notes: UserNoteSummary[];
  refresh(): void;
}

const NoteList: FC<NoteListProps> = ({ notes, refresh }) => {
  if (notes.length === 0) {
    return (
      <CardBody className="text-secondary text-center">There are no notes for this user.</CardBody>
    );
  }

  return (
    <>
      {notes.map((note) => {
        return <UserNoteItem key={note.id} note={note} refresh={refresh} />;
      })}
    </>
  );
};

const AddNote: FC<{ readonly userId: number; refresh(): void }> = ({ userId, refresh }) => {
  const [note, setNote] = useState<UserNoteCreate>({
    category: 'misc',
    text: '',
    userId,
  });

  const form = useForm(async () => {
    await api.createUserNote(note);
    refresh();
  }, [note]);

  return (
    <form onSubmit={form.onSubmit}>
      <AddNoteForm hideUserInput note={note} setNote={setNote} />
      <FormGroup>
        <Button block color="primary" disabled={form.saving} id="addNewNote" type="submit">
          Add New Note
        </Button>
      </FormGroup>
    </form>
  );
};
