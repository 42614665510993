import Masonry from 'masonry-layout';
import React, { useEffect } from 'react';
import { Col } from 'reactstrap';

// eslint-disable import/no-unused-modules
export const MasonryRow: React.FC = ({ children }) => {
  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const msnry = new Masonry(ref.current, {});

    return () => {
      msnry.destroy?.();
    };
  }, []);

  return (
    <Col className="p-0" xs={12}>
      <div className="masonry-row" ref={ref}>
        {children}
      </div>
    </Col>
  );
};
