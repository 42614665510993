import React, { FC, useState } from 'react';
import { ProductModel } from '../../../../../../shared/orders';
import { CreateImageComponent } from './CreateImageComponent';
import { ImageListComponent } from './ImageListComponent';

const enum ImageInfoMode {
  View,
  Create,
}

export interface ImageInfoProps {
  readonly product: ProductModel;
  onUpdate(): void;
}

export const ImageInfoHOC: FC<ImageInfoProps> = ({ product, onUpdate }) => {
  const [mode, setMode] = useState(ImageInfoMode.View);

  if (mode === ImageInfoMode.View) {
    return (
      <ImageListComponent
        onAdd={() => {
          setMode(ImageInfoMode.Create);
        }}
        onUpdate={onUpdate}
        product={product}
      />
    );
  }

  return (
    <CreateImageComponent
      onChange={() => {
        setMode(ImageInfoMode.View);
      }}
      onUpdate={onUpdate}
      product={product}
    />
  );
};
