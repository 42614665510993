import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { OrderRefund, RefundResponse } from '../../../../shared/orders';
import { Order, RefundStatus } from '../../../../shared/orders/model';
import {
  Filter,
  FilterTable,
  MaterialIcon,
  PageHeader,
  RefundPaymentTable,
  refundUpdatePreSubmit,
  UserStateComponent,
} from '../../../components';
import { getOrderItem, getRefund } from '../../../models';
import { capitalize, Fetcher, sum, useFetcher } from '../../../utils';
import { cToUsdStrPref } from '../../../utils/cToUsdStr';
import { OrderItemProduct } from '../../cart';
import { RefundInfo } from './refundInfo';

type RefundApprovalFormData = Record<string, number>;

interface RefundApprovalPostData {
  paymentData: Record<number, number>;
  revoke?: boolean;
  reason: string;
  customerMessage?: string | null;
}

interface RefundApprovalResponse {
  success: boolean;
  amountRefunded: number;
}

const filters: Filter<OrderRefund>[] = [
  {
    displayName: 'Status',
    name: 'status',
    options: ['pending', 'approved', 'denied'].map((type) => {
      return {
        filter: (request) => request.status === type,
        name: capitalize(type),
        value: type,
      };
    }),
  },
];

const DigitalGrantInput: FC<{
  readonly order: Order;
  toggleRevokingGrants(): void;
}> = ({ order, toggleRevokingGrants }) => {
  if (order.orderItems.every((t) => !t.referenceId)) {
    return (
      <FormGroup>
        <div className="custom-control margin-top-10" id="noRevoke">
          <Input name="revoke" type="hidden" value="false" />
          <b>Note: This order does not have any digital grants.</b>
        </div>
      </FormGroup>
    );
  }

  return (
    <FormGroup style={{ marginLeft: '7px' }}>
      <div className="custom-control custom-checkbox margin-top-10">
        <Input
          className="custom-control-input"
          defaultChecked
          id="revoke"
          name="revoke"
          onChange={toggleRevokingGrants}
          type="checkbox"
        />
        <Label className="custom-control-label" for="revoke">
          Revoke Digital Grants?
        </Label>
      </div>
      <FormText color="muted">
        If you want to refund a customer without revoking their digital grants (such as attendance
        levels), uncheck this box.
      </FormText>
    </FormGroup>
  );
};

const StockReturnInput: FC<{
  readonly revokingGrant: boolean;
  readonly order: Order;
  readonly products: RefundResponse['products'];
}> = ({ revokingGrant, order, products }) => {
  if (
    !revokingGrant ||
    order.orderItems.every((t) => !products.find((p) => p.id === t.product.id)?.hasLimit)
  ) {
    return null;
  }

  return (
    <FormGroup style={{ marginLeft: '7px' }}>
      <div className="custom-control custom-checkbox margin-top-10">
        <Input
          className="custom-control-input"
          defaultChecked
          id="stockReturn"
          name="stockReturn"
          type="checkbox"
        />
        <Label className="custom-control-label" for="stockReturn">
          Return item to available stock?
        </Label>
      </div>
      <FormText color="muted">
        If you don't want to re-add this item to the pool of available stock, uncheck this box.
      </FormText>
    </FormGroup>
  );
};

const RejectModal: FC<{
  readonly request: OrderRefund;
  readonly refresh: () => void;
  readonly dismissModal: () => void;
}> = ({ request, refresh, dismissModal }) => {
  return (
    <Modal className="modal-large" id="rejectModal" isOpen>
      <JSONForm
        method="post"
        onSuccess={() => {
          toast.success(`Refund request #${request.id} has been rejected.`);
          refresh();
          dismissModal();
        }}
        path={`/api/refunds/${request.id}/reject`}
      >
        <ModalHeader>Refund #{request.id} &mdash; Reject</ModalHeader>
        <ModalBody>
          <p>
            Your reason for rejecting this refund request will be stored in the audit log and will
            not be shown to the user.
          </p>
          <p>You may optionally provide a message to be sent with the request rejection notice.</p>
          <FormGroup row>
            <Label for="reason" lg={5} sm={12}>
              Reason for Rejection <span className="text-danger">*</span>
            </Label>
            <Col lg={7} sm={12}>
              <Input id="reason" name="reason" required type="textarea" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="customerMessage" lg={5} sm={12}>
              Message to Customer
            </Label>
            <Col lg={7} sm={12}>
              <Input id="customerMessage" name="customerMessage" type="textarea" />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              dismissModal();
            }}
          >
            Cancel
          </Button>{' '}
          <Button color="danger" id="submitRejectRefund" type="submit">
            Reject Refund
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};

const ApproveModal: FC<{
  readonly request: OrderRefund;
  readonly products: RefundResponse['products'];
  readonly refresh: () => void;
  readonly dismissModal: () => void;
}> = ({ request, products, refresh, dismissModal }) => {
  const { order } = request;
  const refund = getRefund(request.order, request.id);
  const totalToRefund = sum(
    refund.items.map((item) => getOrderItem(request.order, item.itemId).price),
  );

  const [revokingGrant, setRevokingGrant] = useState(true);

  return (
    <Modal className="modal-large" id="approveModal" isOpen>
      <JSONForm<RefundApprovalResponse, RefundApprovalFormData & RefundApprovalPostData>
        method="post"
        onSuccess={(data) => {
          if (data.success) {
            toast.success(`Refund request #${request.id} has been approved.`);
          } else {
            toast.warn(
              `
              Refund request #${request.id} was only partially refunded (${cToUsdStrPref(
                data.amountRefunded,
              )})
              due to gateway errors. Please review the Related Payments tab for information.
            `,
              { autoClose: false },
            );
          }

          refresh();
          dismissModal();
        }}
        path={`/api/refunds/${request.id}/approve`}
        preSubmit={refundUpdatePreSubmit}
      >
        <ModalHeader>Refund #{request.id} &mdash; Approve</ModalHeader>
        <ModalBody>
          <p>
            Use the table below to enter the amount to be refunded from one or more payments. The
            available amount shows the maximum remaining amount that can be refunded via that
            individual payment.
          </p>
          <p>
            Your reason for approving this refund request will be stored in the audit log and will
            not be shown to the user. You may optionally provide a message to be sent with the
            request approval notice.
          </p>
          <h4>Products to be Refunded</h4>
          <hr />
          {refund.items.map((item) => (
            <OrderItemProduct
              item={getOrderItem(request.order, item.itemId)}
              key={item.itemId}
              order={order}
              readOnly
            />
          ))}
          <h4>Payments</h4>
          <RefundPaymentTable payments={order.payments || []} totalToRefund={totalToRefund} />
          <DigitalGrantInput
            order={order}
            toggleRevokingGrants={() => setRevokingGrant(!revokingGrant)}
          />
          <StockReturnInput order={order} products={products} revokingGrant={revokingGrant} />
          <FormGroup row>
            <Label for="reason" lg={5} sm={12}>
              Reason for Approval <span className="text-danger">*</span>
            </Label>
            <Col lg={7} sm={12}>
              <Input id="reason" name="reason" required type="textarea" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="customerMessage" lg={5} sm={12}>
              Message to Customer
            </Label>
            <Col lg={7} sm={12}>
              <Input id="customerMessage" name="customerMessage" type="textarea" />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              dismissModal();
            }}
          >
            Cancel
          </Button>{' '}
          <Button color="success" id="submitApproveRefund" type="submit">
            Approve Refund
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};

const RefundElement: FC<{ readonly request: OrderRefund }> = ({ request }) => {
  const refund = getRefund(request.order, request.id);

  return (
    <div className="table-item" id={`refundRequest${request.id}`}>
      <p>
        <strong>
          Refund #{request.id} &mdash; Order #{request.order.id}
        </strong>{' '}
        <RenderElementBadges status={request.status} />
      </p>
      <hr />
      <br />
      {refund.items.map((item) => (
        <OrderItemProduct
          item={getOrderItem(request.order, item.itemId)}
          key={item.itemId}
          order={request.order}
          readOnly
        />
      ))}
    </div>
  );
};

const RefundInfoElement: FC<{
  readonly products: RefundResponse['products'];
  readonly request?: OrderRefund;
  readonly refresh: () => void;
}> = ({ request, products, refresh }) => {
  const [modal, setModal] = useState<'approve' | 'reject' | false>(false);

  if (!request) {
    return <CardBody>Select a refund request from the left side to manage it.</CardBody>;
  }

  return (
    <>
      <RefundInfo
        onAction={(action) => {
          setModal(action);
        }}
        request={request}
      />
      {modal === 'reject' && (
        <RejectModal dismissModal={() => setModal(false)} refresh={refresh} request={request} />
      )}
      {modal === 'approve' && (
        <ApproveModal
          dismissModal={() => setModal(false)}
          products={products}
          refresh={refresh}
          request={request}
        />
      )}
    </>
  );
};

export const PendingRefunds: FC = () => {
  const fetcher = useFetcher(async () => {
    return await api.getRefunds();
  });

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  const { refunds, products } = fetcher.data!;

  return (
    <UserStateComponent>
      <PageHeader>Refund Requests</PageHeader>
      <Row className="justify-content-center" id="refundList">
        {refunds.length > 0 && (
          <Col xs={12}>
            <FilterTable<OrderRefund>
              data={refunds}
              filters={filters}
              infoRenderer={(d) => (
                <RefundInfoElement products={products} refresh={fetcher.refresh} request={d} />
              )}
              renderer={(d) => <RefundElement request={d} />}
            />
          </Col>
        )}
        {refunds.length === 0 && (
          <Col className="text-center" lg={8} xs={12}>
            <MaterialIcon large name="sentiment_satisfied" />
            <h4>There are currently no pending refund requests.</h4>
            <p>
              When a user requests a refund, it will show up here with details on what items they
              want to be refunded, why they would like a refund, and any other additional
              information.
            </p>
          </Col>
        )}
      </Row>
    </UserStateComponent>
  );
};

const RenderElementBadges: FC<{ readonly status: RefundStatus }> = ({ status }) => {
  switch (status) {
    case 'pending': {
      return <Badge color="warning">Pending</Badge>;
    }

    case 'approved': {
      return <Badge color="success">Approved</Badge>;
    }

    case 'denied': {
      return <Badge color="danger">Denied</Badge>;
    }
  }
};
