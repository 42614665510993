import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label } from 'reactstrap';
import { CountrySelector } from '../../../../components';
import { UserLocation } from '../../../../models';
import { useInputState } from '../../../../utils';

interface Props {
  readonly defaultLocation?: UserLocation;
  onChange(loc?: UserLocation): void;
}

export const AddressSearch: FC<Props> = ({ defaultLocation, onChange }) => {
  const [city, setCity] = useInputState('' as string);
  const [zipcode, setZipcode] = useInputState('' as string);
  const [country, setCountry] = useState('' as string);
  const [address, setAddress] = useState<{ found: boolean; geo?: UserLocation } | undefined>(
    defaultLocation ? { found: true, geo: defaultLocation } : undefined,
  );

  useEffect(() => {
    if (address) {
      onChange(address.geo);
    }
  }, [address]);

  const addressSearch = useCallback(async () => {
    try {
      setAddress({
        found: true,
        geo: await api.getRaffleAddressLocation(city, zipcode, country),
      });
    } catch {
      setAddress({ found: false });
    }
  }, [city, zipcode, country]);

  const resetAddress = useCallback(() => {
    setAddress(undefined);
  }, []);

  if (address?.found) {
    const resetBtn = (
      <FormGroup className="text-right">
        <Button color="secondary" onClick={resetAddress} outline>
          Reset
        </Button>
      </FormGroup>
    );

    return (
      <>
        <Col lg={9} xs={12}>
          <FormGroup>
            <Label for="addressCity">Selected Event Venue Location</Label>
            <p>{city ? `${city}, ${zipcode}, ${country}` : 'Location Not Available'}</p>
          </FormGroup>
        </Col>
        <Col className="d-none d-sm-block" lg={3}>
          {resetBtn}
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label for="addressCity">Geolocation Coordinates</Label>
            <p>
              Lat: {address.geo?.latitude}, Long: {address.geo?.longitude}
            </p>
          </FormGroup>
        </Col>
        <Col className="d-block d-sm-none" xs={12}>
          {resetBtn}
        </Col>
      </>
    );
  }

  const canSearch = !!city && !!zipcode && !!country;

  return (
    <>
      <Col xs={12}>
        <FormGroup>
          <Label for="addressCity">Event Venue City</Label>
          <Input id="addressCity" name="addressCity" onChange={setCity} required value={city} />
        </FormGroup>
      </Col>
      <Col lg={6} xs={12}>
        <FormGroup>
          <Label for="addressZipcode">Event Venue Zipcode</Label>
          <Input
            id="addressZipcode"
            name="addressZipcode"
            onChange={setZipcode}
            required
            value={zipcode}
          />
        </FormGroup>
      </Col>
      <Col lg={6} xs={12}>
        <FormGroup>
          <Label for="addressCountry">Event Venue Country</Label>
          <CountrySelector
            autoComplete="country-name"
            classes="form-control"
            id="addressCountry"
            name="addressCountry"
            onChange={setCountry}
            value={country}
          />
        </FormGroup>
      </Col>
      {address && !address.found && (
        <Col className="text-danger" xs={12}>
          No results were found for the given criteria.
        </Col>
      )}
      <Col xs={12}>
        <FormGroup className="text-right">
          <Button color="primary" disabled={!canSearch} onClick={addressSearch} outline>
            Search
          </Button>
        </FormGroup>
      </Col>
    </>
  );
};
