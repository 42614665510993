import { FormPageModel } from '../kiosk';
import { Option, OptionDataPayload, OptionFileInput } from '../options';
import { ProductModel } from '../orders/product';
import { BadgeDesign } from '../user/badgeDesign';
import { RegistrationTicket } from '../user/base';

export const internalRegistrationFlags = new Set(['nukeme']);

export interface RegistrationFlagModel {
  description: string;
  enabledDescription: string | null;
  id: number;
  shortName: string;
  sortOrder: number;
  visibility: boolean;
}

export interface RegistrationFlagUser {
  userId: number;
  badgeName: string;
  firstName: string;
  lastName: string;
  preferredName: string | null;
  username: string;
}

export interface RegistrationOptionInput extends Option {
  required: boolean;
  fromRegistration: boolean;
  fromProduct: boolean;
}

export interface ConRegistrationForm {
  terms: string;
  badgeDesign?: BadgeDesign;
  flags: RegistrationFlagModel[];
  options: RegistrationOptionInput[];
  products: ProductModel[];
  formLayout?: FormPageModel;
}

export interface RegistrationUpsert {
  attendanceTypeId: number;
  badgeName: string;
  emergencyContactName1?: string | null;
  emergencyContactName2?: string | null;
  emergencyContactPhone1?: string | null;
  emergencyContactPhone2?: string | null;
  flags: number[];
  options: OptionDataPayload;
  badgeArtId?: number;
  profilePicture?: OptionFileInput | null;
  raffle?: RegistrationTicket;
  force?: boolean;
}
