import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Raffle } from '../../../../shared/orders/raffle';
import { PageHeader, UserStateComponent } from '../../../components';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { RaffleListItem } from './raffleListItem';

export const RaffleList: FC = () => {
  const history = useHistory();
  return (
    <UserStateComponent>
      <LoadingWrapper<Raffle[], void> dataFetcher={async () => await api.getRaffles()}>
        {(data) => (
          <>
            <PageHeader>Gatekeeping</PageHeader>
            <div>
              <Button
                color="primary"
                id="createRaffle"
                onClick={() => {
                  history.push('/housekeeping/gatekeeping/new');
                }}
              >
                Create New Silo
              </Button>
            </div>
            <hr />
            <Row className="justify-content-center">
              {data.map((raffle) => (
                <Col className="mb-3" key={raffle.id} lg={7} xs={12}>
                  <RaffleListItem raffle={raffle} />
                </Col>
              ))}
            </Row>
          </>
        )}
      </LoadingWrapper>
    </UserStateComponent>
  );
};
