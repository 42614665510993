import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  Col,
  FormText,
  Input,
  Row,
} from 'reactstrap';
import { BadgeArt } from '../models';
import { isMobile } from '../services';
import { Fetcher, isLogicError, useConvention, useFetcher } from '../utils';
import { captureError, LogicError } from '../utils/errorHandling';
import { MaterialIcon } from './MaterialIcon';

interface BadgeArtRowProps {
  readonly registrationId?: number;
  // Previously selected option
  readonly defaultBadgeArtId?: number;
  readonly badgeArtId: number | undefined;
  readonly setBadgeArtId: (value: number) => void;
}

export const BadgeArtSelectorCard: FC<BadgeArtRowProps> = ({
  registrationId,
  setBadgeArtId,
  badgeArtId,
  defaultBadgeArtId,
}) => {
  const { enableBadgeArt } = useConvention();
  const [refreshId, setRefreshId] = useState(0);

  if (!enableBadgeArt) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const badgeArts = useFetcher(async () => {
    const arts = await api.getBadgeArt();

    if (!badgeArtId && arts.length > 0) {
      const randomArts = arts.filter((t) => t.limit === null || t.count < t.limit);

      if (randomArts.length > 0) {
        // Get random art
        setBadgeArtId(randomArts[Math.floor(Math.random() * randomArts.length)].id);
      }
    }

    return arts;
  }, [refreshId]);

  const art: BadgeArt[] = (badgeArts.data ?? []).filter((t) => {
    if (t.limit === null) {
      return true;
    }

    if (defaultBadgeArtId === t.id) {
      return true;
    }

    return t.count < t.limit;
  });

  return (
    <Card>
      <CardHeader>Badge Art</CardHeader>
      <CardBody>
        <Fetcher inline result={badgeArts}>
          <>
            <BadgeArtSelector
              art={art.map((t) => t.id)}
              artId={badgeArtId}
              onChange={setBadgeArtId}
              refresh={() => {
                setRefreshId(refreshId + 1);
              }}
              regId={registrationId}
            />
            <Input id="badgeArtId" name="badgeArtId" type="hidden" value={badgeArtId} />
          </>
        </Fetcher>
      </CardBody>
    </Card>
  );
};

interface BadgeArtSelectorProps {
  readonly art: number[];
  readonly artId?: number;
  readonly regId?: number;
  onChange(artId: number): void;
  refresh(): void;
}

const MobileBadgeArtSelector: FC<{
  readonly art: number[];
  readonly currentArtIndex: number;
  setAnimating(animating: boolean): void;
  goToIndex(index: number): void;
}> = ({ art, currentArtIndex, setAnimating, goToIndex }) => {
  const items = art.map((id) => ({
    altText: `Badge ${id}`,
    src: `/api/badgeart/${id}/image`,
  }));

  const slides = items.map((item) => (
    <CarouselItem
      key={item.src}
      onExited={() => {
        setAnimating(false);
      }}
      onExiting={() => {
        setAnimating(true);
      }}
    >
      <img alt={item.altText} className="img-fluid" src={item.src} />
    </CarouselItem>
  ));

  const moveIndex = useCallback(
    (indexMod: number) => {
      goToIndex((currentArtIndex + indexMod) % art.length);
    },
    [goToIndex, currentArtIndex, art],
  );

  return (
    <Carousel
      activeIndex={currentArtIndex}
      interval={false}
      next={() => {
        moveIndex(1);
      }}
      previous={() => {
        moveIndex(-1);
      }}
    >
      <CarouselIndicators
        activeIndex={currentArtIndex}
        items={items}
        onClickHandler={(idx) => {
          goToIndex(idx);
        }}
      />
      {slides}
      <a
        className="carousel-control-prev"
        onClick={() => {
          moveIndex(-1);
        }}
      >
        <MaterialIcon large name="keyboard_arrow_left" />
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        onClick={() => {
          moveIndex(1);
        }}
      >
        <MaterialIcon large name="keyboard_arrow_right" />
        <span className="sr-only">Next</span>
      </a>
    </Carousel>
  );
};

const BadgeArtSelector: FC<BadgeArtSelectorProps> = ({ art, artId, regId, onChange, refresh }) => {
  const [animating, setAnimating] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentArtIndex = useMemo(() => {
    if (!artId || art.length === 0) {
      return 0;
    }

    const index = art.indexOf(artId);

    return index === -1 ? 0 : index;
  }, [art, artId]);

  const quickUpdateBadge = useCallback(
    async (index: number) => {
      if (regId === undefined) {
        return;
      }

      setLoading(true);

      try {
        await api.updateRegistration(regId, {
          badgeArtId: index,
        });
      } catch (error) {
        captureError(error as Error);
        if (isLogicError(error, LogicError.BadgeArtLimitReached)) {
          refresh();
        }
      }

      setLoading(false);
    },
    [regId, setLoading, refresh],
  );

  const goToIndex = useCallback(
    (newIndex: number) => {
      if (animating || currentArtIndex === newIndex) {
        return;
      }

      onChange(art[newIndex]);

      quickUpdateBadge(art[newIndex]).catch(captureError);
    },
    [onChange, currentArtIndex, animating],
  );

  if (art.length === 0) {
    return (
      <FormText color="muted">
        There is currently no badge art to choose. Check back closer to the convention.
      </FormText>
    );
  }

  if (isMobile) {
    return (
      <MobileBadgeArtSelector
        art={art}
        currentArtIndex={currentArtIndex}
        goToIndex={goToIndex}
        setAnimating={setAnimating}
      />
    );
  }

  return (
    <Row className="justify-content-center">
      {art.map((id, index) => {
        const isSelected = artId === id;
        return (
          <Col key={id} style={{ marginBottom: '1em' }} xl={3} xs={4}>
            <img
              src={`/api/badgeart/${id}/image`}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            {isSelected ? (
              <Button
                block
                className={loading ? 'saving' : 'selected'}
                color="secondary"
                disabled
                id={`badge${id}`}
                outline
                type="button"
              >
                {loading ? 'Saving...' : 'Selected'}
              </Button>
            ) : (
              <Button
                block
                color="primary"
                disabled={loading}
                id={`badge${id}`}
                onClick={() => {
                  goToIndex(index);
                }}
                type="button"
              >
                Select
              </Button>
            )}
          </Col>
        );
      })}
    </Row>
  );
};
