import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, FormText, Label } from 'reactstrap';
import { OptionDataValue } from '../../../shared/options';
import {
  RegistrationFlagModel,
  RegistrationOptionInput,
  RegistrationUpsert,
} from '../../../shared/registration/model';
import { AttendanceFlags, ProductOptionRenderer } from '../../components';
import { useObject } from '../../utils';

interface AdditionalRegOptionsProps {
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
  readonly registration: RegistrationUpsert;
  readonly flags: RegistrationFlagModel[];
  readonly regOptions: RegistrationOptionInput[];
  readonly askForChildren?: boolean;
  readonly hasChildren?: boolean;
  toggleChildren?(): void;
}

export const AdditionalRegOptions: FC<AdditionalRegOptionsProps> = ({
  setRegistration,
  registration,
  flags,
  regOptions,
  askForChildren,
  hasChildren,
  toggleChildren,
}) => {
  const shirtOption = regOptions.find((t) => t.fromRegistration && t.name === 'Shirt Size');
  const [enabledFlags, setEnabledFlags] = useObject(registration, setRegistration, 'flags');

  const onOptionChange = useCallback(
    (id: number, value: OptionDataValue) => {
      setRegistration((old) => ({
        ...old,
        options: {
          ...old.options,
          [id.toString()]: value,
        },
      }));
    },
    [setRegistration],
  );

  return (
    <>
      {flags.length > 0 && (
        <Col className="margin-bottom-10" lg={6} xs={12}>
          <Card className="additional-options">
            <CardHeader>Additional Options</CardHeader>
            <CardBody>
              <FormGroup>
                <AttendanceFlags
                  askForChildren={askForChildren}
                  enabledFlags={enabledFlags}
                  flags={flags}
                  hasChildren={hasChildren}
                  onUpdate={setEnabledFlags}
                  toggleChildren={toggleChildren}
                />
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
      )}
      {shirtOption && (
        <Col className="margin-bottom-10" lg={6} xs={12}>
          <Card className="additional-options margin-bottom-10">
            <CardHeader>Extras</CardHeader>
            <CardBody>
              <FormGroup>
                <Label for="shirtSize">Shirt Size</Label>
                <ProductOptionRenderer
                  defaultValue={registration.options[shirtOption.id]}
                  onChange={onOptionChange}
                  option={shirtOption}
                  required={shirtOption.required}
                />
                <FormText color="muted">
                  We use this to ensure we get the right amount of stock.
                </FormText>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};
