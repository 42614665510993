import { Breadcrumb, MaterialIconText } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { ValueCriterionOptions } from '../../../../../shared/orders/raffle';
import { emptyFallback } from '../../../../utils';
import { RaffleConfiguratorProps } from '../utils';

export const StaticValue: FC<RaffleConfiguratorProps<ValueCriterionOptions>> = ({
  defaultOptions,
  displayName,
  onBack,
  onCancel,
  onConfigure,
}) => {
  const [value, setValue] = useState<number>(defaultOptions?.value ?? 1);
  const onFinish = useCallback(
    () => onConfigure({ name: 'value', options: { value } }),
    [onConfigure, value],
  );

  return (
    <>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <Breadcrumb
              items={[
                { text: `Display Name: ${emptyFallback(displayName, 'None')}` },
                { text: 'Type: Static Value' },
              ]}
            />
            <MaterialIconText name="keyboard_backspace" onClick={onBack}>
              Go Back
            </MaterialIconText>
            <hr />
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="value">Value</Label>
              <Input
                id="value"
                name="value"
                onChange={(e) => {
                  setValue(Number.parseFloat(e.currentTarget.value));
                }}
                required
                type="number"
                value={value}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="editor-finish-btn" color="primary" disabled={!value} onClick={onFinish}>
          Finish
        </Button>
        <Button className="editor-cancel-btn" color="secondary" onClick={onCancel} outline>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};
