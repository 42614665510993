import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useMemo, useState } from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ElementSelector } from '../../../../components';
import { Surcharge } from '../../../../models';
import { LocalSearchProvider } from '../utils';

interface Props {
  readonly categoryId: number;
  readonly open?: boolean;
  onUpdate(): void;
  onCancel(): void;
}

export const AssignCategorySurchargeModal: FC<Props> = ({
  categoryId,
  open,
  onCancel,
  onUpdate,
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const memoizedSearchProvider = useMemo(
    () =>
      new LocalSearchProvider(async () =>
        (await api.getSurcharges()).filter(({ deletedAt }) => !deletedAt),
      ),
    [],
  );

  return (
    <Modal className="modal-large" id="assignSurchargeModal" isOpen={open}>
      <JSONForm<undefined, { surchargeIds: number[] }>
        id="assignSurchargeForm"
        method="post"
        onSuccess={onUpdate}
        path={`/api/products/categories/${categoryId}/surcharges`}
        preSubmit={(r) => (r.inputs!.surchargeIds = selectedIds)}
      >
        <ModalHeader>Assign Category Level Surcharges</ModalHeader>
        <ModalBody>
          <p>
            Selected surcharges will be added to all new and existing products in this category.
          </p>
          <p>
            If a category level surcharge is later removed, it will be unassigned from all products
            in this category.
          </p>
          <hr />
          <FormGroup>
            <Label for="product">Surcharge Search</Label>
            <ElementSelector<Surcharge>
              id="surchargesSelector"
              searchProvider={memoizedSearchProvider}
              selectionIdsChanged={setSelectedIds}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={selectedIds.length === 0} id="assignSurcharges">
            Assign Surcharges
          </Button>
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};
