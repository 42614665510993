import React, { FC, useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { ConventionSharedModel } from '../../../../shared/convention';
import { Fetcher, omit, useConvention, useFetcher } from '../../../utils';
import { captureError } from '../../../utils/errorHandling';
import { ConfigTextBoxSetting } from './AbstractLiveSettings';

export const ContactSettings: FC = () => {
  const config = useConvention();

  const result = useFetcher(async () => {
    return await api.getConvention(config.id);
  }, [config.id]);

  if (!result.complete) {
    return <Fetcher result={result} />;
  }

  return <ContactSettingsForm refresh={result.refresh} value={result.data!} />;
};

const ContactSettingsForm: FC<{ readonly value: ConventionSharedModel; refresh(): void }> = ({
  value,
  refresh,
}) => {
  const [contact, setContact] = useState(value.emailFromOverride);
  const [saving, setSaving] = useState(false);
  const onSave = useCallback(async () => {
    try {
      setSaving(true);
      await api.updateConvention(value.id, { emailFromOverride: contact });
      refresh();
    } catch (error) {
      captureError(error as Error);
    }

    setSaving(false);
  }, [value.id, contact, refresh]);

  const updateEmail = useCallback(
    (key: string, email: string) => {
      if (!email) {
        setContact((old) => omit(old, key));
      }

      setContact((old) => ({
        ...old,
        [key]: email,
      }));
    },
    [setContact],
  );

  return (
    <div>
      <ConfigTextBoxSetting
        description="Contact for dealer applications. This email will be used as the 'from' address for dealer applications."
        displayName="Dealers Email"
        name="dealers"
        placeholder="dealers"
        saving={saving}
        update={(val) => updateEmail('dealers', val!)}
        value={contact.dealers ?? ''}
      />
      <ConfigTextBoxSetting
        description="Contact point for volunteers. This email will be used as the 'from' address for volunteer applications, and displayed as the support email."
        displayName="HR Email"
        name="hr"
        placeholder="hr"
        saving={saving}
        update={(val) => updateEmail('hr', val!)}
        value={contact.hr ?? ''}
      />
      <ConfigTextBoxSetting
        description="Contact point for registration. This email will be used as the 'from' address for registration emails."
        displayName="Registration Email"
        name="registration"
        placeholder="registration"
        saving={saving}
        update={(val) => updateEmail('registration', val!)}
        value={contact.registration ?? ''}
      />

      <Button className="float-right" color="primary" disabled={saving} onClick={onSave}>
        Save
      </Button>
    </div>
  );
};
