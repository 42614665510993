/* eslint-disable import/no-unused-modules */
import React, { FC, useCallback } from 'react';
import { Button } from 'reactstrap';
import { useBoolState } from '../utils';
import { captureError } from '../utils/errorHandling';
import { ActionModal } from './ActionModal';

interface ActionButtonProps {
  readonly id: string;
  readonly title: string;
  readonly children: React.ReactNode;
  // Button inside of the modal
  readonly actionContent: React.ReactNode;
  // Button the user has to click
  readonly buttonContent: React.ReactNode;
  readonly color: 'danger' | 'primary' | 'secondary' | 'success';
  readonly block?: boolean;
  readonly outline?: boolean;
  readonly className?: string | undefined;
  onComplete(): Promise<void>;
}

export const ActionButtonModal: FC<ActionButtonProps> = (props) => {
  const [isOpen, openModal, closeModal] = useBoolState(false);

  const complete = useCallback(async () => {
    try {
      await props.onComplete();
      closeModal();
    } catch (error) {
      captureError(error as Error);
    }
  }, [props.onComplete, closeModal]);

  return (
    <>
      <Button
        block={props.block}
        className={props.className}
        color={props.color}
        id={props.id}
        onClick={openModal}
        outline={props.outline}
      >
        {props.buttonContent}
      </Button>
      <ActionModal
        actionContent={props.actionContent}
        close={closeModal}
        color={props.color}
        id={props.id}
        isOpen={isOpen}
        onComplete={complete}
        title={props.title}
      >
        {props.children}
      </ActionModal>
    </>
  );
};
