import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { Fetcher, useBoolState, useFetcher } from '../../../../utils';
import { KeyView } from './KeyView';
import { WebhookModal } from './WebhookModal';
import { WebhookTable } from './WebhookTable';

export const WebhookPage: FC = () => {
  const [modalOpen, enableModal, disableModal] = useBoolState(false);
  const req = useFetcher(async () => {
    const [types, webhooks, publicKey] = await Promise.all([
      api.getWebhookTypes(),
      api.getWebhooks(),
      api.getWebhookKey(),
    ]);

    return { types, webhooks, publicKey };
  });

  if (!req.complete) {
    return <Fetcher result={req} />;
  }

  const { types, webhooks, publicKey } = req.data!;

  return (
    <div>
      <KeyView value={publicKey} />
      <br />
      <WebhookTable onChange={req.refresh} webhooks={webhooks} />
      <Button color="primary" id="webhook-create" onClick={enableModal}>
        Create new
      </Button>
      <WebhookModal
        onClose={(cancelled) => {
          disableModal();
          if (cancelled) {
            return;
          }

          toast.success('Webhook created!');
          req.refresh();
        }}
        open={modalOpen}
        types={types}
      />
    </div>
  );
};
