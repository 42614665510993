import React, { FC, useCallback, useState } from 'react';
import { FormText } from 'reactstrap';
import { makeLazyComponent } from '../utils/lazy';

const PhoneInputInner = makeLazyComponent(
  async () => await import(/* webpackChunkName: "phoneinput" */ 'react-phone-input-2'),
);

interface PhoneInputProps {
  readonly name?: string;
  readonly disabled?: boolean;
  readonly value: string | null | undefined;
  onChange?(newValue: string | null): void;
}

function comparePhoneNumbers(a: string, b: string): boolean {
  return a.replaceAll(/[ ()-]/g, '') === b.replaceAll(/[ ()-]/g, '');
}

export const PhoneInput: FC<PhoneInputProps> = ({ name, disabled, value, onChange }) => {
  const [didFixCountryCode, setDidFixCountryCode] = useState(false);

  const onPhoneChange = useCallback(
    (
      originalInput: string,
      country: { countryCode: string },
      evt: React.ChangeEvent<HTMLInputElement>,
      formatted: string,
    ) => {
      const isChangeEvent = evt.type === 'change';

      /*
       * If the value was changed by the user, but was previously blank, or only contained the
       * default +1, we need to check if system assumed the country code from the first few digits
       * of the user input. If the formatted output doesn't match the user input, the system
       * dropped the default +1.
       *
       * This typically happens when the user enters a US phone number without the country code
       * through a password manager or autofill tool.
       */
      if (
        isChangeEvent &&
        (!value || value.length === 0 || value === '+1') &&
        country.countryCode !== 'us' &&
        originalInput.length === 10 &&
        !comparePhoneNumbers(originalInput, formatted)
      ) {
        setDidFixCountryCode(true);

        if (originalInput.startsWith('+')) {
          formatted = originalInput;
        } else {
          formatted = `+1${originalInput}`;
        }
      } else {
        setDidFixCountryCode(false);
      }

      onChange?.(
        !formatted || formatted.length === 0 || formatted === '+1'
          ? ''
          : formatted.replaceAll(/[ ()-]/g, ''),
      );
    },
    [onChange, value],
  );

  return (
    <>
      <PhoneInputInner
        country="us"
        disabled={disabled}
        inputProps={{ name }}
        onChange={onPhoneChange}
        value={value}
      />
      {didFixCountryCode && (
        <FormText className="mt-1" color="info">
          We detected a US-formatted phone number. If this is incorrect, please click the flag to
          select the correct country.
        </FormText>
      )}
    </>
  );
};
