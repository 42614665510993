import { MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { OptionDataPayload } from '../../../shared/options';
import { ProductModel } from '../../../shared/orders';
import { RegistrationOptionInput, RegistrationUpsert } from '../../../shared/registration/model';
import { BadgeDesign } from '../../../shared/user/badgeDesign';
import { CurrentUser } from '../../../shared/user/base';
import { useInputModel } from '../../utils';
import { generateRandomName } from '../../utils/nameGenerator';
import {
  BadgePreviewRenderer,
  getPlaceholderData,
  transformNodeMap,
} from '../badgerenderer/BadgeRender';

interface BadgeInfoProps {
  readonly registration: RegistrationUpsert;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
  readonly regOptions: RegistrationOptionInput[];
  readonly attendanceType: ProductModel;
  readonly badgeDesign?: BadgeDesign;
  readonly user: CurrentUser;
}

export const BadgeInfo: FC<BadgeInfoProps> = ({
  registration,
  setRegistration,
  attendanceType,
  badgeDesign,
  user,
  regOptions,
}) => {
  const speciesOption = regOptions.find((t) => t.fromRegistration && t.type === 'text');
  const setBadgeNameEV = useInputModel(setRegistration, 'badgeName');
  const species = (speciesOption && (registration.options[speciesOption.id] as string)) ?? '';

  const randomizeBadgeInfo = useCallback(() => {
    const random = generateRandomName({ badgeName: registration.badgeName, species });
    setRegistration((old) => ({
      ...old,
      badgeName: random.badgeName,
      options: speciesOption
        ? {
            ...old.options,
            [speciesOption.id]: random.species,
          }
        : old.options,
    }));
  }, [setRegistration, registration.badgeName, species]);

  const onChangeSpecies = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (speciesOption) {
        setRegistration((old) => ({
          ...old,
          options: {
            ...old.options,
            [speciesOption.id]: e.target.value,
          },
        }));
      }
    },
    [setRegistration],
  );

  return (
    <>
      <Col className="margin-bottom-10" lg={6} xs={12}>
        <Card>
          <CardHeader>Badge Info</CardHeader>
          <CardBody>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label for="badgeName">Badge Name</Label>
                  <Input
                    id="badgeName"
                    maxLength={32}
                    name="badgeName"
                    onChange={setBadgeNameEV}
                    value={registration.badgeName}
                  />
                </FormGroup>
              </Col>
              {speciesOption && (
                <Col xs={12}>
                  <FormGroup>
                    <Label for="species">
                      {speciesOption.name} {!speciesOption.required && <small>(Optional)</small>}
                    </Label>
                    <Input
                      id={`option${speciesOption.id}`}
                      maxLength={speciesOption.max ?? undefined}
                      name={`options[${speciesOption.id}]`}
                      onChange={onChangeSpecies}
                      required={speciesOption.required}
                      value={species}
                    />
                  </FormGroup>
                </Col>
              )}
              {speciesOption?.name.includes('Species') && (
                <Col xs={12}>
                  <FormGroup>
                    <Label for="randomBadgeInfo">Randomize</Label>
                    <Button
                      className="btn-block"
                      color="primary"
                      id="randomBadgeInfo"
                      onClick={randomizeBadgeInfo}
                      outline
                    >
                      <MaterialIcon className="align-middle" name="shuffle" />
                    </Button>
                  </FormGroup>
                </Col>
              )}
              <Col xs={12}>
                <hr />
              </Col>
              {badgeDesign && (
                <>
                  <Col className="margin-bottom-10" xs={12}>
                    <MaterialIcon name="info" small /> Actual badge print and label may vary from
                    this preview.
                  </Col>
                  <Col xs={12}>
                    <MaterialIcon name="info" small />
                    &nbsp; Badge labels are printed with black text. Emojis will be black and white.
                  </Col>
                </>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
      {badgeDesign ? (
        <Col className="margin-bottom-10" id="badgeDesign" lg={6} xs={12}>
          <Card>
            <CardHeader>Badge Preview</CardHeader>
            <CardBody>
              <BadgeArtComp
                attendanceType={attendanceType}
                badgeArtId={registration.badgeArtId}
                badgeDesign={badgeDesign}
                badgeName={registration.badgeName}
                options={registration.options}
                species={species}
                user={user}
              />
            </CardBody>
          </Card>
        </Col>
      ) : (
        <div id="badgeDesign" />
      )}
    </>
  );
};

interface BadgeArtProps {
  readonly badgeArtId: number | undefined;
  readonly badgeDesign: BadgeDesign;
  readonly badgeName: string;
  readonly species: string;
  readonly user: CurrentUser;
  readonly attendanceType: ProductModel;
  readonly options?: OptionDataPayload;
}

const BadgeArtComp: FC<BadgeArtProps> = ({
  attendanceType,
  badgeArtId,
  user,
  badgeName,
  species,
  badgeDesign,
  options,
}) => {
  const label = getPlaceholderData({
    attendanceType,
    badgeArtId,
    badgeName,
    species,
    user,
    options,
  });

  return (
    <BadgePreviewRenderer
      image={badgeArtId ? `/api/badgeart/${badgeArtId}/image` : ''}
      label={label}
      nodes={transformNodeMap(badgeDesign)}
    />
  );
};
