import React, { FC } from 'react';
import { toast } from 'react-toastify';
import {
  ActionButton,
  ElementHeader,
  MaterialIcon,
  PermissionBoundary,
} from '../../../../components';
import { Surcharge } from '../../../../models';
import { surchargeRateText, surchargeTitle } from '../../surcharges/utils';

interface Props {
  readonly categoryId: number;
  readonly surcharge: Surcharge;
  refresh(): void;
}

export const CategorySurchargeItem: FC<Props> = ({ categoryId, surcharge, refresh }) => (
  <ElementHeader
    icon={{ iconName: 'local_atm' }}
    key={surcharge.id}
    rightContent={
      <PermissionBoundary
        inline
        requiredPermissions={['product:category:manage', 'product:manage']}
      >
        <ActionButton
          action={`/api/products/categories/${categoryId}/surcharges/${surcharge.id}`}
          className="action-delete"
          color="danger"
          method="delete"
          onSuccess={() => {
            toast.success(`Removed Category Level surcharge: ${surcharge.name}`);
            refresh();
          }}
          outline
          title="Delete"
        >
          <MaterialIcon name="delete" />
        </ActionButton>
      </PermissionBoundary>
    }
    title={surcharge.name}
  >
    {surchargeTitle(surcharge)} of {surchargeRateText(surcharge)}
  </ElementHeader>
);
