import { MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { FC, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import {
  internalRegistrationFlags,
  RegistrationFlagModel,
} from '../../../../shared/registration/model';
import { UserListModal } from '../../../components/UserListModal';
import { useFetcher } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { ColumnSettingMulti, DataTable } from './DataTable';

const flagColumns: ColumnSettingMulti<RegistrationFlagModel>[] = [
  {
    label: 'Short Name',
    name: 'shortName',
    type: 'text',
    // Value not allowed to be edited after it is created
    disabled: (t) => t !== undefined,
  },
  {
    name: 'description',
    type: 'text',
  },
  {
    name: 'enabledDescription',
    type: 'textarea',
  },
  {
    label: 'Sort Order',
    name: 'sortOrder',
    type: 'number',
  },
  {
    label: 'Make visible to attendees',
    name: 'visibility',
    type: 'toggle',
  },
];

export const FlagSettings: FC = () => {
  const [showUsers, setShowUsers] = useState<number | null>(null);

  return (
    <Row>
      <Col className="margin-bottom-10" id="flags" xs={12}>
        {showUsers && <FlagUsersModal close={() => setShowUsers(null)} flagId={showUsers} />}
        <LoadingWrapper dataFetcher={async () => await api.getRegistrationFlags()}>
          {(data, refresh) => (
            <DataTable<RegistrationFlagModel>
              canDelete={(t) => !internalRegistrationFlags.has(t.shortName)}
              columns={flagColumns}
              data={data}
              endpoint="/api/registrations/flags"
              extraButtons={(t) => (
                <Button
                  className="action-list"
                  color="secondary"
                  onClick={() => setShowUsers(t.id)}
                  title="List users"
                >
                  <MaterialIcon name="group" />
                </Button>
              )}
              refresh={refresh}
              title="Registration flags"
            />
          )}
        </LoadingWrapper>
      </Col>
    </Row>
  );
};

const FlagUsersModal: FC<{ readonly flagId: number; close(): void }> = ({ flagId, close }) => {
  const userRequest = useFetcher(async () => {
    return await api.getRegistrationFlagUsers(flagId);
  }, [flagId]);

  if (!userRequest.complete) {
    return null;
  }

  return (
    <UserListModal
      close={close}
      title="Users with the flag"
      userList={userRequest.data!.map((t) => t.userId)}
    />
  );
};
