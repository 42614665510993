import React, { FC } from 'react';
import { Row } from 'reactstrap';
import { InfoCard, PageHeader, UserStateComponent } from '../../components';
import { Fetcher, useFetcher } from '../../utils';

export const DealerDashboard: FC = () => {
  const request = useFetcher(async () => {
    return await api.getDealerStats();
  });

  if (!request.complete) {
    return <Fetcher result={request} />;
  }

  const data = request.data!;

  return (
    <UserStateComponent>
      <PageHeader>Dashboard</PageHeader>
      <Row className="justify-content-center" id="dashboard">
        <InfoCard
          className="margin-bottom-10"
          heading="Total Dealers"
          icon="people"
          id="totalDealers"
          largeText
          status="info"
          text={['', data.totalDealers.toString()]}
        />
        <InfoCard
          className="margin-bottom-10"
          heading="Approved Dealers"
          icon="people"
          id="totalApprovedDealers"
          largeText
          status="info"
          text={['', data.approvedDealers.toString()]}
        />
        <InfoCard
          className="margin-bottom-10"
          heading="Paid Dealers"
          icon="people"
          id="totalPaidDealers"
          largeText
          status="info"
          text={['', data.paidDealers.toString()]}
        />
        <InfoCard
          className="margin-bottom-10"
          heading="Approved w/o Reg"
          icon="people"
          id="totalConfirmedNoReg"
          largeText
          status="info"
          text={['', data.confirmedWithoutReg.length.toString()]}
        />
        <InfoCard
          className="margin-bottom-10"
          heading="Without Application"
          icon="people"
          id="noApplication"
          largeText
          status="info"
          text={['', data.withoutApplication.length.toString()]}
        />
      </Row>
    </UserStateComponent>
  );
};
