import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import { BadgeData, BadgeDesign } from '../../../shared/user/badgeDesign';
import { CashierBadgeData } from '../../../shared/user/cashier';
import { HotkeyButton } from '../../components';
import {
  CashierContext,
  CashierContextProps,
  getBadgePrinter,
  PrinterPreferenceMode,
} from './cashiercontext';

interface PrintBadgeData {
  badge: BadgeData;
  design: BadgeDesign;
  printer: PrinterPreferenceMode;
}

function getWithSpecialLabel(label: BadgeData, value: string): BadgeData {
  return {
    ...label,
    data: {
      ...label.data,
      special: {
        labels: value,
      },
    },
  };
}

interface PrintDirectorProps {
  label: BadgeData;
  design: BadgeDesign;
  settings: CashierContextProps;
}

const updateLabel = ({ label, design, settings }: PrintDirectorProps): PrintBadgeData[] => {
  const labels: PrintBadgeData[] = [];
  if (label.data.special) {
    let specialLabels = label.data.special.labels as string[];

    if (specialLabels.includes('MINOR') || specialLabels.includes('CHILD')) {
      labels.push({
        badge: getWithSpecialLabel(label, 'MINOR'),
        design,
        printer:
          settings.config.badgePrintPreferences.find((p) => p.id === 'minor')?.preference ??
          PrinterPreferenceMode.Primary,
      });

      return labels;
    }

    if (specialLabels.includes('STAFF') && settings.config.printSeparateStaffBadge) {
      labels.push({
        badge: getWithSpecialLabel(label, 'STAFF'),
        design,
        printer:
          settings.config.badgePrintPreferences.find((p) => p.id === 'staff')?.preference ??
          PrinterPreferenceMode.Primary,
      });

      specialLabels = specialLabels.filter((l) => l !== 'STAFF');
    }

    if (specialLabels.includes('RECRUIT') && settings.config.printSeparateStaffBadge) {
      labels.push({
        badge: getWithSpecialLabel(label, 'RECRUIT'),
        design,
        printer:
          settings.config.badgePrintPreferences.find((p) => p.id === 'staff')?.preference ??
          PrinterPreferenceMode.Primary,
      });

      specialLabels = specialLabels.filter((l) => l !== 'RECRUIT');
    }

    if (specialLabels.includes('DEALER') && settings.config.printSeparateVendorBadge) {
      labels.push({
        badge: getWithSpecialLabel(label, 'VENDOR'),
        design,
        printer:
          settings.config.badgePrintPreferences.find((p) => p.id === 'vendor')?.preference ??
          PrinterPreferenceMode.Primary,
      });

      specialLabels = specialLabels.filter((l) => l !== 'DEALER');
    }

    label.data.special.labels = specialLabels;
  }

  labels.push({
    badge: label,
    design,
    printer:
      settings.config.badgePrintPreferences.find((p) => p.id === 'attendee')?.preference ??
      PrinterPreferenceMode.Primary,
  });

  labels.reverse();

  return labels;
};

interface PrinterButtonProps {
  readonly hotkey: string;
  readonly badge: CashierBadgeData;
  readonly badgeType?: string;
  readonly isReprint?: boolean;
  readonly requireExtraConfirmation?: boolean;
  readonly outline?: boolean;
  reload(): void;
}

export const PrinterButton: FC<PrinterButtonProps> = ({
  badge,
  badgeType,
  outline,
  hotkey,
  isReprint,
  requireExtraConfirmation,
  reload,
}) => {
  const cashierSettings = useContext(CashierContext);

  const [rendering, setRendering] = useState(false);
  const [printQueue, setPrintQueue] = useState<PrintBadgeData[]>([]);

  useEffect(() => {
    if (!badge?.design) {
      return;
    }

    const queue = badge.labels.flatMap((label) =>
      updateLabel({ label, design: badge.design, settings: cashierSettings }),
    );

    for (const extra of badge.extraLabels) {
      queue.push({
        badge: extra.labels,
        design: extra.design,
        printer: PrinterPreferenceMode.Primary,
      });
    }

    setPrintQueue(queue);
  }, [
    badge,
    cashierSettings.config.printSeparateStaffBadge,
    cashierSettings.config.printSeparateVendorBadge,
    cashierSettings.config.badgePrintPreferences,
    cashierSettings.config.primaryPrinter,
    cashierSettings.config.secondaryPrinter,
  ]);

  const printBadge = useCallback(async () => {
    if (!badge?.design || rendering) {
      return;
    }

    setRendering(true);

    const printItem = printQueue[0];
    const id =
      printItem.printer === PrinterPreferenceMode.Primary ||
      !cashierSettings.config.secondaryPrinter
        ? cashierSettings.config.primaryPrinter
        : cashierSettings.config.secondaryPrinter;

    const printer = getBadgePrinter(cashierSettings, id, {});

    if (printer !== undefined) {
      await printer.print(printItem.badge, printItem.design);
    }

    setPrintQueue((queue) => queue.slice(1));
    setRendering(false);

    if (printQueue.length <= 1) {
      reload();
    }
  }, [badge, cashierSettings, printQueue, rendering, reload]);

  if (printQueue.length > 1) {
    badgeType = `Next ${badgeType ?? 'Badge'} (${printQueue.length} badge remaining)`;
  }

  return (
    <Col lg={12}>
      <HotkeyButton
        actionText="print badge"
        block
        color="primary"
        disabled={!badge || rendering}
        hotkey={hotkey}
        id="printBadge"
        onClick={printBadge}
        outline={isReprint ?? outline}
        requiredKeyPressCount={isReprint || requireExtraConfirmation ? 3 : 1}
      >
        {isReprint && 'Re-'}Print {badgeType ?? 'Badge'}
      </HotkeyButton>
    </Col>
  );
};
