import React, { FC } from 'react';
import { ProductModel } from '../../../shared/orders';
import { AddedToCart, AddedToCartCheck, LazyMarkdown } from '../../components';
import { RegistrationInfo } from '../../models';
import { Fetcher, useFetcher } from '../../utils/useFetcher';

interface Props {
  readonly product: ProductModel;
  readonly registration?: RegistrationInfo;
  modifyRegistration(): void;
}

export const EventRegistrationComplete: FC<Props> = ({
  product,
  registration,
  modifyRegistration,
}) => {
  const secondaryAction = { onClick: modifyRegistration, text: 'Modify Registration' };

  const raffleFetch = useFetcher(async () => {
    if (product?.raffle) {
      return await api.getRaffleById(product.raffle.id);
    }

    return undefined;
  }, [registration]);

  if (registration && registration.paidOrderItem?.productId === product.id) {
    return (
      <AddedToCart
        id="registrationSaved"
        product={product}
        secondaryAction={secondaryAction}
        subtext={<p>Your badge information has been updated and no new balance is due</p>}
        title="Registration Saved!"
      />
    );
  }

  if (product.raffle) {
    if (!raffleFetch.complete) {
      return <Fetcher result={raffleFetch} />;
    }

    const status = raffleFetch.data!.ticket?.status;

    if (status !== 'winner' && status !== 'redeemed' && !product.raffle.roleOverride) {
      // If volunteerOverride is false, or user isn't a volunteer or staff member
      return (
        <AddedToCart
          id="cartEnteredRaffle"
          product={product}
          secondaryAction={secondaryAction}
          subtext={<LazyMarkdown source={product.raffle.enteredSubtext} />}
          title={product.raffle.enteredTitle}
        />
      );
    }
  }

  return <AddedToCartCheck product={product} secondaryAction={secondaryAction} />;
};
