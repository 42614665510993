import React, { FC } from 'react';
import { useUser } from '../../utils';
import {
  BadgePreviewRenderer,
  getPlaceholderData,
  transformNodeMap,
} from '../badgerenderer/BadgeRender';
import { FormContext, FormStateType } from './FormReducer';

interface BadgePreviewRendererProps {
  readonly state: FormStateType;
  readonly context: FormContext;
}

export const BadgePreview: FC<BadgePreviewRendererProps> = ({ context, state }) => {
  const user = useUser()!;

  if (state.type !== 'registration' || !context.badgeDesign || !context.product) {
    return null;
  }

  const speciesOption = context.speciesOptionId;
  const species = speciesOption && state.options[speciesOption];

  const label = getPlaceholderData({
    attendanceType: context.product,
    badgeArtId: state.badgeArtId,
    badgeName: state.badgeName,
    options: state.options,
    species: (species as string) ?? '',
    user,
  });

  return (
    <BadgePreviewRenderer
      image={state.badgeArtId ? `/api/badgeart/${state.badgeArtId}/image` : ''}
      label={label}
      nodes={transformNodeMap(context.badgeDesign)}
    />
  );
};
