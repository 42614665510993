import React, { FC } from 'react';
import { NavLink, RouteComponentProps, Switch } from 'react-router-dom';
import { Nav } from 'reactstrap';
import { PageHeader, UserRoute, UserStateComponent } from '../../../components';
import { OAuthPage } from './oauthApps/OAuthPage';
import { WebhookPage } from './webhooks/WebhookPage';

export const DevelopersFrame: FC<RouteComponentProps> = ({ match: { path, url } }) => {
  return (
    <UserStateComponent>
      <PageHeader>Developers</PageHeader>
      <Nav tabs>
        <NavLink activeClassName="active" className="nav-link" exact to={url}>
          Webhooks
        </NavLink>
        <NavLink activeClassName="active" className="nav-link" to={`${url}/oauth`}>
          OAuth Applications
        </NavLink>
      </Nav>
      <div style={{ marginTop: '20px' }}>
        <Switch>
          <UserRoute
            component={WebhookPage}
            exact
            path={path}
            requirePermissions={['webhook:manage']}
          />
          <UserRoute
            component={OAuthPage}
            path={`${path}/oauth`}
            requirePermissions={['oauth:manage']}
          />
        </Switch>
      </div>
    </UserStateComponent>
  );
};
