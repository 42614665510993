import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Raffle } from '../../../../shared/orders/raffle';
import { LinkButton } from '../../../components';

interface Props {
  readonly raffle: Raffle;
}

export const RaffleListItem: FC<Props> = ({ raffle }) => {
  return (
    <Card id={`raffle${raffle.id}`}>
      <CardBody>
        <h4 style={{ marginRight: '30px' }}>
          {raffle.name} <small>(ID {raffle.id})</small>
        </h4>
        <div className="float-right">
          <LinkButton
            className="view-raffle"
            color="primary"
            outline
            to={`/housekeeping/gatekeeping/${raffle.id}`}
          >
            Manage Silo
          </LinkButton>
        </div>
      </CardBody>
    </Card>
  );
};
