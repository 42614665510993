import { DateTime, MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardFooter } from 'reactstrap';
import { UserNoteBan, UserNoteSummary } from '../../../shared/user/notes';
import { ActionButtonModal, PermissionBoundary } from '../../components';
import { capitalize, renderName } from '../../utils';
import { noteIcons } from './addNoteForm';

interface UserNoteProps {
  readonly note: UserNoteSummary;
  refresh(): void;
}

export const UserNoteItem: FC<UserNoteProps> = ({ note, refresh }) => {
  const { color, icon } = noteIcons[note.category];

  return (
    <Card className="margin-bottom-10" id={`note${note.id}`}>
      <CardBody>
        <span style={{ float: 'left', paddingRight: '10px' }}>
          <MaterialIcon medium name={icon} type={color} />
        </span>
        <PermissionBoundary inline requiredPermissions={['user:note:delete']}>
          <span style={{ float: 'right', paddingLeft: '10px' }}>
            <ActionButtonModal
              actionContent="Delete"
              buttonContent={<MaterialIcon className="delete" name="delete" />}
              color="danger"
              id="deleteNote"
              onComplete={async () => {
                await api.deleteUserNote(note.id);
                refresh();
              }}
              title="Delete note?"
            >
              Are you sure you want to delete this note?
            </ActionButtonModal>
          </span>
        </PermissionBoundary>
        <div>
          <NoteTitle note={note} />
          {note.ban && <BanPill ban={note.ban} />}
        </div>
        {note.text}
      </CardBody>
      <CardFooter>
        {capitalize(note.category)} created by{' '}
        <Link to={`/housekeeping/attendees/user/${note.author.id}`}>{renderName(note.author)}</Link>{' '}
        on <DateTime value={note.createdAt} />
      </CardFooter>
    </Card>
  );
};

const NoteTitle: FC<{ readonly note: UserNoteSummary }> = ({ note }) => {
  if (note.user) {
    return <Link to={`/housekeeping/attendees/user/${note.user.id}`}>{renderName(note.user)}</Link>;
  }

  if (note.ban) {
    return (
      <span>
        {note.ban.firstName} {note.ban.lastName}
      </span>
    );
  }

  return <span>Unknown User</span>;
};

const BanPill: FC<{ readonly ban: UserNoteBan }> = ({ ban }) => {
  return (
    <Badge color="danger" pill>
      Ban {ban.category === 'registration' ? '' : ` on ${capitalize(ban.category)}`}
    </Badge>
  );
};
