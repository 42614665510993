import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import 'react-toastify/scss/main.scss';
import { MenuLink } from '../../shared/config';
import { Header, Sidenav, SidenavItem, SupportAgentBanner, UserRoute } from '../components';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { CartComplete } from '../components/payment/Complete';
import {
  EditAccountComponent,
  EditPersonalComponent,
  OrderHistoryComponent,
} from '../containers/account';
import { useConfig, useConvention } from '../utils';
import { LazySecondFactor } from './account/secondFactor/lazy';
import { SocialLinks } from './account/sociallinks';
import { VolunteerComponent } from './apply';
import { ApplyDealerArea } from './apply/dealer/DealerArea';
import { Changelog } from './changelog';
import { ChildRegistrationComponent } from './convention';
import { Dashboard } from './dashboard';
import { NotFoundError } from './error';
import { OAuthAuthorize, OAuthLogoutComponent } from './oauth';
import { OrderTickets } from './order/tickets';
import { Privacy, TermsComponent, VendorPolicies } from './policy';
import { EditRegistration } from './register/edit';
import { ProductPageHOC } from './store/productPageHOC';
import { Cart, LazyOrderForm, NewRegistration, RegistrationList, Store } from '.';

function renderMenuLinks(menuLinks: MenuLink[], type: string): JSX.Element[] {
  return menuLinks
    .filter((t) => t.type === type)
    .map((t) => (
      <SidenavItem icon={t.icon} key={t.name} link={t.url} requireLoggedIn text={t.name} />
    ));
}

export const CoreContainer: FC = () => {
  const config = useConfig();
  const convention = useConvention();

  return (
    <ErrorBoundary>
      <Sidenav>
        <SidenavItem icon="shopping_cart" id="navCheckout" link="/cart" text="Checkout" />

        <SidenavItem header requireLoggedIn text="Dashboard" />
        <SidenavItem
          icon="supervised_user_circle"
          id="navHousekeeping"
          link="/housekeeping"
          requiredPermissions="system:login"
          text="Housekeeping"
        />
        <SidenavItem icon="dashboard" id="navDashboard" link="/" text="Dashboard" />

        <SidenavItem header requireLoggedIn text="Account Management" />
        <SidenavItem
          icon="portrait"
          id="navAccountDetails"
          link="/account/login"
          requireLoggedIn
          text="Edit Account Details"
        />
        <SidenavItem
          icon="security"
          id="navSecondFactor"
          link="/account/2fa"
          requireLoggedIn
          text="Second Factor"
        />
        <SidenavItem
          icon="portrait"
          id="navPersonalDetails"
          link="/account/personal"
          requireLoggedIn
          text="Edit Personal Details"
        />
        {config.socialLinks && (
          <SidenavItem
            icon="extension"
            id="navExternalApplications"
            link="/account/sociallinks"
            requireLoggedIn
            text="Social Links"
          />
        )}
        <SidenavItem
          icon="history"
          id="navOrderHistory"
          link="/account/orders"
          requireLoggedIn
          text="Order History"
        />

        <SidenavItem header requireLoggedIn text="Attendance" />
        <SidenavItem
          icon="how_to_reg"
          id="navConRegistration"
          link="/event/register"
          requireLoggedIn
          text={`Register for ${convention.shortName}`}
        />
        {convention.maxChildren > 0 && (
          <SidenavItem
            icon="person_add"
            id="navChildRegistration"
            link="/convention/children"
            requireLoggedIn
            text="Register Children"
          />
        )}
        {renderMenuLinks(config.menuLinks, 'attendance')}
        {convention.enableEventStore && (
          <SidenavItem
            icon="attach_money"
            id="navEventStore"
            link="/store"
            requireLoggedIn
            text="Event Store"
          />
        )}
        <ApplicationSelection />
      </Sidenav>
      <div className="page-wrap">
        <Header />
        <SupportAgentBanner />
        <Switch>
          <Route component={TermsComponent} exact path="/policy/terms" />
          <Route component={Privacy} exact path="/policy/privacy" />
          <Route component={VendorPolicies} exact path="/policy/vendor" />
          <UserRoute component={OAuthAuthorize} exact path="/oauth/authorize" />
          <UserRoute component={OAuthLogoutComponent} exact noRedirect path="/oauth/logout" />
          <UserRoute component={EditAccountComponent} exact path="/account/login" />
          <UserRoute component={LazySecondFactor} exact path="/account/2fa" />
          <UserRoute component={EditPersonalComponent} exact path="/account/personal" />
          <UserRoute component={SocialLinks} exact path="/account/sociallinks" />
          <UserRoute component={OrderHistoryComponent} exact path="/account/orders" />
          <UserRoute component={OrderTickets} exact path="/order/:id/tickets" />
          <UserRoute component={Dashboard} exact path="/" />
          <UserRoute component={Cart} exact path="/cart" />
          <UserRoute component={CartComplete} exact path="/cart/complete/:id" />
          <UserRoute component={Changelog} exact path="/changelog" />
          <UserRoute component={ProductPageHOC} exact path="/store/product/:id" />
          <UserRoute component={Store} path="/store" />
          <UserRoute component={ChildRegistrationComponent} exact path="/convention/children" />
          <UserRoute component={RegistrationList} exact path="/event/register" />
          <UserRoute component={NewRegistration} exact path="/event/register/new" />
          <UserRoute component={EditRegistration} exact path="/event/register/edit" />
          <UserRoute component={VolunteerComponent} exact path="/apply/volunteer" />
          <UserRoute component={ApplyDealerArea} path="/vendor" />
          <UserRoute component={LazyOrderForm} exact path="/pay" />
          <Route component={NotFoundError} />
        </Switch>
      </div>
    </ErrorBoundary>
  );
};

const ApplicationSelection: FC = () => {
  const config = useConfig();
  const { enableVolunteers, enableDealers } = useConvention();
  const items: JSX.Element[] = [];

  if (enableVolunteers) {
    items.push(
      <SidenavItem
        icon="people"
        id="navVolunteering"
        key="navVolunteering"
        link="/apply/volunteer"
        requireLoggedIn
        text="Volunteering"
      />,
    );
  }

  for (const link of renderMenuLinks(config.menuLinks, 'application')) {
    items.push(link);
  }

  if (enableDealers) {
    items.push(
      <SidenavItem
        icon="attach_money"
        id="navDealersArea"
        key="navDealersArea"
        link="/vendor"
        requireLoggedIn
        text="Vendor"
      />,
    );
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <SidenavItem header requireLoggedIn text="Applications" />
      {items}
    </>
  );
};
