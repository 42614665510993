export const passwordScore = 2;

export interface PrepareOTPResult {
  secret: string;
  image: string;
}

export interface PrepareBackupResult {
  codes: string[];
}

export const enum SecondFactorType {
  Otp = 'otp',
  Backup = 'backup',
}
export interface MinimalSecondFactor {
  id: number;
  type: SecondFactorType;
  data: string;
  updatedAt: Date;
}
