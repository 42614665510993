import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ProductCategoryModel, ProductModel } from '../../../../../shared/orders/product';
import { PageHeader, UserStateComponent } from '../../../../components';
import { useBoolState } from '../../../../utils';
import { LoadingWrapper } from '../../../../utils/LoadingWrapper';
import { CategoryCreateProduct } from './categoryCreateProduct';
import { CategoryInfo } from './categoryInfo';

interface LoadingData {
  category: ProductCategoryModel;
  products: ProductModel[];
}

const dataFetcher = async (id: number): Promise<LoadingData> => {
  const [category, products] = await Promise.all([
    api.getProductCategoryById(id),
    api.getFullProductsByCategory(id),
  ]);

  return { category, products };
};

export const CategoryView: FC = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const [isCreate, enableIsCreate, disabledIsCreate] = useBoolState(false);

  return (
    <UserStateComponent>
      <LoadingWrapper dataFetcher={dataFetcher} inline passback={Number.parseInt(params.id, 10)}>
        {({ category, products }) => (
          <>
            <PageHeader>Category: {category.name}</PageHeader>
            {isCreate ? (
              <CategoryCreateProduct categoryId={category.id} onToggle={disabledIsCreate} />
            ) : (
              <CategoryInfo
                categoryId={category.id}
                onToggle={enableIsCreate}
                products={products}
              />
            )}
          </>
        )}
      </LoadingWrapper>
    </UserStateComponent>
  );
};
