import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Surcharge } from '../../../models';
import { SurchargeForm } from './SurchargeForm';

interface Props {
  readonly open?: boolean;
  onUpdate(id: number): void;
  onCancel(): void;
}

export const CreateModal: FC<Props> = ({ open, onCancel, onUpdate }) => (
  <Modal id="createSurchargeModal" isOpen={open}>
    <JSONForm<{ surcharge: Surcharge }, { amount: number }>
      id="createSurchargeForm"
      method="post"
      onSuccess={({ surcharge }) => {
        onUpdate(surcharge.id);
      }}
      path="/api/surcharges"
      preSubmit={(r) => (r.inputs!.amount *= 100)}
    >
      <ModalHeader>Create Surcharge</ModalHeader>
      <ModalBody>
        <SurchargeForm />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" id="createSurcharge">
          Create Surcharge
        </Button>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </JSONForm>
  </Modal>
);
