import {
  JSONForm,
  PreSubmitData,
} from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { ProductCategoryModel } from '../../../../shared/orders/product';
import { ElementSelector, MaterialIcon, PermissionBoundary } from '../../../components';
import { useBoolState } from '../../../utils';
import { captureError } from '../../../utils/errorHandling';
import { CategoryBadges } from './CategoryBadges';
import { LocalSearchProvider } from './utils';

interface CategoryItemProps {
  readonly category: ProductCategoryModel;
  onUpdate(): void;
  redirectFn(url: string): void;
}

interface DeleteFormElements {
  deleteItems?: boolean;
  replacementCategoryId?: number;
}

export const CategoryItem: FC<CategoryItemProps> = ({ category, onUpdate, redirectFn }) => {
  const [isDeleteMode, enableDeleteMode, disableDeleteMode] = useBoolState(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [visibility, enableToggleVisbility, disableToggleVisibility] = useBoolState(
    category.isStore,
  );

  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    api.updateCategory(category.id, visibility).then(onUpdate, captureError);
  }, [visibility]);

  const searchProvider = useMemo(
    () => new LocalSearchProvider(async () => await api.getProductCategories()),
    [],
  );

  if (isDeleteMode) {
    return (
      <Card>
        <CardBody>
          <MaterialIcon
            className="float-right pointer"
            id="close"
            name="close"
            onClick={disableDeleteMode}
          />
          <h5>
            Delete Category: {category.name} <small>({category.id})</small>
          </h5>
          <p>
            In order to delete this category, you must either delete all of the associated products
            or transfer them to another category.
          </p>
          <p>
            Deleting a category is a <strong>PERMANENT</strong> action and cannot be undone.
          </p>
          <hr />
          <JSONForm<{}, DeleteFormElements>
            method="delete"
            onSuccess={onUpdate}
            path={`/api/products/categories/${category.id}`}
            preSubmit={deletePreSubmit}
          >
            <Row>
              <Col lg={5} xs={12}>
                <FormGroup>
                  <div className="custom-control custom-checkbox margin-top-10 left-align">
                    <Input
                      className="custom-control-input"
                      id="deleteItems"
                      name="deleteItems"
                      type="checkbox"
                    />
                    <Label className="custom-control-label" for="deleteItems">
                      Delete all existing items
                    </Label>
                  </div>
                </FormGroup>
              </Col>
              <Col lg={2} xs={12}>
                <h2>OR</h2>
              </Col>
              <Col lg={5} xs={12}>
                <FormGroup>
                  <Label for="newCategory">Select New Category</Label>
                  <ElementSelector<ProductCategoryModel>
                    id="categorySelector"
                    maxItems={1}
                    searchProvider={searchProvider}
                    singleSelectionIdChanged={setSelectedId}
                  />
                  <Input
                    id="replacementCategoryId"
                    name="replacementCategoryId"
                    type="hidden"
                    value={selectedId ?? ''}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="danger" id="submitDeleteCategory">
              Delete Category
            </Button>
          </JSONForm>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <div
          style={{
            minWidth: '200px',
            position: 'absolute',
            right: '15px',
            textAlign: 'right',
            top: '15px',
          }}
        >
          <UncontrolledDropdown className="mw-100">
            <DropdownToggle tag="a">
              <MaterialIcon className="pointer" id="moreOptions" name="more_horiz" />
            </DropdownToggle>
            <DropdownMenu right>
              {!category.isRegistration && (
                <>
                  <DropdownItem
                    className="text-danger"
                    id="deleteCategory"
                    onClick={enableDeleteMode}
                  >
                    Delete Category
                  </DropdownItem>
                  <hr />
                </>
              )}

              <PermissionBoundary inline requiredPermissions={['audit:read']}>
                <DropdownItem
                  tag={Link}
                  to={`/housekeeping/auditlog/ProductCategory/${category.id}`}
                >
                  View History
                </DropdownItem>
              </PermissionBoundary>
              {category.isStore ? (
                <DropdownItem id="hideCategory" onClick={disableToggleVisibility}>
                  Hide Category
                </DropdownItem>
              ) : (
                <DropdownItem id="showCategory" onClick={enableToggleVisbility}>
                  Show Category
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <h4 style={{ marginRight: '30px' }}>
          {category.name} <small>({category.id})</small>
        </h4>
        <div className="float-right">
          <Button
            color="primary"
            id="viewProducts"
            onClick={() => {
              redirectFn(`category/${category.id}`);
            }}
            outline
          >
            View Products
          </Button>
        </div>
        <div>
          <CategoryBadges category={category} />
        </div>
      </CardBody>
    </Card>
  );
};

const deletePreSubmit = ({ inputs, requestOptions }: PreSubmitData<DeleteFormElements>) => {
  if (inputs?.deleteItems) {
    requestOptions.query = {
      deleteItems: true,
    };
  }

  if (inputs && !inputs.deleteItems) {
    requestOptions.query = {
      replacementCategoryId: inputs.replacementCategoryId ?? 0,
    };
  }

  inputs = undefined;
};
