import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { ProductModel } from '../../../../shared/orders';
import { LinkButton } from '../../../components';
import { displayName } from '../../../utils';
import { ProductInfoBadges } from './utils';

interface ProductItemProps {
  readonly product: ProductModel;
}

export const ProductItem: FC<ProductItemProps> = ({ product }) => {
  return (
    <Card className="margin-bottom-10">
      <CardBody>
        <h4 style={{ marginRight: '30px' }}>
          {displayName(product)} <small>({product.id})</small>
        </h4>
        <div className="float-right">
          <LinkButton
            color={product.deletedAt ? 'secondary' : 'primary'}
            outline
            to={`/housekeeping/products/${product.id}`}
          >
            View{!product.deletedAt && ' / Edit'} Details
          </LinkButton>
        </div>
        <div className="margin-bottom-10">
          <ProductInfoBadges product={product} />
        </div>
        <div>{product.description ?? <i>No Description Provided</i>}</div>
      </CardBody>
    </Card>
  );
};
