import { MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { Badge, Button, Card, CardBody, CardTitle } from 'reactstrap';
import { EmailOverrideInfo } from '../../../../../shared/email';
import { ActionButtonModal } from '../../../../components';

interface TemplateBodyEntryProps {
  readonly body: {
    raw: string;
    rendered: string;
  };
  readonly active?: boolean;
}

export const DefaultTemplateCard: FC<TemplateBodyEntryProps> = ({ body, active }) => {
  return (
    <Card className="margin-bottom-10">
      <CardBody>
        <CardTitle className="align-middle">
          Default Template{' '}
          <span className="align-middle badge-list compact">
            {active && <Badge color="success">Current</Badge>}
          </span>
        </CardTitle>
        <hr />
        <RenderContainer data={body.rendered} />
      </CardBody>
    </Card>
  );
};

export const TemplateOverrideCard: FC<{
  readonly level: 'convention' | 'organization';
  readonly templateName: string;
  readonly override?: EmailOverrideInfo;
  readonly active?: boolean;
  enableModify(): void;
  setIsCreating(value: boolean): void;
  refresh(): void;
}> = ({ level, override, templateName, active, enableModify, setIsCreating, refresh }) => {
  return (
    <Card className="margin-bottom-10">
      <CardBody>
        <CardTitle className="align-middle">
          <span className="text-capitalize">{level} Level Override</span>{' '}
          <span className="align-middle badge-list compact">
            {active && <Badge color="success">Current</Badge>}
          </span>
          <div className="float-right">
            {!override && (
              <Button
                color="primary"
                onClick={() => {
                  setIsCreating(true);
                  enableModify();
                }}
              >
                Create Override
              </Button>
            )}
            {override && override.disabled && (
              <ActionButtonModal
                actionContent="Reactivate"
                buttonContent="Reactivate Template"
                color="success"
                id="reactivate"
                onComplete={async () => {
                  await api.deleteOverride(templateName, level);

                  refresh();
                  toast.success('Reactivation complete.');
                }}
                title="Reactivate email template?"
              >
                Are you sure you want to reactivate "{templateName}"? This {level} will be
                configured to send this email again.
                <br />
                <br />
                {level === 'organization' && (
                  <p>
                    <MaterialIcon name="warning" /> If this template contains an override at the
                    convention level, this email will still be sent.
                  </p>
                )}
              </ActionButtonModal>
            )}
            {override && !override.disabled && (
              <>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsCreating(false);
                    enableModify();
                  }}
                >
                  <MaterialIcon name="edit" />
                </Button>{' '}
                <ActionButtonModal
                  actionContent="Delete"
                  buttonContent={<MaterialIcon name="delete" />}
                  color="danger"
                  id="delete"
                  onComplete={async () => {
                    await api.deleteOverride(templateName, level);

                    refresh();
                    toast.success('Override has been deleted.');
                  }}
                  title="Delete override?"
                >
                  Are you sure you want to delete this template override?
                </ActionButtonModal>
              </>
            )}
          </div>
        </CardTitle>
        <hr />
        {!override && (
          <div className="content">No {level} level override exists for this template.</div>
        )}
        {override && override.disabled && (
          <Card className="danger">
            <CardBody className="text-center">
              <CardTitle style={{ marginBottom: 0 }}>
                This email will not be sent for this {level}.
              </CardTitle>
            </CardBody>
          </Card>
        )}
        {override && !override.disabled && <RenderContainer data={override.body.rendered} />}
      </CardBody>
    </Card>
  );
};

export const RenderContainer: FC<{ readonly data: string }> = ({ data }) => {
  const frameRef = useRef<HTMLIFrameElement>(null);

  const getLineHeight = useCallback(() => {
    if (frameRef.current?.contentDocument) {
      const frameHeight = frameRef.current.contentDocument.body.clientHeight;
      frameRef.current.style.height = `${frameHeight + 45}px`;
    }
  }, [frameRef]);

  return (
    <iframe
      className="rounded"
      onLoad={getLineHeight}
      ref={frameRef}
      referrerPolicy="same-origin"
      sandbox="allow-same-origin"
      srcDoc={`${data}`}
      style={{ width: '100%', border: '1px solid rgba(0, 0, 0, .125)' }}
    />
  );
};
