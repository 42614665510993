import * as OrderSummary from '../../../../../shared/orders/model';
import { sumBy } from '../../../../utils';

export const enum OrderStatusInfo {
  Paid,
  Unpaid,
  RequiresFulfillment,
  PartiallyFulfilled,
  Fulfilled,
  Cancelled,
  Refunded,
  PartiallyRefunded,
}

export function getOrderStatuses(order: OrderSummary.Order): OrderStatusInfo[] {
  const statuses: OrderStatusInfo[] = [];

  if (order.deletedAt) {
    statuses.push(OrderStatusInfo.Cancelled);
  } else if (order.status === 'unpaid') {
    statuses.push(OrderStatusInfo.Unpaid);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (order.status === 'paid') {
    const refunds = order.payments.filter(({ status }) => status === 'refunded');
    const payments = order.payments.filter(
      ({ status }) => status === 'success' || status === 'refunded',
    );

    if (refunds.length > 0) {
      if (
        sumBy(payments, ({ amount, status }) => (status === 'refunded' ? -amount : amount)) <= 0
      ) {
        statuses.push(OrderStatusInfo.Refunded);
      } else {
        statuses.push(OrderStatusInfo.PartiallyRefunded);
      }
    } else {
      statuses.push(OrderStatusInfo.Paid);
    }

    const fulfillableItems = order.orderItems.filter((oi) => isOrderItemFulfillable(oi));
    if (fulfillableItems.length === 0) {
      statuses.push(OrderStatusInfo.Fulfilled);
    } else if (fulfillableItems.length < order.orderItems.length) {
      statuses.push(OrderStatusInfo.PartiallyFulfilled);
    } else {
      statuses.push(OrderStatusInfo.RequiresFulfillment);
    }
  }

  return statuses;
}

export function isOrderItemFulfillable(
  item: OrderSummary.OrderItem,
  skipFulfilledCheck = false,
): boolean {
  // Already fulfilled or cancelled
  if (!skipFulfilledCheck && (item.dispatchedOn || item.cancelledOn)) {
    return false;
  }

  // Vendor products are not fulfillable
  if (item.product.category.isVendor) {
    return false;
  }

  // Registration products are only fulfillable if they contain addons
  if (item.product.category.isRegistration) {
    return false;
  }

  return item.product.category.isPhysical;
}
