import React, { FC, useMemo } from 'react';
import { FormFeedback } from 'reactstrap';
import { LocalSearchProvider } from '../containers/housekeeping/products/utils';
import { Role } from '../models';
import { useFetcher, useUser } from '../utils';
import { ElementSelector } from './ElementSelector';

interface RoleSelectionProps {
  readonly permissionError: string;
  readonly selectedIds: number[];
  setSelectedIds(vals: number[]): void;
}

export const RoleSelection: FC<RoleSelectionProps> = ({
  permissionError,
  selectedIds,
  setSelectedIds,
}) => {
  const user = useUser()!;
  const canLimitByRole = user.permissions.includes('system:permission:read');
  const rolesFetcher = useFetcher(async () => {
    if (!canLimitByRole) {
      return [];
    }

    return await api.getRoles();
  });

  const searchProvider = useMemo(
    () => new LocalSearchProvider(() => rolesFetcher.data ?? []),
    [rolesFetcher.data],
  );

  if (!rolesFetcher.complete) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ElementSelector<Role>
        defaultSelected={selectedIds.map((id) => {
          const entry = rolesFetcher.data!.find((t) => t.id === id);
          if (entry) {
            return entry;
          }

          return { id } as Role;
        })}
        disabled={!canLimitByRole}
        id="roleSelector"
        searchProvider={searchProvider}
        selectionIdsChanged={setSelectedIds}
      />
      {!canLimitByRole && (
        <div>
          <FormFeedback invalid>{permissionError}</FormFeedback>
        </div>
      )}
    </>
  );
};
