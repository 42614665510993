import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { Surcharge } from '../../../models';
import { displayName } from '../../../utils';
import { surchargeRateText, surchargeTitle } from './utils';

interface SurchargesListItemProps {
  readonly surcharge: Surcharge;
}

export const SurchargesListItem: FC<SurchargesListItemProps> = ({ surcharge }) => {
  const history = useHistory();

  return (
    <>
      <Col
        className="margin-bottom-10 surcharge-item"
        id={`surcharge${surcharge.id}`}
        lg={5}
        xs={12}
      >
        <Card>
          <CardBody>
            <div className="float-right">
              <Button
                className="surcharge-item-view-details"
                color={surcharge.deletedAt ? 'secondary' : 'primary'}
                onClick={() => {
                  history.push(`/housekeeping/surcharges/${surcharge.id}`);
                }}
                outline
              >
                View{!surcharge.deletedAt && ' / Edit'} Details
              </Button>
            </div>
            <h4 style={{ marginRight: '30px' }}>
              {displayName(surcharge)} <small>(ID: {surcharge.id})</small>
            </h4>
            <div>
              {surchargeTitle(surcharge)} of {surchargeRateText(surcharge)}
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xs={12} />
    </>
  );
};
