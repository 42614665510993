import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useMemo, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';
import { ProductModel } from '../../../../../shared/orders';
import { ActionButton, ElementHeader, ElementSelector, MaterialIcon } from '../../../../components';
import { displayName } from '../../../../utils';
import { LocalSearchProvider } from '../utils';

interface UpgradeInfoProps {
  readonly product: ProductModel;
  readonly onUpdate: () => void;
}

const enum UpgradeInfoMode {
  View,
  Edit,
}

export const UpgradeInfo: FC<UpgradeInfoProps> = ({ product, onUpdate }) => {
  const [mode, setMode] = useState(UpgradeInfoMode.View);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const searchProvider = useMemo(
    () => new LocalSearchProvider(async () => await api.getProducts()),
    [],
  );

  if (mode === UpgradeInfoMode.View) {
    return (
      <Card className="margin-top-10">
        <CardHeader>
          <div className="card-sub-header">Upgrade Paths</div>
          {!product.deletedAt && (
            <div className="float-right">
              <Button
                color="success"
                id="edit"
                onClick={() => {
                  setMode(UpgradeInfoMode.Edit);
                }}
                title="Add"
              >
                <MaterialIcon name="add" />
              </Button>
            </div>
          )}
        </CardHeader>
        <CardBody>
          {(!product.upgrades || product.upgrades.length === 0) && (
            <i>There are currently no upgrade paths.</i>
          )}
          {product.upgrades &&
            product.upgrades.length > 0 &&
            product.upgrades.map((u) => (
              <ElementHeader
                icon={{ iconName: 'arrow_upward' }}
                key={u.id}
                rightContent={
                  <ActionButton
                    action={`/api/products/${product.id}/upgrades/${u.id}`}
                    className="action-delete"
                    color="danger"
                    method="delete"
                    onSuccess={onUpdate}
                    outline
                    title="Delete"
                  >
                    <MaterialIcon name="delete" />
                  </ActionButton>
                }
                title={displayName(u)}
              >
                <Badge color="success" pill>
                  Upgrade
                </Badge>
              </ElementHeader>
            ))}
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="margin-top-10">
      <CardHeader>
        <div className="card-sub-header">Upgrade Paths</div>
        <div className="float-right">
          <Button
            color="danger"
            id="cancel"
            onClick={() => {
              setMode(UpgradeInfoMode.View);
            }}
            title="Cancel"
          >
            <MaterialIcon name="cancel" />
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <JSONForm<undefined, { upgradeIds: number[] }>
          method="post"
          onSuccess={() => {
            onUpdate();
            setMode(UpgradeInfoMode.View);
          }}
          path={`/api/products/${product.id}/upgrades`}
          preSubmit={(r) => (r.inputs!.upgradeIds = selectedIds)}
        >
          <FormGroup>
            <Label for="product">Product Search</Label>
            <ElementSelector<ProductModel>
              id="upgradeProductSelector"
              searchProvider={searchProvider}
              selectionIdsChanged={setSelectedIds}
            />
          </FormGroup>
          <Button block color="primary">
            Create Upgrades
          </Button>
        </JSONForm>
      </CardBody>
    </Card>
  );
};
