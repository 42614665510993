import React, { FC, useRef } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import {
  ActionButton,
  DataViewItem,
  DropdownItemProps,
  ElementHeader,
  PaginatedDataView,
  UserStateComponent,
} from '../../../components';
import { DateTime } from '../../../components/Date';
import { Surcharge, SurchargeOrderItem, SurchargeProduct } from '../../../models';
import { displayName, TableKVPair, useBoolState } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { AssignModal } from './AssignModal';
import { surchargeRateText, surchargeTitle } from './utils';

export const SurchargeItem: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [openModal, enableModal, disableModal] = useBoolState(false);
  const associatedProductsRef = useRef<(() => void) | null>(null);

  return (
    <UserStateComponent>
      <LoadingWrapper<Surcharge | undefined, number>
        dataFetcher={async (id) => await api.getSurchargeById(id)}
        inline
        passback={Number.parseInt(match.params.id, 10)}
      >
        {(surcharge, surchargeRefresh) => {
          if (!surcharge) {
            return <Redirect to="/housekeeping/surcharges" />;
          }

          const typeText = surchargeTitle(surcharge);
          const rateText = surchargeRateText(surcharge);
          return (
            <>
              <ElementHeader
                icon={{ iconName: 'list' }}
                title={`${displayName(surcharge)} – ID ${surcharge.id}`}
              >
                <div className="badge-list compact">
                  <span className="pretext">
                    {typeText} of {rateText}
                  </span>
                  <Badge color={surcharge.deletedAt ? 'secondary' : 'success'}>
                    {surcharge.deletedAt ? 'Deleted / Archived' : 'Active'}
                  </Badge>
                </div>
              </ElementHeader>
              <Row className="surcharge-info justify-content-center">
                <Col lg={4} xs={12}>
                  <Card>
                    <CardHeader>{typeText}</CardHeader>
                    <CardBody>
                      <table className="table">
                        <tbody>
                          <TableKVPair
                            displayName="Name / Key"
                            name="name"
                            value={surcharge.name}
                          />
                          <TableKVPair displayName="Type" name="type" value={typeText} />
                          <TableKVPair displayName="Rate" name="rate" value={rateText} />
                          <TableKVPair
                            displayName="Calculation Method"
                            name="calculation"
                            value="Exclusive"
                          />
                          <TableKVPair displayName="Created" name="createdAt" value="Exclusive" />
                          {surcharge.deletedAt && (
                            <TableKVPair
                              displayName="Archived"
                              name="deletedAt"
                              value={<DateTime value={surcharge.deletedAt} />}
                            />
                          )}
                        </tbody>
                      </table>
                    </CardBody>
                    {!surcharge.deletedAt && (
                      <CardFooter>
                        <Row>
                          <Col lg={6} xs={12}>
                            <Button
                              block
                              className="assign-surcharge"
                              color="primary"
                              onClick={enableModal}
                            >
                              Assign to Product
                            </Button>
                          </Col>
                          <Col lg={6} xs={12}>
                            <ActionButton
                              action={`/api/surcharges/${surcharge.id}`}
                              block
                              className="delete-surcharge"
                              color="danger"
                              disabled={surcharge.internal}
                              method="delete"
                              onSuccess={surchargeRefresh}
                              outline
                            >
                              {surcharge.internal ? 'Cannot Be Deleted' : `Delete ${typeText}`}
                            </ActionButton>
                          </Col>
                        </Row>
                      </CardFooter>
                    )}
                  </Card>
                </Col>
                <Col lg={6} xs={12}>
                  <Card className="surcharge-associated-products margin-bottom-10">
                    <CardHeader>Associated Products</CardHeader>
                    <PaginatedDataView<SurchargeProduct>
                      dataFetcher={async (page) =>
                        await api.getSurchargeProductsById(surcharge.id, page)
                      }
                      noResultsText={`No products are associated with this ${typeText}`}
                      pageSize={8}
                      refreshRef={associatedProductsRef}
                      renderer={({ createdAt, product }, refresh) => (
                        <DataViewItem
                          content={
                            <div className="badge-list compact">
                              {product.deletedAt ?? surcharge.deletedAt ? (
                                <Badge color="secondary">Archived</Badge>
                              ) : (
                                <Badge color="success">Assigned</Badge>
                              )}
                            </div>
                          }
                          dropdownItems={getProductDropdownOptions(surcharge, product.id, refresh)}
                          id={`product${product.id}`}
                          rightElement={<DateTime value={createdAt} />}
                          subtitle={`ID: ${product.id}`}
                          title={displayName(product)}
                        />
                      )}
                    />
                  </Card>
                  <Card className="surcharge-associated-orderitems">
                    <CardHeader>Associated Order Items</CardHeader>
                    <PaginatedDataView<SurchargeOrderItem>
                      dataFetcher={async (page) =>
                        await api.getSurchargeOrderItemsById(surcharge.id, page)
                      }
                      noResultsText={`No order items have been processed with this ${typeText}`}
                      pageSize={8}
                      renderer={({ createdAt, orderItem }) => (
                        <DataViewItem
                          content={
                            <div className="badge-list compact">
                              {orderItem.order!.status === 'paid' ? (
                                <Badge color="success">Paid</Badge>
                              ) : (
                                <Badge color="secondary">Unpaid</Badge>
                              )}
                            </div>
                          }
                          dropdownItems={[
                            {
                              className: 'view-order',
                              to: `/housekeeping/orders/${orderItem.orderId}`,
                              text: 'View Order',
                            },
                          ]}
                          id={`orderItem${orderItem.id}`}
                          rightElement={<DateTime value={createdAt} />}
                          subtitle={`Order #${orderItem.orderId}, OrderItem ID #${orderItem.id}`}
                          title={`${displayName(orderItem.product!)} – ${orderItem.order!.userId}`}
                        />
                      )}
                    />
                  </Card>
                </Col>
              </Row>
              <AssignModal
                onCancel={disableModal}
                onUpdate={() => {
                  toast.success('Assigned Surcharge successfully.');
                  disableModal();
                  if (associatedProductsRef.current) {
                    associatedProductsRef.current();
                  }
                }}
                open={openModal}
                surchargeId={surcharge.id}
              />
            </>
          );
        }}
      </LoadingWrapper>
    </UserStateComponent>
  );
};

function getProductDropdownOptions(
  surcharge: Surcharge,
  productId: number,
  refresh: () => void,
): DropdownItemProps[] {
  const out: DropdownItemProps[] = [
    { className: 'view-product', to: `/housekeeping/products/${productId}`, text: 'View Product' },
  ];

  if (!surcharge.deletedAt) {
    out.push({
      className: 'delete-product-surcharge',
      isDangerous: true,
      onClick: async () => {
        await api.deleteSurchargeProduct(productId, surcharge.id);
        toast.success(`Removed surcharge from Product #${productId}`);
        refresh();
      },
      text: 'Unassign',
    });
  }

  return out;
}
