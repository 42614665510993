import React, { FC, useCallback, useState } from 'react';
import { NavLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Nav } from 'reactstrap';
import { DealerConfig } from '../../../shared/dealer';
import { PageHeader, UserStateComponent } from '../../components';
import {
  ConfigBooleanSetting,
  ConfigNumberBoxSetting,
  ConfigTextBoxSetting,
} from '../../containers/housekeeping/settings/AbstractLiveSettings';
import { TermsElement } from '../../containers/housekeeping/settings/Terms';
import { Fetcher, useFetcher } from '../../utils';
import { captureError } from '../../utils/errorHandling';

export const DealerSettingsPage: FC<RouteComponentProps> = ({ match: { path, url } }) => (
  <UserStateComponent>
    <PageHeader>Dealer Settings</PageHeader>
    <Nav tabs>
      <NavLink activeClassName="active" className="nav-link" exact to={url}>
        General
      </NavLink>
      <NavLink activeClassName="active" className="nav-link" to={`${url}/terms`}>
        Terms
      </NavLink>
    </Nav>
    <div style={{ marginTop: '20px' }}>
      <Switch>
        <Route component={DealerSettingsGeneral} exact path={path} />
        <Route component={DealerTermsSettings} exact path={`${path}/terms`} />
      </Switch>
    </div>
  </UserStateComponent>
);

const DealerTermsSettings: FC = () => {
  const fetcher = useFetcher(async () => {
    return await api.getPolicies();
  }, []);

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return (
    <div>
      <TermsElement
        description="Policy to be displayed to vendors when submitting an application."
        displayName="Vendor Policy"
        name="vendor"
        policies={fetcher.data ?? []}
        refresh={fetcher.refresh}
      />
    </div>
  );
};

const DealerSettingsGeneral: FC = () => {
  const fetcher = useFetcher(async () => {
    return await api.getDealerConfig();
  });

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return <DealerSettingsKitchenSink initialConfig={fetcher.data!} />;
};

const DealerSettingsKitchenSink: FC<{ readonly initialConfig: DealerConfig }> = ({
  initialConfig,
}) => {
  const [config, setConfig] = useState(initialConfig);
  const [saving, setSaving] = useState(false);

  const save = useCallback(async () => {
    setSaving(true);
    try {
      await api.updateDealerConfig(config);
      toast.success('Dealer settings have been updated!');
    } catch (error) {
      captureError(error as Error);
    }

    setSaving(false);
  }, [config]);

  return (
    <>
      <h3>Dealers</h3>

      <ConfigBooleanSetting
        description="Enables / disables the dealers module."
        displayName="Enable Dealers Module"
        name="enableDealers"
        saving={saving}
        update={(val) => {
          setConfig((old) => {
            return { ...old, enableDealers: val! };
          });
        }}
        value={config.enableDealers}
      />

      <ConfigNumberBoxSetting
        description="The number of dealer assistants a dealer can have. 0 to disable."
        displayName="Dealer assistant count limit"
        name="dealerAssistantLimit"
        saving={saving}
        update={(val) => {
          setConfig((old) => {
            return { ...old, dealerAssistantLimit: val! };
          });
        }}
        value={config.dealerAssistantLimit}
      />

      <ConfigTextBoxSetting
        description="Contact for dealer applications. This email will be used as the 'from' address for dealer applications."
        displayName="Dealers Email"
        name="dealers"
        placeholder="dealers"
        saving={saving}
        update={(val) => {
          setConfig((old) => {
            return { ...old, dealerEmail: val! };
          });
        }}
        value={config.dealerEmail ?? ''}
      />

      <Button
        className="float-right"
        color="primary"
        disabled={saving}
        id="saveSettings"
        onClick={save}
      >
        Save
      </Button>
    </>
  );
};
