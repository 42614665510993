import { Breadcrumb, InlineHelp, MaterialIconText } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback } from 'react';
import { Button, Col, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { PreviousYearAttendanceCriterionOptions } from '../../../../../shared/orders/raffle';
import { useNumericInputState } from '../../../../utils';
import { RaffleConfiguratorProps } from '../utils';

export const PrevYearsAttended: FC<
  RaffleConfiguratorProps<PreviousYearAttendanceCriterionOptions>
> = ({ defaultOptions, displayName, onBack, onCancel, onConfigure }) => {
  const [previousYears, setPreviousYears] = useNumericInputState(
    defaultOptions?.previousYears ?? 0,
  );

  const onFinish = useCallback(() => {
    onConfigure({ name: 'prevYearAttendance', options: { previousYears } });
  }, [onConfigure, previousYears]);

  return (
    <>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <Breadcrumb
              items={[
                { text: `Display Name: ${displayName ?? 'None'}` },
                { text: 'Type: Previous Years Attended' },
              ]}
            />
            <MaterialIconText name="keyboard_backspace" onClick={onBack}>
              Go Back
            </MaterialIconText>
            <hr />
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="value">
                Maximum Number of Years
                <InlineHelp>
                  <p>
                    This sets the maximum number of years that will be taken into account for how
                    long the attendee has attended. In calculations this is represented by a number
                    from 0 to the maximum.
                  </p>
                  <p>
                    For example: If the maximum is set to 3 (three) then an attendee with 5 (five)
                    years attendance will only have 3 (three) of those counted.
                  </p>
                </InlineHelp>
              </Label>
              <Input
                id="value"
                name="value"
                onChange={setPreviousYears}
                required
                type="number"
                value={previousYears}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="editor-finish-btn"
          color="primary"
          disabled={!previousYears || previousYears <= 0}
          onClick={onFinish}
        >
          Finish
        </Button>
        <Button className="editor-cancel-btn" color="secondary" onClick={onCancel} outline>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};
