import React, { FC } from 'react';
import { ProductModel } from '../../../shared/orders';
import { ConRegistrationForm } from '../../../shared/registration/model';
import { CurrentUser } from '../../../shared/user/base';
import { RegistrationInfo } from '../../models';
import { RegistrationDynamicInfoForm } from './DynamicRegForm';
import { OldRegistrationForm } from './OldRegistrationForm';

interface RegistrationFormProps {
  readonly form: ConRegistrationForm;
  readonly registration?: RegistrationInfo;
  readonly user: CurrentUser;
  readonly attendanceType: ProductModel;
  readonly askForChildren?: boolean;
  readonly hasChildren?: boolean;
  onSuccess(hasChildren?: boolean): void;
}

export const RegistrationForm: FC<RegistrationFormProps> = ({
  onSuccess,
  form,
  registration,
  attendanceType,
  user,
  askForChildren,
  hasChildren,
}) => {
  if (form.formLayout) {
    return (
      <RegistrationDynamicInfoForm
        askForChildren={askForChildren}
        attendanceType={attendanceType}
        hasChildren={hasChildren}
        onSuccess={onSuccess}
        regForm={form}
        registration={registration}
        user={user}
      />
    );
  }

  return (
    <OldRegistrationForm
      askForChildren={askForChildren}
      attendanceType={attendanceType}
      hasChildren={hasChildren}
      onSuccess={onSuccess}
      regForm={form}
      registration={registration}
      user={user}
    />
  );
};
