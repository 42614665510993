/* eslint-disable import/no-unused-modules */
import React, { FC, useCallback } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { captureError } from '../utils/errorHandling';

interface ActionButtonProps {
  readonly id: string;
  readonly title: string;
  readonly children: React.ReactNode;
  // Button inside of the modal
  readonly actionContent: React.ReactNode;
  readonly isOpen: boolean;

  readonly color: 'danger' | 'primary' | 'secondary' | 'success';

  close(): void;
  onComplete(): Promise<void>;
}

export const ActionModal: FC<ActionButtonProps> = (props) => {
  const complete = useCallback(async () => {
    try {
      await props.onComplete();
      props.close();
    } catch (error) {
      captureError(error as Error);
    }
  }, [props.onComplete, props.close]);

  return (
    <Modal id={`${props.id}Modal`} isOpen={props.isOpen} toggle={props.close}>
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter>
        <Button color={props.color} id="complete" onClick={complete}>
          {props.actionContent}
        </Button>{' '}
        <Button color="secondary" id="cancel" onClick={props.close} outline>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
