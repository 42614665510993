import React, { Dispatch, FC, useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { useCheckboxState } from '../utils';
import { getDate } from './Date';

interface TimeZoneInputFieldProps {
  readonly required?: boolean;
  readonly id: string;
  readonly defaultValue: Date;
  readonly timeZone: string;
  readonly min?: Date;
  readonly disabled?: boolean;
  readonly onChange: Dispatch<(prevState: Date) => Date>;
}

export const TimeZoneInputField: FC<TimeZoneInputFieldProps> = ({
  defaultValue,
  id,
  min,
  timeZone,
  required,
  disabled,
  onChange,
}) => {
  // Change from the convention time to the browser time
  const mid = changeTimezone(defaultValue, 'UTC', true);
  const browserDate = changeTimezone(mid, timeZone);

  return (
    <Input
      defaultValue={browserDate.toISOString().slice(0, 16)}
      disabled={disabled}
      id={id}
      min={min?.toISOString().slice(0, 16)}
      onChange={(e) => {
        const newDate = new Date(e.currentTarget.value);

        if (newDate) {
          const offsetValue = changeTimezone(newDate, timeZone, true);
          if (!Number.isNaN(offsetValue.getTime())) {
            onChange(() => offsetValue);
          }
        }
      }}
      required={required}
      type="datetime-local"
    />
  );
};

interface NullableTimeZoneInputFieldProps {
  readonly required?: boolean;
  readonly id: string;
  readonly defaultValue: Date | null;
  readonly timeZone: string;
  readonly min?: Date;
  readonly disabled?: boolean;
  readonly onChange: Dispatch<(prevState: Date | null) => Date | null>;
}

// eslint-disable-next-line import/no-unused-modules
export const NullableTimeZoneInputField: FC<NullableTimeZoneInputFieldProps> = ({
  defaultValue,
  id,
  min,
  timeZone,
  required,
  disabled,
  onChange,
}) => {
  const [checked, setChecked] = useCheckboxState(defaultValue !== null);
  const [value, setValue] = useState(
    () => defaultValue ?? migrateTimezone(trimTime(new Date()), timeZone, true),
  );

  useEffect(() => {
    onChange(() => (checked ? value : null));
  }, [value, checked]);

  return (
    <div className="custom-control custom-checkbox">
      <Input
        checked={checked}
        className="custom-control-input"
        disabled={disabled}
        id={`show${id}`}
        onChange={setChecked}
        type="checkbox"
      />
      <Label className="custom-control-label" for={`show${id}`} style={{ width: '100%' }}>
        <TimeZoneInputField
          defaultValue={value}
          // Disabled can be undefined, `=== true` is required.
          disabled={disabled === true || !checked}
          id={id}
          min={min}
          onChange={setValue}
          required={required}
          timeZone={timeZone}
        />
      </Label>
    </div>
  );
};

export function trimTime(input: Date): Date {
  return new Date(getDate(input));
}

export function migrateTimezone(input: Date, target: string, endOfDay?: boolean): Date {
  const before = new Date(input);
  const mid = changeTimezone(before, 'UTC');
  const newDate = changeTimezone(mid, target, true);

  if (endOfDay) {
    newDate.setDate(newDate.getDate() + 1);
    newDate.setMinutes(newDate.getMinutes() - 1);
  }

  return newDate;
}

function changeTimezone(date: Date, ianatz?: string, inverse = false): Date {
  // Taken from https://stackoverflow.com/a/53652131/99966
  const invdate = new Date(date.toLocaleString('en-US', { timeZone: ianatz }));

  const diff = date.getTime() - invdate.getTime();
  return new Date(date.getTime() - (inverse ? -diff : diff));
}
