import React, { FC, useState } from 'react';
import { Link, Redirect, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Breadcrumb, Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import * as OrderSummary from '../../../../../shared/orders/model';
import { ExtendedUser } from '../../../../../shared/user/extended';
import {
  ActionButton,
  Breadcrumb as BreadcrumbComponent,
  PermissionBoundary,
  RefundRequestModal,
  UserStateComponent,
} from '../../../../components';
import { isResourceError } from '../../../../utils';
import { LoadingWrapper } from '../../../../utils/LoadingWrapper';
import { captureError } from '../../../../utils/errorHandling';
import { OrderListItems, OrderPaymentTableItem, OrderUserInfo, OrderVouchers } from './components';
import { OrderAddProductModal } from './orderAddProductModal';
import { OrderFulfillModal } from './orderFulfillModal';
import { OrderRefundModal } from './orderRefundModal';
import { getOrderStatuses, OrderStatusInfo } from './utils';
import { DeteteOrderVoucherModal, OrderCreateVoucherModal } from './voucherModal';

const markOrderAsPaid = async (order: OrderSummary.Order, onSuccess: () => void) => {
  try {
    await (order.breakdown.total - order.breakdown.paid <= 0
      ? api.settleOrder(order.id)
      : api.pay('writeoff', 'none', order.userId));

    toast.success('Successfully marked order as paid.');
    onSuccess();
  } catch (error) {
    captureError(error as Error);
  }
};

type ModalType =
  | 'add_product'
  | 'create_voucher'
  | 'delete_voucher'
  | 'fulfill'
  | 'refund_request'
  | 'refund';

async function fetchOrder(
  orderId: number,
): Promise<{ order: OrderSummary.Order; user?: ExtendedUser }> {
  const order = await api.getOrderById(orderId);
  let user: ExtendedUser | undefined;

  try {
    user = await api.getExtendedUser(order.userId);
  } catch (error) {
    // The user may be deleted, but the order still exists.
    if (!isResourceError(error, 'User')) {
      throw error;
    }
  }

  return { order, user };
}

export const OrderInfo: FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const orderId = Number.parseInt(match.params.id, 10);

  return (
    <UserStateComponent>
      <LoadingWrapper<{ order: OrderSummary.Order; user?: ExtendedUser }, void>
        dataFetcher={async () => await fetchOrder(orderId)}
        inline
      >
        {({ order, user }, refresh) => {
          return <OrderInfoDetails order={order} refresh={refresh} user={user} />;
        }}
      </LoadingWrapper>
    </UserStateComponent>
  );
};

interface OrderInfoDetailsProps {
  readonly order: OrderSummary.Order;
  readonly user?: ExtendedUser;
  refresh(): void;
}

const OrderInfoDetails: FC<OrderInfoDetailsProps> = ({ order, user, refresh }) => {
  const [modalOpen, setModalOpen] = useState<ModalType | undefined>(undefined);
  const [deleteVoucherId, setDeleteVoucherId] = useState<number>(0);

  if (!order.orderItems) {
    return <Redirect to="/housekeeping/orders" />;
  }

  const status = getOrderStatuses(order);

  const modalData = {
    onCancel: () => {
      setModalOpen(undefined);
    },
    onSuccess: () => {
      refresh();
      setModalOpen(undefined);
    },
    order,
  };

  return (
    <>
      {modalOpen === 'fulfill' && <OrderFulfillModal {...modalData} />}
      {modalOpen === 'refund' && <OrderRefundModal {...modalData} />}
      {modalOpen === 'create_voucher' && <OrderCreateVoucherModal {...modalData} />}
      {modalOpen === 'delete_voucher' && (
        <DeteteOrderVoucherModal voucherId={deleteVoucherId} {...modalData} />
      )}
      {modalOpen === 'add_product' && user && <OrderAddProductModal user={user} {...modalData} />}
      {modalOpen === 'refund_request' && <RefundRequestModal {...modalData} />}

      <Row className="justify-content-center" id={`order${order.id}`}>
        <Col style={{ marginTop: '.15em', marginBottom: '2em' }} xs={12}>
          <BreadcrumbComponent
            items={[
              { text: 'Orders', url: '/housekeeping/orders' },
              { active: true, text: `Order #${order.id}` },
            ]}
          />
        </Col>
        <Col lg={8} xs={12}>
          <Breadcrumb className="button-list">
            <OrderPaymentState status={status} />
            <div className="separator" />
            <OrderActions order={order} refresh={refresh} setModalOpen={setModalOpen} />
            <PermissionBoundary inline requiredPermissions={['audit:read']}>
              <Button
                color="secondary"
                outline
                tag={Link}
                to={`/housekeeping/auditlog/Order/${order.id}`}
              >
                Order History
              </Button>
            </PermissionBoundary>
          </Breadcrumb>
        </Col>
        <Col lg={5} xs={12}>
          <OrderListItems
            onUpdate={() => {
              refresh();
            }}
            openAddProductModal={() => {
              setModalOpen('add_product');
            }}
            order={order}
            status={status}
          />
        </Col>
        <Col className="margin-bottom-10" id="customerInfo" lg={3} xs={12}>
          <Card>
            <CardHeader>Customer Info</CardHeader>
            <CardBody>
              {user ? <OrderUserInfo user={user} /> : <div>User#{order.userId} is deleted.</div>}
            </CardBody>
          </Card>
        </Col>
        <OrderPaymets payments={order.payments} />
        <OrderVouchers
          openAddVoucherModal={() => {
            setModalOpen('create_voucher');
          }}
          openDeleteVoucherModal={(id) => {
            setDeleteVoucherId(id);
            setModalOpen('delete_voucher');
          }}
          order={order}
        />
      </Row>
    </>
  );
};

interface OrderActionsProps {
  readonly order: OrderSummary.Order;
  refresh(): void;
  setModalOpen(type: ModalType): void;
}

const OrderActions: FC<OrderActionsProps> = ({ order, refresh, setModalOpen }) => {
  const status = getOrderStatuses(order);

  function orderCancelled(): void {
    refresh();
    toast.success('Order has been cancelled!');
  }

  if (status.includes(OrderStatusInfo.Cancelled)) {
    return (
      <Button
        color="secondary"
        outline
        tag={Link}
        to={`/housekeeping/attendees/user/${order.userId}`}
      >
        Customer Profile
      </Button>
    );
  }

  if (status.includes(OrderStatusInfo.Unpaid)) {
    return (
      <>
        <PermissionBoundary inline requiredPermissions={['registration:cashier']}>
          <Button
            color="primary"
            id="markAsPaid"
            onClick={async () =>
              await markOrderAsPaid(order, () => {
                refresh();
              })
            }
          >
            Mark as Paid
          </Button>
        </PermissionBoundary>
        <Link className="button-link" to={`/housekeeping/attendees/user/${order.userId}`}>
          <Button color="secondary" outline>
            Customer Profile
          </Button>
        </Link>
        <PermissionBoundary inline requiredPermissions={['order:delete']}>
          <div className="separator" />
          <ActionButton
            action={`/api/orders/${order.id}`}
            color="danger"
            id="cancelOrder"
            method="delete"
            onSuccess={orderCancelled}
            outline
          >
            Cancel Order
          </ActionButton>
        </PermissionBoundary>
      </>
    );
  }

  const isUnfulfilled =
    status.includes(OrderStatusInfo.RequiresFulfillment) ||
    status.includes(OrderStatusInfo.PartiallyFulfilled);

  return (
    <>
      {isUnfulfilled && (
        <PermissionBoundary inline requiredPermissions={['order:fulfill']}>
          <Button
            color="primary"
            id="markAsFulfilled"
            onClick={() => {
              setModalOpen('fulfill');
            }}
          >
            Mark as Fulfilled
          </Button>
        </PermissionBoundary>
      )}
      <Link className="button-link" to={`/housekeeping/attendees/user/${order.userId}`}>
        <Button color="secondary" outline>
          Customer Profile
        </Button>
      </Link>
      {!status.includes(OrderStatusInfo.Refunded) && (
        <PermissionBoundary inline requiredPermissions={['refund:create']}>
          <div className="separator" />
          <Button
            color="danger"
            id="refundRequestOrder"
            onClick={() => {
              setModalOpen('refund_request');
            }}
            outline
          >
            Create Refund Request
          </Button>
          <Button
            color="danger"
            id="refundOrder"
            onClick={() => {
              setModalOpen('refund');
            }}
            outline
          >
            Refund Order
          </Button>
        </PermissionBoundary>
      )}
    </>
  );
};

const OrderPaymets: FC<{ readonly payments?: OrderSummary.Payment[] }> = ({ payments }) => {
  if (!payments || payments.length === 0) {
    return null;
  }

  return (
    <Col lg={8} xs={12}>
      <Card className="margin-bottom-10">
        <CardHeader>Payments</CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Status</th>
                <th>Gateway</th>
                <th>Gateway ID</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <OrderPaymentTableItem key={payment.id} payment={payment} />
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

const OrderPaymentState: FC<{ readonly status: OrderStatusInfo[] }> = ({ status }) => {
  if (status.includes(OrderStatusInfo.Cancelled)) {
    return (
      <div className="text text-secondary" id="orderCancelled">
        Cancelled
      </div>
    );
  }

  if (status.includes(OrderStatusInfo.Unpaid)) {
    return (
      <div className="text text-danger" id="orderNotPaid">
        Not Paid
      </div>
    );
  }

  if (status.includes(OrderStatusInfo.PartiallyRefunded)) {
    return (
      <div className="text text-info" id="orderPartiallyRefunded">
        Paid, but Partially Refunded
      </div>
    );
  }

  if (status.includes(OrderStatusInfo.Refunded)) {
    return (
      <div className="text text-info" id="orderRefunded">
        Paid, but Refunded
      </div>
    );
  }

  return (
    <div className="text text-success" id="orderPaid">
      Paid
    </div>
  );
};
