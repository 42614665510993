import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { Input, Label } from 'reactstrap';

interface NullableCheckboxLabelProps {
  readonly id: string;
  readonly name?: string;
  readonly value?: number | null;
  readonly disabled?: boolean;
  readonly defaultValue?: number | null;
  readonly min?: number;
  readonly max?: number;
  readonly step?: number;
  onChange?(value: number | null): void;
}

export const NullableNumberInput: FC<NullableCheckboxLabelProps> = ({
  id,
  min,
  max,
  defaultValue,
  disabled,
  children,
  step,
  name,
  value,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState<number>(
    value === undefined ? defaultValue ?? 1 : value ?? 1,
  );

  const [checked, setChecked] = useState(
    value === undefined ? defaultValue !== null : value !== null,
  );

  const inputCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = Number.parseFloat(e.currentTarget.value);
      setInputValue(newValue);
      if (checked && !Number.isNaN(newValue)) {
        onChange?.(newValue);
      }
    },
    [checked],
  );

  return (
    <>
      {name && <input name={name} type="hidden" value={checked ? inputValue : ''} />}
      <Label for={id}>{children}</Label>
      <div className="custom-control custom-checkbox">
        <Input
          checked={checked}
          className="custom-control-input"
          disabled={disabled}
          id={`${id}-checkbox`}
          onChange={(e) => {
            setChecked(e.target.checked);
            onChange?.(e.target.checked ? inputValue : null);
          }}
          type="checkbox"
        />
        <Label className="custom-control-label" for={`${id}-checkbox`} style={{ width: '100%' }}>
          <Input
            defaultValue={inputValue ?? ''}
            disabled={!checked || disabled}
            id={id}
            max={max}
            min={min}
            onChange={inputCallback}
            step={step}
            type="number"
          />
        </Label>
      </div>
    </>
  );
};
