import { SurchargeModel } from '../../../../shared/orders/product';
import { cToUsdStrPref } from '../../../utils/cToUsdStr';

export const surchargeTitle = (surcharge: Pick<SurchargeModel, 'internal' | 'type'>): string => {
  if (surcharge.type === 'tax') {
    return 'Tax Rate';
  }

  return surcharge.internal ? 'Platform Fee' : 'Surcharge';
};

export const surchargeRateText = (
  surcharge: Pick<SurchargeModel, 'amount' | 'amountType' | 'type'>,
): string => {
  return surcharge.type === 'tax' || surcharge.amountType === 'percent'
    ? `${surcharge.amount / 100}%`
    : cToUsdStrPref(surcharge.amount);
};
