import React, { FC, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Input,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import {
  DealerArea,
  DealerFullModel,
  dealerStatusList,
  DealerStatusTypes,
} from '../../../../shared/dealer';
import { MaterialIcon, PageHeader, UserStateComponent } from '../../../components';
import { displayName, toggleElementInArray, useNumericInputState } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { Fetcher, useFetcher } from '../../../utils/useFetcher';
import { DealerDetails } from './dealerDetails';
import { tableTypeArchiveWarning } from './util';

export const Dealers: FC = () => (
  <UserStateComponent>
    <Switch>
      <Route component={DealersList} exact path="/housekeeping/dealers/list" />
      <Route component={DealerDetails} path="/housekeeping/dealers/list/:id" />
    </Switch>
  </UserStateComponent>
);

async function getDealerAreas(): Promise<{ areas: DealerArea[] }> {
  const areas = await api.getDealerAreas();

  return { areas };
}

const DealersList: FC = () => {
  const [statusFilter, setStatusFilter] = useState(dealerStatusList.map((t) => t.key));
  const [areaId, setAreaIdEV, setAreaId] = useNumericInputState(0, true);

  const dealers = useFetcher(async () => {
    return areaId ? await api.getAllDealers([areaId]) : [];
  }, [areaId]);

  return (
    <>
      <PageHeader>Dealers</PageHeader>
      <LoadingWrapper
        dataFetcher={async () => {
          const result = await getDealerAreas();
          setAreaId(result.areas[0]?.id);
          return result;
        }}
      >
        {({ areas }) => (
          <>
            <Row className="justify-content-between">
              <Col xs={3}>
                <Row className="align-items-center">
                  <Col className="col-md-auto align-self-center align-middle">
                    <Label for="dealerAreaSelect">Filter by Area:</Label>
                  </Col>
                  <Col className="p-0">
                    <Input
                      id="dealerAreaSelect"
                      onChange={setAreaIdEV}
                      type="select"
                      value={areaId}
                    >
                      {areas.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <ButtonGroup className="margin-bottom-10" style={{ float: 'right' }}>
                  <DealersListStatusFilters
                    areaId={areaId}
                    dealers={dealers.data ?? []}
                    selected={statusFilter}
                    update={setStatusFilter}
                  />
                </ButtonGroup>
              </Col>
            </Row>
            <Fetcher result={dealers}>
              <DealersListTableRows
                areaId={areaId}
                dealers={dealers.data!}
                statusFilter={statusFilter}
              />
            </Fetcher>
          </>
        )}
      </LoadingWrapper>
    </>
  );
};

interface DealersListStatusFiltersProps {
  readonly areaId: number;
  readonly selected: DealerStatusTypes[];
  readonly update: (name: DealerStatusTypes[]) => void;
  readonly dealers: DealerFullModel[];
}

const DealersListStatusFilters: FC<DealersListStatusFiltersProps> = ({
  areaId,
  selected,
  update,
  dealers,
}) => (
  <>
    {dealerStatusList.map((filter) => {
      const active = selected.includes(filter.key);
      const count = dealers.filter((dealer) =>
        dealer.applications.some((a) => a.status === filter.key && a.areaId === areaId),
      ).length;

      return (
        <Button
          active={active}
          color={active ? 'primary' : ''}
          id={`${filter.key}button`}
          key={filter.key}
          onClick={() => update(toggleElementInArray(selected, filter.key))}
        >
          {filter.name} ({count})
        </Button>
      );
    })}
  </>
);

interface DealersListTableRowsProps {
  readonly dealers: DealerFullModel[];
  readonly statusFilter: string[];
  readonly areaId: number;
}

const DealersListTableRows: FC<DealersListTableRowsProps> = ({ dealers, statusFilter, areaId }) => {
  const rows: JSX.Element[] = [];
  const filteredDealers = dealers;

  filteredDealers.sort((a, b) => a.name.localeCompare(b.name));

  for (const dealer of filteredDealers) {
    for (const application of dealer.applications) {
      if (areaId !== application.areaId) {
        continue;
      }

      if (!statusFilter.includes(application.status)) {
        continue;
      }

      rows.push(
        <tr id={`application${application.id}`} key={`${dealer.id}-${application.id}`}>
          <td>
            <Link to={`/housekeeping/dealers/list/${dealer.id}`}>{dealer.name}</Link>
          </td>
          <td>
            <Link to={`/housekeeping/attendees/user/${dealer.user.id}`}>
              {dealer.user.preferredName ?? dealer.user.firstName} {dealer.user.lastName}
            </Link>{' '}
            {dealer.user.hasRestrictionNotice ? (
              <Badge className="has-restriction-notice" color="danger" key="hasRestrictionNotice">
                Restriction
              </Badge>
            ) : undefined}
          </td>
          <td>
            {application.status}{' '}
            {application.status === 'approved' && application.paidOrderItemId && '(Paid)'}
            {application.status === 'approved' && !dealer.registration?.paid && (
              <UnpaidRegistration />
            )}
          </td>
          <td>{application.dealerArea.name}</td>
          <td>
            {application.tableType && displayName(application.tableType)}
            {application.tableType &&
              tableTypeArchiveWarning(application.id, application.tableType)}
          </td>
          <td>{application.tableNumber}</td>
        </tr>,
      );
    }
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Store</th>
          <th>User</th>
          <th>Status</th>
          <th>Area</th>
          <th>Table Type</th>
          <th>Table</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

const UnpaidRegistration: FC = () => {
  return (
    <>
      <span className="inline-icon" id="attendanceTypeMismatch">
        <MaterialIcon name="warning" type="warning" />
      </span>
      <UncontrolledPopover placement="bottom" target="attendanceTypeMismatch" trigger="hover">
        <PopoverBody>
          <p>This user does not have an active paid registration.</p>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};
