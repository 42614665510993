import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import * as Shared from '../../../../shared/options';
import { OptionEditor } from '../../../components/OptionEditor';
import { Fetcher, useFetcher } from '../../../utils';
import { captureError } from '../../../utils/errorHandling';

interface OptionElementProps {
  readonly optionId: number;
  readonly source: Shared.OptionSource;
}

export const OptionElement: FC<OptionElementProps> = ({ optionId, source }) => {
  const result = useFetcher(async () => {
    return await api.getOption(source, optionId);
  }, [optionId]);

  if (!result.complete) {
    return <Fetcher result={result} />;
  }

  return <OptionElementInner option={result.data!} reload={result.refresh} source={source} />;
};

interface OptionElementInnerProps {
  readonly option: Shared.OptionInternal;
  readonly source: Shared.OptionSource;
  reload(): void;
}

const OptionElementInner: FC<OptionElementInnerProps> = ({
  option: defaultOption,
  source,
  reload,
}) => {
  const [option, setOption] = useState(defaultOption);
  const [isEditing, setIsEditing] = useState(false);

  const saveChanges = useCallback(async () => {
    try {
      await api.saveOption(source, option.id, {
        name: option.name,
        max: option.max,
        min: option.min,
        type: option.type,
        selectValues: option.selectValues,
        displayOnCashier: option.displayOnCashier,
        source,
      });

      toast.success('Option has been updated!');
      reload();
    } catch (error) {
      captureError(error as Error);
    }
  }, [option, source]);

  return (
    <Card id="optionEditor">
      <CardBody>
        <h5>Editing Option: {option.name}</h5>
        <hr />
        <OptionEditor option={option} setIsEditing={setIsEditing} setOption={setOption} />
        <Row className="justify-content-center">
          <Col lg={6} xs={12}>
            <Button
              block
              color="primary"
              disabled={isEditing}
              id="submitOption"
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
