import React, { FC } from 'react';
import { Badge } from 'reactstrap';
import { Order } from '../../../../../../shared/orders/model';
import { MaterialIcon } from '../../../../../components';
import { getOrderStatuses, OrderStatusInfo } from '../utils';

interface OrderListItemProps {
  readonly order: Order;
}

export const OrderListItem: FC<OrderListItemProps> = ({ order }) => {
  return (
    <div className="table-item" id={`order${order.id}`}>
      <MaterialIcon className="icon" large name="receipt" />
      <p>
        Order #{order.id} &mdash; {(order.orderItems || []).length} Item(s)
      </p>
      <p className="badge-list small">
        <OrderBadges order={order} />
      </p>
    </div>
  );
};

export const OrderBadges: FC<OrderListItemProps> = ({ order }) => {
  const statuses = getOrderStatuses(order);
  const badges: JSX.Element[] = [];

  for (const status of statuses) {
    switch (status) {
      case OrderStatusInfo.Paid: {
        badges.push(
          <Badge color="success" key="paid">
            Paid
          </Badge>,
        );

        break;
      }

      case OrderStatusInfo.Unpaid: {
        badges.push(
          <Badge color="danger" key="unpaid">
            Unpaid
          </Badge>,
        );

        break;
      }

      case OrderStatusInfo.Cancelled: {
        badges.push(
          <Badge color="secondary" key="cancelled">
            Cancelled
          </Badge>,
        );

        break;
      }

      case OrderStatusInfo.PartiallyRefunded: {
        badges.push(
          <Badge color="info" key="prtiallyRefunded">
            Partially Refunded
          </Badge>,
        );

        break;
      }

      case OrderStatusInfo.Refunded: {
        badges.push(
          <Badge color="info" key="refunded">
            Refunded
          </Badge>,
        );

        break;
      }

      case OrderStatusInfo.RequiresFulfillment: {
        badges.push(
          <Badge color="warning" key="requiresFulfillment">
            Requires Fulfillment
          </Badge>,
        );

        break;
      }

      case OrderStatusInfo.PartiallyFulfilled: {
        badges.push(
          <Badge color="warning" key="partiallyFulfilled">
            Requires Partial Fulfillment
          </Badge>,
        );

        break;
      }

      case OrderStatusInfo.Fulfilled: {
        badges.push(
          <Badge color="success" key="fulfilled">
            Fulfilled
          </Badge>,
        );

        break;
      }
    }
  }

  return <>{badges}</>;
};
