import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback } from 'react';
import { ReCAPTCHA } from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { StripeIdentityUser } from '../../../../shared/user/identity';
import { passwordScore } from '../../../../shared/user/password';
import { PasswordChangeInput, RegisterUserInfo, UniqueAttributeInput } from '../../../components';
import { Address } from '../../../models';
import { ActionType } from '../../../services/ActionTypes';
import { useConfig, useConvention } from '../../../utils';
import { getConventionIdParam } from '../getConventionIdParam';

interface RegistrationModel extends Address {
  'g-recaptcha-response': string | undefined;
  bornAt: string | null;
  phone: string | null;
  preferredName: string;
  firstName: string;
  lastName: string;
}

interface RegisterResponse {
  verified: boolean;
}

function injectCaptcha(data: RegistrationModel, headers: Headers): void {
  headers.append('Recaptcha', data['g-recaptcha-response']!);
  delete data['g-recaptcha-response'];
}

interface RegisterFormProps {
  readonly onComplete: () => void;
  readonly forceValues?: StripeIdentityUser;
  readonly identityId?: string;
}

export const RegisterForm: FC<RegisterFormProps> = (props) => {
  const { onComplete, forceValues, identityId } = props;
  const history = useHistory();
  const { captcha } = useConfig();
  const convention = useConvention();
  const dispatch = useDispatch();

  const registerSuccess = useCallback(async (data: { verified: boolean }) => {
    if (data.verified) {
      const user = await api.getActiveUser();
      dispatch({ type: ActionType.LoginSuccess, user });
      history.push(`/login${getConventionIdParam()}`);
    } else {
      onComplete();
    }
  }, []);

  return (
    <Col lg={6} xs={12}>
      <Card>
        <CardBody className="text-center">
          <div id="step3Section">
            <CardTitle>Account Creation</CardTitle>
            <hr />
            <JSONForm<RegisterResponse, RegistrationModel>
              method="post"
              onSuccess={registerSuccess}
              path="/api/users"
              preSubmit={({ inputs, requestOptions: { headers } }) => {
                injectCaptcha(inputs!, headers as Headers);
              }}
            >
              <IdentityHiddenInput identityId={identityId} />
              <ConventionHiddenInput />
              <FormGroup id="usernameGroup" row>
                <Label for="username" sm={4}>
                  Username
                </Label>
                <Col sm={8}>
                  <UniqueAttributeInput type="username" />
                  <FormText color="muted">
                    Your username is an identifier that uniquely identifies your account.
                  </FormText>
                </Col>
              </FormGroup>
              <FormGroup id="emailGroup" row>
                <Label for="email" sm={4}>
                  Email Address
                </Label>
                <Col sm={8}>
                  <UniqueAttributeInput type="email" />
                </Col>
              </FormGroup>
              <FormGroup id="newsletterGroup" row>
                <Col sm={4} />
                <Col sm={8}>
                  <div className="custom-control custom-checkbox margin-top-10 left-align">
                    <Input
                      className="custom-control-input"
                      defaultChecked
                      id="newsletter"
                      name="newsletter"
                      type="checkbox"
                    />
                    <Label className="custom-control-label" for="newsletter">
                      I want to receive {convention.longName}'s e-mail newsletter.
                    </Label>
                  </div>
                </Col>
              </FormGroup>
              <PasswordChangeInput passwordScore={passwordScore} sm={8} />
              <RegistrationPii forceValues={forceValues} />
              {captcha?.enabled && (
                <>
                  <hr />
                  <FormGroup className="justify-content-center" row>
                    <ReCAPTCHA
                      onChange={() => {
                        /* noop */
                      }}
                      sitekey={captcha.siteKey}
                      size="normal"
                    />
                  </FormGroup>
                </>
              )}
              <hr />
              <Row className="justify-content-center">
                <Col className="margin-top-10" lg={6} xs={12}>
                  <Button block color="primary" id="regFormSubmit" type="submit">
                    Register
                  </Button>
                </Col>
              </Row>
            </JSONForm>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

interface RegistrationPiiProps {
  readonly forceValues?: StripeIdentityUser;
}

const RegistrationPii: FC<RegistrationPiiProps> = ({ forceValues }) => {
  const conv = useConvention();

  if (conv.registrationRequirePii) {
    return (
      <>
        <hr />
        <RegisterUserInfo forceValues={forceValues} />
      </>
    );
  }

  return null;
};

export const IdentityHiddenInput: FC<{ readonly identityId?: string }> = ({ identityId }) => {
  if (identityId) {
    return <Input name="identityId" type="hidden" value={identityId} />;
  }

  return null;
};

const ConventionHiddenInput: FC = () => {
  const params = new URLSearchParams(window.location.search);
  const conventionId = Number.parseInt(params.get('conventionId') ?? '', 10);
  if (!Number.isNaN(conventionId)) {
    return <Input name="conventionId" type="hidden" value={conventionId} />;
  }

  return null;
};
