import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC } from 'react';
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import * as OrderSummary from '../../../../../shared/orders/model';
import { displayName } from '../../../../utils';
import { isOrderItemFulfillable } from './utils';

interface OrderFulfillModalProps {
  readonly order: OrderSummary.Order;
  onSuccess(): void;
  onCancel(): void;
}

export const OrderFulfillModal: FC<OrderFulfillModalProps> = ({ order, onCancel, onSuccess }) => {
  const unfulfilledItems = order.orderItems.filter((oi) => isOrderItemFulfillable(oi));

  return (
    <Modal className="modal-large" id="fulfillModal" isOpen>
      <JSONForm method="patch" onSuccess={onSuccess} path={`/api/users/${order.userId}/fulfill`}>
        <ModalHeader>Fulfill Order #{order.id}</ModalHeader>
        <ModalBody>
          <p>
            The selected items will be marked as fulfilled in full and provided to the attendee when
            submitted.
            <br />
            At this time, it is not possible to partially fulfill an order item.
          </p>
          <Table striped>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Fulfilled?</th>
              </tr>
            </thead>
            <tbody>
              {unfulfilledItems.map((item) => (
                <OrderFulfillModalItem item={item} key={item.id} />
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel} type="button">
            Cancel
          </Button>{' '}
          <Button color="primary" id="fulfillOrder" type="submit">
            Fulfill Order
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};

interface OrderFulfillModalItemProps {
  readonly item: OrderSummary.OrderItem;
}

const OrderFulfillModalItem: FC<OrderFulfillModalItemProps> = ({ item }) => {
  return (
    <tr>
      <td>{displayName(item.product)}</td>
      <td>
        <div className="custom-control custom-checkbox">
          <Input
            className="custom-control-input"
            id={`orderItem${item.id}`}
            name="orderItemIds[]"
            type="checkbox"
            value={item.id}
          />
          <Label className="custom-control-label" for={`orderItem${item.id}`} />
        </div>
      </td>
    </tr>
  );
};
