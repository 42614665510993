import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { OAuthApplicationModel } from '../../../../../shared/oauth';
import { PermissionName } from '../../../../../shared/permissions';
import { ActionButtonModal, MaterialIcon } from '../../../../components';
import { omit } from '../../../../utils';
import { captureError } from '../../../../utils/errorHandling';
import { OAuthManageModal } from './OAuthModal';

interface OAuthTableProps {
  readonly applications: OAuthApplicationModel[];
  readonly permissions: PermissionName[];
  readonly onChange: () => void;
}

// eslint-disable-next-line import/no-unused-modules
export const OAuthTable: FC<OAuthTableProps> = ({ applications, permissions, onChange }) => {
  const [editEntry, setEditEntry] = useState<OAuthApplicationModel | null>(null);
  if (applications.length === 0) {
    return <p>No applications created</p>;
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {applications.map((app) => (
            <tr key={app.id}>
              <td>{app.id}</td>
              <td>{app.name}</td>
              <td>
                <Button color="primary" id={`app-${app.id}-edit`} onClick={() => setEditEntry(app)}>
                  Edit
                </Button>
                <ActionButtonModal
                  actionContent="Delete"
                  buttonContent={<MaterialIcon name="delete" />}
                  className="indent"
                  color="danger"
                  id={`delete-${app.id}`}
                  onComplete={async () => {
                    await api.deleteOAuthApplication(app.id);
                    toast.success('Application deleted!');
                    onChange();
                  }}
                  title="Delete application?"
                >
                  Are you sure you want to delete {app.name}?
                </ActionButtonModal>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editEntry && (
        <EditModal
          close={() => setEditEntry(null)}
          permissions={permissions}
          refresh={onChange}
          startApp={editEntry}
        />
      )}
    </>
  );
};

const EditModal: FC<{
  readonly startApp: OAuthApplicationModel;
  readonly permissions: PermissionName[];
  refresh(): void;
  close(): void;
}> = ({ startApp, permissions, close, refresh }) => {
  const [app, setApp] = useState<OAuthApplicationModel>(startApp);

  const onComplete = useCallback(
    async (cancelled: boolean) => {
      close();
      if (cancelled) {
        return;
      }

      try {
        await api.updateOAuthApplication(app.id, omit(app, 'id'));

        toast.success('Application updated!');
        refresh();
      } catch (error) {
        captureError(error as Error);
      }
    },
    [app],
  );

  return (
    <OAuthManageModal
      app={app}
      onClose={onComplete}
      open
      permissions={permissions}
      setApp={setApp}
    />
  );
};
