import { MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { Dispatch, FC, useCallback } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { FormActionType } from './FormReducer';

interface RandomizeBadgeInfoButtonProps {
  readonly dispatch: Dispatch<FormActionType>;
}

export const RandomizeBadgeInfoButton: FC<RandomizeBadgeInfoButtonProps> = ({ dispatch }) => {
  const randomizeBadgeInfo = useCallback(() => {
    dispatch({ type: 'randomizeBadgeInfo' });
  }, [dispatch]);

  return (
    <FormGroup>
      <Label for="randomBadgeInfo">Randomize</Label>
      <Button
        className="btn-block"
        color="primary"
        id="randomBadgeInfo"
        onClick={randomizeBadgeInfo}
        outline
      >
        <MaterialIcon className="align-middle" name="shuffle" />
      </Button>
    </FormGroup>
  );
};
