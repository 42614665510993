import React, { FC, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { MaterialIcon } from '..';
import { OptionSelectValue } from '../../../shared/options';
import { useInputState } from '../../utils';

interface OptionEditorSelectAddProps {
  readonly allowPrice: boolean;
  readonly existing?: OptionSelectValue;
  onCreate(newOption: OptionSelectValue): void;
}

export const OptionEditorSelectAdd: FC<OptionEditorSelectAddProps> = ({
  onCreate,
  allowPrice,
  existing,
}) => {
  const [value, setValue] = useInputState(existing?.value ?? '');
  const [displayName, setDisplayName] = useInputState(existing?.displayName ?? null, true);
  const [priceModifier, setPriceModifier] = useState(existing?.priceModifier ?? 0);

  return (
    <tr id="newSelectValueForm">
      <td />
      <td>
        <Input disabled={!!existing} id="value" maxLength={255} onChange={setValue} value={value} />
      </td>
      <td>
        <Input
          id="displayName"
          maxLength={255}
          onChange={setDisplayName}
          value={displayName ?? ''}
        />
      </td>
      {allowPrice && (
        <td>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">$</div>
            </div>
            <Input
              defaultValue={priceModifier}
              id="amount"
              onChange={(ev) => {
                setPriceModifier(ev.currentTarget.valueAsNumber * 100);
              }}
              step="0.01"
              type="number"
            />
          </div>
        </td>
      )}
      <td className="text-right">
        <Button
          color="success"
          id="submitNewOptionValue"
          onClick={() => {
            onCreate({ value, displayName, priceModifier });
          }}
          outline
          type="button"
        >
          <MaterialIcon className="align-middle" name="done" />
        </Button>
      </td>
    </tr>
  );
};
