import { Option, OptionDataPayload } from '../../shared/options';
import { RegistrationUpsert } from '../../shared/registration/model';

export function cleanupOptions(options: Option[], data: OptionDataPayload): OptionDataPayload {
  const result: OptionDataPayload = {};

  for (const option of options) {
    const id = option.id;

    if (id in data) {
      result[id] = data[id];
    }
  }

  return result;
}

export function cleanupRegOptions(options: Option[], data: RegistrationUpsert): RegistrationUpsert {
  return {
    ...data,
    options: cleanupOptions(options, data.options),
  };
}
