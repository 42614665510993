import React, { FC, useMemo } from 'react';
import { OrderSearch, OrderSearchFilters, ProductModel } from '../../../../../shared/orders';
import { useQuery } from '../../../../utils';
import { OrderListComponent } from '../../attendee/orders/orderList';

const filtersOptions: { name: string; key: OrderSearchFilters }[] = [
  { name: 'Paid', key: 'paid' },
  { name: 'Unpaid', key: 'unpaid' },
  { name: 'Cancelled', key: 'cancelled' },
  { name: 'With grant', key: 'grant' },
  { name: 'Requires Fulfillment', key: 'unfulfilled' },
];

export const ProductOrders: FC<{ readonly product: ProductModel }> = ({ product }) => {
  const params = useQuery();
  const page = Number.parseInt(params.get('page') ?? '0', 10);
  const filterParam = params.get('filters') ?? '';

  const search = useMemo<OrderSearch>(() => {
    const filters = filterParam.split(',').filter((t) => t.length > 0);
    return {
      filters: filters as OrderSearchFilters[],
      page,
      productId: product.id,
    };
  }, [page, filterParam]);

  return <OrderListComponent options={filtersOptions} search={search} />;
};
