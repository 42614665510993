import React, { FC } from 'react';
import { CardBody, CardHeader, FormGroup } from 'reactstrap';
import { RoleSelection } from '../../../../components/RoleSelection';

interface RoleSelectorProps {
  readonly selectedIds: number[];
  setSelectedIds(vals: number[]): void;
}

export const RoleSelector: FC<RoleSelectorProps> = ({ selectedIds, setSelectedIds }) => {
  return (
    <>
      <CardHeader>Role override</CardHeader>
      <CardBody>
        <FormGroup>
          <p>
            Allow users that are members of specific roles to bypass the silo and create a
            registration.
          </p>
          <RoleSelection
            permissionError="You do not have sufficient permission to set bypass roles on the silo."
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        </FormGroup>
      </CardBody>
    </>
  );
};
