import { Breadcrumb, MaterialIconText } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback } from 'react';
import { Button, Col, ModalBody, ModalFooter, Row } from 'reactstrap';
import { emptyFallback } from '../../../../utils';
import { RaffleConfiguratorProps } from '../utils';

export const RandomNumber: FC<RaffleConfiguratorProps> = ({
  displayName,
  onBack,
  onCancel,
  onConfigure,
}) => {
  const onFinish = useCallback(() => {
    onConfigure({ name: 'cryptoRandom' });
  }, [onConfigure]);

  return (
    <>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <Breadcrumb
              items={[
                { text: `Display Name: ${emptyFallback(displayName, 'None')}` },
                { text: 'Type: Random Number' },
              ]}
            />
            <MaterialIconText name="keyboard_backspace" onClick={onBack}>
              Go Back
            </MaterialIconText>
            <hr />
          </Col>
          <Col xs={12}>
            <p>
              <i>There are no configuration options for this component.</i>
            </p>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="editor-finish-btn" color="primary" onClick={onFinish}>
          Finish
        </Button>
        <Button className="editor-cancel-btn" color="secondary" onClick={onCancel} outline>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};
