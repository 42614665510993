/* eslint-disable import/no-unused-modules */
import { InlineHelp, JSONForm, MaterialIcon, PreSubmitData } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import { Raffle, RunRaffleResults } from '../../../../../shared/orders/raffle';
import { Loading, RadioButtonGroup } from '../../../../components';
import { UserSelector } from '../../../../components/UserSelector';
import { useBoolState } from '../../../../utils';
import { captureError } from '../../../../utils/errorHandling';

interface Props {
  readonly raffle: Raffle;
}

export const RunRaffleComponent: FC<Props> = ({ raffle }) => {
  const [isRunning, setRunning, setNotRunning] = useBoolState(false);
  const [results, setResults] = useState<RunRaffleResults>();
  const onSuccess = useCallback((raffleResults: RunRaffleResults) => {
    setNotRunning();
    setResults(raffleResults);
  }, []);

  const onFailure = useCallback((err: Error) => {
    setNotRunning();
    captureError(err);
    return true;
  }, []);

  const closeModal = useCallback(() => {
    setResults(undefined);
  }, []);

  return (
    <Card>
      <CardBody>
        {isRunning && (
          <Loading>
            <p>Running Raffle. This may take a few minutes...</p>
          </Loading>
        )}
        {results ? (
          <RaffleResultsViewer onClick={closeModal} results={results} />
        ) : (
          <DrawRaffleModalForm
            onFailure={onFailure}
            onSubmit={setRunning}
            onSuccess={onSuccess}
            raffle={raffle}
          />
        )}
      </CardBody>
    </Card>
  );
};

interface FormProps {
  readonly raffle: Raffle;
  onSuccess(results: RunRaffleResults): void;
  onFailure(err: Error): boolean;
  onSubmit(): void;
}

const DrawRaffleModalForm: FC<FormProps> = ({ raffle, onFailure, onSubmit, onSuccess }) => {
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [runType, setRunType] = useState<string>(raffle.criterion ? 'criterion' : 'user');

  const preSubmit = useCallback(
    ({ inputs }: PreSubmitData<{ customRecipients: number[] }>) => {
      if (runType === 'user') {
        inputs!.customRecipients = selectedUsers;
      }

      onSubmit();
    },
    [onSubmit, runType, selectedUsers],
  );

  return (
    <JSONForm<RunRaffleResults, { customRecipients: number[] }>
      method="post"
      onFailure={onFailure}
      onSuccess={onSuccess}
      path={`/api/raffles/${raffle.id}/run`}
      preSubmit={preSubmit}
    >
      <ModalBody>
        <FormGroup>
          <Label>Run type</Label>
          <RadioButtonGroup
            defaultSelected={runType}
            id="runType"
            onChange={setRunType}
            options={[
              {
                label: 'Criterion',
                value: 'criterion',
                icon: 'public',
                disabled: !raffle.criterion,
              },
              { label: 'User selection', value: 'user', icon: 'link' },
            ]}
          />
        </FormGroup>
        {runType === 'criterion' && (
          <FormGroup>
            <Label>Number of Winners to draw</Label>
            <Input id="maxWinners" min={1} name="maxWinners" required step={1} type="number" />
          </FormGroup>
        )}
        {runType === 'user' && (
          <FormGroup>
            <Label>Select users to win</Label>
            <UserSelector id="customRecipients" selectionIdsChanged={setSelectedUsers} />
          </FormGroup>
        )}

        <FormGroup>
          <Label>
            What should happen to existing winners?
            <InlineHelp>
              <ul>
                <li>
                  Do Nothing will keep all current winners in the 'winner' state, allowing them to
                  purchase the raffled products.
                </li>
                <li>
                  Revoke existing wins will mark all unredeemed wins as revoked, making them
                  ineligible to purchase the raffled products or enter the raffle again.
                </li>
                <li>
                  Reset existing wins will set all unredeemed wins as waiting / pending win so they
                  can be re-drawn. During this time they will be ineligible to purchase raffled
                  products.
                </li>
                <li>Delete existing wins will remove all unredeemed winning tickets.</li>
              </ul>
            </InlineHelp>
          </Label>
          <Input id="currentWinnersAction" name="currentWinnersAction" type="select">
            <option value="nothing">Do Nothing</option>
            <option value="revoke">Revoke existing wins</option>
            <option value="reset">Reset existing wins</option>
            <option value="delete">Delete existing wins</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>
            What should happen to non-winners in this draw?
            <InlineHelp>
              <ul>
                <li>
                  Do Nothing will keep all non-winners in the raffle with their existing tickets.
                </li>
                <li>
                  Revoke tickets will mark all non-winner tickets as revoked, making them ineligible
                  to enter the raffle again.
                </li>
                <li>Delete tickets will remove all non-winner tickets.</li>
              </ul>
            </InlineHelp>
          </Label>
          <Input id="nonWinnerAction" name="nonWinnerAction" type="select">
            <option value="nothing">Do Nothing</option>
            <option value="revoke">Revoke tickets</option>
            <option value="delete">Delete tickets</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <div className="custom-control custom-checkbox mt-3">
            <Input
              className="custom-control-input"
              defaultChecked
              id="closeRaffle"
              name="closeRaffle"
              type="checkbox"
            />
            <Label className="custom-control-label" for="closeRaffle">
              Close the raffle to new entries.
            </Label>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="custom-control custom-checkbox mt-3">
            <Input
              className="custom-control-input"
              id="givePointsToLosers"
              name="givePointsToLosers"
              type="checkbox"
            />
            <Label className="custom-control-label" for="givePointsToLosers">
              Give non-winners a ticket point
            </Label>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="custom-control custom-checkbox mt-3">
            <Input className="custom-control-input" id="testRun" name="testRun" type="checkbox" />
            <Label className="custom-control-label" for="testRun">
              Perform a test run (Don't save the results)
            </Label>
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" id="drawRaffleBtn" type="submit">
          Run Raffle Draw
        </Button>
      </ModalFooter>
    </JSONForm>
  );
};

interface ResultProps {
  readonly results: RunRaffleResults;
  onClick(): void;
}

const RaffleResultsViewer: FC<ResultProps> = ({ onClick, results }) => {
  const entryCount = results.weights.length;
  const winnerCount = results.winners.length;

  if (entryCount === 0) {
    return (
      <>
        <ModalBody className="text-center" id="raffleResultsError">
          <div>
            <MaterialIcon large name="whatshot" type="danger" />
            <h4>Raffle Draw Compl-- Wait, what?</h4>
          </div>
          <hr />
          <p>
            There's currently no one entered into the raffle, so we tried to calculate the win rate
            using 0 divided by 0, and the server caught fire.
          </p>
          <p>
            <small>
              (It's not really on fire! But, you may want to wait until people enter first!)
            </small>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClick} outline>
            Close
          </Button>
        </ModalFooter>
      </>
    );
  }

  return (
    <>
      <ModalBody className="text-center" id="raffleResults">
        <div>
          <MaterialIcon large name="check_circle" type="success" />
          <h4>{results.testRun ? 'Test Run Draw Results' : 'Raffle Draw Complete!'}</h4>
        </div>
        <hr />
        <Row>
          <Col className="mb-3" id="raffleTotalWinners" lg={6} xs={12}>
            <h4>Number of Winners</h4>
            <h5>{winnerCount}</h5>
          </Col>
          <Col className="mb-3" id="raffleTotalEntrants" lg={6} xs={12}>
            <h4>Total Entries</h4>
            <h5>{entryCount}</h5>
          </Col>
          <Col id="raffleWinPercentage" xs={12}>
            <h4>Win Percentage</h4>
            <h5>{((winnerCount / entryCount) * 100).toFixed(2)} %</h5>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
          <Col className="text-left" id="raffleWinnerIds" xs={12}>
            <h4>Winning User IDs</h4>
            <p>{results.winners.join(', ')}</p>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClick} outline>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};
