import { Option, OptionDataPayload } from '../options';
import { ProductModel } from '../orders/product';

export type DealerStatusTypes = 'approved' | 'pending' | 'rejected' | 'waitlisted';

export const dealerStatusList: { name: string; key: DealerStatusTypes }[] = [
  { name: 'Pending', key: 'pending' },
  { name: 'Approved', key: 'approved' },
  { name: 'Waitlisted', key: 'waitlisted' },
  { name: 'Rejected', key: 'rejected' },
];

export interface DealerOptionInput extends Option {
  applicationOnly: boolean;
  hidden: boolean;
  required: boolean;
  internalName: string;
  sortOrder: number;
}

export interface DealerApplicationOptionInput extends Option {
  required: boolean;
  productIds: number[];
}

export interface DealerModel {
  name: string;
  email: string;
  addressLine1: string;
  addressLine2: string | null;
  addressCity: string;
  addressZipcode: string;
  addressCountry: string;
  addressState: string;
  options: OptionDataPayload;
}

export interface UpdateDealerApplicationPayload {
  requests: number[];
  tableTypeId: number;
  specialRequests: string;

  status: DealerStatusTypes;
  tableNumber: string | null;
  options: OptionDataPayload;
}

export interface DealerFullModel extends DealerModel {
  id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    preferredName?: string;
    hasRestrictionNotice: boolean;
  };
  registration?: {
    id: number;
    paid: boolean;
  };
  publicID: string;
  applications: DealerApplication[];
}

export interface DealerRequestProduct
  extends Pick<ProductModel, 'deletedAt' | 'displayName' | 'id' | 'name'> {
  paid: boolean;
}

export interface DealerApplication {
  id: number;
  areaId: number;
  specialRequests?: string;
  status: DealerStatusTypes;
  tableNumber?: string;
  paidOrderItemId: number | null;
  dealerRequestTypes: DealerRequestProduct[];
  tableType: DealerRequestProduct;
  dealerArea: DealerArea;
  options: OptionDataPayload;
}

export interface DealerArea {
  id: number;
  name: string;
  description: string;
  enabled: boolean;
  full: boolean;
  open: boolean;
  categoryId: number;
}

export interface DealerForm {
  options: DealerOptionInput[];
  dealerAssistantLimit: number;
}

export type VendorPreferenceState = 'approved' | 'rejected' | 'requested';

export interface SeatingPreference {
  conventionId: number;
  respondedAt?: Date;
  status: VendorPreferenceState;
  requester: {
    id: number;
    name: string;
  };
  target: {
    id: number;
    name: string;
  };
}

export interface DealerAssistance {
  dealerId: number;
  userId: number;
  accepted: boolean;
  user: { id: number; firstName: string; lastName: string; preferredName?: string };
}

export interface DealerConfig {
  enableDealers: boolean;
  dealerAssistantLimit: number;
  dealerEmail: string;
  applicationsEnabled: boolean;
}
