import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { RegistrationUpsert } from '../../../shared/registration/model';
import { PhoneInput } from '../../components';
import { ordinalSuffix, useConvention, useObject } from '../../utils';
import { FormActionType, FormStateType } from './FormReducer';

interface EmergencyContactInfoProps {
  readonly idx: number;
  readonly name: string | null | undefined;
  readonly phone: string | null | undefined;
  readonly setName: Dispatch<SetStateAction<string | null | undefined>>;
  readonly setPhone: Dispatch<SetStateAction<string | null | undefined>>;
}

const EmergencyContactInfo: FC<EmergencyContactInfoProps> = ({
  idx,
  name,
  phone,
  setName,
  setPhone,
}) => {
  const elName = `emergencyContactName${idx}`;
  const elPhone = `emergencyContactPhone${idx}`;
  const ordSuff = ordinalSuffix(idx);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value ?? null);
    },
    [setName],
  );

  return (
    <Row>
      <Col md={6} xs={12}>
        <FormGroup>
          <Label for={elName}>{ordSuff} Contact Name</Label>
          <Input id={elName} name={elName} onChange={onChange} value={name ?? ''} />
        </FormGroup>
      </Col>
      <Col md={6} xs={12}>
        <FormGroup>
          <Label for={elPhone}>{ordSuff} Contact Phone</Label>
          <PhoneInput onChange={setPhone} value={phone} />
        </FormGroup>
      </Col>
    </Row>
  );
};

export const EmergencyContact: FC<{
  readonly registration: RegistrationUpsert;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
}> = ({ registration, setRegistration }) => {
  const convention = useConvention();

  if (convention.virtualConvention) {
    return null;
  }

  return (
    <Col className="margin-bottom-10" lg={12} xs={12}>
      <Card>
        <CardHeader>Emergency Contacts</CardHeader>
        <CardBody>
          <FormGroup>
            Your emergency contact(s) will only be contacted if you end up in the hospital with a
            life-threatening emergency.
          </FormGroup>
          <hr />
          <EmergencyContactInner registration={registration} setRegistration={setRegistration} />
        </CardBody>
      </Card>
    </Col>
  );
};

const EmergencyContactInner: FC<{
  readonly registration: RegistrationUpsert;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
}> = ({ registration, setRegistration }) => {
  const [name1, setName1] = useObject(registration, setRegistration, 'emergencyContactName1');
  const [name2, setName2] = useObject(registration, setRegistration, 'emergencyContactName2');
  const [phone1, setPhone1] = useObject(registration, setRegistration, 'emergencyContactPhone1');
  const [phone2, setPhone2] = useObject(registration, setRegistration, 'emergencyContactPhone2');

  return (
    <>
      <EmergencyContactInfo
        idx={1}
        name={name1}
        phone={phone1}
        setName={setName1}
        setPhone={setPhone1}
      />
      <EmergencyContactInfo
        idx={2}
        name={name2}
        phone={phone2}
        setName={setName2}
        setPhone={setPhone2}
      />
    </>
  );
};

export const EmergencyContactDynamic: FC<{
  readonly state: FormStateType;
  readonly dispatch: Dispatch<FormActionType>;
}> = ({ state, dispatch }) => {
  if (state.type !== 'registration') {
    return null;
  }

  return (
    <>
      <EmergencyContactInfo
        idx={1}
        name={state.emergencyContactName1}
        phone={state.emergencyContactPhone1}
        setName={(v) => dispatch({ type: 'setEmergencyContact', name1: v as string })}
        setPhone={(v) => dispatch({ type: 'setEmergencyContact', phone1: v as string })}
      />
      <EmergencyContactInfo
        idx={2}
        name={state.emergencyContactName2}
        phone={state.emergencyContactPhone2}
        setName={(v) => dispatch({ type: 'setEmergencyContact', name2: v as string })}
        setPhone={(v) => dispatch({ type: 'setEmergencyContact', phone2: v as string })}
      />
    </>
  );
};
