import React, { FC } from 'react';
import { Badge } from 'reactstrap';
import { ProductModel, ProductVisibility } from '../../../../shared/orders';
import { PlatformFeeHelp } from '../../../components';
import { Element, SearchProvider } from '../../../components/ElementSelector';
import { capitalize } from '../../../utils';

/**
 * Search provider that performs all filtering on the client.
 */
export class LocalSearchProvider<T extends Element> implements SearchProvider<T> {
  public constructor(private readonly fetcher: () => Promise<T[]> | T[]) {}

  public async text(txt: string): Promise<T[]> {
    const lowerText = txt.toLocaleLowerCase();
    return (await this.fetcher()).filter(
      ({ id, name, displayName }) =>
        ((id && id.toString() === lowerText) || name?.toLocaleLowerCase().includes(lowerText)) ??
        displayName?.toLocaleLowerCase().includes(lowerText),
    );
  }

  public async ids(ids: number[]): Promise<T[]> {
    return (await this.fetcher()).filter(({ id }) => ids.includes(id));
  }
}

export const checkForPlatformFee = (product: ProductModel): boolean => {
  return product.surcharges.some(
    (surcharge) => surcharge.internal && surcharge.name.startsWith('platform-fee'),
  );
};

export const getPlatformFeeBadge = (product: ProductModel): JSX.Element => {
  const hasPlatformFee = checkForPlatformFee(product);

  return (
    <>
      <Badge color={hasPlatformFee ? 'success' : 'secondary'}>
        {hasPlatformFee ? 'Fees Passed On via Surcharge' : 'Fees Absorbed / Included'}
      </Badge>
      <PlatformFeeHelp />
    </>
  );
};

interface ProductInfoBadgesProps {
  readonly product: ProductModel;
}

type BadgeInfo = [string, string, string];

export const ProductInfoBadges: FC<ProductInfoBadgesProps> = ({ product }) => {
  const idPrefix = `product${product.id}Status`;
  const badges: BadgeInfo[] = [];
  const isInStock =
    (product.stockAvailable === undefined || product.stockAvailable > 0) && !product.deletedAt;

  const isUnlimited = product.stockMaximumQuantity === null;

  if (product.deletedAt) {
    badges.push(['Deleted', 'secondary', 'Deleted / Archived']);
  } else if (product.visibility === ProductVisibility.PUBLIC) {
    badges.push(['Public', 'success', 'Public / Visible']);
  } else if (product.visibility === ProductVisibility.UNLISTED) {
    badges.push(['Unlisted', 'info', 'Unlisted']);
  } else {
    badges.push(['Hidden', 'secondary', 'Hidden']);
  }

  if (isInStock || isUnlimited) {
    badges.push([
      'InStock',
      'success',
      `In Stock${
        isUnlimited
          ? ' - Unlimited'
          : `: ${product.stockAvailable!}/${product.stockMaximumQuantity!} Available`
      }`,
    ]);
  } else if (
    product.stockAvailable &&
    product.stockAvailable < 0 &&
    product.stockMaximumQuantity! > 0
  ) {
    badges.push([
      'OutOfStockOversold',
      'warning',
      `Out of Stock - Exceeded by ${-product.stockAvailable}`,
    ]);
  } else {
    badges.push(['OutOfStock', 'secondary', 'Out of Stock']);
  }

  if (product.maxGrants) {
    badges.push(['limitGrants', 'secondary', `Limit per user: ${product.maxGrants}`]);
  }

  if (product.roleRestriction !== null) {
    badges.push(['roleRestriction', 'secondary', 'Role Restriction']);
  }

  if (product.digitalGrantRestricted) {
    badges.push(['digitalGrantRestricted', 'secondary', 'Grant Restriction']);
  }

  if (product.availableTo && product.availableTo.getTime() < Date.now()) {
    badges.push(['Expired', 'danger', 'Expired']);
  }

  if (product.minAge || product.maxAge) {
    badges.push([
      'AgeRestricted',
      'warning',
      `
      Age Restricted: ${product.minAge && !product.maxAge ? '>=' : ''} ${product.minAge ?? ''}
      ${product.minAge && product.maxAge ? ' – ' : ''}
      ${product.maxAge && !product.minAge ? '<=' : ''} ${product.maxAge ?? ''}
    `,
    ]);
  }

  return (
    <div className="badge-list compact">
      {badges.map(([id, color, text]) => (
        <Badge color={color} id={`${idPrefix}${capitalize(id)}`} key={id}>
          {text}
        </Badge>
      ))}
    </div>
  );
};
