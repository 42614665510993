import { History } from 'history';
import React, { FC, Fragment, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import * as OrderSummary from '../../../shared/orders/model';
import { ProductCategoryModel } from '../../../shared/orders/product';
import { PaymentGatewayConfig } from '../../APIClient';
import {
  ActionButton,
  Loading,
  MaterialIcon,
  PageHeader,
  UserStateComponent,
} from '../../components';
import { paymentGateways } from '../../components/PaymentGateway';
import { PaymentState } from '../../components/payment';
import {
  classNames,
  Fetcher,
  isResourceError,
  sumBy,
  useConvention,
  useFetcher,
  useUser,
} from '../../utils';
import { cToUsdStrPref } from '../../utils/cToUsdStr';
import { captureError } from '../../utils/errorHandling';
import { OrderItemProduct, renderOrderSummary, VoucherComponent } from '.';

import './cart.scss';

interface CartProps {
  readonly history?: History;
  onProcessing?(): void;
  onSuccess?(): void;
  onError?(): void;
}

export const Cart: FC<CartProps> = ({ history, onProcessing, onSuccess, onError }) => {
  const user = useUser();
  const [failureCount, setFailureCount] = useState(0);
  const [paymentState, setPaymentState] = useState(PaymentState.Pending);
  const [paymentStateMessage, setPaymentStateMessage] = useState<string | undefined>(undefined);

  const [currentOrder, setCurrentOrder] = useState<OrderSummary.Order | undefined>(undefined);

  const keepCurrentOrder = useRef(false);

  const fetcher = useFetcher(async () => {
    const [categories, order, gateway] = await Promise.all([
      api.getProductCategories(),
      currentOrder && keepCurrentOrder
        ? api.getOrderById(currentOrder.id)
        : api.getActiveOrder(user!.id),
      api.getPaymentGateways(),
    ]);

    if (currentOrder && keepCurrentOrder && order.status === 'paid') {
      updateOrderState(PaymentState.Success, 'Your payment has been successful! Thank you!');
    }

    setCurrentOrder(order);

    return { categories, order, gateway };
  }, [user]);

  const updateOrderState = useCallback(
    (newPaymentState: PaymentState, newPaymentStateMessage?: string) => {
      switch (newPaymentState) {
        case PaymentState.Processing: {
          if (onProcessing) {
            onProcessing();
          }

          break;
        }

        case PaymentState.Success: {
          if (onSuccess) {
            onSuccess();
          }

          break;
        }

        case PaymentState.Cancelled:
        case PaymentState.Failed:
        case PaymentState.Declined: {
          if (onError) {
            onError();
          }

          setFailureCount(failureCount + 1);

          break;
        }

        case PaymentState.Pending: {
          break;
        }
      }

      setPaymentState(newPaymentState);
      setPaymentStateMessage(newPaymentStateMessage);
    },
    [failureCount],
  );

  if (!fetcher.complete && !isResourceError(fetcher.error, 'Order')) {
    return <Fetcher result={fetcher} />;
  }

  const hasOrder =
    currentOrder &&
    (currentOrder.orderItems.length > 0 || (currentOrder.fees && currentOrder.fees.length > 0));

  return (
    <UserStateComponent>
      <PageHeader>Checkout</PageHeader>
      <Row className="justify-content-center">
        {fetcher.data && hasOrder ? (
          <RenderOrder
            categories={fetcher.data.categories}
            failureCount={failureCount}
            gateway={fetcher.data.gateway}
            keepCurrentOrder={keepCurrentOrder}
            order={fetcher.data.order}
            paymentState={paymentState}
            paymentStateMessage={paymentStateMessage}
            refresh={fetcher.refresh}
            updateOrderState={updateOrderState}
          />
        ) : (
          <EmptyCart history={history} />
        )}
      </Row>
    </UserStateComponent>
  );
};

const EmptyCart: FC<{ readonly history?: History }> = ({ history }) => {
  return (
    <Col className="text-center" id="cartEmpty" lg={8} xs={12}>
      <MaterialIcon large name="shopping_cart" />
      <h4>Nothing in your cart... yet!</h4>
      {history ? (
        <>
          <p>Let's find you an event ticket or some swag!</p>
          <Row className="justify-content-center">
            <Col lg={5} xs={12}>
              <Button block color="primary" outline tag={Link} to="/event/register">
                Go to Registrations
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <p>
          If you were expecting something to be here, try refreshing the page and starting again.
        </p>
      )}
    </Col>
  );
};

const RenderOrder: FC<{
  readonly categories: ProductCategoryModel[];
  readonly gateway: PaymentGatewayConfig;
  readonly paymentState: PaymentState;
  readonly order: OrderSummary.Order;
  readonly keepCurrentOrder: React.MutableRefObject<boolean>;
  readonly failureCount: number;
  readonly paymentStateMessage?: string;
  readonly refresh: () => void;
  readonly updateOrderState: (state: PaymentState, message?: string) => void;
}> = ({
  categories,
  paymentState,
  order,
  refresh,
  keepCurrentOrder,
  paymentStateMessage,
  gateway,
  failureCount,
  updateOrderState,
}) => {
  const items = order.orderItems;
  const isUnpaid = paymentState !== PaymentState.Success && order.status !== 'paid';

  return (
    <>
      <Col className="margin-bottom-10 order" lg={6} md={12}>
        <Card>
          <CardBody>
            <h4>
              {items.length} Item{items.length === 1 ? '' : 's'}
            </h4>
            <hr />
            {order.orderItems.map((item) => (
              <OrderItemProduct
                item={item}
                key={item.id}
                onUpdate={async () => {
                  keepCurrentOrder.current = true;
                  refresh();
                }}
                order={order}
                readOnly={!isUnpaid}
              />
            ))}
            {order.fees && order.fees.length > 0 && (
              <Fragment key="orderFees">
                <div className="order-line">
                  <div className="order-item">
                    <div className="order-item-description">Fees, Charges, and Fines</div>
                    <div className="order-item-price">
                      {cToUsdStrPref(sumBy(order.fees, (fee) => fee.amount))}
                    </div>
                  </div>
                </div>
                <hr />
              </Fragment>
            )}
          </CardBody>
        </Card>
        <Alert className="margin-top-10" color="warning">
          <strong>Important</strong>
          <br />
          When making payments, please do not use the refresh or back buttons on your browser.
        </Alert>
        <Card className="margin-top-10" id="transactionSecurity">
          <CardBody>
            <h2>Transaction Security</h2>
            <hr />
            <p>
              Our payment processor implements Verified by Visa and MasterCard SecureCode security
              measures to protect against unauthorized use of your cards.
            </p>
            <p>
              If your financial institution participates in either of these programs, you may see a
              dialog asking you to verify your identity to complete the transaction. This dialog
              will vary depending on your financial institution.
            </p>
            <p>
              If you have any concerns or issues, please contact your financial institution using
              the customer service number on the back of your card.
            </p>
          </CardBody>
        </Card>
      </Col>
      <Col lg={6} md={12} xl={4}>
        <Card className="margin-bottom-10">
          <CardBody>
            <h4>Order Summary</h4>
            <hr />
            {renderOrderSummary({
              categories,
              isCustomer: true,
              order,
              paymentState,
            })}
          </CardBody>
        </Card>
        <RenderedPaymentState
          failureCount={failureCount}
          paymentState={paymentState}
          paymentStateMessage={paymentStateMessage}
        />
        {isUnpaid && (
          <PaymentOptions
            gateway={gateway}
            keepCurrentOrder={keepCurrentOrder}
            order={order}
            paymentState={paymentState}
            refresh={refresh}
            updateOrderState={updateOrderState}
          />
        )}
      </Col>
    </>
  );
};

const RenderedPaymentState: FC<{
  readonly failureCount: number;
  readonly paymentState: PaymentState;
  readonly paymentStateMessage?: string;
}> = ({ failureCount, paymentState, paymentStateMessage }) => {
  if ([PaymentState.Processing, PaymentState.Pending].includes(paymentState)) {
    return null;
  }

  const success = paymentState === PaymentState.Success;

  return (
    <Card
      className={classNames(
        {
          'bg-danger': !success,
          'bg-success': success,
        },
        'text-white margin-bottom-10 payment-status-card',
      )}
      id={success ? 'paymentComplete' : 'paymentFailed'}
    >
      <CardBody>
        {paymentState === PaymentState.Declined && (
          <p>
            <strong>Your payment was declined</strong> by our payment processor:
          </p>
        )}
        {paymentStateMessage}
        {failureCount >= 3 && (
          <div className="payment-status-repeated-failures">
            <hr />
            <p>
              <small>
                If you repeatedly see this message, you may want to contact the event and your
                financial institution for assistance.
              </small>
            </p>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const PaymentOptions: FC<{
  readonly gateway: PaymentGatewayConfig;
  readonly paymentState: PaymentState;
  readonly order: OrderSummary.Order;
  readonly keepCurrentOrder: React.MutableRefObject<boolean>;
  readonly refresh: () => void;
  readonly updateOrderState: (state: PaymentState, message?: string) => void;
}> = ({ gateway, paymentState, order, keepCurrentOrder, refresh, updateOrderState }) => {
  const { breakdown, ...newOrder } = order;
  const total = breakdown.total - breakdown.paid;
  const isFreeOrder = breakdown.subtotal === 0;

  if (paymentState === PaymentState.Cancelled) {
    return null;
  }

  if (total === 0) {
    return (
      <Card className="margin-bottom-10">
        <CardHeader>{isFreeOrder ? 'Confirm Order' : 'Pay with Voucher Balance'}</CardHeader>
        <CardBody>
          <ActionButton
            action={`/api/orders/${newOrder.id}/settle`}
            block
            color="primary"
            id="confirmVoucherOrder"
            method="post"
            onFailure={captureError}
            onSuccess={async () => {
              keepCurrentOrder.current = true;
              refresh();
            }}
            payload={{}}
          >
            Confirm Order
          </ActionButton>
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <VoucherComponent
        onVoucherApplied={async () => {
          keepCurrentOrder.current = true;
          refresh();
        }}
        orderId={order.id}
      />
      <PaymentCard
        gatewayConfig={gateway}
        paymentState={paymentState}
        total={total}
        updateOrderState={updateOrderState}
      />
    </>
  );
};

interface PaymentCardProps {
  readonly gatewayConfig?: PaymentGatewayConfig;
  readonly paymentState: PaymentState;
  readonly total: number;
  updateOrderState(paymentState: PaymentState, paymentStateMessage?: string): void;
}

const PaymentCard: FC<PaymentCardProps> = (props) => {
  const { updateOrderState, total, paymentState, gatewayConfig } = props;
  const user = useUser();
  const convention = useConvention();

  if (!user) {
    return null;
  }

  for (const gateway of paymentGateways) {
    if (gateway.type === gatewayConfig?.type) {
      return (
        <Card id="paymentCard">
          <CardHeader>Pay by Credit / Debit Card with {gateway.name}</CardHeader>
          {paymentState === PaymentState.Processing && (
            <Loading>
              <p>Processing Payment...</p>
            </Loading>
          )}
          <CardBody>
            <gateway.lazyComponent
              conventionName={convention.longName}
              onUpdate={(state, message) => updateOrderState(state, message)}
              paymentConfig={gatewayConfig}
              total={total}
              user={user}
            />
          </CardBody>
        </Card>
      );
    }
  }

  return (
    <Card>
      <CardHeader>Credit card payment is not configured</CardHeader>
      <CardBody>Credit card payment is not configured</CardBody>
    </Card>
  );
};
