import React, { FC } from 'react';
import { AuditObjectPage } from '../audit/log';

interface TabDetailsProps {
  readonly userId: number;
}

export const UserAuditTab: FC<TabDetailsProps> = ({ userId }) => {
  return <AuditObjectPage entityId="User" objectId={userId.toString()} />;
};
