import React, { FC } from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { Order, OrderItem, Payment, Refund } from '../../shared/orders/model';

export function getOrderItem(order: Order, orderItemId: number): OrderItem {
  return order.orderItems.find((t) => t.id === orderItemId)!;
}

export function getRefund(order: Order, refundId: number): Refund {
  return order.refunds.find((t) => t.id === refundId)!;
}

export const PaymentStatusBadge: FC<{ readonly payment: Payment }> = ({
  payment: { status },
}): JSX.Element => {
  switch (status) {
    case 'success': {
      return <Badge color="success">Succeeded</Badge>;
    }

    case 'failed': {
      return <Badge color="danger">Failed</Badge>;
    }

    case 'refunded': {
      return <Badge color="info">Refund</Badge>;
    }

    default: {
      return (
        <>
          <Badge color="secondary" id="awaitingPaymentBadge">
            Awaiting Payment
          </Badge>
          <UncontrolledTooltip target="awaitingPaymentBadge">
            The user has not entered their payment details.
          </UncontrolledTooltip>
        </>
      );
    }
  }
};
