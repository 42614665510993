import React, { FC, useCallback } from 'react';
import { Permission, UserPermissionVerbose } from '../../../models';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { captureError } from '../../../utils/errorHandling';
import { PermissionEnum, PermissionListComponent } from '../roles';

interface TabDetailsProps {
  readonly userId: number;
  readonly canEdit: boolean;
}

interface LoadingData {
  userPermissions: UserPermissionVerbose[];
  permissions: Permission[];
}

const dataFetcher = async (uid: number): Promise<LoadingData> => {
  const [permissions, userPermissions] = await Promise.all([
    api.getPermissions(),
    api.getUserPermissions(uid),
  ]);

  return {
    permissions,
    userPermissions,
  };
};

export const UserPermissionsTab: FC<TabDetailsProps> = ({ userId, canEdit }) => {
  const update = useCallback(
    async (permissionId: number, state: PermissionEnum) => {
      try {
        await (state === PermissionEnum.Inherit
          ? api.deleteUserPermission(userId, permissionId)
          : api.updateUserPermission(userId, permissionId, state === PermissionEnum.Deny));
      } catch (error) {
        captureError(error as Error);
      }
    },
    ['userId'],
  );

  return (
    <LoadingWrapper<LoadingData, number> dataFetcher={dataFetcher} passback={userId}>
      {({ userPermissions, permissions }, refresh) => (
        <PermissionListComponent
          canEdit={canEdit}
          items={userPermissions}
          permissions={permissions}
          update={async (a, b) =>
            await update(a, b).then(() => {
              refresh(userId);
            })
          }
        />
      )}
    </LoadingWrapper>
  );
};
