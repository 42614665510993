import React, { FC } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { InlineHelp } from '../../../components';
import { Surcharge } from '../../../models';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { surchargeRateText } from '../surcharges/utils';

export const PlatformInfoSettings: FC = () => (
  <LoadingWrapper<Surcharge[], void> dataFetcher={async () => await api.getPlatformFees()} inline>
    {(data) => {
      const regFee = data.find(({ name }) => name === 'platform-fee-registration');
      const otherFee = data.find(({ name }) => name === 'platform-fee-other');

      return (
        <>
          <h3>Platform Info</h3>
          <br />
          <Row>
            <Col className="margin-bottom-10" lg={4} xs={12}>
              <Card>
                <CardBody>
                  These fees are charged by ConCat for use of the platform by your event.
                  <br />
                  <br />
                  By default, our platform fees are absorbed into the cost of each product and are
                  paid by the event, but as the organizer, you can choose to pass these fees to your
                  attendees through the Product Management section.
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} />
            <Col className="margin-bottom-10" lg={2} xs={12}>
              <Card id="platformFeeRegistration">
                <CardBody>
                  <p>
                    Per Registration Platform Fee
                    <InlineHelp>
                      This is the fee charged by ConCat for each paid attendee registration for your
                      event.
                    </InlineHelp>
                  </p>
                  <h3>{surchargeRateText(regFee!)}</h3>
                </CardBody>
              </Card>
            </Col>
            <Col lg={2} xs={12}>
              <Card id="platformFeeOther">
                <CardBody>
                  <p>
                    Other Product Platform Fee
                    <InlineHelp>
                      This is the fee charged by ConCat for non-registration products you sell
                      through the platform, such as items sold via the Event Store and Vendor
                      Tables.
                    </InlineHelp>
                  </p>
                  <h3>{surchargeRateText(otherFee!)}</h3>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      );
    }}
  </LoadingWrapper>
);
