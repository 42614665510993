import React, { FC } from 'react';
import { Input, Label } from 'reactstrap';

interface CheckboxLabelProps {
  readonly id: string;
  readonly name?: string;
  readonly checked?: boolean;
  readonly disabled?: boolean;
  readonly onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const CheckboxLabel: FC<CheckboxLabelProps> = ({
  id,
  disabled,
  children,
  name,
  checked,
  onChange,
}) => {
  return (
    <div className="custom-control custom-checkbox margin-top-10">
      <Input
        checked={checked}
        className="custom-control-input"
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
      />
      <Label className="custom-control-label" for={id}>
        {children}
      </Label>
    </div>
  );
};
