import { DependencyList, FormEvent, useCallback, useState } from 'react';
import { captureError } from './errorHandling';

interface FormSetup {
  saving: boolean;
  onSubmit(e?: FormEvent<HTMLFormElement>): void;
}

export function useForm(callback: () => Promise<void>, deps: DependencyList): FormSetup {
  const [saving, setSaving] = useState(false);
  const onSubmit = useCallback(async (e?: FormEvent<HTMLFormElement>) => {
    setSaving(true);
    try {
      e?.preventDefault();
      await callback();
    } catch (error) {
      captureError(error as Error);
    }

    setSaving(false);
  }, deps);

  return { onSubmit, saving };
}
