import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { RoleUserListItem } from '../../../../shared/user/roles';
import { UserProfilePicture } from '../../../components/UserProfilePicture';
import { Role } from '../../../models';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';

interface RoleUserListProps {
  readonly role: Role;
}

export const RoleUserList: FC<RoleUserListProps> = ({ role }) => {
  const getConventionId = ({ conventionIds }: RoleUserListItem): JSX.Element | null => {
    const notGlobal = conventionIds.filter((t) => t !== 0);
    if (notGlobal.length === 0) {
      return null;
    }

    return <span> (Convention: {notGlobal.join(', ')})</span>;
  };

  return (
    <LoadingWrapper<RoleUserListItem[], number>
      dataFetcher={async (rid) => await api.getRoleUsers(rid)}
      passback={role.id}
    >
      {(users) => (
        <>
          {users.map((user) => (
            <div className="search-item" id={`viewUser_${user.id}`} key={user.id}>
              <Link to={`/housekeeping/attendees/user/${user.id}`}>
                <Col className="userListItem clearfix-after" xs={12}>
                  <UserProfilePicture url={user.profilePictureUrl} />
                  <div>
                    {user.firstName} {user.lastName}
                  </div>
                  <div>
                    {user.username} {getConventionId(user)}
                  </div>
                </Col>
              </Link>
              <hr />
            </div>
          ))}
        </>
      )}
    </LoadingWrapper>
  );
};
