import { Breadcrumb, MaterialIconText } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback } from 'react';
import { Button, Col, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { CompositeCriterionOptions, CompositeOperation } from '../../../../../shared/orders/raffle';
import { emptyFallback, useInputState } from '../../../../utils';
import { RaffleConfiguratorProps } from '../utils';

export const MathOperation: FC<RaffleConfiguratorProps<CompositeCriterionOptions>> = ({
  defaultOptions,
  displayName,
  onBack,
  onCancel,
  onConfigure,
}) => {
  const [operator, setOperator] = useInputState<CompositeOperation>(
    defaultOptions?.operator ?? 'add',
  );

  const onFinish = useCallback(() => {
    onConfigure({ name: 'composite', options: { operator } });
  }, [operator]);

  return (
    <>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <Breadcrumb
              items={[
                { text: `Display Name: ${emptyFallback(displayName, 'None')}` },
                { text: 'Type: Composite Operation' },
              ]}
            />
            <MaterialIconText name="keyboard_backspace" onClick={onBack}>
              Go Back
            </MaterialIconText>
            <hr />
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="operation">Operation</Label>
              <Input
                defaultValue={operator}
                id="operation"
                name="operation"
                onChange={setOperator}
                required
                type="select"
              >
                <option value="add">Add</option>
                <option value="multiply">Multiply</option>
                <option value="max">Max</option>
                <option value="min">Min</option>
                <option value="pow">Pow</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="editor-finish-btn" color="primary" onClick={onFinish}>
          Finish
        </Button>
        <Button className="editor-cancel-btn" color="secondary" onClick={onCancel} outline>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};
