import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { LazyMarkdown } from '../../../components';
import { LogEntry } from '../../../models';
import { makeRelativeLogLink, TableQueryOpts } from './query';

interface LogDetailModalProps {
  readonly entry: LogEntry;
  readonly params: TableQueryOpts;
}

export const LogDetailModal: FC<LogDetailModalProps> = ({ entry, params }) => {
  return (
    <Modal isOpen>
      <ModalHeader>Details</ModalHeader>
      <ModalBody>
        {entry.message && (
          <div>
            <LazyMarkdown source={entry.message} />
          </div>
        )}
        <div>
          Raw:
          <pre>{JSON.stringify(entry.meta, null, 2)}</pre>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" tag={Link} to={makeRelativeLogLink(params, {}, ['view'])}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
