import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardText, Col, Row } from 'reactstrap';
import { Order, ProductModel, ProductVisibility } from '../../../shared/orders';
import { ConRegistrationForm } from '../../../shared/registration/model';
import {
  LazyMarkdown,
  Loading,
  LoadingError,
  MaterialIcon,
  PageHeader,
  RegTypeSelectList,
  WizardComponent,
  WizardStatus,
} from '../../components';
import { RegistrationInfo } from '../../models/RegistrationInfo';
import { displayName, useFetcher, useUser } from '../../utils';
import { LoadingState } from '../../utils/LoadingState';
import { captureError } from '../../utils/errorHandling';
import { getProductPrice, renderPrice } from '../../utils/product';
import { EventRegistrationComplete } from './EventRegistrationComplete';
import { RegistrationForm } from './RegistrationForm';

async function loadRegistration(
  userId: number,
): Promise<{ form: ConRegistrationForm; registrationTypes: ProductModel[]; order: Order }> {
  const order = await api.getActiveOrder(userId, true);
  const form = await api.getRegistrationForm();

  return {
    order,
    form,
    registrationTypes: form.products,
  };
}

export const EventRegistration: FC<{ readonly registration?: RegistrationInfo }> = ({
  registration: initReg,
}) => {
  const user = useUser()!;
  const [registration, setRegistration] = useState(initReg);
  const [regTypeState, setRegTypeState] = useState<WizardStatus>(WizardStatus.InProgress);
  const [reviewState, setReviewState] = useState<WizardStatus>(WizardStatus.NotStarted);
  const [selectedRegistrationType, setSelectedRegistrationType] = useState<number | undefined>();
  const [additionalInfoState, setAdditionalInfoState] = useState<WizardStatus>(
    WizardStatus.NotStarted,
  );

  const fetcher = useFetcher(async () => {
    return await loadRegistration(user.id);
  });

  useEffect(() => {
    if (fetcher.data && registration) {
      const { order } = fetcher.data;
      const orderItem = order.orderItems.find(
        (oi) =>
          oi.referenceId === registration.id &&
          registrationTypes.find(({ id }) => id === oi.product.id),
      );

      const type = registrationTypes.find(
        ({ id }) => id === (orderItem ? orderItem.product.id : registration.attendanceTypeId),
      );

      if (type) {
        selectRegistrationType(type.id);
      }
    }
  }, [fetcher.state]);

  const resetRegSelect = useCallback(() => {
    setRegTypeState(WizardStatus.InProgress);
    if (additionalInfoState === WizardStatus.InProgress) {
      setAdditionalInfoState(WizardStatus.NotStarted);
    } else {
      setAdditionalInfoState(WizardStatus.NotStarted);
      setReviewState(WizardStatus.NotStarted);
    }
  }, [additionalInfoState]);

  const selectRegistrationType = useCallback(
    (productId: number) => {
      setSelectedRegistrationType(productId);
      setRegTypeState(WizardStatus.Completed);

      if (additionalInfoState === WizardStatus.Completed) {
        setReviewState(WizardStatus.InProgress);
      } else {
        setAdditionalInfoState(WizardStatus.InProgress);
      }
    },
    [additionalInfoState],
  );

  const completeRegInfo = useCallback(async () => {
    try {
      const reg = await api.getUserActiveRegistration(user.id);
      setRegistration(reg);
      setAdditionalInfoState(WizardStatus.Completed);
      setReviewState(WizardStatus.InProgress);
    } catch (error) {
      captureError(error as Error);
    }
  }, [user]);

  if (fetcher.state === LoadingState.Loading) {
    return <Loading inline />;
  }

  if (fetcher.state === LoadingState.Error) {
    return <LoadingError errorText={(fetcher.error as Error).message} />;
  }

  const { registrationTypes, form } = fetcher.data!;
  if (!registrationTypes || registrationTypes.length === 0) {
    return <RegistrationClosed />;
  }

  const registrationType = registrationTypes.find((t) => t.id === selectedRegistrationType);
  const validRegistrationTypes = registrationTypes.filter(
    (rt) => selectedRegistrationType === rt.id || rt.visibility === ProductVisibility.PUBLIC,
  );

  return (
    <>
      <PageHeader>Event Registration</PageHeader>
      <Row className="justify-content-center">
        <Col lg={8} xs={12}>
          <WizardComponent
            className="justify-content-center"
            items={[
              {
                status: regTypeState,
                text: 'Registration Type',
              },
              {
                status: additionalInfoState,
                text: 'Additional Information',
              },
              {
                status: reviewState,
                text: 'Review',
              },
            ]}
          />
        </Col>
      </Row>
      <br />
      {regTypeState === WizardStatus.InProgress && (
        <RegTypeSelectList
          attendanceType={registrationType}
          attendanceTypes={validRegistrationTypes}
          onSelect={selectRegistrationType}
          paidAttendanceType={
            registration?.paidOrderItem &&
            validRegistrationTypes.find(({ id }) => id === registration.paidOrderItem!.productId)
          }
          raffle={registration?.raffle}
        />
      )}
      {additionalInfoState === WizardStatus.InProgress && (
        <>
          <ProductSummary
            attendanceType={registrationType!}
            isPaidAttendance={!!registration}
            onReset={resetRegSelect}
          />
          <RegistrationForm
            attendanceType={registrationType!}
            form={form}
            onSuccess={completeRegInfo}
            registration={registration}
            user={user}
          />
        </>
      )}
      {reviewState === WizardStatus.InProgress && (
        <EventRegistrationComplete
          modifyRegistration={resetRegSelect}
          product={registrationType!}
          registration={registration}
        />
      )}
    </>
  );
};

const RegistrationClosed: FC = () => {
  const publicProducts = useFetcher(async () => {
    return await api.getPublicProducts();
  }, []);

  return (
    <>
      <PageHeader>Event Registration</PageHeader>
      <Row className="justify-content-center" id="registrationEmpty">
        <Col lg={6} xs={12}>
          <Card className="danger">
            <CardBody className="text-center">
              <MaterialIcon large name="remove_circle" type="danger" />
              <CardText tag="div">
                <p>There are no products available for registration.</p>
                {(publicProducts.data?.length || 0) > 0 ? (
                  <p>
                    Check the <Link to="/store">Event Store</Link> for other available items.
                  </p>
                ) : (
                  <p>Please check back later.</p>
                )}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

interface ProductSummaryProps {
  readonly attendanceType: ProductModel;
  readonly isPaidAttendance: boolean;
  readonly onReset: () => void;
}

const ProductSummary: FC<ProductSummaryProps> = ({ attendanceType, isPaidAttendance, onReset }) => {
  const productPrice = getProductPrice(attendanceType);
  return (
    <Row className="justify-content-center">
      <Col className="margin-bottom-10" lg={10} xs={12}>
        <Card className="productItem markdown-container">
          <div className="productInfo">
            <h5>{displayName(attendanceType)}</h5>
            <LazyMarkdown source={attendanceType.description ?? '*No Description Provided*'} />
          </div>
          <div className="productPurchaseBar" id="productChangeBar">
            {!isPaidAttendance && renderPrice(productPrice)}
            <Button color="white" onClick={onReset} outline type="button">
              Change
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
};
