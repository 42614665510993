import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import { Fetcher, useFetcher } from '../../../../utils';
import { CreateProduct } from '../createProduct';

interface Props {
  readonly categoryId: number;
  onToggle(): void;
}

export const CategoryCreateProduct: FC<Props> = ({ categoryId, onToggle }) => {
  const history = useHistory();
  const onSuccess = useCallback(
    ({ id }: { id: number }) => {
      history.push(`${history.location.pathname.replace(`category/${categoryId}`, `${id}`)}`);
    },
    [history],
  );

  const categoryFetch = useFetcher(async () => {
    return await api.getProductCategoryById(categoryId);
  }, [categoryId]);

  if (!categoryFetch.complete) {
    return <Fetcher result={categoryFetch} />;
  }

  return (
    <>
      <div>
        <Button color="secondary" onClick={onToggle} outline>
          Back to Products
        </Button>
      </div>
      <hr />
      <div>
        <Row className="justify-content-center" id="createProductForm">
          <CreateProduct category={categoryFetch.data!} onSuccess={onSuccess} />
        </Row>
      </div>
    </>
  );
};
