import { MaterialIconText, TreeView } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { ValidCriterionName, ValidCriterions } from '../../../../../shared/orders/raffle';
import { transformCriterionToTitle } from '../../../../models';
import { clone } from '../../../../utils';
import { ConfigureRaffleNodeModal } from '../configureRaffleNodeModal';
import { addToTree, walkTree } from '../utils';

interface CurrentAction {
  action: 'create' | 'edit';
  targetPath: number[];
  selectedType?: ValidCriterionName;
}

interface Props {
  readonly raffleTree: ValidCriterions | undefined;
  readonly onUpdate: (raffleTree: ValidCriterions) => void;
}

export const WeightEditor: FC<Props> = ({ raffleTree, onUpdate }) => {
  const [currentAction, setCurrentAction] = useState<CurrentAction>();

  const onDelete = useCallback(
    (id: number[]) => {
      if (!raffleTree) {
        return;
      }

      onUpdate(
        walkTree(clone([raffleTree]), id, false, (currentNode, idx) =>
          currentNode.splice(idx, 1),
        )[0],
      );
    },
    [raffleTree],
  );

  const onRaffleNodeConfigured = useCallback(
    (criterion: ValidCriterions) => {
      const clonedTree = clone(raffleTree ? [raffleTree] : []);
      onUpdate(
        (currentAction!.action === 'edit'
          ? walkTree(
              clonedTree,
              currentAction!.targetPath,
              false,
              (currentNode, idx) => (currentNode[idx] = criterion),
            )
          : addToTree(clonedTree, currentAction!.targetPath, criterion))[0],
      );

      setCurrentAction(undefined);
    },
    [currentAction, raffleTree],
  );

  const onChangeToSum = useCallback(() => {
    if (raffleTree) {
      onUpdate({
        name: 'composite',
        options: {
          composites: [raffleTree],
          operator: 'add',
        },
      });
    }

    setCurrentAction(undefined);
  }, [raffleTree]);

  const treeViewItems = raffleTree ? [transformCriterionToTitle(raffleTree)] : [];
  return (
    <Card>
      <CardBody>
        <TreeView
          items={treeViewItems}
          onAddClicked={(targetPath) => {
            setCurrentAction({ action: 'create', targetPath });
          }}
          onDeleteClicked={onDelete}
          onEditClicked={(targetPath) => {
            setCurrentAction({ action: 'edit', targetPath });
          }}
        />
        {currentAction && (
          <ConfigureRaffleNodeModal
            criterion={
              currentAction.action === 'edit'
                ? walkTree([raffleTree!], currentAction.targetPath)[0]
                : undefined
            }
            onCancel={() => {
              setCurrentAction(undefined);
            }}
            onFinish={onRaffleNodeConfigured}
            selectedType={currentAction.selectedType}
          />
        )}
        {!raffleTree && (
          <div className="raffle-weight-get-started">
            <h3>Let's get started!</h3>
            <p>
              If you want to run a fairly weighted raffle with equal chances, select "Add Static
              Weight" and provide a value.
            </p>
            <p>
              Otherwise, if you want to weight based on criteria, select "Add Custom Node" to get
              started.
            </p>
            <div
              className="treeview-child-add treeview-info-child raffle-weight-add-static"
              onClick={() => {
                setCurrentAction({ action: 'create', targetPath: [], selectedType: 'value' });
              }}
            >
              <MaterialIconText name="add">
                <div>Add Static Weight</div>
              </MaterialIconText>
            </div>
            <div
              className="treeview-child-add treeview-info-child raffle-weight-add-custom"
              onClick={() => {
                setCurrentAction({ action: 'create', targetPath: [] });
              }}
            >
              <MaterialIconText name="add">
                <div>Add Custom Node</div>
              </MaterialIconText>
            </div>
          </div>
        )}
        {raffleTree && raffleTree.name !== 'composite' && (
          <div className="raffle-weight-get-started">
            <div
              className="treeview-child-add treeview-info-child raffle-weight-add-static"
              onClick={() => onChangeToSum()}
            >
              <MaterialIconText name="add">
                <div>Add more nodes</div>
              </MaterialIconText>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
