import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { NewSecretModel, OAuthApplicationModel } from '../../../../../shared/oauth';
import { PermissionName } from '../../../../../shared/permissions';
import { Fetcher, omit, useBoolState, useFetcher } from '../../../../utils';
import { captureError } from '../../../../utils/errorHandling';
import { OAuthManageModal } from './OAuthModal';
import { OAuthTable } from './OAuthTable';

export const OAuthPage: FC = () => {
  const [modalOpen, enableModal, disableModal] = useBoolState(false);
  const [newSecret, setNewSecret] = useState<NewSecretModel | null>(null);
  const req = useFetcher(async () => {
    const [applications, permissions] = await Promise.all([
      api.getOAuthApplications(),
      api.getOAuthPermissions(),
    ]);

    return { applications, permissions };
  });

  const onNewSecret = useCallback(
    (secret: NewSecretModel) => {
      req.refresh();
      disableModal();
      setNewSecret(secret);
    },
    [setNewSecret],
  );

  if (newSecret) {
    return <NewSecretModal close={() => setNewSecret(null)} secret={newSecret} />;
  }

  if (!req.complete) {
    return <Fetcher result={req} />;
  }

  const { applications, permissions } = req.data!;

  return (
    <div>
      <OAuthTable applications={applications} onChange={req.refresh} permissions={permissions} />
      <Button color="primary" id="oauth-create" onClick={enableModal}>
        Create new
      </Button>
      {modalOpen && (
        <CreateModal close={disableModal} onComplete={onNewSecret} permissions={permissions} />
      )}
    </div>
  );
};

interface CreateModalProps {
  readonly permissions: PermissionName[];
  close(): void;
  onComplete(secret: NewSecretModel): void;
}

const CreateModal: FC<CreateModalProps> = ({ permissions, close, onComplete }) => {
  const [app, setApp] = useState<OAuthApplicationModel>({
    id: 0,
    name: '',
    description: '',
    applicationPermissions: [],
    redirectUris: [],
  });

  const onClose = useCallback(
    async (cancelled: boolean) => {
      if (cancelled) {
        close();
        return;
      }

      try {
        const result = await api.createOAuthApplication(omit(app, 'id'));
        toast.success(`Application created!`);
        onComplete(result);
      } catch (error) {
        captureError(error as Error);
      }
    },
    [app],
  );

  return (
    <OAuthManageModal app={app} onClose={onClose} open permissions={permissions} setApp={setApp} />
  );
};

const NewSecretModal: FC<{ readonly secret: NewSecretModel; close(): void }> = ({
  secret,
  close,
}) => {
  return (
    <Modal id="newSecretModal" isOpen>
      <ModalHeader>New secret</ModalHeader>
      <ModalBody>
        <p>This is the secret for this application. Save it, as it will not be displayed again:</p>
        <pre id="newSecret">Id: {secret.id}</pre>
        <pre id="newSecret">Secret: {secret.secret}</pre>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" id="complete" onClick={close}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
