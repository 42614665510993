interface TableUpdateParams {
  time?: number;
  reverse?: boolean;
  ignore?: string[];
  view?: string;
}

function setDefined(url: URLSearchParams, record: TableUpdateParams): void {
  for (const [key, value] of Object.entries(record)) {
    if (value === undefined) {
      continue;
    }

    const v = String(value);
    if (v !== '') {
      url.set(key, String(value));
    }
  }
}

export function makeRelativeLogLink(
  params: TableQueryOpts,
  update: TableUpdateParams,
  unset?: (keyof TableUpdateParams)[],
): string {
  // CAST: Values are stringified.
  const search = new URLSearchParams();
  setDefined(search, params);
  setDefined(search, update);
  if (unset) {
    for (const key of unset) {
      search.delete(key);
    }
  }

  return `${location.pathname}?${search.toString()}`;
}

export interface TableQueryOpts {
  highlight?: string;
  view?: string;
  time?: number;
  reverse: boolean;
  offset?: number;
  ignore: string[];
}

export function urlSearchParamsToTableOpts(params: URLSearchParams): TableQueryOpts {
  return {
    highlight: params.get('highlight') ?? undefined,
    ignore: params.get('ignore')?.split(',') ?? [],
    offset: params.has('offset') ? Number.parseInt(params.get('offset')!, 10) : undefined,
    reverse: params.get('reverse') === 'true',
    time: params.has('time') ? Number.parseInt(params.get('time')!, 10) : undefined,
    view: params.get('view') ?? undefined,
  };
}
