import { setUserContext } from '@conventioncatcorp/common-fe/dist/errorHandling';
import { toast } from 'react-toastify';
import { Config } from '../../shared/config';
import { CurrentUser } from '../../shared/user/base';
import { ActionType } from './ActionTypes';

export interface LoginState {
  isLoggedIn: boolean;
  username: string;
  state: string;
}

export enum Theme {
  Light,
  Dark,
}

export interface Preferences {
  theme: Theme;
}

export interface GlobalState {
  config: Config | null;
  preferences: Preferences;
  loaded: boolean;
  showSidenav: boolean;
  bodyOnly: boolean;
  user: CurrentUser | null;
}

export const initialState = {
  bodyOnly: false,
  config: null,
  loaded: false,
  preferences: {
    theme: Theme.Light,
  },
  showSidenav: window.innerWidth >= 575,
  user: null,
};

// TODO: This should be dynamic (https://github.com/ConventionCatCorp/ConCat/issues/505)
export const isMobile = window.innerWidth <= 575;

export interface RootAction {
  type: ActionType;
}

export interface UserAction extends RootAction {
  type: ActionType.LoginReturning | ActionType.LoginSuccess | ActionType.ProfileUpdate;
  user: CurrentUser;
}

export interface ConfigAction extends RootAction {
  config: Config;
}

export interface PreferenceAction extends RootAction {
  preferences: Preferences;
}

export const rootReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: GlobalState = initialState,
  action: RootAction | UserAction,
): GlobalState => {
  switch (action.type) {
    case ActionType.LoginSuccess:
    case ActionType.LoginReturning:
    case ActionType.ProfileUpdate: {
      if (action.type === ActionType.LoginSuccess) {
        toast.success('You are now logged in!');
      }

      const { user } = action as UserAction;
      setUserContext(user.id);
      return {
        ...state,
        user,
      };
    }

    case ActionType.Logout: {
      toast.info('You are now logged out.');
      return {
        ...state,
        user: null,
      };
    }

    case ActionType.LoadingComplete: {
      return {
        ...state,
        loaded: true,
      };
    }

    case ActionType.ConfigReceived: {
      return {
        ...state,
        config: (action as ConfigAction).config,
      };
    }

    case ActionType.PreferenceUpdate: {
      const { preferences } = action as PreferenceAction;
      localStorage.setItem('preferences', JSON.stringify(preferences));
      return {
        ...state,
        preferences,
      };
    }

    case ActionType.SideNavLinkClicked: {
      return {
        ...state,
        showSidenav: isMobile ? false : state.showSidenav,
      };
    }

    case ActionType.SideNavToggle: {
      return {
        ...state,
        showSidenav: !state.showSidenav,
      };
    }

    case ActionType.OnlyShowBody: {
      return {
        ...state,
        bodyOnly: true,
      };
    }

    default: {
      return state;
    }
  }
};
