import React, { FC, useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { capitalize, setElementInArray, useNumericInputState } from '../../../../utils';
import { LoadingWrapper } from '../../../../utils/LoadingWrapper';
import { captureError } from '../../../../utils/errorHandling';

interface RaffleSelectorProps {
  readonly selectionChanged: (ids: number[]) => void;
}

export const RaffleSelector: FC<RaffleSelectorProps> = ({ selectionChanged }) => {
  const [id, setId] = useNumericInputState(-1, true);
  const [states, setStates] = useState(['waiting']);
  const [loading, setLoading] = useState(false);

  // As recommended on the demo on https://reactjs.org/docs/hooks-faq.html#how-can-i-do-data-fetching-with-hooks
  useEffect(() => {
    let ignore = false;
    async function fetchData(): Promise<void> {
      if (id === -1) {
        selectionChanged([]);
        return;
      }

      try {
        setLoading(true);
        const result = await api.getFullRaffleById(id);
        if (!ignore) {
          selectionChanged(
            result.tickets.filter((t) => states.includes(t.status)).map((t) => t.userId),
          );

          setLoading(false);
        }
      } catch (error) {
        captureError(error as Error);
      }
    }

    void fetchData();
    return () => {
      ignore = true;
    };
  }, [id, states]);

  return (
    <LoadingWrapper dataFetcher={async () => await api.getRaffles()}>
      {(raffles) => (
        <div>
          <FormGroup>
            <Label for="raffle">Gatekeeping</Label>
            <Input
              disabled={loading}
              id="raffleRecipients"
              onChange={setId}
              type="select"
              value={id}
            >
              <option key="none" value="-1">
                Select one
              </option>
              {raffles.map((raffle) => (
                <option key={raffle.id} value={raffle.id.toString()}>
                  {raffle.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="ticketStatus">Ticket Status</Label>
            {['waiting', 'winner', 'redeemed', 'rescinded'].map((type) => {
              return (
                <div className="custom-control custom-checkbox margin-top-10" key={type}>
                  <Input
                    checked={states.includes(type)}
                    className="custom-control-input"
                    disabled={loading}
                    id={`ticket_${type}`}
                    onChange={(e) => {
                      setStates(setElementInArray(states, type, e.target.checked));
                    }}
                    type="checkbox"
                  />
                  <Label className="custom-control-label" for={`ticket_${type}`}>
                    {capitalize(type)}
                  </Label>
                </div>
              );
            })}
          </FormGroup>
        </div>
      )}
    </LoadingWrapper>
  );
};
