import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import { useConfig } from '../utils';

export const Footer: FC = () => {
  const config = useConfig();

  return (
    <Navbar className="footer" color="light" light>
      <div>
        <a href="https://concat.app" rel="noopener noreferrer" target="_blank">
          <img src="https://cdn.concat.app/static/powered_by_concat.svg" />
        </a>
        <p>
          Version: <Link to="/changelog">{VERSION}</Link>
        </p>
      </div>
      {config && (
        <p>
          <Link to="/policy/terms">Terms &amp; Conditions</Link>
          &nbsp;|&nbsp;
          <Link to="/policy/privacy">Privacy Policy</Link>
        </p>
      )}
    </Navbar>
  );
};
