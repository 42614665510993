import { Breadcrumb, MaterialIconText } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback } from 'react';
import { Button, Col, ModalBody, ModalFooter, Row } from 'reactstrap';
import { PointsCriterion } from '../../../../../shared/orders/raffle';
import { RaffleConfiguratorProps } from '../utils';

export const PointsValue: FC<RaffleConfiguratorProps<PointsCriterion>> = ({
  displayName,
  onBack,
  onCancel,
  onConfigure,
}) => {
  const onFinish = useCallback(() => onConfigure({ name: 'points', options: {} }), [onConfigure]);

  return (
    <>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <Breadcrumb
              items={[
                { text: `Display Name: ${displayName ?? 'None'}` },
                { text: 'Type: Points Value' },
              ]}
            />
            <MaterialIconText name="keyboard_backspace" onClick={onBack}>
              Go Back
            </MaterialIconText>
            <hr />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="editor-finish-btn" color="primary" onClick={onFinish}>
          Finish
        </Button>
        <Button className="editor-cancel-btn" color="secondary" onClick={onCancel} outline>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};
