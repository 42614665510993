import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
import { OptionInternal } from '../../../shared/options';
import { useNullishNumericInputState, useNumericInputState } from '../../utils';

interface OptionEditorMinMaxProps {
  readonly label: 'Length' | 'Value';
  readonly option: OptionInternal;
  readonly setOption: Dispatch<SetStateAction<OptionInternal>>;
}

export const OptionEditorMinMax: FC<OptionEditorMinMaxProps> = ({ option, label, setOption }) => {
  const isCurrency = option.type === 'currency';
  const [min, setMin] = useNumericInputState(isCurrency ? option.min / 100 : option.min);
  const [max, setMax] = useNullishNumericInputState(
    option.id === 0 ? 40_000 : isCurrency && option.max ? option.max / 100 : option.max,
  );

  useEffect(() => {
    setOption((old) => ({ ...old, max, min }));
  }, [setOption, max, min]);

  return (
    <>
      <Col lg={6} xs={12}>
        <FormGroup>
          <Label for="options">Min {label}</Label>
          <InputGroup>
            {isCurrency && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            )}
            <Input defaultValue={min} name="min" onChange={setMin} type="number" />
          </InputGroup>
        </FormGroup>
      </Col>
      <Col lg={6} xs={12}>
        <FormGroup>
          <Label for="options">
            Max {label} <small>(Optional)</small>
          </Label>
          {/* TODO: Null max? */}
          <InputGroup>
            {option.type === 'currency' && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            )}
            <Input defaultValue={max ?? ''} name="max" onChange={setMax} type="number" />
          </InputGroup>
        </FormGroup>
      </Col>
    </>
  );
};
