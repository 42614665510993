import {
  JSONForm,
  PreSubmitData,
} from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Order } from '../../../../../shared/orders/model';
import { RefundPaymentTable, refundUpdatePreSubmit } from '../../../../components';
import { RefundGrantList, RefundGrantTable } from '../../../../components/RefundGrantTable';

interface OrderRefundModalProps {
  readonly order: Order;
  onSuccess(): void;
  onCancel(): void;
}

interface RefundPostData {
  [key: string]: unknown;
  paymentData: Record<string, number>;
  reason: string;
  removeGrants: RefundGrantList[];
}

export const OrderRefundModal: FC<OrderRefundModalProps> = ({ order, onCancel, onSuccess }) => {
  const [refundGrantList, setRefundGrantList] = useState<RefundGrantList[]>([]);

  const preSubmit = useCallback(
    (form: PreSubmitData<RefundPostData>) => {
      refundUpdatePreSubmit(form);
      form.inputs!.removeGrants = refundGrantList;
    },
    [refundGrantList],
  );

  return (
    <Modal className="modal-large" id="refundModal" isOpen>
      <JSONForm<{}, RefundPostData>
        method="post"
        onSuccess={onSuccess}
        path={`/api/orders/${order.id}/refund`}
        preSubmit={preSubmit}
      >
        <ModalHeader>Manual Refund #{order.id}</ModalHeader>
        <ModalBody>
          <p>
            Use the table below to enter the amount to be refunded from one or more payments. The
            available amount shows the maximum remaining amount that can be refunded via that
            individual payment.
          </p>
          <p>
            Your reason for approving this refund request will be stored in the audit log and will
            not be shown to the user.
          </p>
          <RefundPaymentTable payments={order.payments || []} />
          <RefundGrantTable list={refundGrantList} order={order} setList={setRefundGrantList} />
          <FormGroup row>
            <Label for="reason" lg={5} sm={12}>
              Reason for Approval <span className="text-danger">*</span>
            </Label>
            <Col lg={7} sm={12}>
              <Input id="reason" name="reason" required type="textarea" />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel} type="button">
            Cancel
          </Button>{' '}
          <Button color="primary" id="issueRefund" type="submit">
            Issue Refund
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};
