import React from 'react';
import { NavLink, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Card, CardHeader, Col, Nav, NavItem, Row } from 'reactstrap';
import { ProductCategoryModel, ProductModel } from '../../../shared/orders';
import {
  Breadcrumb,
  BreadcrumbItemProps,
  MaterialIcon,
  ProductCard,
  UserStateComponent,
} from '../../components';
import { Fetcher, useConvention, useFetcher } from '../../utils';

interface StoreData {
  categories: ProductCategoryModel[];
  products: ProductModel[];
}

export const Store: React.FC = () => {
  const match = useRouteMatch();
  const fetchData = useFetcher(async (): Promise<StoreData> => {
    let [categories, products] = await Promise.all([
      api.getProductCategories(),
      api.getPublicProducts(),
    ]);

    categories = categories.filter(({ isStore }) => isStore);
    const catSet = new Set(categories.map((c) => c.id));
    products = products.filter(({ category }) => catSet.has(category.id));

    return { categories, products };
  }, []);

  if (!fetchData.complete) {
    return (
      <UserStateComponent>
        <Fetcher result={fetchData} />
      </UserStateComponent>
    );
  }

  return (
    <UserStateComponent>
      <Switch>
        <Route exact path={`${match.path}`}>
          <StoreContainer data={fetchData.data!} />
        </Route>
        <Route path={`${match.path}/:id`}>
          <StoreContainer data={fetchData.data!} />
        </Route>
      </Switch>
    </UserStateComponent>
  );
};

interface StoreContainerProps {
  readonly data: StoreData;
}

const StoreContainer: React.FC<StoreContainerProps> = ({
  data: { categories, products: allProducts },
}) => {
  const match = useRouteMatch<{ id: string }>();
  const { shortName, hideEventStoreAllProducts } = useConvention();
  const history = useHistory();
  const selectedCategoryId = match.params.id ? Number.parseInt(match.params.id, 10) : undefined;

  const selectedCategory = categories.find(({ id }) => id === selectedCategoryId);
  const breadcrumbItems: BreadcrumbItemProps[] = [{ active: true, text: 'Event Store' }];

  if (selectedCategory) {
    breadcrumbItems.push({ active: true, text: selectedCategory.name });
  }

  const products =
    !hideEventStoreAllProducts && selectedCategoryId === undefined
      ? allProducts
      : allProducts.filter(({ category }) => category.id === selectedCategoryId);

  return (
    <Row className="justify-content-center" id="eventStore">
      <Col style={{ marginTop: '.15em', marginBottom: '2em' }} xs={12}>
        <Breadcrumb items={breadcrumbItems} />
      </Col>
      {categories.length === 0 ? (
        <Col className="text-center" id="noProductsAvailable" lg={10} xs={12}>
          <MaterialIcon large name="sentiment_dissatisfied" />
          <h4>There are no items available.</h4>
          <p>It seems there isn't anything you can purchase right now. Why not check back later?</p>
        </Col>
      ) : (
        <>
          <Col lg={3} xs={12}>
            <Card>
              <CardHeader>Categories</CardHeader>
              <Nav className="productCategories">
                {!hideEventStoreAllProducts && (
                  <NavItem active={selectedCategoryId === undefined}>
                    <NavLink className="nav-link" to="/store">
                      All Products
                    </NavLink>
                  </NavItem>
                )}
                {categories.map((category) => (
                  <NavItem active={selectedCategoryId === category.id} key={category.id}>
                    <NavLink className="nav-link" to={`/store/${category.id}`}>
                      {category.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Card>
          </Col>
          <Col lg={9} xs={12}>
            <Row>
              {products.length === 0 &&
                (hideEventStoreAllProducts ? (
                  <Col className="text-center" id="noCategorySelected" lg={10} xs={12}>
                    <MaterialIcon large name="search" />
                    <h4>Welcome to the {shortName} Store!</h4>
                    <p>
                      You can browse the categories on the left to find an assortment of items
                      available for purchase.
                    </p>
                  </Col>
                ) : (
                  <Col className="text-center" id="noProductsAvailable" lg={10} xs={12}>
                    <MaterialIcon large name="sentiment_dissatisfied" />
                    <h4>There are no items available.</h4>
                    <p>
                      It seems there isn't anything you can purchase right now. Why not check back
                      later?
                    </p>
                  </Col>
                ))}
              {products.map((product) => (
                <Col className="margin-bottom-10" key={product.id} lg={6} xs={12}>
                  <ProductCard
                    onSelect={(id) => history.push(`/store/product/${id}`)}
                    product={product}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};
