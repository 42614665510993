import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { ExportListItem } from '../../../../shared/export';
import { ActionButtonModal, DateTime, MessageCard } from '../../../components';
import { ExportRequest } from '../../../models';
import { Fetcher, useConfig, useFetcher, useQuery, useUser } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';

export const ExportSettings: FC = () => {
  const req = useFetcher(async () => {
    const pendingExportRequests = await api.fetchPendingExportRequests();
    return { pendingExportRequests };
  }, []);

  if (!req.complete) {
    return <Fetcher result={req} />;
  }

  const { pendingExportRequests } = req.data!;

  return (
    <>
      <h3>Export data</h3>
      <br />
      <Row>
        <Col className="margin-bottom-10" lg={12} xs={12}>
          <Card>
            <CardBody>
              <p>Export the convention data from your event.</p>
              <p>
                Data exports may contain Personal Identifiable Information (PII), and should be
                handled carefully by the person downloading the reports.
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} />
        <ExportGate />
      </Row>
      <br />
      <PendingRequestsTable onChange={req.refresh} pendingRequestsArray={pendingExportRequests} />
    </>
  );
};

interface PendingRequestsTableProps {
  readonly pendingRequestsArray: ExportListItem[];
  onChange(): void;
}

export const PendingRequestsTable: FC<PendingRequestsTableProps> = ({
  pendingRequestsArray,
  onChange,
}) => {
  if (pendingRequestsArray.length === 0) {
    return null;
  }

  return (
    <>
      <h3>Pending Requests</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Date/Time Created</th>
            <th>Name of Requesting User</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pendingRequestsArray.map((request) => (
            <tr key={request.id}>
              <td>
                <DateTime value={request.createdAt} />
              </td>
              <td>
                {request.requester.firstName} "{request.requester.preferredName}"{' '}
                {request.requester.lastName}
              </td>
              <td>
                <RequestButtons onChange={onChange} request={request} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const RequestButtons: FC<{ readonly request: ExportListItem; onChange(): void }> = ({
  request,
  onChange,
}) => {
  const user = useUser()!;
  const {
    organization: { approvalDirect },
  } = useConfig();

  if (user.id === request.requester.id) {
    return (
      <ActionButtonModal
        actionContent="Delete"
        buttonContent="Delete"
        className="indent"
        color="primary"
        id={`delete-${request.id}`}
        onComplete={async () => {
          await api.deleteExportRequest(`${request.id}`);
          toast.success('Request deleted!');
          onChange();
        }}
        title="Delete Request"
      >
        Are you sure you want to delete your request?
      </ActionButtonModal>
    );
  }

  if (approvalDirect) {
    return (
      <>
        <ActionButtonModal
          actionContent="Approve"
          buttonContent="Approve"
          className="indent"
          color="primary"
          id={`approve-${request.id}`}
          onComplete={async () => {
            await api.approveExportRequest(`${request.id}`);
            toast.success('Request approved!');
            onChange();
          }}
          title="Approve Request"
        >
          Are you sure you want to approve {request.requester.firstName}{' '}
          {request.requester.lastName}
          's request?
        </ActionButtonModal>
        <ActionButtonModal
          actionContent="Reject"
          buttonContent="Reject"
          className="indent"
          color="danger"
          id={`reject-${request.id}`}
          onComplete={async () => {
            await api.rejectExportRequest(`${request.id}`);
            toast.success('Request rejected!');
            onChange();
          }}
          title="Reject Request"
        >
          Are you sure you want to reject {request.requester.firstName} {request.requester.lastName}
          's request?
        </ActionButtonModal>
      </>
    );
  }

  return (
    <ActionButtonModal
      actionContent="Reject"
      buttonContent="Reject"
      className="indent"
      color="danger"
      id={`reject-${request.id}`}
      onComplete={async () => {
        await api.rejectExportRequest(`${request.id}`);
        toast.success('Request rejected!');
        onChange();
      }}
      title="Reject Request"
    >
      Are you sure you want to reject {request.requester.firstName} {request.requester.lastName}'s
      request?
    </ActionButtonModal>
  );
};

export const ExportBlock: FC = () => {
  const id = useQuery().get('code');

  if (!id) {
    return (
      <MessageCard icon="offline_bolt" level="danger" title="`code` not found on the url query" />
    );
  }

  return (
    <LoadingWrapper dataFetcher={async () => await api.rejectExportRequest(id)}>
      {() => {
        return <div id="rejectedSuccess">The data export request '{id}' has been cancelled.</div>;
      }}
    </LoadingWrapper>
  );
};

const ExportGate: FC = () => {
  return (
    <LoadingWrapper dataFetcher={async () => await api.fetchExportRequestStatus(false)}>
      {(data, refresh) => {
        if (!data) {
          return <RequestExport refresh={refresh} />;
        }

        if (data.status === 'approved') {
          return <ExportList />;
        }

        return <RequestExportStatus request={data} />;
      }}
    </LoadingWrapper>
  );
};

const RequestExport: FC<{ refresh(): void }> = ({ refresh }) => {
  const {
    organization: { approvalDirect },
  } = useConfig();

  const [loading, setLoading] = useState(false);
  async function requestAccess(): Promise<void> {
    setLoading(true);
    await api.fetchExportRequestStatus(true);
    refresh();
    setLoading(false);
  }

  return (
    <Col className="margin-bottom-10" lg={12} xs={12}>
      <Card>
        <CardBody>
          <p>
            {approvalDirect ? (
              <>
                For security and user privacy, data export requires authorized administrator
                approval or a 24-hour delay in which any other admin can cancel the export request.
              </>
            ) : (
              <>
                For security and user privacy, data export requires a 24-hour delay in which any
                other admin can cancel the export request.
              </>
            )}
          </p>
          <Button color="primary" disabled={loading} id="requestExport" onClick={requestAccess}>
            Request access
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
};

const RequestExportStatus: FC<{ readonly request: ExportRequest }> = ({ request }) => {
  const approvalDate = new Date(request.createdAt);
  approvalDate.setDate(approvalDate.getDate() + 1);

  return (
    <Col className="margin-bottom-10" lg={12} xs={12}>
      <Card id="approvalWaiting">
        <CardBody>
          <h4>Your export approval request is pending.</h4>
          <p>
            You can ask an authorized administrator to approve the request. Otherwise the request
            will be auto-approved after a 24-hour safety delay. Auto-approval date/time:{' '}
            <DateTime value={approvalDate} />.
          </p>
        </CardBody>
      </Card>
    </Col>
  );
};

const ExportList: FC = () => {
  return (
    <>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>
              Export all user data, including their address, date of birth, notes, ban status, and
              other details.
            </p>
            <ExportButtons type="user" />
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>
              Export registration data, including user names, address, registration payment status,
              badge name, and other details.
            </p>
            <ExportButtons type="registration" />
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>Export volunteer data, their names, assigned departments, and contact methods.</p>
            <ExportButtons type="volunteer" />
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>
              Export dealer data, their names, approval status, product options, and table options.
            </p>
            <ExportButtons type="dealer" />
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>
              Export order items data. Include each order items, when the order was finished, if the
              item was refunded.
            </p>
            <ExportButtons type="orderItems" />
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>
              Export order data. Include each order detail, including the user, product, refunds and
              payment. (Best analyzed with json)
            </p>
            <ExportButtons type="orders" />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const ExportButtons: FC<{ readonly type: string }> = ({ type }) => {
  return (
    <div id={`export-${type}`}>
      <Button color="primary" href={`/api/export/download/${type}?format=csv`} tag="a">
        CSV
      </Button>{' '}
      <Button color="primary" href={`/api/export/download/${type}?format=json`} tag="a">
        JSON
      </Button>
    </div>
  );
};
