import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import { NavLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Button, Input, Nav } from 'reactstrap';
import { CurrentUser } from '../../../../shared/user/base';
import { ElementHeader, PermissionBoundary } from '../../../components';
import { Permission, Role } from '../../../models';
import { checkPermission } from '../../../utils';
import { captureError } from '../../../utils/errorHandling';
import { AuditObjectPage } from '../audit/log';
import { PermissionEnum, PermissionListComponent } from './PermissionList';
import { RoleUserList } from './RoleUserList';

interface RouterParams {
  id: string;
}

export interface RoleEditProps {
  readonly user: CurrentUser;
  readonly roles: Role[];
  readonly permissions: Permission[];
  readonly refresh: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RoleEdit: FC<RoleEditProps & RouteComponentProps<RouterParams>> = ({
  user,
  roles,
  permissions,
  refresh,
  match,
}) => {
  const calculatedId = Number.parseInt(match.params.id, 10);
  const role = roles.find((t) => t.id === calculatedId)!;

  const updateRole = useCallback(
    async (permissionId: number, state: PermissionEnum) => {
      try {
        if (state === PermissionEnum.Inherit) {
          await api.deletePermissionRole(calculatedId, permissionId);
        } else {
          await api.updatePermissionRole(calculatedId, permissionId, state === PermissionEnum.Deny);
        }
      } catch (error) {
        captureError(error as Error);
      }

      refresh();
    },
    [calculatedId, refresh],
  );

  return (
    <>
      <ElementHeader title={role.name} />
      <Nav tabs>
        <NavLink activeClassName="active" className="nav-link" exact id="main_tab" to={match.url}>
          Permissions
        </NavLink>
        <NavLink
          activeClassName="active"
          className="nav-link"
          id="users_tab"
          to={`${match.url}/users`}
        >
          Users
        </NavLink>
        <PermissionBoundary inline requiredPermissions={['audit:read']}>
          <NavLink
            activeClassName="active"
            className="nav-link"
            id="audit_tab"
            to={`${match.url}/auditlogs`}
          >
            History
          </NavLink>
        </PermissionBoundary>
        {checkPermission(user, 'system:permission:update') && (
          <NavLink
            activeClassName="active"
            className="nav-link"
            id="delete_tab"
            to={`${match.url}/delete`}
          >
            Delete
          </NavLink>
        )}
      </Nav>
      <div id="roleEdit" style={{ paddingTop: '20px' }}>
        <Switch>
          <Route exact path={match.path}>
            <PermissionListComponent
              canEdit={checkPermission(user, 'system:permission:update')}
              items={role.rolePermissions}
              permissions={permissions}
              update={async (a, b) => await updateRole(a, b)}
            />
          </Route>
          <Route path={`${match.path}/users`}>
            <RoleUserList role={role} />
          </Route>
          <Route path={`${match.path}/delete`}>
            <RoleDelete role={role} />
          </Route>
          <Route path={`${match.path}/auditlogs`}>
            <AuditObjectPage entityId="Role" objectId={role.id.toString()} />
          </Route>
        </Switch>
      </div>
    </>
  );
};

const RoleDelete: FC<{ readonly role: Role }> = ({ role }) => {
  const [input, setInput] = useState('');

  const checkInput = useCallback(() => {
    if (role.name !== input) {
      throw new Error('The input does not match the role name.');
    }
  }, [input, role]);

  return (
    <JSONForm<{ id: number }>
      method="delete"
      onSuccess={() => {
        window.location.replace('/housekeeping/roles');
      }}
      path={`/api/roles/${role.id}`}
      preSubmit={() => {
        checkInput();
      }}
    >
      <h3>
        Warning! You are about to delete {role.name}. This operation can not be undone. Are you
        sure?
      </h3>

      <div>
        Please input '{role.name}' on the textbox.
        <Input
          id="deleteRoleCheck"
          onChange={(e) => {
            setInput(e.target.value);
          }}
          value={input}
        />
        <Button color="danger" id="deleteRole">
          Delete
        </Button>
      </div>
    </JSONForm>
  );
};
