import React, { ChangeEvent, createRef, FC, FormEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, FormText } from 'reactstrap';
import { ConventionSharedModel } from '../../../../shared/convention';
import { ActionButtonModal, LinkButton, PermissionBoundary } from '../../../components';
import { TimeZoneDropdown } from '../../../components/TimeZoneDropdown';
import { Fetcher, omit, useConvention, useFetcher } from '../../../utils';
import { captureError } from '../../../utils/errorHandling';
import {
  ConfigBooleanSetting,
  ConfigDateSetting,
  ConfigNumberBoxSetting,
  ConfigTextBoxSetting,
} from './AbstractLiveSettings';

export const ConventionSettings: FC = () => {
  const convention = useConvention();

  const fetcher = useFetcher(async () => {
    return await api.getConvention(convention.id);
  });

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return <ConventionSettingsEdit initialConvention={fetcher.data!} />;
};

// eslint-disable-next-line max-lines-per-function
const ConventionSettingsEdit: FC<{ readonly initialConvention: ConventionSharedModel }> = ({
  initialConvention,
}) => {
  const [convention, setConvention] = useState({ ...initialConvention });
  const [saving, setSaving] = useState(false);

  const save = useCallback(async () => {
    if (saving) {
      return;
    }

    setSaving(true);

    try {
      await api.updateConvention(initialConvention.id, omit(convention, 'id'));
    } catch (error) {
      captureError(error as Error);
    }

    setSaving(false);
  }, [saving, setSaving, initialConvention, convention]);

  const update = useCallback(
    <K extends keyof ConventionSharedModel>(key: K, value: ConventionSharedModel[K]) => {
      setConvention((old) => ({ ...old, [key]: value }));
    },
    [setConvention],
  );

  return (
    <div>
      <div className={saving ? 'savingConvention clearfix' : 'clearfix'} id="conventionForm">
        <h3>Convention</h3>
        <ConfigTextBoxSetting
          description=""
          displayName="Convention Legal Name"
          name="legalName"
          saving={saving}
          update={(val) => {
            update('legalName', val!);
          }}
          value={convention.legalName}
        />
        <ConfigTextBoxSetting
          description="E.g.: 501(c)7 Not-For-Profit"
          displayName="Convention Legal Type"
          name="legalType"
          placeholder="501(c)7 Not-For-Profit"
          saving={saving}
          update={(val) => {
            update('legalType', val!);
          }}
          value={convention.legalType}
        />

        <ConfigTextBoxSetting
          description="Maximum 6 characters"
          displayName="Convention Short Name"
          name="shortName"
          saving={saving}
          update={(val) => {
            update('shortName', val!);
          }}
          value={convention.shortName}
        />
        <ConfigTextBoxSetting
          description="Minimum 6 characters. Shown on the sidebar for the registration link."
          displayName="Convention Long Type"
          name="longName"
          saving={saving}
          update={(val) => {
            update('longName', val!);
          }}
          value={convention.longName}
        />

        <ConfigTextBoxSetting
          description="Usually the name of the hotel"
          displayName="Venue Name"
          name="venue"
          saving={saving}
          update={(val) => {
            update('venue', val!);
          }}
          value={convention.venue}
        />

        <TimeZoneDropdown
          onChange={(val) => {
            update('timeZone', val);
          }}
          saving={saving}
          value={convention.timeZone}
        />

        <ConfigDateSetting
          description="The first day of the convention"
          displayName="Convention Start Date"
          name="startAt"
          saving={saving}
          timezone={convention.timeZone}
          update={(val) => {
            update('startAt', val!);
          }}
          value={convention.startAt}
        />

        <ConfigDateSetting
          description="The last day of the convention"
          displayName="Convention End Date"
          name="endAt"
          saving={saving}
          timezone={convention.timeZone}
          update={(val) => {
            update('endAt', val!);
          }}
          value={convention.endAt}
        />

        <ConfigBooleanSetting
          description="Maintenance Mode for this convention"
          displayName="Convention Maintenance Mode"
          name="maintenanceMode"
          saving={saving}
          update={(val) => {
            update('maintenanceMode', val!);
          }}
          value={convention.maintenanceMode}
        />

        <h3>Kiosk</h3>
        <ConfigBooleanSetting
          description="Enables / disables accepting payments in kiosk mode. This should be disabled if kiosk mode will be used on unsecured shared devices."
          displayName="Allow Online Payments in Kiosk Mode"
          name="kioskPaymentsEnabled"
          saving={saving}
          update={(val) => {
            update('kioskPaymentsEnabled', val!);
          }}
          value={convention.kioskPaymentsEnabled}
        />
        <ConfigBooleanSetting
          description="Send user to the homepage if the website is inactive for more than 30 seconds."
          displayName="Kiosk mode timeout"
          name="kioskTimeout"
          saving={saving}
          update={(val) => {
            update('kioskTimeout', val!);
          }}
          value={convention.kioskTimeout}
        />
        <ConfigBooleanSetting
          description="Send users from the homepage directly into kiosk mode."
          displayName="Default kiosk mode"
          name="defaultKiosk"
          saving={saving}
          update={(val) => {
            update('kioskTimeout', val!);
          }}
          value={convention.kioskTimeout}
        />

        <ConfigBooleanSetting
          description="Enables / disables users transfer their registration to other users."
          displayName="Enable Registration Transfers"
          name="enableRegTransfer"
          saving={saving}
          update={(val) => {
            update('enableRegTransfer', val!);
          }}
          value={convention.enableRegTransfer}
        />

        <ConfigBooleanSetting
          description="Allows users to select badges via the convention registration page."
          displayName="Enable Badge Art Selection"
          name="enableBadgeArt"
          saving={saving}
          update={(val) => {
            update('enableBadgeArt', val!);
          }}
          value={convention.enableBadgeArt}
        />

        <ConfigBooleanSetting
          description="Enables / disables convention registration."
          displayName="Open Registration"
          name="registrationOpen"
          saving={saving}
          update={(val) => {
            update('registrationOpen', val!);
          }}
          value={convention.registrationOpen}
        />

        <ConfigBooleanSetting
          description="Enables / disables event store on the side menu."
          displayName="Enable Event Store"
          name="enableEventStore"
          saving={saving}
          update={(val) => {
            update('enableEventStore', val!);
          }}
          value={convention.enableEventStore}
        />

        <ConfigBooleanSetting
          description="Hide 'All Products' tab from the event store."
          disabled={!convention.enableEventStore}
          displayName="Event Store: Hide 'All Products' Tab"
          name="hideEventStoreAllProducts"
          saving={saving}
          update={(val) => {
            update('hideEventStoreAllProducts', val!);
          }}
          value={convention.hideEventStoreAllProducts}
        />

        <ConfigBooleanSetting
          description="If badges are pre-printed, this allows for the users to be checked-in without printing a badge."
          displayName="Enable Cashier to check-in users"
          name="cashierCheckIn"
          saving={saving}
          update={(val) => {
            update('cashierCheckIn', val!);
          }}
          value={convention.cashierCheckIn}
        />

        <ConfigBooleanSetting
          description="Show elements in the UI search that the user has attended the convention on the previous year."
          displayName="Show 'Returning Attendee' badge on the user profile."
          name="showReturningAttendeePill"
          saving={saving}
          update={(val) => {
            update('showReturningAttendeePill', val!);
          }}
          value={convention.showReturningAttendeePill}
        />

        <ConfigBooleanSetting
          description="Require users to verify their e-mail before being allowed to login. Users that have not verified their email will not receive any additional emails."
          displayName="Requre e-mail confirmation before login."
          name="requireActivation"
          saving={saving}
          update={(val) => {
            update('requireActivation', val!);
          }}
          value={convention.requireActivation}
        />

        <ConfigNumberBoxSetting
          allowNull
          description="Limit the maximum number of convention registrations"
          displayName="Limit attendance count"
          name="limitAttendees"
          saving={saving}
          update={(val) => {
            update('limitAttendees', val);
          }}
          value={convention.limitAttendees}
        />

        <ConfigNumberBoxSetting
          description="Number of children can be registered to be accompanied with a parent (0 to disable)"
          displayName="Max child registration"
          name="maxChildren"
          saving={saving}
          update={(val) => {
            update('maxChildren', val!);
          }}
          value={convention.maxChildren}
        />

        <ConfigNumberBoxSetting
          description="Minimum age required to register an account"
          displayName="Child age threshold"
          name="childAgeThreshold"
          saving={saving}
          update={(val) => {
            update('childAgeThreshold', val!);
          }}
          value={convention.childAgeThreshold}
        />

        <ConfigNumberBoxSetting
          description="Minimum age required before requiring a parental consent form. Set as same the Minimum child age to disable"
          displayName="Minor age threshold"
          name="minorAgeThreshold"
          saving={saving}
          update={(val) => {
            update('minorAgeThreshold', val!);
          }}
          value={convention.minorAgeThreshold}
        />

        <ConfigTextBoxSetting
          allowNull
          description="Users will be displayed this error message when attempting to refund"
          displayName="Disable refunds"
          name="disableRefunds"
          saving={saving}
          update={(val) => {
            update('disableRefunds', val);
          }}
          value={convention.disableRefunds}
        />

        <Button
          className="float-right"
          color="primary"
          disabled={saving}
          id="saveConvention"
          onClick={async () => await save()}
        >
          Save
        </Button>
      </div>
      <hr />
      <UploadLogoComponent />
      <PermissionBoundary requiredPermissions={['kiosk:manage']}>
        <hr />
        <RegistrationFormEditor />
      </PermissionBoundary>
    </div>
  );
};

const RegistrationFormEditor: FC = () => {
  const fetch = useFetcher(async () => {
    const pageLayout = await api.getRegistrationFormLayout(false);
    return { pageLayout };
  }, []);

  if (!fetch.complete) {
    return <Fetcher result={fetch} />;
  }

  return (
    <div className="clearfix">
      <h3>Registration Form Editor</h3>
      <div className="custom-control left-align margin-top-10">
        <div>
          EXPERIMENTAL - This feature is still in development. Edit the registration form, to add
          custom text, add/remove elements, or add other options.
        </div>
        <div>
          <LinkButton id="openRegEditor" to="/housekeeping/editor/registration">
            Go to Registration Form Editor
          </LinkButton>
        </div>
        {fetch.data!.pageLayout && (
          <div>
            <ActionButtonModal
              actionContent="Delete"
              buttonContent={<>Delete form</>}
              color="danger"
              id="deleteRegForm"
              onComplete={async () => {
                await api.deleteRegistrationFormLayout();
                toast.success('Layout deleted!');
                fetch.refresh();
              }}
              title="Delete Registration Layout?"
            >
              Are you sure you want to delete the registration layout form? This action can not be
              undone.
            </ActionButtonModal>
          </div>
        )}
      </div>
    </div>
  );
};

const UploadLogoComponent: FC = () => {
  return (
    <div className="clearfix">
      <h3>Logo</h3>
      <UploadLogoInnerComponent />
    </div>
  );
};

const UploadLogoInnerComponent: FC = () => {
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [success, setSuccess] = useState(false);
  const [artLoaded, setArtLoaded] = useState(false);

  const uploadInput = createRef<HTMLInputElement>();

  const uploadBadgeArt = useCallback(
    async (file: File) => {
      setUploadingLogo(true);

      try {
        await api.setLogo(file);
        setSuccess(true);
        setUploadingLogo(false);
      } catch (error) {
        captureError(error as Error);
        setUploadingLogo(false);
      }
    },
    [setSuccess, setUploadingLogo],
  );

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const logoFile = e.currentTarget.logoFile as HTMLInputElement;
      if (!logoFile.files || logoFile.files.length === 0) {
        toast.error('No file was selected');
        return;
      }

      await uploadBadgeArt(logoFile.files[0]);
    },
    [uploadBadgeArt],
  );

  const update = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.files && e.currentTarget.files.length > 0) {
        setArtLoaded(true);
      } else if (!e.currentTarget.files || e.currentTarget.files.length === 0) {
        setArtLoaded(false);
      }
    },
    [setArtLoaded],
  );

  if (success) {
    return <div id="logoUploadSuccess">Upload success</div>;
  }

  if (uploadingLogo) {
    return <div id="logoUploadProgress">Uploading...</div>;
  }

  return (
    <div className="custom-control left-align margin-top-10">
      <form id="fileUpload" onSubmit={onSubmit}>
        <input
          accept="image/png"
          id="logoFile"
          name="logoFile"
          onChange={update}
          ref={uploadInput}
          type="file"
        />
        <FormText color="muted">
          Logo will be displayed on the homepage. Recommended width is 64px.
        </FormText>
        <Button className="float-right" color="primary" disabled={!artLoaded} id="submitLogo">
          Save Logo
        </Button>
      </form>
    </div>
  );
};
