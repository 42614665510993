import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';
import { MaterialIcon } from '../../../components';
import { Permission } from '../../../models';

export enum PermissionEnum {
  Inherit,
  Allow,
  Deny,
}

interface PermissionItem {
  permissionId: number;
  invert: boolean;
  inherited?: string;
}

export const PermissionListComponent: FC<{
  readonly items: PermissionItem[];
  readonly permissions: Permission[];
  readonly update: (permissionId: number, state: PermissionEnum) => Promise<void>;
  readonly canEdit: boolean;
}> = ({ items, permissions, update, canEdit }) => {
  const itemMap: Record<number, PermissionItem> = {};

  const [loading, setLoading] = useState(false);

  const localUpdate = useCallback(
    async (permissionId: number, state: PermissionEnum) => {
      if (!canEdit || loading) {
        return;
      }

      setLoading(true);
      await update(permissionId, state);
      setLoading(false);
    },
    [update, loading, setLoading, canEdit],
  );

  for (const item of items) {
    itemMap[item.permissionId] = item;
  }

  return (
    <>
      {permissions.map((permission) => (
        <RolePermissionListItem
          canEdit={canEdit}
          item={itemMap[permission.id]}
          key={permission.id}
          loading={loading}
          permission={permission}
          update={localUpdate}
        />
      ))}
    </>
  );
};

const IconGetter: FC<{
  readonly item?: PermissionItem;
  readonly loading: boolean;
}> = ({ loading, item }) => {
  const className = loading || item?.inherited ? 'inherited-checkbox' : 'normal-checkbox';

  if (!item) {
    return <MaterialIcon className={className} id="none" medium name="check_box_outline_blank" />;
  }

  if (item.invert) {
    return (
      <MaterialIcon
        className={className}
        id="deny"
        medium
        name="indeterminate_check_box"
        style={{ color: 'red' }}
      />
    );
  }

  return (
    <MaterialIcon
      className={className}
      id="allow"
      medium
      name="check_box"
      style={{ color: 'green' }}
    />
  );
};

const RolePermissionListItem: FC<{
  readonly permission: Permission;
  readonly item?: PermissionItem;
  readonly update: (permissionId: number, state: PermissionEnum) => void;
  readonly loading: boolean;
  readonly canEdit: boolean;
}> = ({ permission, item, update, loading, canEdit }) => {
  const localUpdate = useCallback(() => {
    if (loading) {
      return;
    }

    if (item?.inherited && item.invert) {
      toast.error(
        `This permission is denied by '${item.inherited}'. Remove user from the role first.`,
      );

      return;
    }

    if (!item || item.inherited) {
      update(permission.id, PermissionEnum.Allow);
      return;
    }

    if (item.invert) {
      update(permission.id, PermissionEnum.Inherit);
      return;
    }

    update(permission.id, PermissionEnum.Deny);
  }, [update, item, loading]);

  return (
    <Col className="clearfix-after" id={`permission_${permission.id}`} xs={12}>
      <div style={{ float: 'right', cursor: canEdit ? 'pointer' : undefined }}>
        <span id="buttonToggle" onClick={localUpdate} title={item?.inherited}>
          <IconGetter item={item} loading={loading} />
        </span>
      </div>
      <div>{permission.name}</div>
      <div>{permission.description}</div>
      <hr />
    </Col>
  );
};
