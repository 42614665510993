import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { ProductModel } from '../../../shared/orders/product';
import { ConRegistrationForm, RegistrationUpsert } from '../../../shared/registration/model';
import { CurrentUser } from '../../../shared/user/base';
import { BadgeArtSelectorCard } from '../../components';
import { mapProductAddons } from '../../containers/store/ProductConfigurator';
import { RegistrationInfo } from '../../models';
import { handleUnknownOptionError, isLogicError, useForm, useToggle } from '../../utils';
import { captureError, LogicError } from '../../utils/errorHandling';
import { cleanupOptions } from '../../utils/options';
import { AdditionalRegOptions } from './AdditionalRegOptions';
import { BadgeInfo } from './BadgeInfo';
import { ConventionPolicy } from './ConventionPolicy';
import { EmergencyContact } from './EmergencyContactInfo';
import { ProductOptionViewerList } from './ProductOptionViewerList';

interface OldRegistrationFormProps {
  readonly attendanceType: ProductModel;
  readonly registration?: RegistrationInfo;
  readonly user: CurrentUser;
  readonly regForm: ConRegistrationForm;
  readonly askForChildren?: boolean;
  readonly hasChildren?: boolean;
  onSuccess(hasChildren?: boolean): void;
}

export const OldRegistrationForm: FC<OldRegistrationFormProps> = ({
  registration: conreg,
  attendanceType,
  user,
  regForm,
  onSuccess,
  askForChildren,
  hasChildren: hasChildrenInput,
}) => {
  const [hasChildren, toggleHasChildren] = useToggle(!!hasChildrenInput);
  const isPaidAttendance = !!conreg?.paidOrderItem;
  const [policyChecked, setPolicyChecked] = useState(!!conreg);
  const options = mapProductAddons(attendanceType.options, attendanceType.addons);

  const [registration, setRegistration] = useState<RegistrationUpsert>(() => ({
    attendanceTypeId: attendanceType.id,
    badgeName: conreg?.badgeName ?? '',
    emergencyContactName1: conreg?.emergencyContactName1 ?? null,
    emergencyContactName2: conreg?.emergencyContactName2 ?? null,
    emergencyContactPhone1: conreg?.emergencyContactPhone1 ?? null,
    emergencyContactPhone2: conreg?.emergencyContactPhone2 ?? null,
    flags: conreg?.flags ?? [],
    options: cleanupOptions([...regForm.options, ...attendanceType.options], conreg?.options ?? {}),
    badgeArtId: conreg?.badgeArtId,
  }));

  const form = useForm(async () => {
    if (regForm.terms && !policyChecked) {
      toast.error("You must accept the convention's policy");
      return;
    }

    if (registration.emergencyContactName1 === '') {
      registration.emergencyContactName1 = null;
    }

    if (registration.emergencyContactPhone1 === '') {
      registration.emergencyContactPhone1 = null;
    }

    if (registration.emergencyContactName2 === '') {
      registration.emergencyContactName2 = null;
    }

    if (registration.emergencyContactPhone2 === '') {
      registration.emergencyContactPhone2 = null;
    }

    try {
      if (conreg) {
        await api.updateRegistration(conreg.id, registration);
      } else {
        await api.createRegistration(user.id, registration);
      }
    } catch (error) {
      if (isLogicError(error, LogicError.UnknownProductOption)) {
        handleUnknownOptionError(error, options);
      } else {
        captureError(error as Error);
      }
    }

    onSuccess();
  }, [conreg, user.id, registration, onSuccess, regForm, policyChecked]);

  return (
    <form onSubmit={form.onSubmit}>
      <Row className="justify-content-center">
        <Col lg={10} xs={12}>
          <Row id="registrationForm">
            <BadgeInfo
              attendanceType={attendanceType}
              badgeDesign={regForm.badgeDesign}
              regOptions={regForm.options}
              registration={registration}
              setRegistration={setRegistration}
              user={user}
            />
            <AdditionalRegOptions
              askForChildren={askForChildren}
              flags={regForm.flags}
              hasChildren={hasChildren}
              regOptions={regForm.options}
              registration={registration}
              setRegistration={setRegistration}
              toggleChildren={toggleHasChildren}
            />
            {options.length > 0 && (
              <Col className="margin-bottom-10" lg={12} xs={12}>
                <Card className="additional-options">
                  <CardHeader>Product Options</CardHeader>
                  <CardBody>
                    <ProductOptionViewerList
                      isPaidAttendance={isPaidAttendance}
                      options={options}
                      setRegistration={setRegistration}
                      values={registration.options}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col className="margin-bottom-10" lg={12} xs={12}>
              <BadgeArtSelectorCard
                badgeArtId={registration.badgeArtId}
                defaultBadgeArtId={conreg?.badgeArtId}
                registrationId={conreg?.id}
                setBadgeArtId={(badgeArtId) => setRegistration((old) => ({ ...old, badgeArtId }))}
              />
            </Col>
            <EmergencyContact registration={registration} setRegistration={setRegistration} />
            {regForm.terms && (
              <ConventionPolicy
                checked={policyChecked}
                disabled={!!conreg}
                onChange={setPolicyChecked}
                policy={regForm.terms}
              />
            )}
          </Row>
        </Col>
        <Col className="margin-top-10" lg={6} xs={12}>
          <Button block color="primary" id="submitForm" type="submit">
            {conreg ? 'Update Registration' : 'Register'}
          </Button>
        </Col>
      </Row>
    </form>
  );
};
