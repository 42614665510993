import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { ProductDiscount, ProductModel } from '../../../../../shared/orders';
import {
  ActionButton,
  DateTime,
  ElementHeader,
  ElementSelector,
  MaterialIcon,
} from '../../../../components';
import {
  migrateTimezone,
  TimeZoneInputField,
  trimTime,
} from '../../../../components/TimeZoneInputField';
import { Role } from '../../../../models';
import { timeAdjust, useConvention, useUser } from '../../../../utils';
import { cToUsdStr, cToUsdStrPref } from '../../../../utils/cToUsdStr';
import { LocalSearchProvider } from '../utils';

interface DiscountInfoProps {
  readonly product: ProductModel;
  readonly onUpdate: () => void;
}

const enum DiscountInfoMode {
  View,
  Edit,
}

export const DiscountInfo: FC<DiscountInfoProps> = ({ product, onUpdate }) => {
  const { timeZone } = useConvention();
  const [mode, setMode] = useState(DiscountInfoMode.View);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const user = useUser()!;
  const searchProvider = useMemo(
    () => new LocalSearchProvider(async () => await api.getRoles()),
    [],
  );

  const dateNow = timeAdjust(-60);
  const defaultDate =
    dateNow.getTime() > product.availableFrom!.getTime()
      ? migrateTimezone(trimTime(dateNow), timeZone)
      : product.availableFrom!;

  const [availableTo, setAvailableTo] = useState(defaultDate);
  const [availableFrom, setAvailableFrom] = useState(defaultDate);

  if (mode === DiscountInfoMode.View) {
    return (
      <Card className="margin-top-10 discountList">
        <CardHeader>
          <div className="card-sub-header">Discounts</div>
          {!product.deletedAt && (
            <div className="float-right">
              <Button
                color="success"
                id="createDiscountBtn"
                onClick={() => {
                  setMode(DiscountInfoMode.Edit);
                }}
                title="Add"
              >
                <MaterialIcon name="add" />
              </Button>
            </div>
          )}
        </CardHeader>
        <CardBody>
          {product.discounts.length === 0 && <i>There are no discounts for this product.</i>}
          {product.discounts.map((discount) => (
            <ElementHeader
              icon={{ iconName: 'card_giftcard' }}
              key={discount.id}
              rightContent={
                <DiscountActions discount={discount} onUpdate={onUpdate} productId={product.id} />
              }
              title={
                <>
                  Discounted to {cToUsdStrPref(discount.price)} until{' '}
                  <DateTime timeZone={timeZone} value={discount.availableTo} />
                </>
              }
            >
              <DiscountBadges discount={discount} />
              &nbsp;
              {discount.roleId && (
                <Badge className={`discount-role-pill-${discount.roleId}`} color="warning" pill>
                  Restricted to Role ID {discount.roleId}
                </Badge>
              )}
            </ElementHeader>
          ))}
        </CardBody>
      </Card>
    );
  }

  const canLimitByRole = user.permissions.includes('system:permission:read');

  return (
    <Card className="margin-top-10 createDiscount">
      <CardHeader>
        <div className="card-sub-header">Discounts</div>
        <div className="float-right">
          <Button
            color="danger"
            id="cancel"
            onClick={() => {
              setMode(DiscountInfoMode.View);
            }}
            title="Cancel"
          >
            <MaterialIcon name="cancel" />
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <JSONForm<undefined, { roleIds: number[] }>
          method="post"
          onSuccess={() => {
            onUpdate();
            setMode(DiscountInfoMode.View);
          }}
          path={`/api/products/${product.id}/discounts`}
          preSubmit={(r) => (r.inputs!.roleIds = selectedIds)}
        >
          <Row className="text-center">
            <Col lg={3} xs={5}>
              <strong>Discounted Price</strong>
              <h2 style={{ marginTop: '5px' }}>
                <Row>
                  <Col xs={2}>$</Col>
                  <Col xs={10}>
                    <Input
                      defaultValue={cToUsdStr(product.price)}
                      id="price"
                      min={0}
                      name="price"
                      required
                      step={0.01}
                      style={{ fontSize: '1.25rem', marginTop: '-3px' }}
                      type="number"
                    />
                  </Col>
                </Row>
              </h2>
            </Col>
            <Col lg={1} xs={2}>
              <MaterialIcon large name="arrow_back_ios" />
            </Col>
            <Col lg={2} xs={5}>
              <strong>Current Price</strong>
              <h2 style={{ marginTop: '5px' }}>{cToUsdStrPref(product.price)}</h2>
            </Col>
            <Col lg={5} xs={12}>
              <FormGroup style={{ textAlign: 'initial' }}>
                <Label for="roleSelector">
                  Limit Discount to Role(s) <small>(Optional)</small>
                </Label>
                <ElementSelector<Role>
                  disabled={!canLimitByRole}
                  id="roleSelector"
                  searchProvider={searchProvider}
                  selectionIdsChanged={setSelectedIds}
                />
                {!canLimitByRole && (
                  <FormFeedback invalid>
                    You do not have sufficient permission to limit discounts by role.
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label for="availableFrom">Available From</Label>
                <Input name="availableFrom" type="hidden" value={availableFrom.toISOString()} />
                <TimeZoneInputField
                  defaultValue={defaultDate}
                  id="availableFrom"
                  onChange={setAvailableFrom}
                  required
                  timeZone={timeZone}
                />
              </FormGroup>
            </Col>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label for="availableTo">Available Until</Label>
                <Input name="availableTo" type="hidden" value={availableTo.toISOString()} />
                <TimeZoneInputField
                  defaultValue={defaultDate}
                  id="availableTo"
                  min={defaultDate}
                  onChange={setAvailableTo}
                  required
                  timeZone={timeZone}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <Button
                block
                color="primary"
                id="submitDiscountPrice"
                outline
                style={{ marginTop: '10px' }}
              >
                Create Discount Price
              </Button>
            </Col>
          </Row>
        </JSONForm>
      </CardBody>
    </Card>
  );
};

const DiscountBadges: FC<{ readonly discount: ProductDiscount }> = ({ discount }) => {
  const { timeZone } = useConvention();
  const now = Date.now();
  if (discount.deletedAt) {
    return (
      <Badge className="discount-pill-deleted" color="danger" pill>
        Deleted
      </Badge>
    );
  }

  if (discount.availableTo.getTime() <= now) {
    return (
      <Badge className="discount-pill-expired" color="danger" pill>
        Expired
      </Badge>
    );
  }

  if (discount.availableFrom.getTime() > now) {
    return (
      <>
        <UncontrolledTooltip placement="bottom" target={`notYetActive${discount.id}`}>
          Discount available from <DateTime timeZone={timeZone} value={discount.availableTo} />
        </UncontrolledTooltip>
        <Badge
          className="discount-pill-expired"
          color="info"
          id={`notYetActive${discount.id}`}
          pill
        >
          Not yet active
        </Badge>
      </>
    );
  }

  return (
    <Badge className="discount-pill-active" color="success" pill>
      Active
    </Badge>
  );
};

const DiscountActions: FC<{
  readonly discount: ProductDiscount;
  readonly productId: number;
  readonly onUpdate: () => void;
}> = ({ onUpdate, productId, discount }) => {
  if (discount.deletedAt) {
    return null;
  }

  return (
    <ActionButton
      action={`/api/products/${productId}/discounts/${discount.id}`}
      className={`action-delete delete-discount-${discount.id}`}
      color="danger"
      method="delete"
      onSuccess={onUpdate}
      outline
      title="Delete"
    >
      <MaterialIcon name="delete" />
    </ActionButton>
  );
};
