import React, { FC, useCallback } from 'react';
import { Button } from 'reactstrap';
import { ActionType, store, Theme } from '../services';
import { DateRange, useConfig, useConvention, usePreferences, useUser } from '../utils';
import { UserAvatar } from '../utils/UserAvatar';
import { MaterialIcon, SidenavItem } from '.';

const UserInfo: FC = () => {
  const config = useConfig();
  const convention = useConvention();
  const logout = useCallback(async () => {
    const result = await api.logout();

    if (result?.redirect_token) {
      const activeConvention = config.conventions.find((c) => c.id === config.activeConventionId);

      if (activeConvention) {
        window.location.replace(
          `${location.protocol}//${activeConvention.domain}/oauth/logout?redirect_token=${result.redirect_token}&conventionId=${convention.id}`,
        );

        return;
      }
    }

    store.dispatch({ type: ActionType.Logout });
  }, []);

  const { preferredName, username, profilePictureUrl, id } = useUser()!;
  return (
    <div className="user-info">
      <div className="avatar">
        <UserAvatar size="small" url={profilePictureUrl} />
      </div>
      <div className="greeting" title={`ID: #${id}\nUsername: ${username}`}>
        <p>Welcome back,</p>
        <h4>{preferredName ?? username}</h4>
      </div>
      <div className="clearfix" />
      <div className="padding-10">
        <Button className="btn-block" color="secondary" id="logout" onClick={logout}>
          Logout
        </Button>
      </div>
    </div>
  );
};

export const Sidenav: FC = (props) => {
  const user = useUser();
  const preferences = usePreferences();

  const toggleSidenav = useCallback(() => {
    store.dispatch({
      type: ActionType.SideNavToggle,
    });
  }, []);

  const switchTheme = useCallback(
    (theme: Theme) => {
      store.dispatch({
        preferences: {
          ...preferences,
          theme,
        },
        type: ActionType.PreferenceUpdate,
      });
    },
    [preferences],
  );

  const lightTheme = useCallback(() => {
    switchTheme(Theme.Light);
  }, []);

  const darkTheme = useCallback(() => {
    switchTheme(Theme.Dark);
  }, []);

  return (
    <div className="sidenav" id="sidenav">
      <Button className="toggle-btn" color="primary" onClick={toggleSidenav}>
        <MaterialIcon name="close" />
      </Button>
      <ConventionInfo />
      {user ? <UserInfo /> : null}
      <ul>
        {props.children}
        <SidenavItem header requireLoggedIn text="Theme Settings" />
        <div className="theme-switcher">
          <div className="light" id="themeLight" onClick={lightTheme} />
          <div className="dark" id="themeDark" onClick={darkTheme} />
        </div>
      </ul>
    </div>
  );
};

const ConventionInfo: FC = () => {
  const { longName, startAt, endAt, timeZone } = useConvention();
  return (
    <div className="event-info">
      <h4>{longName}</h4>
      <p id="conventionDateRange">
        <DateRange end={endAt} start={startAt} timeZone={timeZone} />
      </p>
    </div>
  );
};
