import { classNames } from '@conventioncatcorp/common-fe';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { MaterialIcon } from '.';

interface SelectBlockItem {
  /*
   * Ignore other values we don't use.
   * Allows other keys to be present in the items object without causing a TypeError.
   */
  [key: string]: unknown;

  name: string;
  icon?: string;
  img?: string;
  id?: string;
}

interface SelectBlockProps {
  readonly items: SelectBlockItem[];
  readonly defaultSelectedIds?: number[];
  readonly textOnly?: true;
  readonly allowMultiple?: true;
  readonly ordered?: boolean;
  readonly disabled?: boolean;
  readonly max?: number | null;
  onChange?(active: number[]): void;
}

export const SelectBlocks: FunctionComponent<SelectBlockProps> = ({
  allowMultiple,
  disabled,
  items,
  defaultSelectedIds,
  textOnly,
  ordered,
  max,
  onChange,
}) => {
  const [selectedIds, setSelectedIds] = useState(defaultSelectedIds ?? []);
  const onChangeFn = useCallback((idx: number[]) => onChange && onChange(idx), [onChange]);
  const updateSelectedIds = useCallback(
    (idx: number) => {
      if (disabled) {
        return;
      }

      if (allowMultiple) {
        const newList = selectedIds.includes(idx)
          ? selectedIds.filter((i) => i !== idx)
          : selectedIds.concat(idx);

        if (max && newList.length > max) {
          toast.error(`You can only select up to ${max} items`);
          return;
        }

        setSelectedIds(newList);
      } else {
        setSelectedIds([idx]);
      }
    },
    [allowMultiple, disabled, selectedIds],
  );

  useEffect(() => {
    if (!allowMultiple && selectedIds.length === 0) {
      return;
    }

    onChangeFn(selectedIds);
  }, [selectedIds]);

  return (
    <Row className="select-block-container">
      {items.map((item, idx) => {
        const selected = selectedIds.indexOf(idx);
        return (
          <SelectBlock
            // Disabled, or (not selected and at limit)
            disabled={disabled || (selected === -1 && !!max && selectedIds.length >= max)}
            item={item}
            key={item.id ?? idx}
            onClick={() => updateSelectedIds(idx)}
            ordered={ordered}
            selected={selected}
            textOnly={textOnly}
          />
        );
      })}
    </Row>
  );
};

interface SelectBlockItemProps {
  readonly item: SelectBlockItem;
  readonly textOnly?: true;
  readonly selected: number;
  readonly ordered?: boolean;
  readonly disabled?: boolean;
  onClick(): void;
}

const SelectBlock: FunctionComponent<SelectBlockItemProps> = ({
  item: { id, icon, img, name },
  textOnly,
  onClick,
  selected,
  ordered,
  disabled,
}) => {
  return (
    <Col
      className={classNames({ selected: selected !== -1, textOnly }, 'select-block')}
      id={id}
      lg={4}
      onClick={onClick}
      style={{ cursor: disabled ? 'not-allowed' : undefined }}
      xs={12}
    >
      {selected !== -1 && (
        <MaterialIcon
          className="select-block-selected"
          name={ordered ? 'circle' : 'check_circle'}
          type="success"
        />
      )}
      {ordered && selected !== -1 && (
        <div
          className="select-block-selected"
          style={{
            width: '24px',
            backgroundColor: 'transparent',
            lineHeight: '24px',
            color: 'white',
          }}
        >
          {selected + 1}
        </div>
      )}

      {icon && (
        <div className="select-block-icon">
          <MaterialIcon large name={icon} />
        </div>
      )}
      {img && (
        <div className="select-block-icon">
          <img src={img} />
        </div>
      )}
      <div className="select-block-text">{name}</div>
    </Col>
  );
};
