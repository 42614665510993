import React, { FC, useEffect } from 'react';
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { SurchargeAmountType, SurchargeType } from '../../../../shared/orders/model';
import { InlineHelp } from '../../../components';
import { useInputState } from '../../../utils';

interface Props {
  readonly wide?: boolean;
}

export const SurchargeForm: FC<Props> = ({ wide }) => {
  const [type, setType] = useInputState<SurchargeType>('surcharge');
  const [amountType, setAmountTypeEv, setAmountType] = useInputState<SurchargeAmountType>('fixed');

  const isFixed = amountType === 'fixed';
  const isTax = type === 'tax';

  useEffect(() => {
    if (isTax) {
      setAmountType('percent');
    }
  }, [type]);

  return (
    <Row>
      <Col lg={wide && 6} xs={12}>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input id="name" name="name" placeholder="Surcharge Name" required />
        </FormGroup>
      </Col>
      <Col lg={wide && 6} xs={12}>
        <FormGroup>
          <Label for="name">Display Name</Label>
          <Input id="displayName" name="displayName" placeholder="Display Name" required />
        </FormGroup>
      </Col>
      <Col lg={wide && 6} xs={12}>
        <FormGroup>
          <Label for="type">
            Type
            <InlineHelp>
              <Label>Surcharge Type Information</Label>
              <ul className="flushLeft">
                <li>
                  <strong>Surcharge</strong>: An arbitrary fixed amount or percent-based charge.
                </li>
                <li>
                  <strong>Tax Rate</strong>: An involuntary percent-based charge required by a
                  government entity.
                </li>
              </ul>
            </InlineHelp>
          </Label>
          <Input id="type" name="type" onChange={setType} required type="select">
            <option value="surcharge">Surcharge</option>
            <option value="tax">Tax Rate</option>
          </Input>
        </FormGroup>
      </Col>
      <Col lg={wide && 6} xs={12}>
        <FormGroup>
          <Label for="amountType">Amount Type</Label>
          <Input
            defaultValue={isTax || !isFixed ? 'percent' : 'fixed'}
            id="amountType"
            name="amountType"
            onChange={setAmountTypeEv}
            required
            type="select"
          >
            <option disabled={isTax} value="fixed">
              Fixed Amount
            </option>
            <option value="percent">Percentage</option>
          </Input>
        </FormGroup>
      </Col>
      <Col lg={wide && 6} xs={12}>
        <FormGroup>
          <Label for="amount">Amount</Label>
          <InputGroup>
            {isFixed && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            )}
            <Input
              id="amount"
              max={isFixed ? undefined : '100'}
              min="0.01"
              name="amount"
              required
              step="0.01"
              type="number"
            />
            {!isFixed && (
              <InputGroupAddon addonType="append">
                <InputGroupText>%</InputGroupText>
              </InputGroupAddon>
            )}
          </InputGroup>
        </FormGroup>
      </Col>
    </Row>
  );
};
