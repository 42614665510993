import React, { FC, useCallback } from 'react';
import { RegionDropdownProps } from 'react-country-region-selector';
import { Input } from 'reactstrap';
import { makeLazyComponent } from '../utils/lazy';

const RegionSelectorComponent = makeLazyComponent(async () => ({
  default: (
    await import(/* webpackChunkName: "countryregionselector" */ 'react-country-region-selector')
  ).RegionDropdown,
}));

export const RegionSelector: FC<RegionDropdownProps> = ({ ...props }) => {
  const { name } = props;

  const onAutoComplete = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      /**
       * Delay the change event to allow the region selector to update first, then update the region selector by id.
       * We don't need to have a delay as the region selector will be populated by the time this event triggers, but
       * for safety, we're using 50ms.
       */
      setTimeout(() => {
        const addressStateInput = document.getElementById('addressState') as HTMLInputElement;

        // If it's not a select... idk what to do
        if (!addressStateInput.type.startsWith('select')) {
          return;
        }

        const regionSelector = addressStateInput as unknown as HTMLSelectElement;

        // Find the option that matches the input value
        const option = Array.from(regionSelector.options).find(
          (o) =>
            o.text.toLowerCase() === e.target.value.toLowerCase() ||
            o.value.toLowerCase() === e.target.value.toLowerCase(),
        );

        if (option) {
          regionSelector.value = option.value;
        }

        // Fire the change event
        const event = new Event('change', { bubbles: true });
        regionSelector.dispatchEvent(event);
      }, 50);
    },
    [name],
  );

  /**
   * This is a workaround for the region selector not being populated by the browser's autocomplete feature
   * due to the regions not being populated until shortly after the country is selected.
   *
   * There doesn't seem to be a way to get the browser to populate the region selector as the option doesn't
   * yet exist, so the next best thing is to store it temporarily then update it once the options are available.
   */

  return (
    <>
      <Input
        autoComplete="address-level1"
        onChange={onAutoComplete}
        style={{ opacity: 0, position: 'absolute', left: '-9999px', top: '-9999px' }}
        type="text"
      />
      <RegionSelectorComponent {...props} />
    </>
  );
};
