import { MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { FC } from 'react';
import {
  Badge,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { ProductImage, ProductImageType } from '../../../../../../shared/orders/product';
import { ActionButton } from '../../../../../components';

interface ImageListItemComponentProps {
  readonly productId: number;
  readonly image: ProductImage;
  onUpdate(): void;
}

export const ImageListItemComponent: FC<ImageListItemComponentProps> = ({
  productId,
  image,
  onUpdate,
}) => {
  return (
    <Col id={`image-${image.id}`} lg={3} xs={12}>
      <Card color={image.type === 'primary' ? 'primary' : 'secondary'} outline>
        <CardBody>
          <img src={`/api/products/${productId}/images/${image.id}`} style={{ maxWidth: '100%' }} />
        </CardBody>
        <CardFooter>
          <Row>
            <Col lg={6} xs={12}>
              {image.type === 'primary' && <Badge color="primary">Primary</Badge>}
              {image.type === 'image' && <Badge color="info">Image</Badge>}
              {image.type === 'badgeArt' && <Badge color="warning">Badge Art</Badge>}
              {image.type === 'hidden' && <Badge color="secondary">Hidden</Badge>}
            </Col>
            <Col lg={6} xs={12}>
              <ImageUpdateButtons image={image} onUpdate={onUpdate} productId={productId} />
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  );
};

interface ImageUpdateButtonsProps {
  readonly productId: number;
  readonly image: ProductImage;
  onUpdate(): void;
}

const ImageUpdateButtons: FC<ImageUpdateButtonsProps> = ({ productId, image, onUpdate }) => {
  return (
    <ButtonGroup>
      <UncontrolledDropdown>
        <DropdownToggle caret className="open-menu">
          <MaterialIcon name="settings" />
        </DropdownToggle>
        <DropdownMenu>
          {image.type !== 'primary' && (
            <SetImageType
              assetId={image.id}
              className="setPrimary"
              onUpdate={onUpdate}
              productId={productId}
              type="primary"
            >
              Set Primary
            </SetImageType>
          )}
          {image.type !== 'hidden' && (
            <SetImageType
              assetId={image.id}
              className="setHidden"
              onUpdate={onUpdate}
              productId={productId}
              type="hidden"
            >
              Set Hidden
            </SetImageType>
          )}
          {image.type !== 'badgeArt' && (
            <SetImageType
              assetId={image.id}
              className="setBadgeArt"
              onUpdate={onUpdate}
              productId={productId}
              type="badgeArt"
            >
              Set Badge Art
            </SetImageType>
          )}
          <ActionButton
            action={`/api/products/${productId}/images/${image.id}`}
            block
            className="dropdown-item action-delete"
            color="danger"
            method="delete"
            onSuccess={onUpdate}
          >
            Delete
          </ActionButton>
        </DropdownMenu>
      </UncontrolledDropdown>
    </ButtonGroup>
  );
};

interface SetImageTypeProps {
  readonly className: string;
  readonly productId: number;
  readonly assetId: string;
  readonly type: ProductImageType;
  onUpdate(): void;
}

const SetImageType: FC<SetImageTypeProps> = ({
  productId,
  type,
  assetId,
  onUpdate,
  children,
  className,
}) => {
  return (
    <ActionButton
      action={`/api/products/${productId}/images/${assetId}`}
      block
      className={`dropdown-item ${className}`}
      color="secondary"
      method="patch"
      onSuccess={onUpdate}
      outline
      payload={{ type }}
    >
      {children}
    </ActionButton>
  );
};
