import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Row } from 'reactstrap';
import { Surcharge } from '../../../models';
import { useBoolState } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { CreateModal } from './CreateModal';
import { SurchargesListItem } from './surchargesListItem';

export const SurchargesList: FC = () => {
  const history = useHistory();
  const [openModal, enableModal, disableModal] = useBoolState(false);

  return (
    <LoadingWrapper<Surcharge[], void> dataFetcher={async () => await api.getSurcharges()} inline>
      {(data) => (
        <>
          <div>
            <Button color="primary" id="createSurcharge" onClick={enableModal}>
              Create New Surcharge
            </Button>
          </div>
          <hr />
          <Row className="justify-content-center">
            {data.map((surcharge) => (
              <SurchargesListItem key={surcharge.id} surcharge={surcharge} />
            ))}
          </Row>
          <CreateModal
            onCancel={disableModal}
            onUpdate={(id) => {
              toast.success('Created new surcharge.');
              history.push(`/housekeeping/surcharges/${id}`);
            }}
            open={openModal}
          />
        </>
      )}
    </LoadingWrapper>
  );
};
