import React, { FC, ReactNode, useCallback } from 'react';
import {
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
import { getDate } from '../../../components/Date';

interface ConfigSettingProps<T> {
  readonly name: string;
  readonly displayName: string;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly disabled?: boolean;
  readonly description: string;
  readonly saving: boolean;
  readonly value: T | null;
  readonly update: (value: T | null) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly placeholder?: string;
}

export const ConfigBooleanSetting: FC<ConfigSettingProps<boolean>> = ({
  displayName,
  disabled,
  description,
  name,
  value,
  saving,
  update,
}) => (
  <div className="custom-control custom-checkbox left-align margin-top-10">
    <Input
      checked={!!value}
      className={`custom-control-input ${value ? 'yes' : 'no'}`}
      disabled={disabled || saving}
      id={name}
      onChange={(e) => {
        update(e.target.checked);
      }}
      type="checkbox"
    />
    <Label className="custom-control-label" for={name}>
      {displayName}
      <FormText color="muted">{description}</FormText>
    </Label>
  </div>
);

interface ConfigTextBoxSettingProps<T extends number | string> extends ConfigSettingProps<T> {
  readonly allowNull?: boolean;
}

interface BaseConfigTextBoxSettingProps<T extends number | string>
  extends ConfigTextBoxSettingProps<T> {
  readonly children: ReactNode;
  readonly defaultValue: T;
}

const BaseConfigTextBoxSetting = <T extends number | string>(
  props: BaseConfigTextBoxSettingProps<T>,
) => {
  // const BaseConfigTextBoxSetting: FC<BaseConfigTextBoxSettingProps> = props => {
  const {
    displayName,
    description,
    name,
    value,
    saving,
    update,
    allowNull,
    children,
    defaultValue,
    disabled,
  } = props;

  return (
    <div
      className={`custom-control left-align margin-top-10 ${allowNull ? 'custom-checkbox' : ''}`}
    >
      {allowNull && (
        <Input
          checked={value !== null}
          className={`custom-control-input ${value ? 'yes' : 'no'}`}
          disabled={saving || disabled}
          id={`${name}-checkbox`}
          onChange={(e) => {
            update(e.target.checked ? defaultValue : null);
          }}
          type="checkbox"
        />
      )}
      <Label className={allowNull ? 'custom-control-label' : ''} for={`${name}-checkbox`}>
        {displayName}
      </Label>

      {children}
      <FormText color="muted">{description}</FormText>
    </div>
  );
};

export const ConfigTextBoxSetting: FC<ConfigTextBoxSettingProps<string>> = (props) => {
  const { name, value, saving, update, placeholder, disabled } = props;
  return (
    <BaseConfigTextBoxSetting {...props} defaultValue="">
      <Input
        disabled={value === null || saving || disabled}
        id={name}
        name={name}
        onChange={(e) => {
          update(e.target.value);
        }}
        placeholder={placeholder}
        value={value?.toString()}
      />
    </BaseConfigTextBoxSetting>
  );
};

interface ConfigNumberBoxSettingProps extends ConfigTextBoxSettingProps<number> {
  readonly currency?: boolean;
}

export const ConfigNumberBoxSetting: FC<ConfigNumberBoxSettingProps> = (props) => {
  const { name, value, saving, update, placeholder, currency, disabled } = props;
  return (
    <BaseConfigTextBoxSetting {...props} defaultValue={0}>
      <InputGroup>
        {currency && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>$</InputGroupText>
          </InputGroupAddon>
        )}
        <Input
          disabled={value === null || saving || disabled}
          id={name}
          name={name}
          onChange={(e) => {
            update(e.target.valueAsNumber);
          }}
          placeholder={placeholder}
          type="number"
          value={value?.toString() ?? '0'}
        />
      </InputGroup>
    </BaseConfigTextBoxSetting>
  );
};

interface ConfigDateSettingProps extends ConfigSettingProps<Date> {
  readonly timezone: string;
}

export const ConfigDateSetting: FC<ConfigDateSettingProps> = (props) => {
  const { value } = props;

  const parseDate = useCallback(
    (input: string) => {
      const before = new Date(input);
      const mid = changeTimezone(before, 'UTC');
      const newDate = changeTimezone(mid, props.timezone, true);
      if (!Number.isNaN(newDate.getTime())) {
        props.update(newDate);
      }
    },
    [props.timezone, props.update],
  );

  return (
    <div className="custom-control left-align margin-top-10">
      <FormGroup>
        <Label for={props.name}>{props.displayName}</Label>
        <Input
          defaultValue={getDate(value!)}
          disabled={props.saving || props.disabled}
          id={props.name}
          name={props.name}
          onChange={(e) => {
            parseDate(e.target.value);
          }}
          type="date"
        />
        <FormText color="muted">{props.description}</FormText>
      </FormGroup>
    </div>
  );
};

function changeTimezone(date: Date, ianatz?: string, inverse = false): Date {
  // Taken from https://stackoverflow.com/a/53652131/99966
  const invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz,
    }),
  );

  const diff = date.getTime() - invdate.getTime();
  return new Date(date.getTime() - (inverse ? -diff : diff));
}
