import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';
import { MenuLink } from '../../../../shared/config';
import { InlineHelp } from '../../../components';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { ColumnSetting, DataTable } from './DataTable';

const menuLinkColumns: ColumnSetting<MenuLink>[] = [
  {
    name: 'name',
    type: 'text',
  },
  {
    name: 'url',
    type: 'text',
  },
  {
    label: (
      <>
        icon <InlineHelp href="https://material.io/resources/icons/">Icon References</InlineHelp>
      </>
    ),
    name: 'icon',
    type: 'text',
  },
  {
    name: 'order',
    type: 'number',
  },
  {
    enumValues: [
      {
        name: 'Application',
        value: 'application',
      },
      {
        name: 'Attendance',
        value: 'attendance',
      },
    ],
    name: 'type',
    type: 'enum',
  },
];

export const MenuLinkSettings: FC = () => (
  <LoadingWrapper<MenuLink[], void> dataFetcher={async () => await api.getMenuLinks()} inline>
    {(data, refresh) => (
      <Row>
        <Col className="margin-bottom-10" id="menuLinkSettings" xs={12}>
          <DataTable<MenuLink>
            columns={menuLinkColumns}
            data={data}
            endpoint="/api/menulinks"
            refresh={refresh}
            title="Menu Link"
          />
        </Col>
      </Row>
    )}
  </LoadingWrapper>
);
