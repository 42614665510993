import React, { FC } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { ProductCategoryModel } from '../../../../shared/orders';
import { OrderItemElement } from '../../../components';
import { Fetcher, noop, useConvention, useFetcher } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';

interface TabDetailsProps {
  readonly userId: number;
  refreshContents(force?: boolean): void;
}

export const UserOrdersTab: FC<TabDetailsProps> = ({ userId, refreshContents }) => {
  const convention = useConvention();

  const request = useFetcher(async () => {
    return await api.getOrdersForUser(userId);
  }, [userId]);

  async function createNew(): Promise<void> {
    await api.getActiveOrder(userId, true);
    refreshContents(true);
  }

  if (!request.complete) {
    return <Fetcher result={request} />;
  }

  const orders = request.data!;

  return (
    <LoadingWrapper<ProductCategoryModel[], void>
      dataFetcher={async () => await api.getProductCategories()}
      inline
    >
      {(categories) => (
        <Row className="justify-content-center">
          {orders.filter((t) => t.status === 'unpaid' && t.conventionId === convention.id)
            .length === 0 && (
            <Col className="margin-bottom-10" lg={8} xs={12}>
              <Button color="primary" id="createOrder" onClick={createNew}>
                Create new order
              </Button>
            </Col>
          )}
          {orders.length === 0 && (
            <Col id="noOrders" xs={12}>
              <Card>
                <CardBody className="text-secondary text-center">
                  There are no orders for this user.
                </CardBody>
              </Card>
            </Col>
          )}
          <Col className="resultTable" id="ordersTable" lg={8} xs={12}>
            {orders
              .filter((t) => t.status === 'paid' || t.conventionId === convention.id)
              .map((order) => (
                <OrderItemElement
                  categories={categories}
                  id={`order_${order.id}`}
                  key={order.id}
                  onSelect={noop}
                  order={order}
                />
              ))}
          </Col>
        </Row>
      )}
    </LoadingWrapper>
  );
};
