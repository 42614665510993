/* eslint-disable import/no-unused-modules */

export const noteCategories = [
  'commendation',
  'feedback',
  'misc',
  'reprimand',
  // A request will be displayed on the cashier interface when the user checks in
  'cashier',
  'request',
  // Restrictions will be prominently displayed, but not actively stop the user
  'restriction',
  // A ban will actively stop the user from doing something
  'ban',
] as const;

export type NoteCategory = (typeof noteCategories)[number];

export const banTypes = ['registration', 'dealer', 'volunteer', 'other'] as const;

export type BanType = (typeof banTypes)[number];

export const HRRestrictedCategories: NoteCategory[] = ['ban', 'restriction'];

export interface UserNoteSummary {
  id: number;
  category: NoteCategory;
  text: string;
  createdAt: Date;
  updatedAt: Date;
  user?: {
    id: number;
    username: string;
    firstName: string;
    preferredName?: string;
    lastName: string;
  };

  author: {
    id: number;
    username: string;
    firstName: string;
    preferredName?: string;
    lastName: string;
  };

  ban?: UserNoteBan;
}

export interface UserNoteBan {
  category: BanType;
  firstName: string;
  lastName: string;
  bornAt: Date | null;
}

export interface UserNoteSearch {
  userId?: number;
  category?: NoteCategory;
  search?: string;
}

export interface UserNoteCreate {
  userId?: number;
  category: NoteCategory;
  ban?: UserNoteBan;
  text: string;
}
