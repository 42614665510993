import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';
import { ExtendedUser, UserRole } from '../../../../shared/user/extended';
import { RoleWithPermissions } from '../../../../shared/user/roles';
import { RoleScope } from '../../../APIClient';
import { capitalize, useConvention } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { captureError } from '../../../utils/errorHandling';

interface RolesTabProps {
  readonly userData: ExtendedUser;
  refreshContents(force?: boolean): void;
}

export const RolesTab: FC<RolesTabProps> = ({ userData, refreshContents }) => {
  return (
    <LoadingWrapper<RoleWithPermissions[], void>
      dataFetcher={async () => await api.getUserRoles()}
      inline
    >
      {(roles) => (
        <>
          {roles.map((r) => {
            return (
              <RoleRow
                key={r.id}
                refresh={() => {
                  refreshContents(true);
                }}
                role={r}
                roles={userData.roles!}
                userId={userData.id}
              />
            );
          })}
        </>
      )}
    </LoadingWrapper>
  );
};

interface RoleRowProps {
  readonly role: RoleWithPermissions;
  readonly roles: UserRole[];
  readonly userId: number;
  refresh(): void;
}

const RoleRow: FC<RoleRowProps> = ({ role, roles, refresh, userId }) => {
  const { id } = role;
  const convetion = useConvention();
  const hasConvention = roles.some((r) => r.id === id && r.conventionIds.includes(convetion.id));
  const hasGlobal = roles.some((r) => r.id === id && r.conventionIds?.some((ur) => ur === 0));

  const toggleRole = async (type: RoleScope) => {
    const remove = type === 'convention' ? hasConvention : hasGlobal;
    try {
      await (remove
        ? api.updateUserRole(userId, id, 'remove', type)
        : api.updateUserRole(userId, id, 'add', type));

      toast.success(`${remove ? 'Removed' : 'Added'} role ${role.name}`);
      refresh();
    } catch (error) {
      captureError(error as Error);
    }
  };

  return (
    <Col className="margin-top-10" lg={4} xs={12}>
      <Card>
        <CardHeader>
          {role.name}
          <RoleRowButton enabled={hasConvention} id={id} title="convention" toggle={toggleRole} />
          <RoleRowButton enabled={hasGlobal} id={id} title="global" toggle={toggleRole} />
        </CardHeader>
        {role.description && <CardBody>{role.description}</CardBody>}
      </Card>
    </Col>
  );
};

interface RoleRowButtonProps {
  readonly id: number;
  readonly enabled: boolean;
  readonly title: 'convention' | 'global';
  toggle(scope: RoleScope): void;
}

export const RoleRowButton: FC<RoleRowButtonProps> = ({ id, enabled, title, toggle }) => {
  return (
    <Button
      className="float-right"
      color={enabled ? 'primary' : 'secondary'}
      id={`roleRowButton-${id}-${title}`}
      onClick={() => {
        toggle(title);
      }}
      style={{ padding: '0px 5px', marginRight: '5px' }}
    >
      {capitalize(title)}
    </Button>
  );
};
