import React, { useCallback, useContext, useState } from 'react';
import { CustomInput, Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  InputMultiSelect,
  MaterialIcon,
  MultiSelectDirection,
  MultiSelectItem,
} from '../../components';
import { CashierContext, CashierContextProps, PrinterPreferenceMode } from './cashiercontext';

import './settingsModal.scss';

export const CashierSettings: React.FC = () => {
  const cashierSettings = useContext(CashierContext);
  const [modalOpen, setModalOpen] = useState(false);

  const open = useCallback(() => {
    setModalOpen(true);
    cashierSettings.updateCashierSettings!({
      enableESCHotkey: false,
    });
  }, [cashierSettings]);

  const close = useCallback(() => {
    setModalOpen(false);
    cashierSettings.updateCashierSettings!({
      enableESCHotkey: true,
    });
  }, [cashierSettings]);

  return (
    <div className="cashier-settings">
      <MaterialIcon name="settings" onClick={open} type="muted" />
      {modalOpen && <CashierSettingsModal close={close} />}
    </div>
  );
};

interface ModalProps {
  readonly close: () => void;
}

const CashierSettingsModal: React.FC<ModalProps> = ({ close }) => {
  return (
    <Modal className="modal-large cashier-settings-modal hide-print" isOpen toggle={close}>
      <ModalHeader toggle={close}>Configure registration station</ModalHeader>
      <ModalBody>
        <UpdateCashierSettings />
      </ModalBody>
    </Modal>
  );
};

const UpdateCashierSettings: React.FC = () => {
  const cashierSettings = useContext(CashierContext);

  return (
    <div className="cashier-settings-form">
      <div className="cashier-settings-form-item">
        <div className="cashier-form-item-input">
          <CashierSettingsToggle
            defaultChecked={cashierSettings.config.enablePayments}
            id="accept-payments"
            label="Enable payment acceptance"
            name="accept-payments"
            onChange={(checked, cs) => {
              cs.updateCashierSettings!({ enablePayments: checked });
            }}
          />
        </div>
        <div className="cashier-form-item-description">
          <p>
            If enabled, this registration station will be able to accept payments. This will allow
            cashiers to take payments for any balance due on an attendee's account.
          </p>
          <p>
            Otherwise, cashiers will be informed they should direct the attendee to a different
            registration station.
          </p>
        </div>
      </div>
      <div className="cashier-settings-form-item">
        <div className="cashier-form-item-input">
          <CashierSettingsToggle
            defaultChecked={cashierSettings.config.requireIDScan}
            id="require-id-check"
            label="Require ID barcode scan"
            name="require-id-check"
            onChange={(checked, cs) => {
              cs.updateCashierSettings!({ requireIDScan: checked });
            }}
          />
        </div>
        <div className="cashier-form-item-description">
          <p>
            If enabled, this registration station require an ID barcode to be scanned before a badge
            can be printed.
          </p>
          <p>
            Otherwise, cashiers will be informed they should direct the attendee to a different
            registration station.
          </p>
        </div>
      </div>
      <div className="cashier-settings-form-item">
        <div className="cashier-form-item-input">
          <CashierSettingsToggle
            defaultChecked={cashierSettings.config.enablePrintingStaffBadges}
            id="enable-staff-checkin"
            label="Enable staff check-in"
            name="enable-staff-checkin"
            onChange={(checked, cs) => {
              cs.updateCashierSettings!({ enablePrintingStaffBadges: checked });
            }}
          />
        </div>
        <div className="cashier-form-item-description">
          <p>
            If enabled, this registration station will be able to print staff and volunteer badges.
            This affects printing badges for anyone with an approved volunteer application.
          </p>
          <p>
            Otherwise, cashiers will be informed they should direct the attendee to a different
            registration station.
          </p>
        </div>
      </div>
      <div className="cashier-settings-form-item">
        <div className="cashier-form-item-input">
          <CashierSettingsToggle
            defaultChecked={cashierSettings.config.printSeparateStaffBadge}
            id="print-separate-staff-badge"
            label="Print separate staff badge"
            name="print-separate-staff-badge"
            onChange={(checked, cs) => {
              cs.updateCashierSettings!({ printSeparateStaffBadge: checked });
            }}
          />
        </div>
        <div className="cashier-form-item-description">
          <p>
            If enabled, this registration station will print two badges, one with the attendee's
            chosen attendance type, and one with the "STAFF" text.
          </p>
          <p>
            Otherwise, a combination badge will be printed with the attendee's chosen attendance
            type and the "STAFF" text together, separated by a slash.
          </p>
        </div>
      </div>
      <div className="cashier-settings-form-item">
        <div className="cashier-form-item-input">
          <CashierSettingsToggle
            defaultChecked={cashierSettings.config.printSeparateVendorBadge}
            id="print-separate-vendor-badge"
            label="Print separate vendor badge"
            name="print-separate-vendor-badge"
            onChange={(checked, cs) => {
              cs.updateCashierSettings!({ printSeparateVendorBadge: checked });
            }}
          />
        </div>
        <div className="cashier-form-item-description">
          <p>
            If enabled, this registration station will print two badges, one with the attendee's
            chosen attendance type, and one with the "VENDOR" text.
          </p>
          <p>
            Otherwise, a combination badge will be printed with the attendee's chosen attendance
            type and the "VENDOR" text together, separated by a slash.
          </p>
        </div>
      </div>
      <div>
        <CashierBadgeTypePreference />
      </div>
    </div>
  );
};

interface ToggleProps {
  readonly defaultChecked: boolean;
  readonly id: string;
  readonly label: string;
  readonly name: string;
  readonly onChange: (checked: boolean, cs: CashierContextProps) => void;
}

const CashierSettingsToggle: React.FC<ToggleProps> = ({
  defaultChecked,
  id,
  label,
  name,
  onChange,
}) => {
  const cashierSettings = useContext(CashierContext);
  const [showSaved, setShowSaved] = useState(false);

  const internalOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked, cashierSettings);
      setShowSaved(true);

      setTimeout(() => {
        setShowSaved(false);
      }, 2500);
    },
    [cashierSettings, onChange],
  );

  return (
    <>
      <CustomInput
        defaultChecked={defaultChecked}
        id={id}
        label={label}
        name={name}
        onChange={internalOnChange}
        type="switch"
      />
      {showSaved && (
        <div className="cashier-form-item-saved" id="enablePayments-saved">
          Saved!
        </div>
      )}
    </>
  );
};

const CashierBadgeTypePreference: React.FC = () => {
  const [showSaved, setShowSaved] = useState(false);
  const cashierSettings = useContext(CashierContext);

  const primary: MultiSelectItem[] = cashierSettings.config.badgePrintPreferences
    .filter((b) => b.preference === PrinterPreferenceMode.Primary)
    .map((b) => ({ value: b.text, id: b.id }));

  const secondary: MultiSelectItem[] = cashierSettings.config.badgePrintPreferences
    .filter((b) => b.preference === PrinterPreferenceMode.Secondary)
    .map((b) => ({ value: b.text, id: b.id }));

  const onMove = useCallback(
    (items: MultiSelectItem[], type: MultiSelectDirection) => {
      cashierSettings.updateCashierSettings!({
        badgePrintPreferences: cashierSettings.config.badgePrintPreferences.map((b) => {
          if (items.some((i) => i.id === b.id)) {
            return {
              ...b,
              preference:
                type === MultiSelectDirection.Left
                  ? PrinterPreferenceMode.Primary
                  : PrinterPreferenceMode.Secondary,
            };
          }

          return b;
        }),
      });

      setShowSaved(true);

      setTimeout(() => {
        setShowSaved(false);
      }, 2500);
    },
    [cashierSettings],
  );

  return (
    <div className="cashier-badgetype-preferences">
      <div className="cashier-badgetype-header">
        <div>Badge type printer preferences</div>
        {showSaved && (
          <div className="cashier-form-item-saved" id="badgeTypePreferences-saved">
            Saved!
          </div>
        )}
      </div>
      <p className="cashier-explanation-text">
        Select the printer(s) that should be used for each type of badge. If the secondary printer
        is not configured, all badges of that type will be printed on the primary printer.
      </p>
      <InputMultiSelect
        leftItems={primary}
        leftLabel="Primary Printer"
        onMove={onMove}
        rightItems={secondary}
        rightLabel="Secondary Printer"
      />
    </div>
  );
};
