import React, { FC, useCallback } from 'react';
import { FormText, Input, Label } from 'reactstrap';
import { RegistrationFlagModel } from '../../shared/registration/model';
import { toggleElementInArray, useConvention } from '../utils';

interface AttendanceFlagsProps {
  readonly flags: RegistrationFlagModel[];
  readonly enabledFlags: number[];
  readonly readOnly?: boolean;
  readonly askForChildren?: boolean;
  readonly hasChildren?: boolean;
  toggleChildren?(): void;
  onUpdate(flags: number[]): void;
}

export const AttendanceFlags: FC<AttendanceFlagsProps> = ({
  enabledFlags,
  flags,
  readOnly,
  askForChildren,
  hasChildren,
  onUpdate,
  toggleChildren,
}) => {
  const { childAgeThreshold } = useConvention();
  const onFlagChange = useCallback(
    ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
      if (!readOnly) {
        const flagId = Number.parseInt(currentTarget.value, 10);
        onUpdate(toggleElementInArray(enabledFlags, flagId));
      }
    },
    [readOnly, enabledFlags, onUpdate],
  );

  return (
    <>
      {flags.map((flag) => {
        const isEnabled = enabledFlags.includes(flag.id);
        const key = `flag${flag.id}`;
        return (
          <div className="custom-control custom-checkbox margin-top-10" key={key}>
            <Input
              className="custom-control-input"
              defaultChecked={isEnabled}
              disabled={readOnly}
              id={key}
              name="flags[]"
              onChange={onFlagChange}
              type="checkbox"
              value={flag.id}
            />
            <Label className="custom-control-label" for={`flag${flag.id}`}>
              {flag.description}
              {isEnabled ? <FormText color="muted">{flag.enabledDescription}</FormText> : null}
            </Label>
          </div>
        );
      })}
      {askForChildren && (
        <div className="custom-control custom-checkbox margin-top-10">
          <Input
            checked={hasChildren}
            className="custom-control-input"
            id="registerChildren"
            onChange={toggleChildren}
            type="checkbox"
          />
          <Label className="custom-control-label" for="registerChildren">
            I would like to register children under {childAgeThreshold}
          </Label>
        </div>
      )}
    </>
  );
};
