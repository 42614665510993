import React, { FC } from 'react';
import * as OrderSummary from '../../../../../../shared/orders/model';
import { IconColor } from '../../../../../components';
import { capitalize, checkPermission, useUser } from '../../../../../utils';
import { isOrderItemFulfillable, OrderStatusInfo } from '../utils';
import { OrderInfoItemGroup } from './OrderInfoItemGroup';

interface OrderListItemsProps {
  readonly order: OrderSummary.Order;
  readonly status: OrderStatusInfo[];
  readonly openAddProductModal: () => void;
  readonly onUpdate: () => void;
}

interface OrderItemInfoGroup {
  orderItems: OrderSummary.OrderItem[];
  icon: string;
  color?: IconColor;
}

interface OrderItemGrouping {
  [key: string]: OrderItemInfoGroup;
  cancelled: OrderItemInfoGroup;
  fulfilled: OrderItemInfoGroup;
  unfulfilled: OrderItemInfoGroup;
}

export const OrderListItems: FC<OrderListItemsProps> = (props) => {
  const { status, order, openAddProductModal, onUpdate } = props;
  const user = useUser();

  if (status.includes(OrderStatusInfo.Cancelled)) {
    return (
      <OrderInfoItemGroup
        icon="cancel_schedule_send"
        order={order}
        orderItems={order.orderItems}
        title="Cancelled"
      />
    );
  }

  if (status.includes(OrderStatusInfo.Unpaid)) {
    return (
      <OrderInfoItemGroup
        addButton={checkPermission(user!, 'registration:cashier') ? openAddProductModal : undefined}
        icon="hourglass_empty"
        onUpdate={onUpdate}
        order={order}
        orderItems={order.orderItems}
        title="Pending Payment"
      />
    );
  }

  const out: JSX.Element[] = [];
  const sortedOrderItems = sortOrderItems(order.orderItems);

  for (const key of Object.keys(sortedOrderItems)) {
    const { orderItems, icon, color } = sortedOrderItems[key];
    if (orderItems.length === 0) {
      continue;
    }

    out.push(
      <OrderInfoItemGroup
        icon={icon}
        iconColor={color}
        key={key}
        onUpdate={onUpdate}
        order={order}
        orderItems={orderItems}
        title={capitalize(key)}
      />,
    );
  }

  return <>{out}</>;
};

function sortOrderItems(orderItems: OrderSummary.OrderItem[]): OrderItemGrouping {
  return {
    cancelled: {
      icon: 'cancel_schedule_send',
      orderItems: orderItems.filter(({ cancelledOn }) => cancelledOn),
    },
    fulfilled: {
      color: 'primary',
      icon: 'send',
      orderItems: orderItems.filter((oi) => oi.dispatchedOn ?? !isOrderItemFulfillable(oi)),
    },
    unfulfilled: {
      color: 'warning',
      icon: 'schedule',
      orderItems: orderItems.filter((oi) => isOrderItemFulfillable(oi)),
    },
  };
}
