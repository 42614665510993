import React, { FC } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { ProductCategoryModel } from '../../../../shared/orders/product';
import { ProductForm } from './productForm';

interface CreateProductProps {
  readonly category: ProductCategoryModel;
  onSuccess(props: { id?: number }): void;
}

export const CreateProduct: FC<CreateProductProps> = ({ category, onSuccess }) => {
  return (
    <Col lg={8} xs={12}>
      <Card>
        <CardBody>
          <h4>New Product</h4>
          <hr />
          <ProductForm
            action={`/api/products/categories/${category.id}`}
            category={category}
            method="post"
            onSuccess={onSuccess}
          />
        </CardBody>
      </Card>
    </Col>
  );
};
