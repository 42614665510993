import { classNames } from '@conventioncatcorp/common-fe';
import React, { FC } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { UserAvatar } from '../utils/UserAvatar';
import { MaterialIcon } from './MaterialIcon';

import './ElementHeader.scss';

interface AvatarProps {
  url?: string;
  iconName?: string;
}

interface HeaderAction {
  id: string;
  danger?: boolean;
  header?: boolean;
  disabled?: boolean;
  divider?: boolean;
  onClick?: () => void;
  href?: string;
  text?: string;
}

interface ElementHeaderProps {
  readonly icon?: AvatarProps;
  readonly title: JSX.Element | string;
  readonly rightContent?: JSX.Element;
  readonly actions?: HeaderAction[];
}

export const ElementHeader: FC<ElementHeaderProps> = ({
  actions,
  children,
  icon,
  title,
  rightContent,
}) => (
  <>
    <div className="element-header clearfix">
      {rightContent && <div className="float-right">{rightContent}</div>}
      <div>
        {icon?.url && (
          <div className="float-left" style={{ marginRight: '15px' }}>
            <UserAvatar size="medium" url={icon.url} />
          </div>
        )}
        {icon?.iconName && <MaterialIcon className="material-avatar" medium name={icon.iconName} />}
      </div>
      <div className="element-header-target">
        <div className="element-header-title">
          <h4>{title}</h4>
          {children && <div className="element-header-child">{children}</div>}
        </div>
        {actions && actions.length > 0 && (
          <div className="element-header-actions">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="secondary" outline>
                Actions
              </DropdownToggle>
              <DropdownMenu>
                {actions?.map((action) => (
                  <DropdownItem
                    className={classNames({ 'text-danger': action.danger })}
                    disabled={action.disabled}
                    divider={action.divider}
                    header={action.header}
                    href={action.href}
                    key={action.id}
                    onClick={action.onClick}
                  >
                    {action.text}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        )}
      </div>
      <div className="element-header-clear" />
    </div>
    <hr style={{ marginTop: '5px' }} />
  </>
);
