import React, { FC, Fragment } from 'react';
import { Badge } from 'reactstrap';
import { Option, OptionDataValue, OptionFile } from '../../../shared/options';

interface OptionSimpleDisplayProps {
  readonly value?: OptionDataValue;
  readonly option: Option;
}

export const OptionSimpleDisplay: FC<OptionSimpleDisplayProps> = ({ option, value }) => {
  if (option?.type === 'multi') {
    return <MultiOption option={option} values={value as string[]} />;
  }

  if (option?.type === 'select') {
    return <SelectOption option={option} value={value as string} />;
  }

  if (option?.type === 'text' || option?.type === 'phone') {
    return <TextOption value={value as string} />;
  }

  if (option?.type === 'file') {
    return <FileOptionDisplay value={value as OptionFile[]} />;
  }

  return <div>Option '{option.type}' not supported</div>;
};

interface FileOptionDisplayProps {
  readonly value: OptionFile[];
}

const FileOptionDisplay: FC<FileOptionDisplayProps> = ({ value }) => {
  return (
    <ul>
      {value.map((file) => {
        return (
          <li key={file.id}>
            <a href={file.url} rel="noreferrer" target="_blank">
              {file.fileName}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

interface TextOptionProps {
  readonly value: string;
}

const TextOption: FC<TextOptionProps> = ({ value }) => {
  return <p>{value}</p>;
};

interface SelectOptionProps {
  readonly option: Option;
  readonly value: string;
}

const SelectOption: FC<SelectOptionProps> = ({ option, value }) => {
  const selectValues = option.selectValues ?? [];
  const selectedValue = selectValues.find((t) => t.value === value);

  return <p>{selectedValue?.displayName ?? selectedValue?.value}</p>;
};

interface MultiOptionProps {
  readonly option: Option;
  readonly values?: string[];
}

const MultiOption: FC<MultiOptionProps> = ({ option, values }) => {
  const selectValues = option.selectValues ?? [];

  return (
    <span className="badge-list compact">
      {selectValues.map((value) => {
        const id = value.value;
        const enabled = values?.includes(id);
        return (
          <Fragment key={id}>
            <Badge color={enabled ? 'success' : 'secondary'} id={`optionValue${id}`}>
              {value.displayName ?? id}
            </Badge>
          </Fragment>
        );
      })}
    </span>
  );
};
