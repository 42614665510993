import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Badge, Button, Card, Col, Row } from 'reactstrap';
import { DealerForm, DealerFullModel, DealerStatusTypes } from '../../../../shared/dealer';
import { LazyMarkdown } from '../../../components';
import { useConfig } from '../../../utils';
import { DealerButtons } from './DealerButtons';

interface AreaSelectionProps {
  readonly dealer: DealerFullModel;
  readonly form: DealerForm;
}

export const AreaSelection: FC<AreaSelectionProps> = ({ dealer, form }) => {
  const config = useConfig();

  return (
    <>
      <DealerButtons dealer={dealer} dealerAssistantLimit={form.dealerAssistantLimit} />
      <hr />
      {dealer.applications.length === 0 && (
        <Alert color="info">
          You haven't yet submitted an application this year. Apply for one of the areas below.
        </Alert>
      )}
      <Row>
        {config.dealersAreas.map((dealerArea) => {
          const application = dealer.applications.find((t) => t.areaId === dealerArea.id);
          let title = application ? 'Select' : 'Create';

          if (dealerArea.full) {
            title = 'FULL';
          }

          if (!dealerArea.open) {
            title = 'CLOSED';
          }

          return (
            <Col className="margin-bottom-10" key={dealerArea.id} lg={6} xs={12}>
              <Card className="productItem" id={`dealerArea${dealerArea.id}`}>
                <div className="productInfo">
                  <h5>{dealerArea.name}</h5>
                  {application && (
                    <StatusBadge
                      grant={!!application.paidOrderItemId}
                      status={application.status}
                    />
                  )}
                  <p>
                    {dealerArea.description ? (
                      <LazyMarkdown source={dealerArea.description} />
                    ) : (
                      <i>No Description Provided</i>
                    )}
                  </p>
                </div>
                <div className="productPurchaseBar">
                  <Button
                    color="success"
                    disabled={!dealerArea.open}
                    id={`selectdealerArea${dealerArea.id}`}
                    tag={Link}
                    to={`/vendor/space/${dealerArea.id}`}
                  >
                    {title}
                  </Button>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

interface StatusBadgeProps {
  readonly status: DealerStatusTypes;
  readonly grant: boolean;
}

const StatusBadge: FC<StatusBadgeProps> = ({ status, grant }) => {
  const badges: JSX.Element[] = [];

  switch (status) {
    case 'approved': {
      badges.push(
        <Badge color="success" key="approved" style={{ marginRight: '5px' }}>
          Approved
        </Badge>,
      );

      if (!grant) {
        badges.push(
          <Badge color="warning" key="payment">
            Pending Payment
          </Badge>,
        );
      }

      break;
    }

    case 'rejected': {
      badges.push(
        <Badge color="warning" key="rejected">
          Rejected
        </Badge>,
      );

      break;
    }

    case 'waitlisted': {
      badges.push(
        <Badge color="warning" key="waitlisted">
          Waitlisted
        </Badge>,
      );

      break;
    }

    case 'pending': {
      badges.push(
        <Badge color="warning" key="pending">
          Pending Approval
        </Badge>,
      );

      break;
    }
  }

  return <>{badges}</>;
};
