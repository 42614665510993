import React, { FC, useMemo } from 'react';
import { FormFeedback } from 'reactstrap';
import { ProductModel } from '../../shared/orders';
import { LocalSearchProvider } from '../containers/housekeeping/products/utils';
import { useFetcher, useUser } from '../utils';
import { ElementSelector } from './ElementSelector';

interface ProductSelectionProps {
  readonly permissionError: string;
  readonly selectedIds: number[];
  readonly excludedIds: number[];
  setSelectedIds(vals: number[]): void;
}

export const ProductSelection: FC<ProductSelectionProps> = ({
  permissionError,
  selectedIds,
  excludedIds,
  setSelectedIds,
}) => {
  const user = useUser()!;
  const canLimitByGrant = user.permissions.includes('product:read');
  const productFetcher = useFetcher(async () => {
    if (!canLimitByGrant) {
      return [];
    }

    return (await api.getProducts()).filter((product) => !excludedIds.includes(product.id));
  }, []);

  const searchProvider = useMemo(
    () => new LocalSearchProvider(() => productFetcher.data ?? []),
    [productFetcher.data],
  );

  if (!productFetcher.complete) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ElementSelector<ProductModel>
        defaultSelected={selectedIds.map((id) => {
          const entry = productFetcher.data!.find((t) => t.id === id);
          if (entry) {
            return entry;
          }

          return { id } as ProductModel;
        })}
        disabled={!canLimitByGrant}
        id="productSelector"
        searchProvider={searchProvider}
        selectionIdsChanged={setSelectedIds}
      />
      {!canLimitByGrant && (
        <div>
          <FormFeedback invalid>{permissionError}</FormFeedback>
        </div>
      )}
    </>
  );
};
