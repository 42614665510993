import React from 'react';
import { OSPrinterManager } from './printers/OSPrinter';
import {
  BadgePrinterBase,
  parsePrinterUid,
  PrinterConfig,
  PrinterManagerBase,
  ReceiptPrinterBase,
} from './printers/PrinterBase';
import { ReceiptPrinterManager } from './printers/ReceiptPrinter';
import { LabelPrinterManager } from './printers/ZebraPrinter';

export interface CashierSettingsModel extends CashierPrinterSettings {
  enableESCHotkey: boolean;
  enablePayments: boolean;
  enablePrintingStaffBadges: boolean;
  printSeparateStaffBadge: boolean;
  printSeparateVendorBadge: boolean;
  requireIDScan: boolean;
  badgePrintPreferences: BadgeTypePreference[];
}

interface CashierPrinterSettings {
  /** Primary badge printer. */
  primaryPrinter: string;
  primaryPrinterConfig: PrinterConfig;
  /** Secondary badge printer, for printing minor badges or other alternate badges. */
  secondaryPrinter?: string;
  secondaryPrinterConfig?: PrinterConfig;
}

export interface CashierContextProps {
  printerManagers: PrinterManagerBase[];
  receiptPrinterManagers: ReceiptPrinterManager[];
  config: CashierSettingsModel;

  updateCashierSettings?(settings: Partial<CashierSettingsModel>): void;
}

export function getBadgePrinterManagers(): PrinterManagerBase[] {
  return [new OSPrinterManager(), new LabelPrinterManager()];
}

export function getReceiptPrinterManagers(): ReceiptPrinterManager[] {
  return [new ReceiptPrinterManager()];
}

export enum PrinterPreferenceMode {
  Primary = 'primary',
  Secondary = 'secondary',
}

interface BadgeTypePreference {
  id: string;
  text: string;
  preference: PrinterPreferenceMode;
}

const validBadgeTypes: BadgeTypePreference[] = [
  { id: 'staff', text: 'Staff badges', preference: PrinterPreferenceMode.Primary },
  { id: 'attendee', text: 'Attendee badges', preference: PrinterPreferenceMode.Primary },
  { id: 'vendor', text: 'Vendor badges', preference: PrinterPreferenceMode.Primary },
  { id: 'minor', text: 'Minor badges', preference: PrinterPreferenceMode.Secondary },
];

export const defaultContext: CashierSettingsModel = {
  badgePrintPreferences: validBadgeTypes,
  enableESCHotkey: true,
  enablePayments: false,
  enablePrintingStaffBadges: true,
  requireIDScan: false,
  primaryPrinter: 'os:os-print',
  primaryPrinterConfig: {},
  printSeparateStaffBadge: false,
  printSeparateVendorBadge: false,
};

export const saveCashierSettings = (settings: CashierSettingsModel): void => {
  localStorage.setItem('cashierSettings', JSON.stringify(settings));
};

export function getBadgePrinter(
  settings: CashierContextProps,
  printerString: string,
  printerConfig?: PrinterConfig,
): BadgePrinterBase | undefined {
  const printerId = parsePrinterUid(printerString);
  if (printerId === undefined) {
    return;
  }

  const manager = settings.printerManagers.find((m) => m.type === printerId.type);
  const printer = manager?.usePrinter(printerId.serial, printerConfig ?? {});

  return printer as BadgePrinterBase;
}

// eslint-disable-next-line import/no-unused-modules
export function getReceiptPrinter(
  settings: CashierContextProps,
  printerString?: string,
  printerConfig?: PrinterConfig,
): ReceiptPrinterBase | undefined {
  const printerId = parsePrinterUid(printerString);
  if (printerId === undefined) {
    // Can also be interpreted as not caring which printer we use. Find the first one.
    return settings.receiptPrinterManagers.flatMap((m) => m.getPrinters()).at(0);
  }

  const manager = settings.receiptPrinterManagers.find((m) => m.type === printerId.type);
  return manager?.usePrinter(printerId.serial, printerConfig ?? {});
}

export const loadCashierSettings = (settings: CashierSettingsModel): CashierSettingsModel => {
  const settingsString = localStorage.getItem('cashierSettings');

  if (!settingsString) {
    return settings;
  }

  const loadedSettings = JSON.parse(settingsString) as CashierSettingsModel;

  return {
    ...settings,
    badgePrintPreferences: loadedSettings.badgePrintPreferences,
    enableESCHotkey: loadedSettings.enableESCHotkey,
    enablePayments: loadedSettings.enablePayments,
    enablePrintingStaffBadges: loadedSettings.enablePrintingStaffBadges,
    printSeparateStaffBadge: loadedSettings.printSeparateStaffBadge,
    printSeparateVendorBadge: loadedSettings.printSeparateVendorBadge,
    requireIDScan: loadedSettings.requireIDScan,
    primaryPrinter: loadedSettings.primaryPrinter,
    secondaryPrinter: loadedSettings.secondaryPrinter,
  };
};

export const CashierContext = React.createContext<CashierContextProps>({
  printerManagers: [],
  receiptPrinterManagers: [],
  config: defaultContext,
});
