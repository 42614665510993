import { PermissionName } from '../permissions';

export interface OptionFile {
  contentType: string;
  fileName: string;
  size: number;
  id: string;
  url: string;
}

export interface OptionFileInput {
  contentType: string;
  fileName: string;
  size: number;
  data: string;
  id?: undefined;
}

export type OptionFileUnion = OptionFile | OptionFileInput;

// Value that can be printed / logged
export type OptionDataValuePrint = string[] | number | string | null;

export type OptionDataPayloadPrint = Record<string, OptionDataValuePrint>;

// Value loaded from the database
export type OptionDataValue = OptionDataValuePrint | OptionFile[];

export type OptionDataPayload = Record<number, OptionDataValue>;

// Value that is coming from the browser / includes large field data
export type OptionDataValueInput = OptionDataValue | OptionFileUnion[];

export type OptionDataPayloadInput = Record<number, OptionDataValueInput>;

export type OptionType = 'currency' | 'file' | 'multi' | 'number' | 'phone' | 'select' | 'text';

export type OptionSource = 'dealer' | 'product' | 'volunteer';

interface SourceConfigProps {
  allowCreate: boolean;
  allowPrice: boolean;
  permission: PermissionName;
}

export const SourceConfig: Record<OptionSource, SourceConfigProps> = {
  product: {
    allowCreate: true,
    allowPrice: true,
    permission: 'product:manage',
  },
  volunteer: {
    allowCreate: false,
    allowPrice: false,
    permission: 'volunteer:update',
  },
  dealer: {
    allowCreate: false,
    allowPrice: false,
    permission: 'vendor:update',
  },
};

export interface Option {
  id: number;
  max: number | null;
  min: number;
  name: string;
  description: string;
  type: OptionType;
  source: OptionSource;
  style?: string;
  selectValues?: OptionSelectValue[];
}

export interface OptionInternal extends Option {
  displayOnCashier: boolean;
}

export interface OptionFormData {
  max?: number;
  min?: number;
  name: string;
  type: OptionType;
  values?: OptionSelectValue[];
}

export interface OptionSelectValue {
  displayName: string | null;
  value: string;
  priceModifier: number;
}

export interface ProductOption {
  optionId: number;
  productId: number;
  required: boolean;
  option: Option;
}

export type OptionSavePayload = Pick<
  OptionInternal,
  'displayOnCashier' | 'max' | 'min' | 'name' | 'selectValues' | 'source' | 'type'
>;
