import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

interface DatePickerProps {
  readonly defaultDate: string | null;
  readonly disabled?: boolean;
  readonly onChange?: (date: string) => void;
}

const monthLabels = [
  'January (1)',
  'February (2)',
  'March (3)',
  'April (4)',
  'May (5)',
  'June (6)',
  'July (7)',
  'August (8)',
  'September (9)',
  'October (10)',
  'November (11)',
  'December (12)',
];

function getDaysInMonth(month: number, year: number): number {
  return new Date(year, month, 0).getDate();
}

function getFieldFromDate(field: 'day' | 'month' | 'year', date?: string): number | null {
  if (!date || !date.includes('-')) {
    return null;
  }

  const splitDate = date.split('-');
  switch (field) {
    case 'day': {
      return Number.parseInt(splitDate[2], 10);
    }

    case 'month': {
      return Number.parseInt(splitDate[1], 10);
    }

    case 'year': {
      return Number.parseInt(splitDate[0], 10);
    }
  }
}

export const DatePicker: FC<DatePickerProps> = ({ defaultDate, disabled, onChange }) => {
  const [selectedMonth, setSelectedMonth] = useState<number>(
    (defaultDate && getFieldFromDate('month', defaultDate)) || 1,
  );

  const [selectedDay, setSelectedDay] = useState<number>(
    (defaultDate && getFieldFromDate('day', defaultDate)) || 1,
  );

  const [selectedYear, setSelectedYear] = useState<number>(
    // Not sure if this is even necessary, but setting it to current year is stupid 🤷
    (defaultDate && getFieldFromDate('year', defaultDate)) || new Date().getFullYear() - 21,
  );

  const updateMonth = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMonth(Number.parseInt(event.target.value, 10));
  }, []);

  const updateDay = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDay(Number.parseInt(event.target.value, 10));
  }, []);

  const updateYear = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedYear(Number.parseInt(event.target.value, 10));
  }, []);

  useEffect(() => {
    const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
    if (selectedDay > daysInMonth) {
      setSelectedDay(1);
    } else {
      const month = selectedMonth.toString().padStart(2, '0');
      const day = selectedDay.toString().padStart(2, '0');
      onChange?.(`${selectedYear}-${month}-${day}`);
    }
  }, [selectedMonth, selectedYear, selectedDay]);

  const generateYearArray = useCallback(() => {
    return Array.from({ length: 120 }, (_, index) => new Date().getFullYear() - index).filter(
      (year) => year >= 1900,
    );
  }, []);

  return (
    <div>
      <Row>
        <Col lg={6}>
          <FormGroup>
            <Label for="date-month">Month</Label>
            <Input disabled={disabled} id="date-month" onChange={updateMonth} type="select">
              {monthLabels.map((month, index) => (
                <option
                  key={month.replace(' ', '_')}
                  selected={index + 1 === selectedMonth}
                  value={index + 1}
                >
                  {month}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col lg={3}>
          <FormGroup>
            <Label for="date-day">Day</Label>
            <Input disabled={disabled} id="date-day" onChange={updateDay} type="select">
              {Array.from({ length: getDaysInMonth(selectedMonth, selectedYear) }).map(
                (_, index) => (
                  <option
                    // eslint-disable-next-line react/no-array-index-key -- what the fuck else would I use?!
                    key={`day${index}`}
                    selected={index + 1 === selectedDay}
                    value={index + 1}
                  >
                    {index + 1}
                  </option>
                ),
              )}
            </Input>
          </FormGroup>
        </Col>
        <Col lg={3}>
          <FormGroup>
            <Label for="date-year">Year</Label>
            <Input disabled={disabled} id="date-year" onChange={updateYear} type="select">
              {generateYearArray().map((year) => (
                <option key={`year${year}`} selected={year === selectedYear} value={year}>
                  {year}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
