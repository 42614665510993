import React, { CSSProperties, FC, Fragment, useCallback } from 'react';
import { toast } from 'react-toastify';
import { OptionDataPayload } from '../../../shared/options';
import { noImage, ProductModel } from '../../../shared/orders';
import {
  BadgeData,
  badgeDefaultSpecial,
  BadgeDesign,
  DesignerNode,
  DesignerNodeImage,
  DesignerNodeNode,
  DesignerNodeRoot,
  DesignerNodeText,
  NodeConditional,
} from '../../../shared/user/badgeDesign';
import { CurrentUser } from '../../../shared/user/base';
import { OccamyText } from '../../components/OccamyText';
import { loadFonts } from '../../containers/housekeeping/settings/badgedesigner/font';
import { displayName } from '../../utils';
import { LoadingWrapper } from '../../utils/LoadingWrapper';
import { makeLazyComponent } from '../../utils/lazy';

const RenderBarcode = makeLazyComponent(
  async () =>
    (await import(/* webpackChunkName: "barcodeRender" */ './BarcodeRenderer')).RenderBarcode,
);

export type NodeMap = Record<string, DesignerNode>;

export function isRoot(node: DesignerNode): node is DesignerNodeRoot {
  return node.type === 'node' && 'xstart' in node;
}

interface BadgePreviewRenderedProps {
  readonly label: BadgeData;
  readonly nodes: NodeMap;
  readonly image?: string;
  readonly debug?: boolean;
}

export const BadgePreviewRenderer: FC<BadgePreviewRenderedProps> = ({
  image,
  label,
  nodes,
  debug,
}) => {
  const root = nodes.root as DesignerNodeRoot;

  if (root.preprintedCanvas) {
    const canvas = root.preprintedCanvas;
    return (
      <div
        style={{
          backgroundColor: '#585858',
          backgroundImage: image ? `url('${image}')` : '',
          backgroundPosition: 'center bottom',
          backgroundSize: 'cover',
          borderRadius: '0 0 5px 5px',
          color: '#000',
          height: `${canvas.height}in`,
          margin: '0 auto',
          position: 'relative',
          width: `${canvas.width}in`,
        }}
      >
        <div
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            height: `${root.height}in`,

            position: 'absolute',
            top: `${canvas.height - root.height - canvas.fromBottom}in`,
            width: `${canvas.width}in`,
          }}
        >
          <div
            style={{
              left: `${canvas.width / 2 - root.width / 2}in`,
              position: 'absolute',
            }}
          >
            <BadgePageRender debug={debug} labels={[label]} nodes={nodes} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: '#aaa',
        color: '#000',
        height: `${root.height}in`,
        margin: '0 auto',
        width: `${root.width}in`,
      }}
    >
      <BadgePageRender debug={debug} labels={[label]} nodes={nodes} />
    </div>
  );
};

interface BadgeBaseProps {
  readonly nodes: NodeMap;
  readonly selectedNode?: string;
  readonly debug?: boolean;
}

interface BadgeContainerProps extends BadgeBaseProps {
  readonly pos: number;
  readonly badge: BadgeData;
}

const BadgeContainer: FC<BadgeContainerProps> = ({ pos, badge, debug, nodes, selectedNode }) => {
  const { root } = nodes;

  if (!root || !isRoot(root)) {
    return null;
  }

  const measurements = {
    height: root.height,
    pagepitch: 11,
    width: root.width,
    xpitch: root.width + root.xspacing,
    ypitch: root.height + root.yspacing,
  };

  // TODO: Make the number of items per page to be configurable.
  const pageNumber = Math.floor(pos / 300);
  const itemsPerColumn = 300;
  const numberOfColumns = 1;
  const toppos =
    root.ystart +
    (measurements.ypitch * (pos % itemsPerColumn) + measurements.pagepitch * pageNumber);

  const leftpos =
    root.xstart + measurements.xpitch * (Math.floor(pos / itemsPerColumn) % numberOfColumns);

  return (
    <div
      className="badgeLabel d-block"
      id={`badge${pos}`}
      style={{
        border: debug ? '1px solid black' : undefined,
        height: `${measurements.height}in`,
        left: `${leftpos}in`,
        overflow: 'hidden',
        position: 'absolute',
        top: `${toppos}in`,
        width: `${measurements.width}in`,
      }}
    >
      <BadgeRender
        data={badge}
        debug={debug}
        nodes={nodes}
        root="root"
        selectedNode={selectedNode}
      />
    </div>
  );
};

interface BadgePageRenderProps {
  readonly labels: BadgeData[];
  readonly nodes: NodeMap;
  readonly selectedNode?: string;
  readonly debug?: boolean;
}

export const BadgePageRender: FC<BadgePageRenderProps> = (props) => {
  const { nodes, labels, debug, selectedNode } = props;

  const fontFetcher = useCallback(async () => {
    try {
      await loadFonts(nodes);
    } catch (error) {
      toast.error((error as Error).message);
    }
  }, [nodes]);

  return (
    <LoadingWrapper dataFetcher={fontFetcher} passback={nodes}>
      {() => (
        <div style={{ position: 'absolute' }}>
          {labels.map((badge, idx) => (
            <BadgeContainer
              badge={badge}
              debug={debug}
              key={badge.registrationId}
              nodes={nodes}
              pos={idx}
              selectedNode={selectedNode}
            />
          ))}
        </div>
      )}
    </LoadingWrapper>
  );
};

interface BadgeRenderProps {
  readonly nodes: Record<string, DesignerNode>;
  readonly root: string;
  readonly selectedNode?: string;
  readonly data: BadgeData;
  readonly debug?: boolean;
}

const BadgeRender: FC<BadgeRenderProps> = ({ nodes, root, selectedNode, data, debug }) => {
  const node = nodes[root];

  if (node.type === 'node') {
    return (
      <BadgeRenderNode
        data={data}
        debug={debug}
        node={node}
        nodes={nodes}
        selectedNode={selectedNode}
      />
    );
  }

  if (node.type === 'text') {
    return <BadgeRenderText data={data} debug={debug} node={node} />;
  }

  if (node.type === 'image') {
    return <BadgeRenderImage data={data} node={node} />;
  }

  return null;
};

const BadgeRenderText: FC<{
  readonly data: BadgeData;
  readonly node: DesignerNodeText;
  readonly debug?: boolean;
}> = ({ node, data, debug }) => {
  const font = node.fontFamily === 'custom' ? node.customFontFamily : node.fontFamily;
  const style = applyPosition(node, {
    fontFamily: `"${font!}", sans-serif`,
    fontSize: `${node.fontSize}in`,
    lineHeight: 1,
    textAlign: node.textAlign || 'left',
    textTransform: node.textTransform || 'none',
    color: node.color || 'rgb(0,0,0)',
    fontWeight: node.fontWeight || 'normal',
  });

  const getTextValue = useCallback(
    (newNode: DesignerNodeText) => {
      if (newNode.variableType && newNode.variableValue) {
        const {
          options,
          isVendor,
          isVendorAssistant,
          isStaff,
          isVounteer,
          isMinor,
          isGuardian,
          isUnderage,
        } = data;

        if (node.variableType === 'option') {
          if (!options) {
            return '';
          }

          return options[Number.parseInt(node.variableValue, 10)]?.toString() ?? '';
        }

        if (node.variableType === 'special') {
          const specials = node.specialDisplayValues ?? badgeDefaultSpecial;

          const values: string[] = [];

          if (specials.includes('registration')) {
            const productName = data.data.registration?.attendanceType?.toString() ?? '';
            if (productName) {
              values.push(productName);
            }
          }

          if (specials.includes('dealer') && (isVendor || isVendorAssistant)) {
            values.push('VENDOR');
          }

          if (specials.includes('staff') && isStaff) {
            values.push('STAFF');
          }

          // Do not put staff and recruit on the same badge
          if (specials.includes('recruit') && isVounteer && !values.includes('STAFF')) {
            values.push('RECRUIT');
          }

          if (specials.includes('volunteer') && isVounteer && !values.includes('STAFF')) {
            values.push('VOLUNTEER');
          }

          if (specials.includes('minor') && isMinor) {
            values.push('MINOR');
          }

          if (specials.includes('guardian') && isGuardian && !isUnderage) {
            values.push('GUARDIAN');
          }

          if (specials.includes('underage') && isUnderage) {
            values.push('CHILD');
          }

          return values.join(' / ');
        }

        const type = data.data[node.variableType];
        if (type) {
          const value = type[node.variableValue];

          if (Array.isArray(value)) {
            return value.join(' / ');
          }

          if (value) {
            return value.toString();
          }
        }
      }

      if (debug) {
        return node.placeholderText ?? '';
      }

      return '';
    },
    [node, data, debug],
  );

  let text = getTextValue(node);

  if (node.prefixPadding) {
    text = text.padStart(node.prefixPadding.length, node.prefixPadding.character);
  }

  if (font === 'datamatrix') {
    return (
      <div id={node.id} key={node.id} style={style}>
        <RenderBarcode text={text} />
      </div>
    );
  }

  if (node.autoResize) {
    const innerStyle: CSSProperties = {
      overflowWrap: 'break-word',
      justifyContent: node.textAlign || 'left',
      display: 'flex',
      alignItems: 'center',
    };

    return (
      <div id={node.id} key={node.id} style={style}>
        <OccamyText
          grow={node.growText}
          key={text}
          maxFontSizeVariation={node.growTextMax}
          minFontSizeVariation={node.growTextMin}
          shrink={node.shrinkText}
          style={innerStyle}
        >
          {text}
        </OccamyText>
      </div>
    );
  }

  return (
    <div id={node.id} key={node.id} style={style}>
      {text}
    </div>
  );
};

interface BadgeRenderNodeProps {
  readonly node: DesignerNodeNode;
  readonly nodes: Record<string, DesignerNode>;
  readonly selectedNode?: string;
  readonly data: BadgeData;
  readonly debug?: boolean;
}

function shouldRenderNode(data: BadgeData, conditional: NodeConditional, debug?: boolean) {
  if (conditional.debug && debug) {
    return true;
  }

  const invert = !!conditional.invert;
  let result = invert;

  if (conditional.minor && data.isMinor) {
    result = !invert;
  }

  if (conditional.underage && data.isUnderage) {
    result = !invert;
  }

  if (conditional.dealer && data.isVendor) {
    result = !invert;
  }

  if (conditional.volunteer && data.isVounteer) {
    result = !invert;
  }

  if (conditional.vendorAssistant && data.isVendorAssistant) {
    result = !invert;
  }

  if (conditional.productId && data.data.registration?.attendanceTypeId === conditional.productId) {
    result = !invert;
  }

  if (conditional.roleId && data.roleIds.includes(conditional.roleId)) {
    result = !invert;
  }

  return result;
}

const BadgeRenderNode: FC<BadgeRenderNodeProps> = ({ node, nodes, selectedNode, data, debug }) => {
  if (node.conditional && !shouldRenderNode(data, node.conditional, debug)) {
    return null;
  }

  const styles: CSSProperties = {};

  if (node.rotate) {
    styles.transform = `rotate(-90deg) translateX(-${node.height}in)`;
    styles.transformOrigin = 'top left';

    node = {
      ...node,
      width: node.height,
      height: node.width,
    };
  }

  return (
    <div key={node.id} style={applyPosition(node, styles)}>
      {node.children.map((t) => {
        const component = (
          <BadgeRender
            data={data}
            debug={debug}
            key={t}
            nodes={nodes}
            root={t}
            selectedNode={selectedNode}
          />
        );

        if (t === selectedNode) {
          const child = nodes[t];
          const style = applyPosition(child, {
            border: '1px solid rgba(0,0,0,0.1)',
          });

          return (
            <Fragment key={node.id}>
              {component}
              <div style={style} />
            </Fragment>
          );
        }

        return component;
      })}
    </div>
  );
};

const BadgeRenderImage: FC<{ readonly node: DesignerNodeImage; readonly data: BadgeData }> = ({
  node,
  data,
}) => {
  let url = node.url ?? noImage;

  if (node.source === 'badgeDesign' && data.badgeArt) {
    url = data.badgeArt;
  } else if (node.source === 'product' && data.productArt) {
    url = data.productArt;
  } else if (node.source === 'profilePic' && data.profilePictureUrl) {
    url = data.profilePictureUrl;

    if (node.ignoreEmpty && url.includes('gravatar')) {
      if (node.url) {
        url = node.url;
      } else {
        return null;
      }
    }
  }

  return (
    <div
      key={node.id}
      style={applyPosition(node, {
        overflow: 'hidden',
        background: `url('${url}')`,
        backgroundSize: node.size,
        backgroundRepeat: node.repeat,
        backgroundPosition: node.position,
        borderRadius: node.circle ? '50%' : undefined,
      })}
    />
  );
};

function applyPosition(node: DesignerNode, style: CSSProperties): CSSProperties {
  style.height = `${node.height}in`;
  style.position = 'absolute';
  style.width = `${node.width}in`;

  if (node.type === 'text') {
    if (node.horizontalBinding === 'left') {
      style.left = `${node.x}in`;
    } else {
      style.right = `${node.x}in`;
    }

    if (node.verticalBinding === 'top') {
      style.top = `${node.y}in`;
    } else {
      style.bottom = `${node.y}in`;
    }

    return style;
  }

  style.left = `${node.x}in`;
  style.top = `${node.y}in`;
  return style;
}

export function transformNodeMap(badgeDesign: BadgeDesign): NodeMap {
  const nodeMap: NodeMap = {};
  for (const value of badgeDesign.content) {
    nodeMap[value.id] = value;
  }

  return nodeMap;
}

interface PlaceholderDataArgs {
  attendanceType: ProductModel;
  user: CurrentUser;
  species: string;
  badgeName: string;
  badgeArtId?: number;
  options?: OptionDataPayload;
}

export function getPlaceholderData(input: PlaceholderDataArgs): BadgeData {
  const { user, attendanceType, badgeArtId, badgeName, species, options } = input;

  return {
    data: {
      registration: {
        attendanceType: displayName(attendanceType),
        attendanceTypeId: attendanceType.id,
        badgeArt: badgeArtId!,
        badgeId: 1234,
        badgeName,
        id: 1234,
        species,
      },
      special: {
        labels: [displayName(attendanceType)],
      },
      user: {
        addressCity: user.addressCity,
        addressCountry: user.addressCountry,
        addressState: user.addressState,
        bornAt: user.bornAt?.toString() ?? null,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        preferredName: user.preferredName ?? '',
        username: user.username,
      },
    },
    options,
    registrationId: 0,
    profilePictureUrl: input.user.profilePictureUrl,
    isMinor: false,
    isVounteer: false,
    isVendor: false,
    isGuardian: false,
    isUnderage: false,
    isVendorAssistant: false,
    isStaff: false,
    roleIds: [],
    badgeArt: badgeArtId ? `/api/badgeart/${badgeArtId}/image` : undefined,
  };
}
