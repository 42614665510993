import { ValidCriterions } from '../../../../shared/orders/raffle';
import { clone } from '../../../utils';

export interface RaffleConfiguratorProps<T = {}> {
  defaultOptions?: T;
  displayName?: string;
  onConfigure(opts: Omit<ValidCriterions, 'displayName'>): void;
  onCancel(): void;
  onBack(): void;
}

interface RaffleComponent {
  [key: string]: unknown;
  componentName: string;
  description: string;
  name: string;
  icon: string;
}

export const componentList: RaffleComponent[] = [
  {
    name: 'Arithmetic',
    icon: 'calculate',
    componentName: 'MathOperation',
    description:
      'Arithmetic is a component that allows you to nest other components, combining them together using a mathematical operation, such as addition or multiplication.',
  },
  {
    name: 'Random Number',
    icon: 'shuffle',
    componentName: 'RandomNumber',
    description:
      'Random Number is a component that generates a random decimal between 0 and 1 to add randomness to your raffle.',
  },
  {
    name: 'Address Distance',
    icon: 'location_on',
    componentName: 'AddressDistance',
    description:
      'Address Distance is a component that allows you to prefer attendees closer or further away from your event, using a decimal between 0 and 1.',
  },
  {
    name: 'Previous Years Attendance',
    icon: 'calendar_today',
    componentName: 'PrevYearsAttendance',
    description:
      'Previous Years Attendance is a component that allows you to provide a bonus towards attendees that have previously attended your event.',
  },
  {
    name: 'Static Value',
    icon: 'looks_one',
    componentName: 'StaticValue',
    description:
      'Static Value is a fixed number (decimal or integer) used to modify weights of other components.',
  },
  {
    name: 'Ticket points',
    icon: 'local_activity',
    componentName: 'PointsValue',
    description: 'Give the number points directly assigned to each ticket.',
  },
];

export const criterionToComponentName: Record<string, string> = {
  addressDistance: 'AddressDistance',
  composite: 'MathOperation',
  cryptoRandom: 'RandomNumber',
  points: 'PointsValue',
  prevYearAttendance: 'PrevYearsAttendance',
  value: 'StaticValue',
};

export const componentNameToId = (name = ''): number | undefined =>
  componentList.findIndex(({ componentName }) => componentName === criterionToComponentName[name]);

class TreeIndexUndefinedError extends Error {
  public constructor(public readonly position: number[]) {
    super('Invalid tree path: currentIdx is undefined');
    this.name = 'TreeIndexUndefinedError';
  }
}

class TreeNodeNotCompositeError extends Error {
  public constructor(
    public readonly currentIdx: number,
    public readonly position: number[],
  ) {
    super('Invalid tree path: node is not composite');
    this.name = 'TreeNodeNotCompositeError';
  }
}

export function addToTree(
  tree: ValidCriterions[],
  path: number[],
  newCriterion: ValidCriterions,
  position: number[] = [],
): ValidCriterions[] {
  const currentIdx = path.shift();

  if (currentIdx === undefined) {
    if (tree.length === 0) {
      return [newCriterion];
    }

    throw new TreeIndexUndefinedError(position);
  }

  const criterion = tree[currentIdx];

  if (criterion.name !== 'composite') {
    throw new TreeNodeNotCompositeError(currentIdx, position);
  }

  if (path.length > 0) {
    criterion.options.composites = addToTree(
      clone(criterion.options.composites),
      path,
      newCriterion,
      [...position, currentIdx],
    );

    return tree;
  }

  if (!criterion.options.composites) {
    criterion.options.composites = [];
  }

  criterion.options.composites.push(newCriterion);

  return tree;
}

export function walkTree(
  tree: ValidCriterions[],
  path: number[],
  walk = true,
  action?: (currentNode: ValidCriterions[], targetIdx: number) => void,
): ValidCriterions[] {
  let currentNode = tree;
  const position: number[] = [];
  const targetPath = clone(path);
  for (let idx = targetPath.shift(); idx !== undefined; idx = targetPath.shift()) {
    const node = currentNode[idx];
    position.push(idx);
    if (targetPath.length === 0) {
      if (walk) {
        return [node];
      }

      action!(currentNode, idx);
      break;
    }

    if (!node || node.name !== 'composite') {
      throw new TreeNodeNotCompositeError(idx, position);
    }

    currentNode = node.options.composites;
  }

  return tree;
}
