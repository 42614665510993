import React, { Dispatch, FC, useCallback, useMemo, useReducer } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import {
  FormNodeLinkTermsAgreementModel,
  FormNodeTermsAgreementModel,
  FormNodeType,
  FormPageModel,
  FormSectionModel,
} from '../../../shared/kiosk';
import { OptionDataValue } from '../../../shared/options';
import { ProductModel, ProductOptionInput } from '../../../shared/orders';
import { ConRegistrationForm, RegistrationUpsert } from '../../../shared/registration/model';
import { CurrentUser } from '../../../shared/user/base';
import { AttendanceFlags, ProductOptionViewer } from '../../components';
import { FormNodeLinkTermsAgreement } from '../../components/formElements/models/FormNodeLinkTermsAgreement';
import { FormNodeMarkdown } from '../../components/formElements/models/FormNodeMarkdown';
import { FormNodeTermsAgreement } from '../../components/formElements/models/FormNodeTermsAgreement';
import { ProfilePictureEditor } from '../../containers/account/profilePictureEditor';
import { RegistrationInfo } from '../../models';
import { ActionType, store } from '../../services';
import { handleUnknownOptionError, isLogicError, useForm, useUser } from '../../utils';
import { captureError, LogicError } from '../../utils/errorHandling';
import { generateRandomName } from '../../utils/nameGenerator';
import { cleanupOptions } from '../../utils/options';
import { BadgePreview } from './BadgePreview';
import { EmergencyContactDynamic } from './EmergencyContactInfo';
import {
  FormActionType,
  FormContext,
  FormStateOptionConfig,
  FormStateRegistration,
  FormStateType,
} from './FormReducer';
import { RandomizeBadgeInfoButton } from './RandomizeBadge';

interface ConventionFormProps {
  readonly attendanceType: ProductModel;
  readonly registration?: RegistrationInfo;
  readonly user: CurrentUser;
  readonly regForm: ConRegistrationForm;
  readonly askForChildren?: boolean;
  readonly hasChildren?: boolean;
  onSuccess(hasChildren?: boolean): void;
}

function getRegistrationUpsert(state: FormStateRegistration): RegistrationUpsert {
  return {
    attendanceTypeId: state.attendanceTypeId,
    badgeName: state.badgeName ?? '',
    emergencyContactName1: state.emergencyContactName1,
    emergencyContactName2: state.emergencyContactName2,
    emergencyContactPhone1: state.emergencyContactPhone1,
    emergencyContactPhone2: state.emergencyContactPhone2,
    flags: state.enabledFlags,
    options: state.options,
    badgeArtId: state.badgeArtId,
    profilePicture: state.profilePicture,
  };
}

export const RegistrationDynamicInfoForm: FC<ConventionFormProps> = ({
  registration: conreg,
  attendanceType,
  user,
  regForm,
  onSuccess,
  hasChildren,
  askForChildren,
}) => {
  const isPaidAttendance = !!conreg?.paidOrderItem;
  const initialState = useMemo<FormStateRegistration>(() => {
    return {
      attendanceTypeId: attendanceType.id,
      badgeArtId: conreg?.badgeArtId,
      badgeName: conreg?.badgeName ?? '',
      // TODO: Store checked aggregements in the database
      checkedAgreements: conreg ? ['conventionTerms'] : [],
      emergencyContactName1: conreg?.emergencyContactName1 ?? null,
      emergencyContactName2: conreg?.emergencyContactName2 ?? null,
      emergencyContactPhone1: conreg?.emergencyContactPhone1 ?? null,
      emergencyContactPhone2: conreg?.emergencyContactPhone2 ?? null,
      enabledFlags: conreg?.flags ?? [],
      flags: regForm.flags,
      hasChildren,
      options: cleanupOptions(
        [...regForm.options, ...attendanceType.options],
        conreg?.options ?? {},
      ),
      type: 'registration',
    };
  }, [conreg, regForm, hasChildren, attendanceType]);

  const reducer = useCallback(
    (old: FormStateRegistration, action: FormActionType) => {
      switch (action.type) {
        case 'updateOption': {
          return {
            ...old,
            options: {
              ...old.options,
              [action.optionId.toString()]: action.value,
            },
          } as FormStateRegistration;
        }

        case 'setProfilePicture': {
          return {
            ...old,
            profilePicture: action.file,
          } as FormStateRegistration;
        }

        case 'randomizeBadgeInfo': {
          const speciesOption = regForm.options.find(
            (t) => t.fromRegistration && t.type === 'text',
          );

          const species = (old.options[speciesOption!.id] as string) ?? '';
          const random = generateRandomName({ badgeName: old.badgeName, species });
          return {
            ...old,
            badgeName: random.badgeName,
            options: speciesOption
              ? {
                  ...old.options,
                  [speciesOption.id]: random.species,
                }
              : old.options,
          };
        }

        case 'setEmergencyContact': {
          return {
            ...old,
            emergencyContactName1: action.name1 ?? old.emergencyContactName1,
            emergencyContactName2: action.name2 ?? old.emergencyContactName2,
            emergencyContactPhone1: action.phone1 ?? old.emergencyContactPhone1,
            emergencyContactPhone2: action.phone2 ?? old.emergencyContactPhone2,
          } as FormStateRegistration;
        }

        case 'updateFlags': {
          return {
            ...old,
            enabledFlags: action.flags,
          } as FormStateRegistration;
        }

        case 'setBadgeName': {
          return {
            ...old,
            badgeName: action.value ?? old.badgeName,
          } as FormStateRegistration;
        }

        case 'setPolicyChecked': {
          if (action.checked) {
            return {
              ...old,
              checkedAgreements: [...old.checkedAgreements, action.policy],
            } as FormStateRegistration;
          }

          return {
            ...old,
            checkedAgreements: old.checkedAgreements.filter((t) => t !== action.policy),
          } as FormStateRegistration;
        }

        case 'toggleChildren': {
          return {
            ...old,
            hasChildren: !old.hasChildren,
          } as FormStateRegistration;
        }
      }
    },
    [regForm],
  );

  const [formState, dispatch] = useReducer(reducer, initialState);

  const context = useMemo<FormContext>(() => {
    let other: ProductOptionInput[] = [...regForm.options, ...attendanceType.options];
    const optionConfig: Record<string, FormStateOptionConfig> = {};
    const speciesOption = regForm.options.find((t) => t.fromRegistration && t.type === 'text');

    for (const option of [...regForm.options, ...attendanceType.options]) {
      let disabled = false;

      if (isPaidAttendance) {
        if (option.type === 'currency') {
          disabled = true;
        }

        if (option.type === 'select' || option.type === 'multi') {
          disabled = !!option.selectValues?.some((t) => t.priceModifier > 0);
        }
      }

      optionConfig[option.id] = {
        disabled,
      };
    }

    for (const section of regForm.formLayout!.sections) {
      for (const node of section.nodes) {
        if (node.type === 'option') {
          other = other.filter((t) => t.id !== node.optionId);
        }
      }
    }

    return {
      badgeDesign: regForm.badgeDesign,
      flags: regForm.flags,
      options: regForm.options,
      otherOptions: other,
      product: attendanceType,
      optionConfig,
      speciesOptionId: speciesOption?.id,
    };
  }, [regForm, isPaidAttendance, attendanceType]);

  const form = useForm(async () => {
    let policyChecked = true;
    const { checkedAgreements } = formState;

    for (const section of regForm.formLayout!.sections) {
      for (const node of section.nodes) {
        if (node.type === 'termsAgreement' && !checkedAgreements.includes(node.termsKey)) {
          policyChecked = false;
        }

        if (node.type === 'linkTermsAgreement' && !checkedAgreements.includes('convention')) {
          policyChecked = false;
        }
      }
    }

    if (!policyChecked) {
      toast.error("You must accept the convention's policy");
      return;
    }

    const registration = getRegistrationUpsert(formState);

    try {
      if (conreg) {
        await api.updateRegistration(conreg.id, registration);
      } else {
        await api.createRegistration(user.id, registration);
      }
    } catch (error) {
      if (isLogicError(error, LogicError.UnknownProductOption)) {
        handleUnknownOptionError(error, context.otherOptions);
      } else {
        captureError(error as Error);
      }
    }

    // For updating the profile picture, if it has changed
    const activeUser = await api.getActiveUser();
    store.dispatch({
      type: ActionType.ProfileUpdate,
      user: activeUser,
    });

    onSuccess(formState.hasChildren);
  }, [conreg, user.id, formState, onSuccess, regForm]);

  return (
    <form onSubmit={form.onSubmit} style={{ width: '100%' }}>
      <Row className="justify-content-center">
        <Col lg={10} xs={12}>
          <Row id="registrationForm">
            <RegistrationPageRenderer
              askForChildren={askForChildren}
              context={context}
              dispatch={dispatch}
              formState={formState}
              page={regForm.formLayout!}
            />
          </Row>
        </Col>
        <Col className="margin-top-10" lg={6} xs={12}>
          <Button block color="primary" disabled={form.saving} id="submitForm" type="submit">
            {conreg ? 'Update Registration' : 'Register'}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

interface RegistrationPageRendererProps {
  readonly page: FormPageModel;
  readonly context: FormContext;
  readonly formState: FormStateRegistration;
  readonly dispatch: Dispatch<FormActionType>;
  readonly askForChildren?: boolean;
}

const RegistrationPageRenderer: FC<RegistrationPageRendererProps> = ({
  page,
  context,
  formState,
  dispatch,
  askForChildren,
}) => {
  return (
    <>
      {page.sections.map((section) => {
        return (
          <RegistrationSectionRenderer
            askForChildren={askForChildren}
            context={context}
            dispatch={dispatch}
            formState={formState}
            key={section.id}
            section={section}
          />
        );
      })}
    </>
  );
};

interface RegistrationSectionRendererProps {
  readonly section: FormSectionModel;
  readonly context: FormContext;
  readonly formState: FormStateRegistration;
  readonly dispatch: Dispatch<FormActionType>;
  readonly askForChildren?: boolean;
}

const RegistrationSectionRenderer: FC<RegistrationSectionRendererProps> = ({
  section,
  context,
  formState,
  dispatch,
  askForChildren,
}) => {
  return (
    <Col
      className="margin-bottom-10"
      id="badgeDesign"
      lg={section.size === 'large' ? 12 : 6}
      xs={12}
    >
      <Card>
        <CardHeader>{section.name}</CardHeader>
        <CardBody>
          {section.nodes.map((node) => {
            return (
              <RegistrationNodeRenderer
                askForChildren={askForChildren}
                context={context}
                dispatch={dispatch}
                formState={formState}
                key={node.id}
                node={node}
              />
            );
          })}
        </CardBody>
      </Card>
    </Col>
  );
};

interface RegistrationNodeRendererProps {
  readonly node: FormNodeType;
  readonly context: FormContext;
  readonly formState: FormStateRegistration;
  readonly dispatch: Dispatch<FormActionType>;
  readonly askForChildren?: boolean;
}

const RegistrationNodeRenderer: FC<RegistrationNodeRendererProps> = ({
  node,
  context,
  formState,
  dispatch,
  askForChildren,
}) => {
  switch (node.type) {
    case 'text': {
      return <>{node.text}</>;
    }

    case 'randomizeButton': {
      return <RandomizeBadgeInfoButton dispatch={dispatch} />;
    }

    case 'option': {
      const option = context.options.find((t) => t.id === node.optionId);

      if (!option) {
        if (node.optionId === 'badgeName') {
          return <BadgeNameInput dispatch={dispatch} state={formState} />;
        }

        return null;
      }

      return (
        <DynamicOptionRenderer
          context={context}
          dispatch={dispatch}
          option={option}
          state={formState}
        />
      );
    }

    case 'profilePicture': {
      return (
        <ProfilePictureInput
          dispatch={dispatch}
          ignoreGravatar={node.ignoreGravatar ?? false}
          title={node.title}
        />
      );
    }

    case 'divider': {
      return <hr />;
    }

    case 'badgePreview': {
      return <BadgePreview context={context} state={formState} />;
    }

    case 'emergencyContacts': {
      return <EmergencyContactDynamic dispatch={dispatch} state={formState} />;
    }

    case 'otherOptions': {
      return (
        <DynamicOptionViewerList
          context={context}
          dispatch={dispatch}
          options={context.otherOptions}
          state={formState}
        />
      );
    }

    case 'attendanceFlags': {
      return (
        <AttendanceFlagsRenderer
          askForChildren={askForChildren}
          context={context}
          dispatch={dispatch}
          state={formState}
        />
      );
    }

    case 'attendanceType': {
      throw new Error('Not implemented yet: "attendanceType" case');
    }

    case 'login': {
      throw new Error('Not implemented yet: "login" case');
    }

    case 'cart': {
      throw new Error('Not implemented yet: "cart" case');
    }

    case 'linkTermsAgreement': {
      const linkTermsNode = node;
      return (
        <DynamicFormNodeLinkTermsAgreement
          dispatch={dispatch}
          node={linkTermsNode}
          state={formState}
        />
      );
    }

    case 'markdown': {
      const markdownNode = node;
      return <FormNodeMarkdown {...markdownNode} />;
    }

    case 'pii': {
      throw new Error('Not implemented yet: "pii" case');
    }

    case 'subheader': {
      throw new Error('Not implemented yet: "subheader" case');
    }

    case 'termsAgreement': {
      const termsNode = node;
      return (
        <DynamicFormNodeTermsAgreement dispatch={dispatch} node={termsNode} state={formState} />
      );
    }

    case 'oauthLogin': {
      throw new Error('Not implemented yet: "oauthLogin" case');
    }

    case 'submit': {
      throw new Error('Not implemented yet: "submit" case');
    }

    default: {
      return <i>UnhandledNode: {JSON.stringify(node)}</i>;
    }
  }
};

interface BadgeNameInputProps {
  readonly state: FormStateType;
  readonly dispatch: Dispatch<FormActionType>;
}

const BadgeNameInput: FC<BadgeNameInputProps> = ({ state, dispatch }) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'setBadgeName', value: event.target.value });
    },
    [dispatch],
  );

  if (state.type !== 'registration') {
    return null;
  }

  return (
    <FormGroup>
      <Label for="badgeName">Badge Name</Label>
      <Input
        id="badgeName"
        maxLength={32}
        name="badgeName"
        onChange={onChange}
        value={state.badgeName}
      />
    </FormGroup>
  );
};

interface AttendanceFlagsRendererProps {
  readonly state: FormStateType;
  readonly context: FormContext;
  readonly dispatch: Dispatch<FormActionType>;
  readonly askForChildren?: boolean;
}

const AttendanceFlagsRenderer: FC<AttendanceFlagsRendererProps> = ({
  state,
  context,
  dispatch,
  askForChildren,
}) => {
  const onUpdate = useCallback(
    (flags: number[]) => dispatch({ type: 'updateFlags', flags }),
    [dispatch],
  );

  const toggleChildren = useCallback(() => dispatch({ type: 'toggleChildren' }), [dispatch]);

  if (state.type !== 'registration' || !context.flags) {
    return null;
  }

  return (
    <FormGroup>
      <AttendanceFlags
        askForChildren={askForChildren}
        enabledFlags={state.enabledFlags}
        flags={context.flags}
        hasChildren={state.hasChildren}
        onUpdate={onUpdate}
        toggleChildren={toggleChildren}
      />
    </FormGroup>
  );
};

interface ProfilePictureInputProps {
  readonly title?: string;
  readonly ignoreGravatar: boolean;
  readonly dispatch: Dispatch<FormActionType>;
}

const ProfilePictureInput: FC<ProfilePictureInputProps> = ({ dispatch, title, ignoreGravatar }) => {
  const user = useUser()!;

  return (
    <Row className="justify-content-center text-center">
      <Col lg={12} xs={12}>
        <ProfilePictureEditor
          ignoreGravatar={ignoreGravatar}
          inputUser={user}
          onChange={(file) => {
            dispatch({ type: 'setProfilePicture', file });
          }}
          title={title}
        />
      </Col>
    </Row>
  );
};

interface DynamicOptionRendererProps {
  readonly option: ProductOptionInput;
  readonly state: FormStateType;
  readonly context: FormContext;
  readonly dispatch: Dispatch<FormActionType>;
}

const DynamicOptionRenderer: FC<DynamicOptionRendererProps> = ({
  context,
  option,
  state,
  dispatch,
}) => {
  const onOptionChange = useCallback(
    (id: number, value: OptionDataValue) => {
      dispatch({
        type: 'updateOption',
        optionId: id,
        value,
      });
    },
    [dispatch],
  );

  const optionState = state.options[option.id];
  const optionConfig = context.optionConfig[option.id];

  return (
    <ProductOptionViewer
      disabled={optionConfig?.disabled}
      onChange={onOptionChange}
      option={option}
      value={optionState}
    />
  );
};

interface DynamicOptionsViewerProps {
  readonly context: FormContext;
  readonly options?: ProductOptionInput[];
  readonly state: FormStateType;
  readonly dispatch: Dispatch<FormActionType>;
}

const DynamicOptionViewerList: FC<DynamicOptionsViewerProps> = ({
  state,
  options,
  dispatch,
  context,
}) => {
  if (!options) {
    return null;
  }

  return (
    <Row>
      {options.map((o) => {
        return (
          <Col key={o.id} lg={6} xs={12}>
            <DynamicOptionRenderer context={context} dispatch={dispatch} option={o} state={state} />
          </Col>
        );
      })}
    </Row>
  );
};

interface DynamicFormNodeLinkTermsAgreementProps {
  readonly node: FormNodeLinkTermsAgreementModel;
  readonly state: FormStateType;
  readonly dispatch: Dispatch<FormActionType>;
}

const DynamicFormNodeLinkTermsAgreement: FC<DynamicFormNodeLinkTermsAgreementProps> = ({
  node,
  state,
  dispatch,
}) => {
  const setPolicyChecked = useCallback(
    (checked: boolean) => {
      dispatch({ type: 'setPolicyChecked', checked, policy: 'convention' });
    },
    [dispatch],
  );

  return (
    <FormNodeLinkTermsAgreement
      {...node}
      checked={state.checkedAgreements.includes('convention')}
      setChecked={setPolicyChecked}
    />
  );
};

interface DynamicFormNodeTermsAgreementProps {
  readonly node: FormNodeTermsAgreementModel;
  readonly state: FormStateType;
  readonly dispatch: Dispatch<FormActionType>;
}

const DynamicFormNodeTermsAgreement: FC<DynamicFormNodeTermsAgreementProps> = ({
  node,
  state,
  dispatch,
}) => {
  const setPolicyChecked = useCallback(
    (checked: boolean) => {
      dispatch({ type: 'setPolicyChecked', checked, policy: node.termsKey });
    },
    [dispatch],
  );

  return (
    <FormNodeTermsAgreement
      {...node}
      checked={state.checkedAgreements.includes(node.termsKey)}
      setChecked={setPolicyChecked}
    />
  );
};
