import { APIError } from '@conventioncatcorp/common-fe';
import React, { useCallback, useEffect, useState } from 'react';
import { Loading, LoadingError } from '../components';
import { LoadingState } from './LoadingState';

interface FetcherResult<T> {
  data: T | undefined;
  state: LoadingState;
  complete: boolean;
  error: unknown;
  refresh: () => void;
}

interface FetcherProps<T> {
  readonly result: Pick<FetcherResult<T>, 'error' | 'state'>;
  readonly inline?: boolean;
  readonly children?: React.ReactElement;
}

export const Fetcher = <T,>({
  result,
  inline,
  children,
}: FetcherProps<T>): React.ReactElement | null => {
  if (result.state === LoadingState.Loading) {
    return <Loading inline={inline} />;
  }

  if (result.state === LoadingState.Error) {
    const { error } = result;
    let errorMessage = 'Failed to load';

    if (error instanceof APIError) {
      const { errors } = error.apiResponse;
      if (errors.resource) {
        errorMessage = `${errors.resource.name} not found`;
      }

      if (errors.access) {
        errorMessage = `Missing permissions: ${errors.access.permissions?.join(', ')}`;
      }
    }

    return <LoadingError errorText={errorMessage} />;
  }

  if (!children) {
    return null;
  }

  return children;
};

export function useFetcher<T>(
  effect: () => Promise<T>,
  deps: readonly unknown[] = [],
): FetcherResult<T> {
  const [refreshToken, setRefreshToken] = useState(0);
  const [data, setData] = useState<T | undefined>(undefined);
  const [state, setState] = useState<LoadingState>(LoadingState.Loading);
  const [error, setError] = useState<unknown>();

  const refresh = useCallback((): void => {
    setRefreshToken(refreshToken + 1);
  }, [refreshToken]);

  useEffect(() => {
    let ignore = false;

    async function fetchData(): Promise<void> {
      setState(LoadingState.Loading);
      setError(undefined);
      try {
        const result = await effect();
        if (!ignore) {
          setData(result);
          setState(LoadingState.Done);
        }
      } catch (error_) {
        if (!ignore) {
          setError(error_);
          setState(LoadingState.Error);
        }
      }
    }

    // Let the callback fire asyncronously
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
    // The callback is used a destructor - this avoids race condtions
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, refreshToken]);

  return {
    complete: state === LoadingState.Done,
    data,
    state,
    error,
    refresh,
  };
}
