import React, { FC, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Badge, Card, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import { OrderSearch, OrderSearchFilters } from '../../../../../shared/orders/search';
import { Breadcrumb, Loading, PaginationBar, UserStateComponent } from '../../../../components';
import { Fetcher, toggleElementInArray, useFetcher, useQuery } from '../../../../utils';
import { LoadingState } from '../../../../utils/LoadingState';
import { OrderListItem } from './components';

const filtersOptions: { name: string; key: OrderSearchFilters }[] = [
  { name: 'Paid', key: 'paid' },
  { name: 'Unpaid', key: 'unpaid' },
  { name: 'Cancelled', key: 'cancelled' },
  { name: 'Previously Refunded', key: 'previously_refunded' },
  { name: 'Requires Fulfillment', key: 'unfulfilled' },
];

export const OrderList: FC = () => {
  const params = useQuery();
  const page = Number.parseInt(params.get('page') ?? '0', 10);
  const filterParam = params.get('filters') ?? '';

  const search = useMemo<OrderSearch>(() => {
    const filters = filterParam.split(',').filter((t) => t.length > 0);
    return {
      filters: filters as OrderSearchFilters[],
      page,
    };
  }, [page, filterParam]);

  return (
    <UserStateComponent>
      <Row className="justify-content-center">
        <Col style={{ marginTop: '.15em', marginBottom: '2em' }} xs={12}>
          <Breadcrumb items={[{ active: true, text: 'Orders' }]} />
        </Col>
      </Row>
      <OrderListComponent options={filtersOptions} search={search} />
    </UserStateComponent>
  );
};

interface OrderListComponentProps {
  readonly options: { name: string; key: OrderSearchFilters }[];
  readonly search: OrderSearch;
}

export const OrderListComponent: FC<OrderListComponentProps> = ({ options, search }) => {
  const location = useLocation();
  const history = useHistory();

  const orders = useFetcher(async () => {
    // return await api.getOrders(filters, page);
    return await api.getOrders(search);
  }, [search]);

  function updateUrl(p: number, f: string[]): void {
    history.push(`${location.pathname}?page=${p}&filters=${f.join(',')}`);
  }

  if (orders.state === LoadingState.Error) {
    return <Fetcher result={orders} />;
  }

  return (
    <>
      <Row>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label>Filters</Label>
            <div className="badge-list">
              {options.map((option) => (
                <FilterButton
                  enabled={search.filters.includes(option.key)}
                  id={option.key}
                  key={option.key}
                  name={option.name}
                  onClick={() => {
                    updateUrl(search.page, toggleElementInArray(search.filters, option.key));
                  }}
                />
              ))}
            </div>
          </FormGroup>
        </Col>
        <Col md={6} xs={12}>
          <PaginationBar
            onChange={(p) => {
              updateUrl(p, search.filters);
            }}
            page={search.page}
            totalPages={Math.ceil((orders.data?.totalCount ?? 0) / 100)}
          />
        </Col>
      </Row>
      <Card className="table resultTable">
        <CardHeader>{orders.data?.totalCount} Result(s)</CardHeader>
        {orders.state === LoadingState.Loading && <Loading />}
        {orders.data?.items.map((order) => (
          <Link
            key={order.id}
            style={{ color: 'inherit', textDecoration: 'inherit' }}
            to={`/housekeeping/orders/${order.id}`}
          >
            <OrderListItem order={order} />
          </Link>
        ))}
      </Card>
    </>
  );
};

interface FilterButtonProps {
  readonly name: string;
  readonly id: string;
  readonly enabled: boolean;
  readonly onClick: () => void;
}

const FilterButton: FC<FilterButtonProps> = (props) => {
  const { name, id, enabled, onClick } = props;

  return (
    <Badge color={enabled ? 'primary' : 'secondary'} id={id} onClick={onClick}>
      {name}
    </Badge>
  );
};
