import React, { FC, Fragment } from 'react';
import { classNames } from '../utils';
import { MaterialIcon } from '.';

export enum WizardStatus {
  NotStarted,
  InProgress,
  Pending,
  Completed,
  Error,
}

export interface WizardItem {
  status: WizardStatus;
  text: string;
}

export const WizardComponent: FC<{ readonly items: WizardItem[]; readonly className?: string }> = ({
  items,
  className,
}) => {
  return (
    <div className={`wizard text-center ${className!}`}>
      {items.map((v, i) => {
        return <WizardStep id={i} key={`${v.status}-${v.text}`} value={v} />;
      })}
    </div>
  );
};

const WizardStep: FC<{ readonly id: number; readonly value: WizardItem }> = ({ id, value }) => {
  const { status, text } = value;
  return (
    <Fragment key={id}>
      {id > 0 && <div className="wizard-divider" />}
      <div
        className={classNames(
          {
            active: status === WizardStatus.InProgress,
            complete: status === WizardStatus.Completed,
            error: status === WizardStatus.Error,
            loading: status === WizardStatus.Pending,
          },
          'wizard-step',
        )}
      >
        <div className="wizard-step-number">
          <span>
            <WizardNumber id={id} status={status} />
          </span>
        </div>
        <div className="wizard-step-description">
          <span>{text}</span>
        </div>
      </div>
    </Fragment>
  );
};

const WizardNumber: FC<{ readonly id: number; readonly status: WizardStatus }> = ({
  id,
  status,
}) => {
  if (status === WizardStatus.Completed) {
    return <MaterialIcon name="check" />;
  }

  if (status === WizardStatus.Error) {
    return <MaterialIcon name="close" />;
  }

  return <>{`${id + 1}`}</>;
};
