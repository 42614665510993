import { MaterialIconText } from '@conventioncatcorp/common-fe';
import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { ProductModel } from '../../../../../shared/orders';
import * as OrderSummary from '../../../../../shared/orders/model';
import { ElementSelector, Loading } from '../../../../components';
import {
  handleUnknownOptionError,
  isLogicError,
  useConvention,
  useFetcher,
  useToggle,
  yearDiff,
} from '../../../../utils';
import { LogicError } from '../../../../utils/errorHandling';
import { LocalSearchProvider, ProductInfoBadges } from '../../products/utils';

interface OrderAddProductModalProps {
  readonly user: { bornAt: Date | null };
  readonly order: OrderSummary.Order;
  onSuccess(): void;
  onCancel(): void;
}

export const OrderAddProductModal: FC<OrderAddProductModalProps> = ({
  user,
  order,
  onCancel,
  onSuccess,
}) => {
  const [productId, setProductId] = useState<number | undefined>(undefined);
  const productList = useFetcher(async () => await api.getProducts(), []);
  const start = useConvention().startAt;
  const [confirmAge, toggleConfirmAge] = useToggle(false);
  const [confirmOutOfStock, toggleConfirmOutOfStock] = useToggle(false);
  const searchProvider = useMemo(
    () => new LocalSearchProvider(() => productList.data!),
    [productList],
  );

  const selected = productList.data?.find((t) => t.id === productId);
  const outOfStock = selected && selected.stockAvailable === 0;

  // TODO: Fix scenario where age is not provided
  const userAge = user.bornAt ? yearDiff(user.bornAt, start) : 999;
  const notInAgeRange =
    selected &&
    ((selected.minAge && userAge < selected.minAge) ||
      (selected.maxAge && userAge > selected.maxAge));

  const canSubmit =
    !!productId && (!notInAgeRange || confirmAge) && (!outOfStock || confirmOutOfStock);

  const onFailure = useCallback(
    (error: Error) => {
      if (isLogicError(error, LogicError.UnknownProductOption)) {
        handleUnknownOptionError(error, selected?.options);

        return true;
      }

      return false;
    },
    [selected],
  );

  return (
    <Modal className="modal-large" id="addProductModal" isOpen>
      <JSONForm
        method="post"
        onFailure={onFailure}
        onSuccess={onSuccess}
        path={`/api/orders/${order.id}/product`}
      >
        <ModalHeader>Add product to Order #{order.id}</ModalHeader>
        <ModalBody>
          <p>Select a product to be force-added into this cart.</p>
          <Input name="productId" type="hidden" value={productId?.toString() ?? ''} />
          {productList.data ? (
            <FormGroup>
              <Label for="product">Product Search</Label>
              <ElementSelector<ProductModel>
                id="addProductSelector"
                maxItems={1}
                searchProvider={searchProvider}
                singleSelectionIdChanged={setProductId}
              />
            </FormGroup>
          ) : (
            <Loading />
          )}
          {selected && <ProductInfoBadges product={selected} />}
          {notInAgeRange && (
            <Alert
              color="danger"
              id="ageWarning"
              onClick={toggleConfirmAge}
              style={{ cursor: 'pointer' }}
            >
              <MaterialIconText
                name={confirmAge ? 'check_box' : 'check_box_outline_blank'}
                style={{ cursor: 'pointer' }}
              >
                Confirm that the user does not meet the age restrtion. User is {userAge} years old.
              </MaterialIconText>
            </Alert>
          )}
          {outOfStock && (
            <Alert
              color="danger"
              id="outOfStockWarning"
              onClick={toggleConfirmOutOfStock}
              style={{ cursor: 'pointer' }}
            >
              <MaterialIconText
                name={confirmOutOfStock ? 'check_box' : 'check_box_outline_blank'}
                style={{ cursor: 'pointer' }}
              >
                Confirm that you are adding a product to the user cart that is out of stock.
              </MaterialIconText>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel} type="button">
            Cancel
          </Button>{' '}
          <Button color="primary" disabled={!canSubmit} id="addOrderProduct" type="submit">
            Add
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};
