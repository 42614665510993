import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { CurrentUser } from '../../../../shared/user/base';
import { PageHeader } from '../../../components';
import { Role } from '../../../models';
import { checkPermission } from '../../../utils';

export const RoleList: FC<{
  readonly user: CurrentUser;
  readonly roles: Role[];
}> = ({ user, roles }) => {
  const [createModal, setCreateModal] = useState(false);

  return (
    <>
      {createModal && (
        <Modal id="createRoleModal" isOpen toggle={() => setCreateModal(false)}>
          <JSONForm<{ id: number }>
            method="post"
            onSuccess={({ id }) => {
              setCreateModal(false);
              window.location.replace(`/housekeeping/roles/${id}`);
            }}
            path="/api/roles"
          >
            <ModalHeader toggle={() => setCreateModal(false)}>Create New Role</ModalHeader>
            <ModalBody>
              <p>Choose the role name:</p>
              <FormGroup>
                <Label for="name">Name:</Label>
                <Input id="name" name="name" />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description:</Label>
                <Input id="description" name="description" />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" id="submitNewRole">
                Create
              </Button>{' '}
              <Button color="secondary" onClick={() => setCreateModal(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </JSONForm>
        </Modal>
      )}
      <PageHeader>Roles</PageHeader>
      {checkPermission(user, 'system:permission:update') && (
        <Col style={{ textAlign: 'right', padding: '15px' }} xs={12}>
          <Button
            id="createRoleButton"
            onClick={() => {
              setCreateModal(true);
            }}
          >
            Add new role
          </Button>
        </Col>
      )}
      <Col xs={12}>
        <Card>
          <CardBody id="roleList">
            {roles.map((t) => (
              <Fragment key={t.id}>
                <Col className="clearfix-after" xs={12}>
                  <Link to={`/housekeeping/roles/${t.id}`}>
                    <div>{t.name}</div>
                  </Link>
                  <div>{t.description}</div>
                  <hr />
                </Col>
              </Fragment>
            ))}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
