import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { TemplateEditor } from './TemplateEditor';
import { TemplateList } from './TemplateList';

export const TemplateSettings: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={TemplateList} exact path={path} />
      <Route component={TemplateEditor} path={`${path}/:templateName`} />
    </Switch>
  );
};
