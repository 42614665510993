import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { UserStateComponent } from '../../../components';
import { Permission, Role } from '../../../models';
import { useUser } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { RoleEdit } from './RoleEdit';
import { RoleList } from './RoleList';

interface LoadingData {
  permissions: Permission[];
  roles: Role[];
}

const dataFetcher = async (): Promise<LoadingData> => {
  const [permissions, roles] = await Promise.all([api.getPermissions(), api.getRoles()]);
  return { permissions, roles };
};

export const RolesPage: FC = () => {
  const { path } = useRouteMatch();
  const user = useUser();

  return (
    <LoadingWrapper<LoadingData, void> dataFetcher={dataFetcher}>
      {(data, refresh) => (
        <UserStateComponent>
          <Switch>
            <Route component={() => <RoleList {...data} user={user!} />} exact path={path} />
            <Route
              path={`${path}/:id`}
              render={(props) => <RoleEdit {...data} {...props} refresh={refresh} user={user!} />}
            />
          </Switch>
        </UserStateComponent>
      )}
    </LoadingWrapper>
  );
};
