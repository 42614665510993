import React, { FC, useCallback, useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import {
  AddressDistanceCriteronOptions,
  BaseCriterionOptions,
  CompositeCriterionOptions,
  PreviousYearAttendanceCriterionOptions,
  ValidCriterionName,
  ValidCriterions,
  ValueCriterionOptions,
} from '../../../../shared/orders/raffle';
import {
  AddressDistance,
  MathOperation,
  PrevYearsAttended,
  RandomNumber,
  SelectList,
  StaticValue,
} from './components';
import { PointsValue } from './components/pointsValue';
import { componentList, componentNameToId, criterionToComponentName } from './utils';

interface Props {
  readonly criterion?: ValidCriterions;
  readonly selectedType?: ValidCriterionName;
  onFinish(criterion: ValidCriterions): void;
  onCancel(): void;
}

export const ConfigureRaffleNodeModal: FC<Props> = ({
  criterion,
  selectedType,
  onCancel,
  onFinish,
}) => {
  const [selectedComponentId, setSelectedComponentId] = useState<number | null>(
    selectedType
      ? componentList.findIndex(
          ({ componentName }) => componentName === criterionToComponentName[selectedType],
        )
      : null,
  );

  const [showComponentSelect, setShowComponentSelect] = useState(!selectedComponentId);
  const [displayName, setDisplayName] = useState<string | undefined>(criterion?.displayName);
  const onComponentSelected = useCallback((id: number) => {
    setSelectedComponentId(id);
    setShowComponentSelect(false);
  }, []);

  const onFinishFn = useCallback(
    (opts: ValidCriterions) => {
      if (criterion && opts.name === 'composite' && opts.name === criterion.name) {
        opts.options.composites = criterion.options.composites;
      }

      onFinish({ displayName, ...opts });
    },
    [criterion, displayName],
  );

  return (
    <Modal isOpen>
      <ModalHeader>{criterion ? 'Update' : 'Add New'} Raffle Component</ModalHeader>
      {showComponentSelect ? (
        <SelectList
          defaultComponentId={selectedComponentId ?? componentNameToId(criterion?.name)}
          name={displayName}
          onCancel={onCancel}
          onNameChange={setDisplayName}
          onNextClicked={onComponentSelected}
        />
      ) : (
        getComponent({
          defaultOptions: criterion?.options,
          displayName,
          id: selectedComponentId!,
          onBack: () => {
            setShowComponentSelect(true);
          },
          onCancel,
          onConfigure: onFinishFn,
        })
      )}
    </Modal>
  );
};

interface RenderComponentProps {
  defaultOptions?: BaseCriterionOptions;
  displayName?: string;
  id: number;
  onBack(): void;
  onCancel(): void;
  onConfigure(opts: ValidCriterions): void;
}

function getComponent({ id, defaultOptions, ...props }: RenderComponentProps): JSX.Element | null {
  switch (componentList[id].componentName) {
    case 'MathOperation': {
      return (
        <MathOperation defaultOptions={defaultOptions as CompositeCriterionOptions} {...props} />
      );
    }

    case 'RandomNumber': {
      return <RandomNumber {...props} />;
    }

    case 'AddressDistance': {
      return (
        <AddressDistance
          defaultOptions={defaultOptions as AddressDistanceCriteronOptions}
          {...props}
        />
      );
    }

    case 'StaticValue': {
      return <StaticValue defaultOptions={defaultOptions as ValueCriterionOptions} {...props} />;
    }

    case 'PrevYearsAttendance': {
      return (
        <PrevYearsAttended
          defaultOptions={defaultOptions as PreviousYearAttendanceCriterionOptions}
          {...props}
        />
      );
    }

    case 'PointsValue': {
      return <PointsValue {...props} />;
    }
  }

  return null;
}
