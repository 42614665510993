import React, { FC, useMemo } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

interface TimeZoneDropdownProps {
  readonly value: string;
  readonly saving: boolean;
  onChange(value: string): void;
}

const getTimeZonesWithOffsets = () => {
  const date = new Date();
  return Intl.supportedValuesOf('timeZone')
    .map((timeZone: string) => {
      const parts = new Intl.DateTimeFormat('en-us', {
        timeZone,
        timeZoneName: 'shortOffset',
      }).formatToParts(date);

      const offset = parts.find((t) => t.type === 'timeZoneName')?.value ?? 'N/A';
      return { timeZone, offset };
    })
    .sort((a, b) => a.offset.localeCompare(b.offset));
};

export const TimeZoneDropdown: FC<TimeZoneDropdownProps> = ({ value, saving, onChange }) => {
  const tzList = useMemo(() => getTimeZonesWithOffsets(), []);

  return (
    <FormGroup className="custom-control left-align margin-top-10">
      <Label for="timezone">Convention Timezone</Label>
      <Input
        disabled={saving}
        id="conventionTimeZone"
        name="timeZone"
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        type="select"
        value={value}
      >
        {tzList.map((option) => {
          return (
            <option key={option.timeZone} value={option.timeZone}>
              {`${option.offset} - ${option.timeZone}`}
            </option>
          );
        })}
      </Input>
    </FormGroup>
  );
};
