import React, { FC, useEffect, useMemo, useState } from 'react';
import { CardBody, CardHeader, FormGroup } from 'reactstrap';
import { RaffleProduct } from '../../../../../shared/orders/raffle';
import { ElementSelector } from '../../../../components';
import { LocalSearchProvider } from '../../products/utils';

interface Props {
  readonly defaultProducts?: RaffleProduct[];
  onChange(productIds: number[]): void;
}

export const ProductSelector: FC<Props> = ({ defaultProducts = [], onChange }) => {
  const [selectedProducts, setSelectedProducts] = useState<RaffleProduct[]>(defaultProducts);
  const memoizedSearchProvider = useMemo(
    () => new LocalSearchProvider(async () => await api.getProducts()),
    [],
  );

  useEffect(() => {
    onChange(selectedProducts.map(({ id }) => id));
  }, [selectedProducts]);

  return (
    <>
      <CardHeader>Associated Products</CardHeader>
      <CardBody>
        <FormGroup>
          <ElementSelector<RaffleProduct>
            defaultSelected={selectedProducts}
            id="raffleProductSelector"
            searchPlaceholder="Search products by name or ID"
            searchProvider={memoizedSearchProvider}
            selectionChanged={setSelectedProducts}
          />
        </FormGroup>
      </CardBody>
    </>
  );
};
