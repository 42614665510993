import React, { CSSProperties, FC, useState } from 'react';
import { Link, Route, RouteComponentProps, Switch, useHistory } from 'react-router-dom';
import { Button, Container, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import {
  Breadcrumb,
  BreadcrumbItemProps,
  LazyMarkdown,
  MaterialIcon,
  UserStateComponent,
} from '../../../components';
import { DateT, DateTime } from '../../../components/Date';
import { LogEntry, LogResult } from '../../../models';
import { useQuery } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { LogDetailModal } from './LogDetailModal';
import { TimePickerModal } from './TimePicker';
import { makeRelativeLogLink, TableQueryOpts, urlSearchParamsToTableOpts } from './query';

import './style.scss';

export const AuditPage: FC = () => {
  return (
    <UserStateComponent>
      <Switch>
        <Route component={ListAuditEntities} exact path="/housekeeping/auditlog" />
        <Route component={AuditEntityRoute} exact path="/housekeeping/auditlog/:entityId" />
        <Route
          component={AuditObjectRoute}
          exact
          path="/housekeeping/auditlog/:entityId/:objectId"
        />
      </Switch>
    </UserStateComponent>
  );
};

const AuditEntityRoute: FC<RouteComponentProps<{ entityId: string }>> = (props) => {
  const { entityId } = props.match.params;
  return <AuditObjectPage entityId={entityId} showBreadcrumbs />;
};

const AuditObjectRoute: FC<RouteComponentProps<{ entityId: string; objectId: string }>> = (
  props,
) => {
  const { entityId, objectId } = props.match.params;
  return <AuditObjectPage entityId={entityId} objectId={objectId} showBreadcrumbs />;
};

const ListAuditEntities: FC = () => {
  return (
    <LoadingWrapper dataFetcher={async () => await api.getAuditEntities()}>
      {(entities) => {
        return (
          <>
            <LogsBreadcrumbs />
            <div>Select a pivot to browse logs by:</div>
            <ul>
              {entities.map((entity) => {
                return (
                  <li key={entity.name}>
                    <Link to={`/housekeeping/auditlog/${entity.name}`}>{entity.name}</Link>
                  </li>
                );
              })}
            </ul>
          </>
        );
      }}
    </LoadingWrapper>
  );
};

interface AuditObjectPageProps {
  readonly entityId: string;
  readonly objectId?: string;
  readonly showBreadcrumbs?: boolean;
}

export const AuditObjectPage: FC<AuditObjectPageProps> = (props) => {
  const [pickerModal, setPickerModal] = useState(false);
  const history = useHistory();
  const params = urlSearchParamsToTableOpts(useQuery());

  function pickerModalCallback(time?: number): void {
    setPickerModal(false);

    if (time) {
      history.push(makeRelativeLogLink(params, { time }, ['reverse']));
    }
  }

  const payload = {
    entityId: props.entityId,
    ignoreTypes: params.ignore,
    objectId: props.objectId,
    offset: params.offset,
    pageSize: 30,
    reverse: params.reverse,
    time: params.time,
  };

  return (
    <>
      {pickerModal && <TimePickerModal callback={pickerModalCallback} />}
      <LoadingWrapper
        dataFetcher={async () => await api.getAuditLogs(payload)}
        key={JSON.stringify(payload)}
      >
        {(logs) => {
          const detailResult = logs.results.find((t) => t.uniqueId === params.view);

          return (
            <div>
              {detailResult && <LogDetailModal entry={detailResult} params={params} />}
              {props.showBreadcrumbs && (
                <div>
                  <LogsBreadcrumbs
                    entity={logs.entity}
                    object={props.objectId ? { name: logs.object, id: props.objectId } : undefined}
                  />
                </div>
              )}
              <div>
                {params.ignore.map((type) => {
                  return (
                    <Button
                      key={type}
                      tag={Link}
                      to={makeRelativeLogLink(params, {
                        ignore: params.ignore.filter((t) => t !== type),
                      })}
                    >
                      {type}
                    </Button>
                  );
                })}
              </div>
              <Container>
                {logs.results.map((entry) => (
                  <LogRow entry={entry} key={entry.uniqueId + entry.type} params={params} />
                ))}
              </Container>
              <LogPagination logs={logs} params={params} setPickerModal={setPickerModal} />
            </div>
          );
        }}
      </LoadingWrapper>
    </>
  );
};

interface LogPaginationProps {
  readonly logs: LogResult;
  readonly params: TableQueryOpts;
  readonly setPickerModal: (enable: boolean) => void;
}

const LogPagination: FC<LogPaginationProps> = ({ logs, params, setPickerModal }) => {
  return (
    <Pagination aria-label="Page navigation example">
      <PaginationItem disabled={!logs.prevPage}>
        <PaginationLink
          first
          tag={Link}
          to={makeRelativeLogLink(params, {}, ['reverse', 'time'])}
        />
      </PaginationItem>
      <PaginationItem disabled={!logs.prevPage} title={new Date(logs.prevPage!).toDateString()}>
        <PaginationLink
          previous
          tag={Link}
          to={makeRelativeLogLink(params, { time: logs.prevPage, reverse: true })}
        />
      </PaginationItem>
      <PaginationItem disabled={!logs.results[0]}>
        <PaginationLink
          href="#"
          id="auditSetTimeModal"
          onClick={(e) => {
            e.preventDefault();
            setPickerModal(true);
          }}
        >
          {logs.results[0] ? (
            <DateT
              options={{ weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }}
              value={logs.results[0].createdAt}
            />
          ) : (
            <>(Empty)</>
          )}
        </PaginationLink>
      </PaginationItem>
      <PaginationItem
        disabled={!logs.nextPage}
        id="auditNextPage"
        title={new Date(logs.nextPage!).toDateString()}
      >
        <PaginationLink
          next
          tag={Link}
          to={makeRelativeLogLink(params, { time: logs.nextPage }, ['reverse'])}
        />
      </PaginationItem>
      <PaginationItem disabled={!logs.nextPage}>
        <PaginationLink
          last
          tag={Link}
          to={makeRelativeLogLink(params, { reverse: true }, ['time'])}
        />
      </PaginationItem>
    </Pagination>
  );
};

interface LogRowProps {
  readonly entry: LogEntry;
  readonly params: TableQueryOpts;
}

const LogRow: FC<LogRowProps> = ({ entry, params }) => {
  const { highlight } = params;
  const isSupportEntry = entry.type.startsWith('support-');
  const styles: CSSProperties = {
    border: highlight === entry.uniqueId ? '2px solid black' : undefined,
    backgroundColor: isSupportEntry ? '#ffefef' : undefined,
  };

  return (
    <div className="log-row pl-3" id={entry.uniqueId} style={styles}>
      <div className="log-row-timestamp">
        <div style={{ fontSize: '12px' }}>
          <DateTime value={entry.createdAt} />
          <span style={{ verticalAlign: 'middle', paddingLeft: '10px', float: 'right' }}>
            <Link
              className="remove-audit-type"
              to={makeRelativeLogLink(params, { ignore: [...params.ignore, entry.type] })}
            >
              <MaterialIcon name="visibility_off" small style={{ color: '#cc3333' }} />
            </Link>
            <Link to={makeRelativeLogLink(params, { view: entry.uniqueId })}>
              <MaterialIcon name="info" small />
            </Link>
          </span>
        </div>
      </div>
      <div className="log-row-body">
        <div style={{ marginLeft: '10px', display: 'inline-flex' }}>
          {isSupportEntry && <MaterialIcon className="mr-2" name="support_agent" small />}
          {entry.message ? (
            <LazyMarkdown source={entry.message} />
          ) : (
            <p>
              <Link to={`/housekeeping/auditlog/${entry.entityId}/${entry.objectId}`}>
                {entry.entityId}/{entry.objectId}
              </Link>{' '}
              - {entry.type} - {JSON.stringify(entry.meta)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const LogsBreadcrumbs: FC<{
  readonly entity?: string;
  readonly object?: { name: string; id: string };
}> = (props) => {
  const { entity, object } = props;
  const items: BreadcrumbItemProps[] = [];

  items.push({ active: !entity, text: 'Audit Logs', url: '/housekeeping/auditlog' });

  if (entity) {
    items.push({
      active: !object,
      text: entity,
      url: `/housekeeping/auditlog/${entity}`,
    });

    if (object) {
      items.push({
        active: true,
        text: object.name,
        url: `/housekeeping/auditlog/${entity}/${object.id}`,
      });
    }
  }

  return <Breadcrumb items={items} />;
};
