import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { PageHeader, UserStateComponent } from '../../../components';
import { Fetcher, useFetcher } from '../../../utils';
import { CategoryItem, CreateCategory } from '.';

export const ProductCategoriesContainer: FC = () => {
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);

  const fetcher = useFetcher(async () => {
    return await api.getProductCategories();
  });

  const history = useHistory();

  const categories = useMemo(() => {
    if (!fetcher.data) {
      return [];
    }

    return fetcher.data.filter((category) => !category.isVendor);
  }, [fetcher]);

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return (
    <UserStateComponent>
      <PageHeader>Product Categories</PageHeader>
      {isCreatingCategory && (
        <>
          <div>
            <Button
              color="secondary"
              onClick={() => {
                setIsCreatingCategory(!isCreatingCategory);
              }}
              outline
            >
              Back to Categories
            </Button>
          </div>
          <hr />
          <div>
            <Row className="justify-content-center">
              <CreateCategory />
            </Row>
          </div>
        </>
      )}
      {!isCreatingCategory && (
        <>
          <div>
            <Button
              color="primary"
              id="createCategory"
              onClick={() => {
                setIsCreatingCategory(!isCreatingCategory);
              }}
            >
              Create New Category
            </Button>
          </div>
          <hr />
          <div>
            <Row className="justify-content-center">
              {categories.map((category) => (
                <Col
                  className="margin-bottom-10"
                  id={`category${category.id}`}
                  key={category.id}
                  lg={8}
                  xs={12}
                >
                  <CategoryItem
                    category={category}
                    onUpdate={fetcher.refresh}
                    redirectFn={(url) => {
                      history.push(`/housekeeping/products/${url}`);
                    }}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </UserStateComponent>
  );
};
