import React, { FC } from 'react';
import { Alert, Button, Card, CardBody, Collapse, Input } from 'reactstrap';
import { useToggle } from '../../../../utils';

export const KeyView: FC<{ readonly value: string }> = ({ value }) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <>
      <Button color="primary" onClick={toggle} style={{ marginBottom: '1rem' }}>
        View verification key
      </Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <Alert color="warning">
              Use this public key to verify the authenticity of requests coming from concat.
              <br />
              You should retrieve and cache the key until verification fails. It can be retrieved
              from <code>/api/webhooks/key</code>. For more details see{' '}
              <a href="https://jwt.io/" referrerPolicy="no-referrer">
                jwt.io
              </a>
            </Alert>
            <Input defaultValue={value} readOnly style={{ height: '150px' }} type="textarea" />
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
};
