import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { OptionInternal, OptionSource } from '../../../../shared/options';
import { OptionEditor } from '../../../components/OptionEditor';
import { captureError } from '../../../utils/errorHandling';

interface CreateOptionProps {
  readonly source: OptionSource;
  onCreate(option: OptionInternal): void;
}

export const CreateOption: FC<CreateOptionProps> = ({ source, onCreate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [option, setOption] = useState<OptionInternal>({
    id: 0,
    max: null,
    min: 0,
    name: '',
    type: 'text',
    description: '',
    displayOnCashier: false,
    source,
    selectValues: [],
  });

  const create = useCallback(async () => {
    try {
      const newOption = await api.createOption(source, {
        name: option.name,
        max: option.max,
        min: option.min,
        type: option.type,
        selectValues: option.selectValues,
        displayOnCashier: option.displayOnCashier,
        source,
      });

      onCreate(newOption);
      toast.success('New option has been created');
    } catch (error) {
      captureError(error as Error);
    }
  }, [option, source, onCreate]);

  return (
    <Card id="newOptionForm">
      <CardBody>
        <h5>Creating Option</h5>
        <hr />
        <OptionEditor option={option} setIsEditing={setIsEditing} setOption={setOption} />
        <Row>
          <Col xs={12}>
            <Button block color="primary" disabled={isEditing} id="submitOption" onClick={create}>
              Create
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
