import React, { FC } from 'react';
import { checkPermission, useUser } from '../../../utils';
import { VolunteerFormComponent } from '../../apply/volunteer/form';

interface TabDetailsProps {
  // User being modified
  readonly userData: {
    id: number;
    phone?: string;
    email?: string;
  };
}

export const UserVolunteerTab: FC<TabDetailsProps> = ({ userData }) => {
  const user = useUser()!;
  return (
    <VolunteerFormComponent isAdmin={checkPermission(user, 'volunteer:update')} user={userData} />
  );
};
