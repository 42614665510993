import React, { FC } from 'react';
import { Input, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { ProductModel } from '../../../../shared/orders';
import { MaterialIcon } from '../../../components';

export const tableTypeArchiveWarning = (
  idx: number,
  tableType: Pick<ProductModel, 'deletedAt'>,
): JSX.Element | undefined => {
  if (!tableType.deletedAt) {
    return;
  }

  return (
    <>
      &nbsp;
      <span id={`dealer${idx}TableTypeArchived`}>
        <MaterialIcon name="warning" type="warning" />
      </span>
      <UncontrolledPopover
        placement="bottom"
        target={`dealer${idx}TableTypeArchived`}
        trigger="hover"
      >
        <PopoverBody>
          <p>This table type has been archived and can no longer be assigned if changed.</p>
          <p>A new currently active table type should be assigned this dealer, where possible.</p>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

interface EditEntryProps {
  readonly displayName: string;
  readonly name: string;
  readonly value?: string;
  readonly edit: boolean;
  renderEdit?(): JSX.Element;
}

export const EditEntry: FC<EditEntryProps> = ({ displayName, edit, renderEdit, value, name }) => {
  return (
    <tr>
      <th scope="row">{displayName}</th>
      <td id={`dealer-${name}`}>
        {edit && !renderEdit && <Input defaultValue={value ?? ''} id={name} name={name} />}
        {edit && renderEdit?.()}
        {!edit && <>{value}</>}
      </td>
    </tr>
  );
};
