import React, { FC } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { ProductModel } from '../../../../../../shared/orders/product';
import { MaterialIcon } from '../../../../../components';
import { ImageListItemComponent } from './ImageListItemComponent';

interface ImageListProps {
  readonly product: ProductModel;
  onAdd(): void;
  onUpdate(): void;
}

export const ImageListComponent: FC<ImageListProps> = ({
  product: { id, images, canAddMoreImages, deletedAt },
  onAdd,
  onUpdate,
}) => {
  return (
    <Card className="margin-top-10" id="productImages">
      <CardHeader>
        <div className="card-sub-header">Product Images</div>
        {!deletedAt && canAddMoreImages && (
          <div className="float-right">
            <Button color="success" id="create" onClick={onAdd} title="Create">
              <MaterialIcon name="add" />
            </Button>
          </div>
        )}
      </CardHeader>
      <CardBody>
        <Row>
          {images.length === 0 && (
            <Col xs={12}>
              <i>There are no images for this product.</i>
            </Col>
          )}
          {images.map((image) => (
            <ImageListItemComponent
              image={image}
              key={image.id}
              onUpdate={onUpdate}
              productId={id}
            />
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};
